'use strict';
// @flow

import type { IConta } from './types';
import { UnexpectedApiResponseError } from './errors';
import { fetchJSON } from './fetch';
import { AdminEmpresasResource } from './admin/empresas';
import { Resource } from './resource';

export * from './types';

export default {
  whoami() {
    return fetchJSON('/whoami');
  },

  /**
   * Desconecta o usuário do sistema.
   *
   * @returns {Promise<string>} a URL para onde o navegador deve ser redirecionado.
   */
  async logout() {
    // HACK: chamamos o fetch desta maneira para garantir que o Devise irá considerar uma navegação
    // e preencher o "flash" com a mensagem correta.
    const r = await fetch('/users/sign_out', {
      credentials: 'include', method: 'delete', headers: { 'Accept': '*/*', 'X-Skip-CSRF': 'true' },
    });

    if (!r.ok)
      throw new UnexpectedApiResponseError('Não foi possível desconectar seu usuário', await r.text());

    return '/';
  },

  counters(counter : string) : Promise<{ [k : string] : number }> {
    return fetchJSON(`/app/counters?counter[]=${ counter }`);
  },

  async municipios() : Promise<{ total : number, items : Array<{ id : string, nome : string, uf : { sigla : string } }> }> {
    const r = await fetchJSON('/app/municipios');
    if (!r)
      throw new UnexpectedApiResponseError('Não foi possível obter a lista de municípios', r);

    return r;
  },

  admin: {
    contas: (new Resource('/app/admin/contas') : Resource<IConta>),
    empresas: new AdminEmpresasResource('/app/admin/empresas'),
  },
};
