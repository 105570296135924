// @flow
'use strict';

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, Dispatch, State } from 'lib/types';
import * as filtroLivre from './filtro_livre';
import request from 'lib/request';
import { ajaxError } from '../alerts';
import { formataQuery } from '../../lib/formata_query';

const ABRE_BUSCA_ESTRUTURADA  = 'nfse/filtroEstruturado/ABRE_BUSCA_ESTRUTURADA',
      FECHA_BUSCA_ESTRUTURADA = 'nfse/filtroEstruturado/FECHA_BUSCA_ESTRUTURADA';

const FiltrosRecord = new Immutable.Record({
  prestador: '', tomador: '', intermediario: '', emitido_em: '', emitido_em_ate: '',
  numero: '', numero_ate: '', valor: '', valor_ate: '', codigo_verificacao: '', chave: '',
  status_sefaz_normal: false, status_sefaz_cancelada: false, municipio: '', modal_open: false,
});

export default function reducer(state : any = new FiltrosRecord(), action : Action = {}) {
  switch (action.type) {
    case ABRE_BUSCA_ESTRUTURADA:
      return state
          .set('modal_open', true);
    case FECHA_BUSCA_ESTRUTURADA:
      return state = new FiltrosRecord();

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['nfse', 'filtroEstruturado'], Immutable.Map());
export const modalOpenSelector = createSelector<*, *, *, *>(rootSelector, root => root.get('modal_open', false));

// thunk actions

export function inicializaBuscaEstruturada() {
  return async function(dispatch : Dispatch<*>) {
    dispatch({ type: ABRE_BUSCA_ESTRUTURADA });
  };
}

export function finalizaBuscaEstruturada() {
  return async function(dispatch : Dispatch<*>) {
    dispatch({ type: FECHA_BUSCA_ESTRUTURADA });
  };
}

export function aplicaFiltro(form : any) {
  if (form.toJS)
    form = form.toJS();

  return async function(dispatch : Dispatch<*>) {
    form = formataQuery(form);

    dispatch(finalizaBuscaEstruturada());

    request.get(`/app/nfse/busca-avancada`)
        .accept('json')
        .query(form)
        .then(r => dispatch(filtroLivre.atualizaListaEFiltro(r.body)))
        .catch(e => dispatch(ajaxError('Erro ao aplicar filtro de busca estruturada', e)));
  };
}