// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfe from '../../../form_exportacao_nfe/campos_nfe/selecao_todos_nfe';
import { Input } from '../../../../../../../common/input';

type CamposPisProps = {
  component : React.ComponentType<*>,
}

export default function CamposPis({ component } : CamposPisProps) {
  const SelecaoTodos = component || SelecaoTodosNfe;

  return (
      <FormSection name="dadosPis">
        <SelecaoTodos nomes={ ['dadosPis'] } titulo="Dados do PIS"/>

        <div className="grupo-checkbox">
          <Input name="cstPis" label="Situação Tributária" checkbox/>
          <Input name="valorBcPis" label="Base Cálculo PIS" checkbox/>
          <Input name="aliquotaPercentualPis" label="Alíquota em Percentual" checkbox/>
          <Input name="valorPis" label="Valor do PIS" checkbox/>
          <Input name="qtdVendidaPis" label="Quantidade Vendida" checkbox/>
          <Input name="aliquotaReaisPis" label="Alíquota em Reais" checkbox/>
        </div>
      </FormSection>
  );
}
