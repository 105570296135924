// @flow
'use strict';

import type { TipoFormatoTela } from './column_detalhes';
import { formatHelper as fmt } from '../../../lib/viewUtils';
import { toNumberBrDecimal4, toNumberBrDecimalAny } from '../../../lib/toNumberBrDecimal';

const TRIBUTACAO = {
  T: 'Tributado em São Paulo',
  F: 'Tributado Fora de São Paulo',
  I: 'Isento/Imune',
  J: 'ISS Suspenso por Decisão Judicial',
  A: 'Tributado em São Paulo, porém Isento',
  B: 'Tributado Fora de São Paulo, porém Isento',
  D: 'Tributado em São Paulo com Isenção Parcial',
  M: 'Tributado em São Paulo, porém com Indicação de Imunidade Subjetiva',
  N: 'Tributado Fora de São Paulo, porém com Indicação de Imunidade Subjetiva',
  R: 'Tributado em São Paulo, porém com Indicação de Imunidade Objetiva',
  S: 'Tributado Fora de São Paulo, porém com Indicação de Imunidade Objetiva',
  X: 'Tributado em São Paulo, porém Exigibilidade Suspensa',
  V: 'Tributado Fora de São Paulo, porém Exigibilidade Suspensa',
  P: 'Exportação de Serviços',
};

export function formatoTela(tipo : ?TipoFormatoTela, valor : ?any) {
  if ((typeof valor !== 'number' && !valor) || !tipo)
    return valor;

  if (tipo === 'data')
    return fmt.formatDate(valor);

  if (tipo === 'dataHora')
    return fmt.formatDateTime(valor);

  if (tipo === 'situacao')
    return situacao(valor);

  if (tipo === 'moeda')
    return moeda(valor);

  if (tipo === 'percentual')
    return percentual(valor);

  if (tipo === 'modelo')
    return modelo(valor);

  if (tipo === 'cep') {
    const valorCep = String(valor);

    return fmt.formatId(`${ '0'.repeat(valorCep.length > 8 ? 0 : 8 - valorCep.length) }${ valorCep.replace(/\D/g, '') }`, 'cep');
  }

  if (tipo === 'documento')
    return fmt.formatId(valor);

  if (tipo === 'tributacao')
    return tributacao(valor);

  return valor;
}

function situacao(v : any) {
  if (v === 'N')
    return 'Normal';
  if (v === 'C')
    return 'Cancelada';

  return v;
}

function moeda(v : any) {
  if (!v && typeof v !== 'number')
    return v;

  const valorDecimal = v === 0 ? '0,00' : toNumberBrDecimalAny(v);
  return valorDecimal ? `R$ ${ valorDecimal }` : '';
}

function percentual(v : any) {
  if (!v && typeof v !== 'number')
    return v;

  const valorDecimal = toNumberBrDecimal4(v);
  return valorDecimal ? `${ valorDecimal } %` : '';
}

function tributacao(v : any) {
  return TRIBUTACAO[v] || v;
}

function modelo(v : number) {
  switch (v.toString()) {
    case '55':
      return '55 - NF-e';
    case '57':
      return '57 - CT-e';
    case '59':
      return '59 - CF-e';
    case '65':
      return '65 - NFC-e';
    case '67':
      return '67 - CT-e OS';
    default:
      return v;
  }
}