// @flow
'use strict';

import * as React from 'react';
import { Button, Col, Form, Row, Well } from 'react-bootstrap';

import { withFormValues } from 'lib/forms';
import { formatHelper as fmt, useEffectOnce } from 'lib/viewUtils';

import Icone from 'components/common/icone';
import { Input } from 'components/common/input';

export const SITUACAO_FORNECEDOR = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
];

export const MUNICIPIO_SERVICO = [
  { value: '3304557', label: 'Rio de Janeiro / RJ' },
  { value: '3518800', label: 'Guarulhos / SP' },
  { value: '3106200', label: 'Belo Horizonte / MG' },
];

type FormularioProps = {
  handleSubmit : () => void,
  id : string,
  navigateToIndex : () => void,
  carregaContas : () => void,
  excluiFornecedor : (id : string) => void,
  contas : { value : string, label : string }[],
};

export function Formulario({ handleSubmit, id, navigateToIndex, excluiFornecedor, carregaContas, contas } : FormularioProps) {
  const excluiFornecedorDetalhes = React.useCallback(() => {
    if (window.confirm('Tem certeza que deseja excluir esse prestador?'))
      excluiFornecedor(id);
  }, [excluiFornecedor, id]);

  useEffectOnce(() => {
    carregaContas();
  });

  return (
      <Form onSubmit={ handleSubmit } horizontal>
        <section className="visualizacao-dados">
          <fieldset>
            <legend>Dados da Conta</legend>
            <Row>
              <Col md={ 10 }>
                <Input name="contaId" label="Conta" options={ contas } required/>
              </Col>
            </Row>
          </fieldset>
          <fieldset>
            <legend>Dados do Prestador de Serviço</legend>
            <Row>
              <Col md={ 10 }>
                <Input name="cnpj" label="CNPJ do Prestador" mascara="99.999.999/9999-99" required/>
                <Input name="nome" label="Nome ou Razão Social do Prestador" required/>
                <Input name="inscricao" label="Inscrição Municipal do Prestador" required/>
                <Input name="municipio" label="Município do Prestador" options={ MUNICIPIO_SERVICO }
                       required/>
                <Input name="ativo" label="Ativo" options={ SITUACAO_FORNECEDOR } required/>
              </Col>
            </Row>
          </fieldset>
          <fieldset>
            <legend>Dados do Cadastro</legend>
            <Row>
              <Col md={ 10 }>
                <Input format={ fmt.formatDateSped } name="createdAt" label="Data de Cadastro" somenteLeitura/>
                <Input name="autor" label="Usuário que cadastrou" somenteLeitura/>
              </Col>
            </Row>
          </fieldset>
        </section>

        <Well bsStyle="sm" className="clearfix">
          <div className="pull-left">
            <Button onClick={ navigateToIndex }>
              <Icone nome="window-close" estilo="far">Cancelar</Icone>
            </Button>{ ' ' }
            <Button type="submit" bsStyle="primary">
              <Icone nome="save" estilo="far">Salvar Prestador</Icone>
            </Button>
          </div>

          { id &&
          <div className="pull-right">
            <Button bsStyle="danger" onClick={ excluiFornecedorDetalhes }>
              <Icone nome="trash-alt" estilo="fal">Excluir Prestador</Icone>
            </Button>
          </div>
          }
        </Well>
      </Form>
  );
}

export default withFormValues(true, 'nome')(Formulario);
