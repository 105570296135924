'use strict';
// @flow

import * as React from 'react';

import LabelSituacao from './label_situacao';
import UltimaConsulta from './ultima_consulta';
import BotaoReconsulta from './botao_reconsulta';

import type { IDocumento } from 'lib/api';
import type { LabelSituacaoProps } from './tipos';

type SituacaoProps = {
  documento? : ?IDocumento,
  reconsulta? : bool, // se verdadeiro, habilita o botão para reconsulta
}

export default function Situacao(props : SituacaoProps) {
  if (!props.documento)
    return null;

  return (
      <div className="panel panel-default situacoes">
        <div className="panel-heading">
          Situações
          <div className="pull-right"><BotaoReconsulta { ...props }/></div>
        </div>
        <div className="panel-body">
          <ItensPainel { ...props } />
          <div className="ultima-consulta r">
            <UltimaConsulta data={ props.documento.ultimaConsulta }/>
          </div>
        </div>
      </div>
  );
}

function ItensPainel({ documento } : SituacaoProps) {
  if (!documento)
    return null;

  const { situacaoArquivo, situacao } = documento;

  return (
      <dl>
        <dt>Arquivo</dt>
        <dd><LabelSituacao { ...propsParaSituacaoArquivo(situacaoArquivo) }/></dd>
        <dt>SEFAZ</dt>
        <dd><LabelSituacao { ...(situacao && situacao.sefaz : any) } /></dd>
      </dl>
  );
}

function propsParaSituacaoArquivo(situacaoArquivo : ?string) : LabelSituacaoProps {
  switch (situacaoArquivo) {
    case 'ok':
      return {
        label: 'Válido', tipo: 'success', children: <span>
          <p>Esta situação indica que o XML do documento <strong>está em custódia da Consyst-e,
            e passou por todas as validações estruturais</strong> (sintaxe, esquema e assinatura).</p>
          <p>Para ser considerado válido, porém, a situação na SEFAZ deve estar OK também.</p>
        </span>,
      };
    case null:
      return {
        label: 'Indefinida', tipo: 'default', children: <span>
          <p>Indica que o documento ainda não teve sua situação averiguada pelo sistema.</p>
          <p>Se a situação permanecer assim após alguns minutos, entre em contato com o suporte.</p>
        </span>,
      }
    case undefined:
    case '':
    case 'not_found':
      return {
        label: 'Não Encontrado', tipo: 'warning', children: <span>
          <p>Esta situação indica que o XML do documento em questão <strong>não está armazenado
            no Portal Consyst‑e</strong>.</p>
          <p>O fornecedor pode ainda não ter enviado o documento.</p>
        </span>,
      };

    case 'syntax_error':
      return {
        label: 'Erro de Sintaxe', tipo: 'danger', children: <span>
          <p>Esta situação indica que o XML do documento está em custódia no Consyst-e e <strong>apresenta erro
            estrutural de sintaxe</strong>.</p>
          <p>Isso significa que o documento não é um XML válido. Solicite o documento correto ao fornecedor.</p>
        </span>,
      };

    case 'invalid_schema':
      return {
        label: 'Schema Inválido', tipo: 'danger', children: <span>
          <p>Esta situação indica que o XML do documento está em custódia da Consyst‑e,
            porém <strong>apresenta erro estrutural de esquema</strong>. Isso significa
            que o documento não segue as orientações da SEFAZ sobre o modelo de distribuição
            de documentos, e não deve ser aceito até ser corrigido pelo fornecedor.</p>
          <p><em>Note que este erro pode acontecer mesmo com documentos já autorizados,
            pois não se refere à validade da operação em si, mas sim do formato de distribuição.</em></p>
        </span>,
      };

    case 'wrong_environment':
      return {
        label: 'Ambiente Incorreto', tipo: 'danger', children: <span>
          <p>Esta situação indica que o documento em questão <strong>foi emitido para o ambiente
            incorreto da SEFAZ </strong>(normalmente homologação).</p>
        </span>,
      };

    case 'invalid_dsig':
      return {
        label: 'Assinatura Digital Inválida', tipo: 'danger', children: <span>
          <p>Esta situação indica que o XML do documento está em custódia no Consyst‑e,
            porém <strong>apresenta assinatura digital inválida</strong>. Isso significa
            que o documento, após ser autorizado pela SEFAZ, sofreu alterações (intencionais ou não).
            O emissor deve enviar a versão original (autorizada) do mesmo.</p>
          <p><em>Note que este erro pode acontecer mesmo com documentos já autorizados,
            pois não se refere à validade da operação em si, mas sim do formato de distribuição.</em></p>
        </span>,
      };

    default:
      return {
        label: 'Com Erros', tipo: 'danger', children: <span>
          <p>Esta situação indica que o XML do documento está em custódia no Consyst‑e,
            porém <strong>apresenta algum erro estrutural</strong>.</p>
          <p>Se a situação persistir, contate o suporte técnico.</p>
        </span>,
      };
  }
}