// @flow
'use strict';

import { connect } from 'react-redux';

import { abreHistorico } from '../../../../reducers/nfe/historico';

import BotaoHistorico from '../../../common/detalhes_documento/botao_historico';

export default connect<*, *, *, *, *, *>(null, { abreHistorico })(BotaoHistorico);
