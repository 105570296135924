// @flow
'use strict';

import Icms00 from './icms00';
import Icms20 from './icms20';
import Icms40 from './icms40';
import Icms60 from './icms60';
import Icms90 from './icms90';

export default {
  Icms00, Icms20, Icms40, Icms60, Icms90
};
