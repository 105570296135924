// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, State } from '../../lib/types';

type Conta = {
  id : string,
  nome : string,
}

const DADOS_SELECT_RECEBIDOS = 'dahsboard/conta/DADOS_SELECT_RECEBIDOS';

const INITIAL_STATE = Immutable.fromJS({
  items: [],
});

export default function reducer(state : Immutable.Map<*, *> = INITIAL_STATE, action : Action = { type: '' }) {
  switch (action.type) {
    case DADOS_SELECT_RECEBIDOS:
      return state.set('items', action.dados);
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['dashboard', 'conta'], Immutable.Map());

export const todosSelector = createSelector<*, *, *, *>(rootSelector, root => root.get('items'));

// action creators

export function dadosSelectRecebidos(dados : Conta[]) {
  return { type: DADOS_SELECT_RECEBIDOS, dados: Immutable.fromJS(dados) };
}