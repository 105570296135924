// @flow
'use strict';

import * as React from 'react';

import { Input } from '../../../../../common/input';

export default function CamposDocumento() {
  return (
      <React.Fragment>
          <Input name="versao" label="Versão" checkbox/>
          <Input name="modeloDescricao" label="Modelo" checkbox/>
          <Input name="chave" label="Chave" checkbox/>
          <Input name="numero" label="Número" checkbox/>
          <Input name="serie" label="Série" checkbox/>
          <Input name="ambienteDescr" label="Ambiente" checkbox/>
          <Input name="emitidoEm" label="Data de Emissão" checkbox/>
      </React.Fragment>
  );
}