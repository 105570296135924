// @flow
'use strict';

import * as _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';

import * as manifestacao from 'reducers/cte/manifestacao';
import { ajaxError, alert } from 'reducers/alerts';
import * as modal from 'reducers/modal';
import request from 'lib/request';
import { formToQuery } from 'lib/viewUtils';

import ModalManifestaCte from './modal_manifesta_cte';

export default compose(
    connect<*, *, *, *, *, *>(createStructuredSelector({
          open: modal.openModalSelector,
          initialValues: manifestacao.rootSelector,
        }),
        { ...manifestacao, ...modal }),
    reduxForm({
      form: 'manifestacaoCte', enableReinitialize: true,
      onSubmit(form, dispatch) {
        request.patch(form.get('url'))
            .accept('json')
            .query(formToQuery(_.omit(form.toJS(), 'url')))
            .then((r) => {
              if (r.body.alertas && r.body.alertas.length > 0)
                window.alert(r.body.alertas.join('\n\n'));
              else {
                dispatch(alert('info', 'Desacordo do serviço enviado.'));
                dispatch(reset('manifestacaoCte'));
              }
            })
            .catch(e => dispatch(ajaxError('Erro desconhecido ao enviar desacordo do serviço. Entre em contato com o suporte.', e)));
      },
    }),
)(ModalManifestaCte);
