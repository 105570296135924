// @flow
'use strict';

import * as Immutable from 'immutable';
import Promise from 'bluebird';

import type { Action, State, Dispatch, GetState } from 'lib/types';
import * as lista from './lista';
import request from '../../../lib/request';
import { arquivoPath } from '../../../components/sped/arquivos/arquivo_path';

const INICIA_UPLOAD   = 'sped/arquivos/novo/INICIA_UPLOAD',
      ALTERA_PROGRESS = 'sped/arquivos/novo/ALTERA_PROGRESS',
      FINALIZA_UPLOAD = 'sped/arquivos/novo/FINALIZA_UPLOAD',
      ADICIONA_ERRO   = 'sped/arquivos/novo/ADICIONA_ERRO',
      NOVO_UPLOAD     = 'sped/arquivos/novo/NOVO_UPLOAD ';

const DEFAULT_STATE = Immutable.fromJS({
  uploading: false,
  progress: null,
  uploadResult: null,
  error: null,
});

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action = {}) {
  switch (action.type) {
    case INICIA_UPLOAD:
      return state.set('uploading', true);
    case ALTERA_PROGRESS:
      return state.set('progress', action.body);
    case FINALIZA_UPLOAD:
      return state.set('uploadResult', action.body)
          .set('uploading', false)
          .set('progress', 'done');
    case ADICIONA_ERRO:
      return state.set('error', action.body)
          .set('uploading', false);
    case NOVO_UPLOAD:
      return state = DEFAULT_STATE;

    default:
      return state;
  }
}

// actions

export function iniciaUpload() {
  return { type: INICIA_UPLOAD };
}

export function alteraProgress(p : ProgressEvent) {
  return { type: ALTERA_PROGRESS, body: Immutable.fromJS(p) };
}

export function finalizaUpload(result : any) {
  return { type: FINALIZA_UPLOAD, body: Immutable.fromJS(result) };
}

export function adicionaErro(e : any) {
  return { type: ADICIONA_ERRO, body: Immutable.fromJS(e) };
}

export function novoUpload() {
  return { type: NOVO_UPLOAD };
}

// selectors

export const rootSelector = (state : State) => state.getIn(['sped', 'arquivos', 'novo'], Immutable.Map());

// thunk actions

export function uploadArquivos(files : any[]) {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    Promise
        .try(() => {
          const formData = new FormData();
          for (let i = 0; i < files.length; i++)
            formData.append('file[]', files[i]);
          return formData;
        })
        .then(formData => request.post(arquivoPath.base)
            .accept('json')
            .send(formData)
            .on('progress', e => dispatch(alteraProgress(e))),
        )
        .then(r => dispatch(finalizaUpload(r.body)))
        .then(() => dispatch(lista.carregaLista(lista.lastQuerySelector(getState()).toJS())))
        .catch(e => dispatch(adicionaErro(e)));
  };
}