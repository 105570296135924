// @flow
'use strict';

import * as React from 'react';
import { Button, Col, Form, Row, Well } from 'react-bootstrap';
import { Field } from 'redux-form/immutable';

import dateFormat from 'lib/dateFormat';
import { withFormValues } from 'lib/forms';

import Icone from 'components/common/icone';
import { Input } from 'components/common/input';

import Editor from './editor';

export const TIPOS_NOTICIA = [
  { value: 'link', label: 'link' },
  { value: 'texto', label: 'texto' },
];

type FormularioProps = {
  handleSubmit : () => void,
  tipo : ?string,
  id : string,
  navigateToIndex : () => void,
  excluiNoticia : (id : string) => void,
};

export function Formulario({ handleSubmit, tipo, id, navigateToIndex, excluiNoticia } : FormularioProps) {
  const excluiNoticiaDetalhes = React.useCallback(() => {
    if (window.confirm('Tem certeza que deseja excluir essa notícia?'))
      excluiNoticia(id);
  }, [excluiNoticia, id]);

  return (
      <Form onSubmit={ handleSubmit } horizontal>
        <section className="visualizacao-dados">
          <fieldset>
            <legend>Dados Básicos</legend>
            <Row>
              <Col md={ 6 }>
                <Input normalize={ dateFormat } name="data" label="Data" required/>
                <Input name="autor" label="Autor" somenteLeitura/>
              </Col>
              <Col md={ 6 }>
                <Input name="titulo" label="Título" required/>
                <Input normalize={ dateFormat } name="createdAt" label="Data de Criação" somenteLeitura/>
              </Col>
            </Row>
          </fieldset>
          <fieldset>
            <legend>Dados da Notícia</legend>
            <Row>
              <Col md={ 6 }>
                <Input name="tipo" label="Tipo" options={ TIPOS_NOTICIA } required/>
              </Col>
            </Row>
            { tipo === 'link' && <Input sm={ 10 } name="link" label="Link"/> }
            { tipo === 'texto' && <Field component={ Editor } name="texto" label="Texto"/> }
          </fieldset>
        </section>

        <Well bsStyle="sm" className="clearfix">
          <div className="pull-left">
            <Button onClick={ navigateToIndex }>
              <Icone nome="window-close" estilo="far">Cancelar</Icone>
            </Button>{ ' ' }
            <Button type="submit" bsStyle="primary">
              <Icone nome="save" estilo="far">Salvar Notícia</Icone>
            </Button>
          </div>

          { id &&
          <div className="pull-right">
            <Button bsStyle="danger" onClick={ excluiNoticiaDetalhes }>
              <Icone nome="trash-alt" estilo="fal">Excluir Notícia</Icone>
            </Button>
          </div>
          }
        </Well>
      </Form>
  );
}

export default withFormValues(true, 'tipo')(Formulario);
