// @flow
'use strict';

import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import { COL_MD_1, COL_MD_10 } from '../constantes';

import Icone from '../../../common/icone';

const STYLE_TRANSPARENTE_TABELA = { backgroundColor: 'rgba(255,255,255,0.0)', fontSize: 15, marginLeft: 10 };

type NoticiaDashboardProps = {
  children? : React.Node,
};

export default function NoticiaDashboard({ children } : NoticiaDashboardProps) {
  return (
      <li style={ STYLE_TRANSPARENTE_TABELA }>
        <Row>
          <Col { ...COL_MD_1 }>
            <Icone nome="caret-right" estilo="fas" cor="text-muted" large />
          </Col>
          <Col { ...COL_MD_10 } className="coluna-link-noticia">
            { children }
          </Col>
        </Row>
      </li>
  );
}