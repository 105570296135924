// @flow
'use strict';

import * as React from 'react';

import { withFormValues } from '../../../../../../lib/forms';

import CamposItem from './campos_item/campos_item';
import CamposDocumentoReferenciado from './campos_documento_referenciado';
import CamposCobranca from '../form_exportacao_nfe/campos_nfe/campos_cobranca';
import SelecaoTodosNfe from '../form_exportacao_nfe/campos_nfe/selecao_todos_nfe';
import SelecaoTodosNfce from '../form_exportacao_nfce/campos_nfce/selecao_todos_nfce';
import CamposPagamento from '../common/campos_pagamento';

type CamposNfeENfceProps = {
  tipo : string,
  linhaPor? : number,
  tipoDocumento : string,
  component : React.ComponentType<*>,
  children? : React.Node,
};

export function CamposNfeENfce({ linhaPor, tipoDocumento, component, children } : CamposNfeENfceProps) {

  const nomes = ['dadosBasicos', 'emit', 'dest', 'transacoesConsyste', 'localRetirada', 'localEntrega', 'dadosTotais', 'transporte'];

  if (tipoDocumento === 'nfe')
    nomes.push('manifestacaoDestinatario');

  if (linhaPor === 2)
    nomes.push('dadosBasicosItens', 'dadosOperacao', 'dadosCest', 'dadosIcms', 'dadosPis',  'dadosCofins', 'dadosIcmsInterestadual', 'dadosIpi');
  else if (linhaPor === 3)
    nomes.push('docReferenciadoNfe', 'docReferenciadoNf', 'docReferenciadoCte', 'docReferenciadoCupom');
  else if (linhaPor === 5)
    nomes.push('cobranca', 'parcela');
  else if (linhaPor === 6)
    nomes.push('pagamento', 'cartao');

  return (
      <section className="download-lista">
        { tipoDocumento === 'nfce' &&
            <SelecaoTodosNfce nomes={ nomes } selecionarTodos titulo="SELECIONAR TODOS"/> }
        { tipoDocumento === 'nfe' &&
            <SelecaoTodosNfe nomes={ nomes } selecionarTodos titulo="SELECIONAR TODOS"/> }

        <fieldset className="grupo-download-lista">
          <legend>Dados do Documento</legend>

          { children }
        </fieldset>

        { linhaPor === 2 && <CamposItem tipoDocumento={ tipoDocumento } component={ component }/> }

        { linhaPor === 3 && tipoDocumento === 'nfe' && <CamposDocumentoReferenciado component={ component }/> }

        { linhaPor === 5 && tipoDocumento === 'nfe' && <CamposCobranca/> }

        { linhaPor === 6 && <CamposPagamento component={ component }/> }
      </section>
  );
}

export default withFormValues(true, 'linhaPor', 'tipoDocumento')(CamposNfeENfce);