// @flow
'use strict';

import * as React from 'react';

import type { IInformacoesPedidoEmissaoNff } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';

type InformacoesPedidoEmissaoNffProps = {
  informacoesPedidoEmissaoNff : IInformacoesPedidoEmissaoNff,
};

export default function InformacoesPedidoEmissaoNff({ informacoesPedidoEmissaoNff } : InformacoesPedidoEmissaoNffProps) {
  return (
      <>
        <h3>Informações do Pedido de Emissão da NFF</h3>
        <h4>Solicitação do Pedido de Emissão da NFF</h4>
        <RowDetalhes data={ informacoesPedidoEmissaoNff } headerRow={ false }>
          <ColumnDetalhes field="solicitacaoPedidoEmissaoNff" textoPreformatado/>
        </RowDetalhes>
      </>
  );
}
