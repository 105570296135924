// @flow
'use strict';

import React from 'react';
import { FormSection } from 'redux-form/immutable';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

import Icone from '../../../common/icone';
import CamposDocumentos from './campos_documentos';
import CamposItens from './campos_itens';
import CampoSituacaoSefaz from '../../../common/campos_busca_estruturada/campo_situacao_sefaz';
import CampoSituacaoArquivo from '../../../common/campos_busca_estruturada/campo_situacao_arquivo';
import CampoSituacaoCustodia from '../../../common/campos_busca_estruturada/campo_situacao_custodia';
import { InputForm } from '../../../common/input';
import { CAMPOS_SITUACAO_SEFAZ_NFCE, CAMPOS_SITUACAO_CUSTODIA_NFCE } from '../../constantes';

type ModalProps = {
  open : bool,
  suporte : bool,
  fechaModal : ()  => void,
  handleSubmit : () => void,
}

export const OPCAO_BOOLEANO = [
  { value: 'sim', label: 'Sim' },
  { value: 'nao', label: 'Não' },
];

export default function ModalBuscaEstruturada({ open, suporte, fechaModal, handleSubmit } : ModalProps) {
  return (
      <Modal show={ open } onHide={ fechaModal } dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Busca Avançada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={ handleSubmit }>
            <section className="busca-estruturada">
              <Row>
                <Col md={ 7 }>
                  <CamposDocumentos/>
                </Col>
                <Col md={ 5 }>
                  <CamposItens/>
                </Col>
              </Row>

              <legend className="titulo-grupo-nfce">Situações</legend>
              <Row>
                <Col md={ 6 }>
                  <FormSection name="situacao_sefaz">
                    <CampoSituacaoSefaz campos={ CAMPOS_SITUACAO_SEFAZ_NFCE }/>
                  </FormSection>

                  { suporte &&
                  <FormSection name="situacao_custodia">
                    <CampoSituacaoCustodia campos={ CAMPOS_SITUACAO_CUSTODIA_NFCE }/>
                  </FormSection>
                  }
                </Col>
                <Col md={ 6 }>
                  <FormSection name="situacao_arquivo">
                    <CampoSituacaoArquivo/>
                  </FormSection>
                  <InputForm className="campo-booleano" sm={ 8 } name="custodiado" label="Custodiado"
                             optionsRadio={ OPCAO_BOOLEANO }/>
                  <InputForm className="campo-booleano" sm={ 8 } name="lido" label="Lido"
                             optionsRadio={ OPCAO_BOOLEANO }/>
                </Col>
              </Row>
            </section>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={ fechaModal }>Fechar</Button>
          <Button bsStyle="primary" onClick={ handleSubmit }><Icone nome="search" estilo="fas">Buscar</Icone></Button>
        </Modal.Footer>
      </Modal>
  );
}
