// @flow
'use strict';

import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormSection } from 'redux-form/lib/immutable';

import CamposPessoa from './campos_pessoa';
import CamposTransacoesConsyste from './campos_transacoes_consyste';
import SelecaoTodosNfse from './selecao_todos_nfse';
import { Input } from '../../../../../../common/input';

export default function CamposNfse() {

  const nomes = ['dadosBasicos', 'transacoesConsyste', 'prestador', 'tomador'];

  return (
      <section className="download-lista">
        <SelecaoTodosNfse nomes={ nomes } selecionarTodos titulo="SELECIONAR TODOS"/>

        <fieldset className="grupo-download-lista">
          <legend>Dados do Documento</legend>

          <Row>
            <Col md={ 6 }>
              <FormSection name="dadosBasicos">
                <SelecaoTodosNfse nomes={ ['dadosBasicos'] } titulo="Dados Básicos"/>

                <div className="grupo-checkbox">
                  <Input name="numero" label="Número" checkbox/>
                  <Input name="codigoVerificacao" label="Cód. Verificação" checkbox/>
                  <Input name="emitidoEm" label="Data de Emissão" checkbox/>
                  <Input name="municipio" label="Município" checkbox/>
                  <Input name="discriminacao" label="Discriminação do Serviço" checkbox/>
                  <Input name="valor" label="Valor Total" checkbox/>
                  <Input name="tributacao" label="Tributação" checkbox/>
                  <Input name="simplesOpcaoDesc" label="Opção Simples" checkbox/>
                </div>
              </FormSection>

              <CamposTransacoesConsyste/>
            </Col>
            <Col md={ 6 }>
              <CamposPessoa nome="prestador" titulo="Dados do Emitente"/>
              <CamposPessoa nome="tomador" titulo="Dados do Tomador"/>
            </Col>
          </Row>
        </fieldset>
      </section>
  );
}
