// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { State, Action, Dispatch, GetState } from '../lib/types';
import { decisaoSelector, fechaDecisao, salvaDecisao } from './decisaoPortaria';

export const ADICIONA_ITENS = 'decisao_mercadoria/ADICIONA_ITENS';

const DEFAULT_STATE = Immutable.fromJS({
  observacao: '',
  itens: [],
});

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action) {
  switch (action.type) {
    case ADICIONA_ITENS:
      return state.set('itens', action.itens);
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.get('decisaoMercadoria', DEFAULT_STATE);

export const itensSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('itens', Immutable.List()));

// thunk action creators

export function adicionaItens(itens : Immutable.List<*, *>) {
  return async function(dispatch : Dispatch<*>) {
    if (itens) {
      const itensState = itens.map((item : Immutable.Map<*, *>, index : number) => ({
        codigo: item.get('codigo'), descricao: item.get('descricao'),
        pedido_nro: item.get('pedido_nro'), pedido_item: item.get('pedido_item'),
        pendente: item.get('pendente', false), item: item.get('item'),
        posicao: index,
      }));
      dispatch({ type: ADICIONA_ITENS, itens: itensState });
    }
  };
}

export function salva(form : any) {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const decisao = decisaoSelector(getState());

    if (decisao !== 'receber_com_pendencia') {
      dispatch(salvaDecisao(form.get('observacao'), form.get('itens', Immutable.List())));
      return dispatch(fechaDecisao());
    }

    if (await confirm('Confirma a pendência no recebimento das mercadorias?')) {
      dispatch(salvaDecisao(form.get('observacao'), form.get('itens', Immutable.List())));
      return dispatch(fechaDecisao());
    }
  };
}