// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/immutable';

import CampoIntervalo from '../../../common/campo_intervalo';
import { InputForm } from '../../../common/input';

export default function CamposItens() {
  return (
      <FormSection name="itens">
        <legend>Itens</legend>
        <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="descricao"
                   label="Descrição"/>
        <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="codigo" label="Código"/>
        <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="ncm" label="NCM"/>
        <CampoIntervalo columnClassName="no-padding-left" rotulo="Val. Unit." nome="valor_unitario"/>
        <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="ean" label="EAN"/>
      </FormSection>
  );
}
