// @flow
'use strict';

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';
import { push } from 'react-router-redux';

import type { Action, Dispatch, GetState, State } from '../../../lib/types';
import * as Paths from '../../../paths';
import { buscaEstruturadaParaBuscaLivre } from 'lib/buscas';
import { currentRouteSelector } from '../../routing';
import { carregaLista } from './lista';
import { formataQuery } from '../../../lib/formata_query';

export const MUDA_FILTRO = 'sped/escriturados/filtro/MUDA_FILTRO';
const LIMPA_FILTRO       = 'sped/escriturados/filtro/LIMPA_FILTRO',
      INICIALIZA_FILTROS = 'sped/escriturados/filtro/INICIALIZA_FILTROS',
      BUSCA_AVANCADA     = 'sped/escriturados/filtro/BUSCA_AVANCADA',
      BUSCA_BASICA       = 'sped/escriturados/filtro/BUSCA_BASICA';

const FiltrosRecord = new Immutable.Record({
  chave: '', serie: '', numero: '', emitente: '', cnpj: '', emitido_em_de: '', emitido_em_ate: '',
  tipo_operacao_id: '', modelo: '', custodiado: '', custodiado_em_de: '', custodiado_em_ate: '', tipo_emissao: '',
  situacao_sefaz: '', situacao_arquivo: null, escriturado: null, cod_manifestacao_cd: '', tomador: '',
  ctes_tomados: null, situacao_desacordo_servico: '',
});

const INITIAL_STATE = Immutable.fromJS({
  atual: 'escriturado',
  escriturado: new FiltrosRecord(),
  livre: '',
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case INICIALIZA_FILTROS:
      return state.set('escriturado', action.valores);
    case MUDA_FILTRO:
      return state.setIn(['escriturado', action.nome], action.valor);
    case LIMPA_FILTRO:
      return state.set('escriturado', new FiltrosRecord());
    case BUSCA_AVANCADA:
      return state
          .set('atual', 'livre')
          .set('livre', action.busca);
    case BUSCA_BASICA:
      return state
          .setIn('atual', 'escriturado');
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['sped', 'escriturados', 'filtro'], Immutable.Map());

export const filtrosEscrituradosSelector = createSelector<*, *, *, *>(rootSelector, root => root.get('escriturado'));

export const filtroAtualSelector = createSelector<*, *, *, *>(rootSelector, root => root.get('atual'));
export const buscaAvancadaSelector = createSelector<*, *, *, *>(rootSelector, root => root.get('livre'));

export const filtroParamsSelector = createSelector<*, *, *, *, *, *>(filtroAtualSelector, buscaAvancadaSelector, filtrosEscrituradosSelector,
    (filtroAtual, q, escriturados) =>
        ((filtroAtual === 'livre' ? { q } : escriturados.toJS())),
);

// action creators

export function trocaPagina(page : number) {
  return function(dispatch : Dispatch<*>, getState : GetState) {
    const query = formataQuery(currentRouteSelector(getState()).get('query').toJS());

    dispatch(push(Paths.sped.escriturados.colecao({ ...query, page })));
    dispatch(carregaLista({ ...query, page }));
  };
}

export function mudaBuscaAvancada(busca : string) {
  return { type: BUSCA_AVANCADA, busca };
}

export function mudaFiltro(nome : string, valor : any) {
  return { type: MUDA_FILTRO, nome, valor };
}

export function limpaFiltro() {
  return { type: LIMPA_FILTRO };
}

export function limpaBuscaAvancada() {
  return mudaBuscaAvancada('');
}

export function inicializaFiltros(query : { q : ?string }) {
  return async function(dispatch : Dispatch<*>) {
    if (query && query.q)
      await dispatch({ type: BUSCA_AVANCADA, busca: query.q });
    else
      await dispatch({ type: INICIALIZA_FILTROS, valores: new FiltrosRecord(query) });

    dispatch(carregaLista(query));
  };
}

export function alternaParaBuscaBasica() {
  return { type: BUSCA_BASICA };
}

export function alternaParaBuscaAvancada() {
  return function(dispatch : Dispatch<*>, getState : GetState) {
    const params = filtrosEscrituradosSelector(getState());

    return dispatch(mudaBuscaAvancada(buscaEstruturadaParaBuscaLivre(formataQuery(params))));
  };
}

export function aplicaFiltro() {
  return function(dispatch : Dispatch<*>, getState : GetState) {
    const params = filtroParamsSelector(getState()),
          query  = { ...formataQuery(params), page: undefined };

    dispatch(push(Paths.sped.escriturados.colecao(query)));
    dispatch(carregaLista(query));
  };
}