// @flow
'use strict';

import React from 'react';
import { useDispatch } from 'react-redux';

import type { RelatorioType } from './types';
import { excluiModeloRelatorio } from '../../../../../reducers/modelo_relatorio/modal_lista_relatorios';
import { setValoresIniciais } from '../../../../../reducers/lista_documentos/functions';
import { abreRelatorio, fechaListaRelatorios } from '../../../../../reducers/modelo_relatorio/modal_escolha_tipo_relatorio';

import Icone from '../../../../common/icone';

type LinhaTabelaProps = {
  relatorio : RelatorioType,
}

export default function LinhaTabela({ relatorio } : LinhaTabelaProps) {
  const dispatch = useDispatch();

  const excluirRelatorio = React.useCallback(() => {
    if (window.confirm('Confirma exclusão do relatório?'))
      dispatch(excluiModeloRelatorio(relatorio.id, relatorio.tipo));
  }, [dispatch, relatorio.id, relatorio.tipo]);

  const abrirRelatorio = React.useCallback(() => {
    dispatch(setValoresIniciais(relatorio.camposExportacao, relatorio.tipo));
    dispatch(abreRelatorio());
    dispatch(fechaListaRelatorios());
  }, [dispatch, relatorio.camposExportacao, relatorio.tipo]);

  return (
      <tr className="cursor-pointer">
        <td onClick={ abrirRelatorio }>{ relatorio.nome }</td>
        <td onClick={ abrirRelatorio }>{ relatorio.criadoEm }</td>
        <td onClick={ abrirRelatorio }>{ relatorio.criadoPor }</td>
        <td>
          { relatorio.permiteExclusao &&
          <a onClick={ excluirRelatorio } className="cursor-pointer">
            <Icone nome="trash-alt" estilo="far" large titulo="Excluir"/>
          </a>
          }
        </td>
      </tr>
  );
}
