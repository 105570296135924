// @flow
'use strict';

import * as React from 'react';
import { Button } from 'react-bootstrap';

import Icone from '../../common/icone';
import ModalDownloadLista from './modal_download_lista';

type ExportacaoListaProps = {
  open : bool,
  abreModal : (tipo : string, query : any) => void,
  tipo : string,
  query : any,
}

export default function ExportacaoLista({ open, abreModal, ...props } : ExportacaoListaProps) {
  const abreModalComQuery = React.useCallback(() => abreModal(props.tipo, props.query), [abreModal, props.tipo, props.query]);

  return (
      <React.Fragment>
        <Button type="button" bsStyle="primary" onClick={ abreModalComQuery }>
          <Icone nome="file-alt" estilo="fal">Exportar Lista</Icone>
        </Button>

        { open && <ModalDownloadLista { ...props }/> }
      </React.Fragment>
  );
}