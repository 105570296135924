// @flow
'use strict';

import * as React from 'react';

import FormExportacao from '../form_exportacao';
import CamposNfse from './campos_nfse';

type FormProps = {
  handleSubmit : () => void,
}

export default function FormExportacaoNfse(props : FormProps) {
  return (
      <FormExportacao { ...props } tipo="nfse">
        <CamposNfse/>
      </FormExportacao>
  );
}