// @flow
'use strict';

import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosCte from './selecao_todos_cte';
import { Input } from '../../../../../../common/input';

export default function CamposCarga() {
  return (
      <fieldset className="grupo-download-lista">
        <legend>Documentos da Carga</legend>
        <p className="instrucao-em-titulo">A lista retornará uma linha para cada documento de carga.</p>

        <Row>
          <Col md={ 6 }>
            <FormSection name="docCargaNf">
              <SelecaoTodosCte nomes={ ["docCargaNf"] }
                               tituloComQuebraDeLinha
                               titulo="Notas Fiscais (modelos: 01 ou 1A e avulsa; 04 - NF de Produtor)"/>

              <div className="grupo-checkbox">
                <Input name="romaneioNf" label="Romaneio" checkbox/>
                <Input name="pedidoNf" label="Pedido" checkbox/>
                <Input name="modeloNf" label="Modelo" checkbox/>
                <Input name="serieNf" label="Série" checkbox/>
                <Input name="numeroNf" label="Número" checkbox/>
                <Input name="emissaoNf" label="Emissão" checkbox/>
                <Input name="valorBcIcmsNf" label="Base Cálculo ICMS" checkbox/>
                <Input name="valorIcmsNf" label="Valor ICMS" checkbox/>
                <Input name="valorBcIcmsStNf" label="Base Cálculo ICMS ST" checkbox/>
                <Input name="valorIcmsStNf" label="Valor ICMS ST" checkbox/>
                <Input name="valorProdutosNf" label="Valor Produtos" checkbox/>
                <Input name="valorTotalNf" label="Valor Total" checkbox/>
                <Input name="cfopNf" label="CFOP" checkbox/>
                <Input name="pesoNf" label="Peso" checkbox/>
              </div>
            </FormSection>
          </Col>

          <Col md={ 6 }>
            <FormSection name="docCargaNfe">
              <SelecaoTodosCte nomes={ ["docCargaNfe"] } tituloComQuebraDeLinha titulo="Notas Fiscais eletrônicas (modelo 55 ou 65)"/>

              <div className="grupo-checkbox">
                <Input name="chaveNfe" label="Chave" checkbox/>
                <Input name="entregaNfe" label="Data Entrega" checkbox/>
              </div>
            </FormSection>

            <FormSection name="docCargaOutros">
              <SelecaoTodosCte nomes={ ["docCargaOutros"] } titulo="Demais Documentos"/>

              <div className="grupo-checkbox">
                <Input name="tipoDoc" label="Tipo de Documento" checkbox/>
                <Input name="descricaoDoc" label="Descrição" checkbox/>
                <Input name="numeroDoc" label="Número" checkbox/>
                <Input name="emissaoDoc" label="Emissão" checkbox/>
                <Input name="valorDoc" label="Valor" checkbox/>
              </div>
            </FormSection>
          </Col>
        </Row>
      </fieldset>
  );
}