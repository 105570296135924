// @flow
'use strict';

import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import CamposBasicos from './campos_basicos';
import CamposCest from './campos_cest';
import CamposOperacao from './campos_operacao';
import CamposIcms from './campos_impostos/campos_icms';
import CamposPis from './campos_impostos/campos_pis';
import CamposCofins from './campos_impostos/campos_cofins';

type CampoItemNfceProps = {
  component : React.ComponentType<*>,
}

export default function CampoItemNfce({ component } : CampoItemNfceProps) {
  return (
      <Row>
        <Col md={ 6 }>
          <CamposBasicos component={ component }/>
          <CamposCest component={ component }/>
          <CamposOperacao component={ component }/>
          <CamposPis component={ component }/>
          <CamposCofins component={ component }/>
        </Col>
        <Col md={ 6 }>
          <CamposIcms component={ component }/>
        </Col>
      </Row>
  );
}