// @flow
'use strict';

import * as React from 'react';

import type { IProdutoEspecifico } from '../../../types';

import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../../../common/detalhes_documento/table_detalhes';

type ProdutoEspecificoProps = {
  produtoEspecifico : IProdutoEspecifico,
};

export default function ProdutosEspecificos({ produtoEspecifico } : ProdutoEspecificoProps) {
  if (!produtoEspecifico)
    return null;

  const { veiculoNovo, medicamento, armamentos, combustivel, papelImune } = produtoEspecifico;

  return (
      <div id="table-dados-basicos">
        <h3>Produto Específico</h3>
        { veiculoNovo &&
        <>
          <h4>Veículos Novos</h4>
          <RowDetalhes data={ veiculoNovo } className="no-margin-bottom">
            <ColumnDetalhes field="tipoOperacao" width="25%">Tipo Operação</ColumnDetalhes>
            <ColumnDetalhes field="chassi" width="25%">Chassi</ColumnDetalhes>
            <ColumnDetalhes field="cor" width="25%">Cor</ColumnDetalhes>
            <ColumnDetalhes field="serie" width="25%">Série</ColumnDetalhes>
          </RowDetalhes>
          <RowDetalhes data={ veiculoNovo } className="no-margin-bottom">
            <ColumnDetalhes field="tipoCombustivel" width="25%">Tipo Combust.</ColumnDetalhes>
            <ColumnDetalhes field="numMotor" width="25%">Núm. Motor</ColumnDetalhes>
            <ColumnDetalhes field="anoModFabricacao" width="25%">Ano Mod. Fabric.</ColumnDetalhes>
            <ColumnDetalhes field="anoFabricacao" width="25%">Ano Fabric.</ColumnDetalhes>
          </RowDetalhes>
          <RowDetalhes data={ veiculoNovo }>
            <ColumnDetalhes field="tipoVeiculo" width="25%">Tipo Veículo</ColumnDetalhes>
            <ColumnDetalhes field="especieVeiculo" width="75%">Espécie Veículo</ColumnDetalhes>
          </RowDetalhes>
        </>
        }

        { medicamento &&
        <>
          <h4>Medicamentos / Mat. Prima Farmac.</h4>
          <RowDetalhes data={ medicamento }>
            <ColumnDetalhes field="codProdAnvisa" width="50%">Cód. Prod. ANVISA</ColumnDetalhes>
            <ColumnDetalhes field="precoMaxConsum" type="moeda" width="50%">Preço Máx. Consum.</ColumnDetalhes>
          </RowDetalhes>
        </>
        }

        { armamentos && armamentos.length > 0 &&
        <>
          <h4>Armamentos</h4>
          <TableDetalhes data={ armamentos } noDataClass="padding-10">
            <ColumnDetalhes field="indTipo" width="25%">Ind. Tipo</ColumnDetalhes>
            <ColumnDetalhes field="numSerieArma" width="25%">Núm. Série Arma</ColumnDetalhes>
            <ColumnDetalhes field="numSerieCano" width="25%">Núm. Série Cano</ColumnDetalhes>
            <ColumnDetalhes field="descricao" width="25%">Descrição</ColumnDetalhes>
          </TableDetalhes>
        </>
        }

        { combustivel &&
        <>
          <h4>Combustíveis</h4>
          <RowDetalhes data={ combustivel }>
            <ColumnDetalhes field="codProdAnp" width="25%">Cód. Prod. ANP</ColumnDetalhes>
            <ColumnDetalhes field="descricao" width="25%">Descrição</ColumnDetalhes>
            <ColumnDetalhes field="codAutoriz" width="25%">Cód. Autoriz.</ColumnDetalhes>
            <ColumnDetalhes field="ufConsumo" width="25%">UF Consumo</ColumnDetalhes>
          </RowDetalhes>
        </>
        }

        { papelImune &&
        <>
          <h4>Papel Imune</h4>
          <RowDetalhes data={ papelImune }>
            <ColumnDetalhes field="numRecopi">Núm. RECOPI </ColumnDetalhes>
          </RowDetalhes>
        </>
        }
      </div>
  );
}
