// @flow
'use strict';

import React from 'react';
import { Checkbox } from 'react-bootstrap';
import classnames from "classnames";

type SelecaoTodosProps = {
  nomes : string[],
  titulo : string,
  selecionarTodos : bool,
  tituloComQuebraDeLinha : bool,
  atualizaSelecaoTodos : (campo? : string[], valor : bool) =>  void,
}

export default class SelecaoTodos extends React.PureComponent<SelecaoTodosProps, *> {
  state = { checked: false };
  static defaultProps = { selecionarTodos: false, tituloComQuebraDeLinha: false };

  atualizaSelecaoTodos = () => {
    this.props.atualizaSelecaoTodos(this.props.nomes, !this.state.checked);
    this.setState({ checked: !this.state.checked });
  };

  render() {
    return (
        <div className={ classnames("selecao-todos-checkbox",
            { 'selecionar-todos': this.props.selecionarTodos },
            { 'titulo-com-quebra-de-linha': this.props.tituloComQuebraDeLinha }) }>

          <Checkbox onChange={ this.atualizaSelecaoTodos } checked={ this.state.checked }>
            <span className="selecao-todos-texto">
              { this.props.titulo }
            </span>
          </Checkbox>

        </div>
    );
  }
}