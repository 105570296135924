// @flow

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import * as React from 'react';

const client = Bugsnag.start({
  apiKey: 'fed3cd9f3863315f63232886a6cdfe19',
  enabledReleaseStages: ['staging', 'production'],
  releaseStage: detectReleaseStage(),
  plugins: [new BugsnagPluginReact()],
  logger: null,
});

function detectReleaseStage() {
  const { host } = window.location || {};

  switch (host) {
    case 'portal.consyste.com.br':
      return 'production';
    case 'hml.consyste.com.br':
      return 'staging';
    default:
      return 'development';
  }
}

export default client;

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
