// @flow
'use strict';

import React from 'react';
import { Button, Modal } from 'react-bootstrap';

type ModalProps = {
  open : bool,
  fechaModal : ()  => void,
}

export default function ModalInformacoes({ open, fechaModal } : ModalProps) {
  return (
      <Modal show={ open } onHide={ fechaModal } dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Busca Rápida</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            A busca rápida é sua principal ferramenta para encontrar documentos no Portal.
            Ao pesquisar diretamente, as palavras e números que você pesquisou serão verificadas nos campos:
            número, série, CNPJs e razão social dos envolvidos (emissor, destinatário, remetente, transportadora, etc).
          </p>
          <p>Para tornar a busca mais precisa, é possível utilizar operadores. Alguns exemplos:</p>

          <dl>
            <dt>
              <kbd>numero: 1234</kbd>
            </dt>
            <dd>Procura uma nota com o número &quot;1234&quot;.</dd>
            <dt>
              <kbd>numero: [1200 TO 1300]</kbd>
            </dt>
            <dd>Procura por notas com numeração entre 1200 e 1300.</dd>
            <dt>
              <kbd>serie: 2</kbd>
            </dt>
            <dd>Procura uma nota com a série &quot;2&quot;.</dd>
            <dt>
              <kbd>emit_nome: &quot;empresa de fornecimento&quot;</kbd>
            </dt>
            <dd>Procura por notas emitidas com as palavras &quot;empresa de fornecimento&quot; no nome do emissor.</dd>
          </dl>

          <p>
            Todos os operadores podem ser combinados – neste caso, só serão apresentados documentos
            que atendam a todos os critérios especificados. Para buscar documentos com critérios inclusivos,
            utilize &quot;OR&quot; entre os operadores:
          </p>

          <dl>
            <dt>
              <kbd>serie: 2 OR numero: 1234</kbd>
            </dt>
            <dd>Procura por notas com a série = &quot;2&quot; ou número = &quot;1234&quot;</dd>
          </dl>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={ fechaModal }>Fechar</Button>
        </Modal.Footer>
      </Modal>
  );
}