// @flow
'use strict';

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { formatHelper } from '../../../../../lib/viewUtils';
import { reiniciaCampos } from '../../../../../reducers/lista_documentos/functions';
import { fechaModal } from '../../../../../reducers/modal_exportacao_lista';
import {
  listaRelatoriosSelector, openRelatorioSelector, abreListaRelatorios, abreRelatorio, fechaRelatorio,
  fechaListaRelatorios,
} from '../../../../../reducers/modelo_relatorio/modal_escolha_tipo_relatorio';

import FormTipoDocumento from '../form_tipo_documento';
import ModalListaRelatorios from '../modal_lista_relatorios';

type TipoExportacaoProps = {
  totalDocumentos : number,
  tipo : string,
}

export default function ModalTipoExportacao({ totalDocumentos, tipo } : TipoExportacaoProps) {
  const openRelatorio   = useSelector(openRelatorioSelector),
        listaRelatorios = useSelector(listaRelatoriosSelector);

  const dispatch             = useDispatch(),
        abrirListaRelatorios = React.useCallback(() => dispatch(abreListaRelatorios()), [dispatch]);

  const abrirNovoRelatorio = React.useCallback(() => {
    dispatch(abreRelatorio());
    dispatch(reiniciaCampos(tipo));
  }, [dispatch, tipo]);

  const fechaModais = React.useCallback(() => {
    dispatch(fechaRelatorio());
    dispatch(fechaListaRelatorios());
    dispatch(fechaModal());
  }, [dispatch]);

  if (listaRelatorios) {
    return (
        <Modal show={ listaRelatorios } onHide={ fechaModais } dialogClassName="custom-modal">
          <ModalListaRelatorios tipo={ tipo }/>
        </Modal>
    );
  }

  if (openRelatorio) {
    return (
        <Modal show={ openRelatorio } onHide={ fechaModais } dialogClassName="custom-modal">
          <FormTipoDocumento tipo={ tipo }/>
        </Modal>
    );
  }

  return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Escolha do tipo de exportação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Um total de { formatHelper.numberWithDelimiter(totalDocumentos) } documentos
            constarão no arquivo exportado.</p>
          <p>Deseja fazer uma configuração nova ou recuperar um relatório anterior?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={ abrirNovoRelatorio }>Nova Configuração</Button>
          <Button type="button" bsStyle="primary" onClick={ abrirListaRelatorios }>Recuperar Configuração Salva</Button>
        </Modal.Footer>
      </>
  );
}
