// @flow
'use strict';

import CofinsAliq from './cofins_aliq';
import CofinsQtde from './cofins_qtde';
import CofinsNt from './cofins_nt';
import CofinsOutr from './cofins_outr';

export default {
  CofinsAliq, CofinsQtde, CofinsNt, CofinsOutr,
};