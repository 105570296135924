// @flow
'use strict';

import * as React from 'react';
import { Table } from 'react-bootstrap';

import type { IDetalheContainer } from '../../../types';

import ContainersInfNfes from './containers_inf_nfes';
import ContainersInfNfs from './containers_inf_nfs';
import NoDataRow from '../../../../../common/no_data_row';

type InformacaoContainerProps = {
  container : IDetalheContainer | null,
};

export default function InformacaoContainer({ container } : InformacaoContainerProps) {
  return (
      <Table className="table-condensed table-striped inner">
        <tbody>
        <tr>
          <th colSpan={ 3 }>Identificação do Container</th>
        </tr>
        <tr className={ container ? '' : 'nodata' }>
          <td colSpan={ 3 }>{ container ? container.idContainer : 'Nenhum Registro.' }</td>
        </tr>

        <tr>
          <th className="text-center" colSpan={ 3 }>Documentos dos Containers</th>
        </tr>
        <tr>
          <th className="text-center" colSpan={ 3 }>Informações das NFs</th>
        </tr>
        <tr>
          <th>Série</th>
          <th>Núm.</th>
          <th>Un. Medida Rateada</th>
        </tr>

        { container && container.infNfs && container.infNfs.length > 0 ?
            <ContainersInfNfs infNfs={ container.infNfs }/> : <NoDataRow span={ 3 }>Nenhum Registro.</NoDataRow>
        }

        <tr>
          <th className="text-center" colSpan={ 3 }>Informações das NF-es</th>
        </tr>
        <tr>
          <th colSpan={ 2 }>Chave da NF-e</th>
          <th>Un. Medida Rateada</th>
        </tr>

        { container && container.infNfes && container.infNfes.length > 0 ?
            <ContainersInfNfes infNfes={ container.infNfes }/> : <NoDataRow span={ 3 }>Nenhum Registro.</NoDataRow>
        }
        </tbody>
      </Table>
  );
}
