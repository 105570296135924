'use strict';

// @flow

import * as React from 'react';

import { connectEx, withProvider } from 'lib/reduxTools';
import type { IDocumento } from 'lib/api';
import { useEffectOnce } from 'lib/viewUtils';

import * as documentoActions from '../../reducers/documento';
import type { IActions } from './tipos';
import Paineis from './paineis';

type PainelLateralProps = {
  url : string,              // URL do documento
  documento? : ?IDocumento,  // objeto representando o documento
  env? : ?string,            // ambiente de execução do Rails - necessário para exibirmos opções de debug
  loading? : ?bool,          // informa se a tela está em estado de carregamento
  modeloId? : string,

  ignoreInitialize? : ?bool, // indica se deve ignorar initialize de documento no componentWillMount

  actions : IActions,        // ações
}

export function PainelLateral(props : PainelLateralProps) {
  useEffectOnce(() => {
    if (!props.ignoreInitialize)
        // inicializa a store com o documento que chegou via props
      props.actions.initialize(props.url);
  });

  return <Paineis { ...props } />;
}

/**
 * Conecta o componente à store do Redux e às actions.
 */
export default withProvider()(connectEx('documento', (documentoActions : IActions))(PainelLateral));
