// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { carregandoSelector, filtrosSelector } from '../../../../reducers/cte/resumo_lista';
import { filtrarResumoLista, opcaoResumoSelector } from '../../../../reducers/cte/filtro_livre';

import ResumoLista from '../../../common/resumo_lista/resumo_lista';

const mapStateToProps    = createStructuredSelector({
        carregando: carregandoSelector, filtros: filtrosSelector, filtroAtual: opcaoResumoSelector,
      }),
      mapDispatchToProps = {
        filtrarResumoLista,
      };

export default connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(ResumoLista);
