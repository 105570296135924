'use strict';
// @flow

import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useEffectOnce } from 'lib/viewUtils';

import { RenderedAlert } from './rendered_alert';
import type { AlertsProps } from './types';

import './alerts.css';

const FadeTransition = (props) => (
    <CSSTransition { ...props } classNames="novos-alertas" timeout={ { enter: 500, exit: 300 } }/>
);

export default function Alerts({ initialAlerts, alert, alerts = [], close } : AlertsProps) {
  useEffectOnce(() => {
    const ia = initialAlerts;
    if (ia && ia.length > 0)
      setTimeout(() => ia.forEach(a => alert(a.type, a.message)), 500);
  });

  const renderedAlerts = alerts.map(a => (
      <FadeTransition key={ a.seq }>
        <RenderedAlert alert={ a } close={ close }/>
      </FadeTransition>
  ));
  return (
      <section className="container alerts">
        <TransitionGroup>
          { renderedAlerts }
        </TransitionGroup>
      </section>
  );
}
