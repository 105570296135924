'use strict';
// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import VirtualizedSelect from 'react-virtualized-select';

import type { IOptionsSelector, IReduxFormInputProps } from './types';

type VirtualizedSelectControlProps = {
  input : IReduxFormInputProps,
  optionsProps : any,
}

export function VirtualizedSelectControl({ optionsProps, input } : VirtualizedSelectControlProps) {
  return <VirtualizedSelect { ...input } simpleValue { ...optionsProps }/>;
}

function mapStateToProps(state, ownProps : { optionsSelector : IOptionsSelector }) {
  return { optionsProps: ownProps.optionsSelector(state) };
}

export default connect<*, *, *, *, *, *>(mapStateToProps)(VirtualizedSelectControl);