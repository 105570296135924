'use strict';
// @flow

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { withProvider } from '../../../lib/reduxTools';
import { ajaxError, alert, clear, close, todosAlertasSelector } from '../../../reducers/alerts';

import Alerts from './alerts';

const mapStateToProps = createStructuredSelector({ alerts: todosAlertasSelector });

const connectedAlerts = connect<*, *, *, *, *, *>(mapStateToProps, { ajaxError, alert, clear, close })(Alerts);

export const AlertsApp = withProvider()(connectedAlerts);

export default connectedAlerts;
