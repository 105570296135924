// @flow
'use strict';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';

import { rootSelector, manifestaLoteNfe } from 'reducers/nfe/modal_manifestacao_lote';

import ModalManifestacaoLote from './modal_manifestacao_lote';

export default compose(
    connect<*, *, *, *, *, *>(createStructuredSelector({
      initialValues: rootSelector,
    })),
    reduxForm({
      form: 'manifestacaoLoteNfe',
      onSubmit: manifestaLoteNfe,
    }),
)(ModalManifestacaoLote);
