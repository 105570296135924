'use strict';

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import api from 'lib/api';
import { makeSearch } from 'lib/viewUtils';
import * as alerts from './alerts';

const LISTA_MUNICIPIOS      = 'municipios/LISTA_MUNICIPIOS',
      LISTA_MUNICIPIOS_OK   = 'municipios/LISTA_MUNICIPIOS_OK',
      LISTA_MUNICIPIOS_ERRO = 'municipios/LISTA_MUNICIPIOS_ERRO';

const INITIAL_STATE = Immutable.fromJS({
  carregando: false,
  items: [],
});

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LISTA_MUNICIPIOS:
      return state.set('carregando', true);

    case LISTA_MUNICIPIOS_OK:
      return state.set('carregando', false).set('items', Immutable.fromJS(action.items));

    case LISTA_MUNICIPIOS_ERRO:
      return state.set('carregando', false).set('items', Immutable.List());

    default:
      return state;
  }
}

export const rootSelector = (state) => state.get('municipios');

/**
 * Monta props para o componente react-select, contendo todos os municípios, otimizados para busca, e com um indicador
 * de quando estão carregando.
 *
 * Está estruturado em um selector para que seja aplicado memoization no resultado.
 */
export const municipiosParaSelect = createSelector(rootSelector, (muns) => {
  const options = muns.get('items').map(m => (
      { value: m.get('id'), label: `${m.get('nome')} / ${m.getIn(['uf', 'sigla'])}` }
  )).toArray();

  return {
    isLoading: muns.get('carregando'),
    filterOptions: makeSearch(options),
    options
  };
});

export const municipiosParaBusca = createSelector(rootSelector, (muns) => {
  const options = muns.get('items').map(m => (
    { value: m.get('codigo_ibge'), label: `${m.get('nome')} / ${m.getIn(['uf', 'sigla'])}` }
  )).toArray();

  return {
    isLoading: muns.get('carregando'),
    filterOptions: makeSearch(options),
    options
  };
});

/**
 * Carrega a lista de municípios, se necessário.
 */
export function carregaMunicipios() {
  return async function(dispatch, getState) {
    const mun = rootSelector(getState());
    if (mun.get('items').size > 0)
      return;

    await dispatch({ type: LISTA_MUNICIPIOS });

    try {
      const r = await api.municipios();

      await dispatch({ type: LISTA_MUNICIPIOS_OK, items: r.items });
    }
    catch (e) {
      await dispatch(alerts.alert('danger', 'Erro ao carregar lista de municípios.'));
      await dispatch({ type: LISTA_MUNICIPIOS_ERRO });
    }
  };
}
