// @flow
'use strict';

import * as Immutable from 'immutable';
import type { Action, Dispatch, State } from '../lib/types';

const ABRE_ALERTA  = 'alertaCertificadoExpirado/ABRE_ALERTA',
      FECHA_ALERTA = 'alertaCertificadoExpirado/FECHA_ALERTA';

const DEFAULT_STATE = Immutable.fromJS({
  open: false
});

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action = {}) {
  switch (action.type) {
    case ABRE_ALERTA:
      return state.set('open', true);
    case FECHA_ALERTA:
      return state.set('open', false);
    default:
      return state;
  }
}

export const openAlertaSelector = (state : State) => state.getIn(['alertaCertificadoExpirado', 'open'], false);

export function fechaAlerta() {
  return function(dispatch : Dispatch<*>) {
    dispatch({ type: FECHA_ALERTA });
  };
}

export function abreAlerta() {
  return function(dispatch : Dispatch<*>) {
    dispatch({ type: ABRE_ALERTA });
  };
}
