// @flow
'use strict';

import * as React from 'react';

import type { ItemProps } from '../types';
import { tipoDocumento } from './abas_informacoes';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';
import TableDetalhesComLinhas from '../../../common/detalhes_documento/table_detalhes_com_linhas';
import DadosComplementares from './dados_complementares';

export default function Cte({ item } : ItemProps) {
  const atores = {
    Emitente: item.emitente,
    Tomador: item.tomador,
  };

  const opcoesAtores = Object.entries(atores)
      .filter(([, v]) => !!v)
      .map(([k, v]) =>
          (
              <div key={ k }>
                <h3>{ k }</h3>
                <RowDetalhes data={ v }>
                  <ColumnDetalhes field="cnpj" type="documento" width="18%">{ tipoDocumento(v) }</ColumnDetalhes>
                  <ColumnDetalhes field="nomeRazaoSocial" width="60%">Nome / Razão Social</ColumnDetalhes>
                  <ColumnDetalhes field="ie" width="12%">IE</ColumnDetalhes>
                  <ColumnDetalhes field="uf" width="10%">UF</ColumnDetalhes>
                </RowDetalhes>
              </div>
          ));

  return (
      <div className="tab-pane active">
        <h3>Dados do CT-e</h3>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="numero" width="33%">Número</ColumnDetalhes>
          <ColumnDetalhes field="serie" width="15%">Série</ColumnDetalhes>
          <ColumnDetalhes field="modeloId" type="modelo" width="18%">Modelo</ColumnDetalhes>
          <ColumnDetalhes field="emitidoEm" type="dataHora" width="33%">Data de Emissão</ColumnDetalhes>
        </RowDetalhes>

        <h3>Valores</h3>
        <RowDetalhes data={ item.valores }>
          <ColumnDetalhes field="valorTotal" type="moeda" width="33%">Valor Total Serviço</ColumnDetalhes>
          <ColumnDetalhes field="baseCalculoIcms" type="moeda" width="33%">Base Cálculo ICMS</ColumnDetalhes>
          <ColumnDetalhes field="valorIcms" type="moeda" width="33%">Valor ICMS</ColumnDetalhes>
        </RowDetalhes>

        { opcoesAtores }

        <h3>Características</h3>
        <TableDetalhesComLinhas data={ item.caracteristicas }>
          <tr>
            <ColumnDetalhes field="modal" width="20%">Modal</ColumnDetalhes>
            <ColumnDetalhes field="tipoServico" width="30%">Tipo Serviço</ColumnDetalhes>
            <ColumnDetalhes field="finalidade" width="20%">Finalidade</ColumnDetalhes>
            <ColumnDetalhes field="forma" width="30%">Forma</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="cfop">CFOP</ColumnDetalhes>
            <ColumnDetalhes field="naturezaPrestacao" colSpan={ 2 }>Natureza da Prestação</ColumnDetalhes>
            <ColumnDetalhes field="digestValue"><i>Digest Value</i> do CT-e</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="inicioPrestacao" colSpan={ 2 }>Início da Prestação</ColumnDetalhes>
            <ColumnDetalhes field="fimPrestacao" colSpan={ 2 }>Fim da Prestação</ColumnDetalhes>
          </tr>
        </TableDetalhesComLinhas>

        { item.dadosComplementares && <DadosComplementares dadosComplementares={ item.dadosComplementares }/> }
      </div>
  );
}
