// @flow

import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { todosSelector, carregandoSelector } from '../../../../../reducers/dashboard/empresa';

import SelecaoEmpresa from './SelecaoEmpresa';
import { formatoTela } from '../../../../common/detalhes_documento/formato_tela';

const empresasSelector = createSelector(todosSelector,
    (empresas) =>
        empresas
            .map(empresa => ({
              value: empresa.get('id'),
              label: `${ formatoTela('documento', empresa.get('cnpj', '')) || '' }${ nomeFantasiaEmpresa(empresa.get('fantasia')) }`,
            }))
            .toArray());

function nomeFantasiaEmpresa(fantasia : ?string) {
  if (!fantasia)
    return '';

  return ` - ${ fantasia }`;
}

// seletor composto, substitui a função mapStateToProps
const mapStateToProps = createStructuredSelector({ opcoesEmpresas: empresasSelector, carregando: carregandoSelector });

export default connect<*, *, *, *, *, *>(mapStateToProps)(SelecaoEmpresa);