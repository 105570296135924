// @flow
'use strict';

import * as React from 'react';
import ReactSelect from 'react-select';
import { Button, ControlLabel, FormControl, FormGroup, Navbar } from 'react-bootstrap';

import { SITUACAO_FORNECEDOR } from '../formulario/formulario';
import Icone from 'components/common/icone';

type BuscaBasicaProps = {
  filtros : {
    cnpj : ?string, contaId : ?string, nome : ?string, municipio : ?string, ativo : ?string
  },
  mudaFiltro : (string, any) => void,
  limpaFiltro : () => void,
  aplicaFiltro : () => void,
  contas : Array<{ label : string, value : string }>,
};

export default function CamposBusca({ filtros, mudaFiltro, limpaFiltro, aplicaFiltro, contas } : BuscaBasicaProps) {
  const {
          cnpj, contaId, nome, ativo,
        } = filtros || {};

  const handleSetFor    = React.useCallback((k) => v => mudaFiltro(k, v), [mudaFiltro]),
        handleChangeFor = React.useCallback((k) => e => mudaFiltro(k, e.target.value), [mudaFiltro]);

  const reiniciaFiltro = React.useCallback(() => {
    limpaFiltro();
    aplicaFiltro();
  }, [limpaFiltro, aplicaFiltro]);

  const filtroConta  = (
      <FormGroup controlId="contaId">
        <ControlLabel srOnly>Conta</ControlLabel>
        <ReactSelect options={ contas } placeholder="Conta" value={ contaId }
                     style={ { width: '20em' } } simpleValue
                     onChange={ handleSetFor('contaId') }/>
      </FormGroup>
  ), filtroCNPJ      = (
      <FormGroup controlId="cnpj">
        <ControlLabel srOnly>CNPJ do Prestador</ControlLabel>
        <FormControl name="cnpj" placeholder="CNPJ do Prestador" type="text" value={ cnpj }
                     onChange={ handleChangeFor('cnpj') }
                     style={ { width: '12em' } }/>
      </FormGroup>
  ), filtroNome      = (
      <FormGroup controlId="nome">
        <ControlLabel srOnly>Nome do Prestador</ControlLabel>
        <FormControl name="nome" placeholder="Nome do Prestador" type="text" value={ nome }
                     onChange={ handleChangeFor('nome') }
                     style={ { width: '20em' } }/>
      </FormGroup>
  ), filtroAtivo     = (
      <FormGroup>
        <ControlLabel srOnly>Ativo</ControlLabel>
        <ReactSelect options={ SITUACAO_FORNECEDOR } placeholder="Ativo" value={ ativo }
                     style={ { width: '5em' } } simpleValue
                     onChange={ handleSetFor('ativo') }/>
      </FormGroup>
  );

  return (
      <Navbar fluid className="sped-busca-basica">
        <Navbar.Header><Navbar.Brand>Filtros</Navbar.Brand></Navbar.Header>

        <Navbar.Form>
          { contas && contas.length > 1 && filtroConta } { ' ' }
          { filtroCNPJ } { ' ' }
          { filtroNome } { ' ' }
          { filtroAtivo } { ' ' }
        </Navbar.Form>

        <Navbar.Form className="botoes">
          <div className="pull-right">
            <Button type="reset" onClick={ reiniciaFiltro }>
              <Icone nome="eraser" estilo="fas">Limpar</Icone>
            </Button> { ' ' }
            <Button type="submit" bsStyle="primary">
              <Icone nome="filter" estilo="far">Filtrar</Icone>
            </Button>
          </div>
          <div className="clearfix"/>
        </Navbar.Form>
      </Navbar>
  );
}
