'use strict';
// @flow

import React from 'react';
import { Remarkable } from 'remarkable';
import { linkify } from 'remarkable/linkify';

type MarkdownProps = {
  /** texto (no formato Markdown) a apresentar */
  text : string,
  breaks? : bool,
}

export default function Markdown({ text, ...props } : MarkdownProps) {
  const md = React.useMemo(() => (
      new Remarkable({
        breaks: false, ...props,
        html: true, xhtmlOut: false, langPrefix: 'language-',
        typographer: true, quotes: '“”‘’',
      }).use(linkify)
  ), [props]);

  const html = React.useMemo(() => md.render(text), [md, text]);

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={ { __html: html } }/>;
}
