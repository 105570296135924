'use strict';
// @flow

import * as React from 'react';
import classnames from 'classnames';

import type { DataRowProps } from './types';

/**
 * Componente padrão para montar as linhas da tabela. Basicamente delega a renderização de cada
 * célula ao próprio componente Column.
 *
 * @param row a linha a apresentar.
 * @param columns a lista de colunas a apresentar.
 * @param onClick código a ser chamado quando a linha for clicada.
 */
export default function DataRow({ row, columns, onClick } : DataRowProps<*>) {
  const handleClick = React.useCallback(() => onClick && onClick(row), [onClick, row]);

  const trProps = !onClick
      ? {}
      : { onClick: handleClick, className: classnames(row.cssClasses, 'cursor-pointer') };

  return (
      <tr { ...trProps }>
        { columns.map((col : React.Element<any>, i) => React.cloneElement(col, { row, key: i })) }
      </tr>
  );
}
