'use strict';
// @flow

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import type { ISpedArquivoCompleto } from '../types';
import * as detalhes from '../../../../reducers/sped/arquivos/detalhes';
import { jsSelector } from '../../../../lib/viewUtils';

import DadosArquivo from './dados_arquivo';
import DadosEmpresa from './dados_empresa';
import ListaDocumentos from './lista_documentos';
import Opcoes from './opcoes';
import Filtros from './filtros';

type DetalhesArquivoSpedProps = {
  arquivo? : ISpedArquivoCompleto,
  routeParams : { id : string };
  location : { query : {} },
};

export function DetalhesArquivoSped({ arquivo, routeParams, location } : DetalhesArquivoSpedProps) {
  return (
      <div>
        <Row>
          <Col md={ 6 }> <DadosEmpresa empresa={ arquivo && arquivo.empresa }/> </Col>
          <Col md={ 6 }> <DadosArquivo arquivo={ arquivo }/> </Col>
        </Row>
        <Filtros routeParams={ routeParams } location={ location } arquivo={ arquivo }/>
        <ListaDocumentos arquivo={ arquivo } location={ location }/>
        <Opcoes arquivo={ arquivo } id={ routeParams.id } params={ location.query }/>
      </div>
  );
}

const mapStateToProps = createStructuredSelector({
  arquivo: jsSelector(detalhes.itemSelector),
});

export default connect<*, *, *, *, *, *>(mapStateToProps)(DetalhesArquivoSped);