'use strict';
// @flow

import * as React from 'react';
import classnames from 'classnames';

import type { DataRowProps } from 'components/common/data_table/types';

export default function DataRowColor({ row, columns, onClick } : DataRowProps<*>) {
  const handleClick = React.useCallback(() => onClick && onClick(row), [onClick, row]);

  const className = classnames([
    'cursor-pointer',
    corColuna(row.ativo),
  ]);

  return (
      <tr className={ className } onClick={ handleClick }>
        { columns.map((col : React.Element<any>, i) => React.cloneElement(col, { row, key: i })) }
      </tr>
  );
}

function corColuna(ativo : bool) {
  if (ativo === false)
    return 'disabled';

  return '';
}
