// @flow
'use strict';

import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import type { IHistorico } from './types';

import DataTable from '../../common/data_table/data_table';
import { Column } from '../../common/data_table';

type ModalHistoricoProps = {
  open : bool,
  fechaModal : () => void,
  item : { historico : IHistorico[] },
}

export default function ModalHistorico({ item, open, fechaModal } : ModalHistoricoProps) {
  const historico = item.historico && item.historico.length > 0 ? item.historico : null;

  return (
      <Modal show={ open } onHide={ fechaModal } dialogClassName="modal-historico">
        <Modal.Header closeButton>
          <Modal.Title>Histórico do Documento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable className="table table-condensed table-striped"
                     data={ historico }>
            <Column expr="data" align="left" width="10em">Data</Column>
            <Column expr="usuario.nome,usuario.email" align="center" hasTitle formatter={ usuario }>Usuário</Column>
            <Column expr="operacao.descricao,operacao.observacao" align="left" formatter={ operacao }>
              Operação
            </Column>
          </DataTable>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={ fechaModal }>Fechar</Button>
        </Modal.Footer>
      </Modal>
  );
}

export function usuario(sn : string[]) {
  if (sn.length === 2 && !sn[0] && !sn[1])
    return '-';

  return sn;
}

export function operacao(sn : string[]) {
  if (sn.length === 2 && sn[1]) {
    return (
        <span>
          { sn[0] }
          <em>{ ` (${ sn[1] })` }</em>
        </span>
    );
  }

  return sn[0] || '-';
}
