// @flow
'use strict';

import * as React from 'react';

import { formatHelper } from 'lib/viewUtils';
import { permiteDownload } from './util';

import EsqueletoModal from '../../common/esqueleto_modal';

type ModalProps = {
  open : bool,
  limiteDownload : number,
  totalDocumentos : number,
  onConfirma() : void,
  fechaModal() : void,
}

export default function ModalDownload({
                                        open, limiteDownload, totalDocumentos, onConfirma, fechaModal,
                                      } : ModalProps) {

  const baseProps = { open, onClose: fechaModal };

  if (!permiteDownload(totalDocumentos, limiteDownload)) {
    return (
        <EsqueletoModal { ...baseProps } acceptLabel="Ok" onAccept={ fechaModal } hideCancel>
          <p>Você solicitou o download de { formatHelper.numberWithDelimiter(totalDocumentos) } documentos,
            que é superior ao limite de { formatHelper.numberWithDelimiter(limiteDownload) } documentos.</p>
          <p>Por favor, refine sua busca, de forma a reduzir a quantidade de documentos no mesmo download.</p>
        </EsqueletoModal>
    );
  }

  return (
      <EsqueletoModal { ...baseProps } acceptLabel="Confirmar" onAccept={ onConfirma }>
        <p>Você solicitou o download dos documentos da busca atual,
          que compreende { formatHelper.numberWithDelimiter(totalDocumentos) } documentos.</p>
        <p>O progresso da operação poderá ser acompanhado pela opção <q>Downloads</q>, no menu lateral.</p>
        <p>Confirma a solicitação?</p>
      </EsqueletoModal>
  );
}