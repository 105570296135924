// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosCte from './selecao_todos_cte';
import { Input } from '../../../../../../common/input';

export default function CamposSimplesCarga() {
  return (
      <FormSection name="basicoCarga">
        <SelecaoTodosCte nomes={ ["basicoCarga"] } titulo="Dados da Carga"/>

        <div className="grupo-checkbox">
          <Input name="valorTotalCarga" label="Valor Total" checkbox/>
          <Input name="produtoPredominante" label="Produto Predominante" checkbox/>
          <Input name="outrasCaracteristicas" label="Outras Características" checkbox/>
        </div>
      </FormSection>
  );
}
