// @flow
'use strict';

import * as React from 'react';
import { Col, ControlLabel, Row } from 'react-bootstrap';

import { Input } from '../input';

type CampoSituacaoCustodiaProps = {
  campos : { label : string, name : string }[],
}

export default function CampoSituacaoCustodia({ campos } : CampoSituacaoCustodiaProps) {
  return (
      <Row className="campo-formulario campo-input">
        <ControlLabel className="col-sm-4 titulo-checkbox">Situação de Custódia</ControlLabel>
        <Col sm={ 8 } className="opcao-checkbox margin-bottom-1">
          { campos && campos.length > 0 &&
              campos.map((c, k) => <Input key={ k } name={ c.name } label={ c.label } checkbox/>)
          }
        </Col>
      </Row>
  );
}
