// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';
import { Col, Row } from 'react-bootstrap';

import SelecaoTodosNfe from './selecao_todos_nfe';
import { Input } from '../../../../../../common/input';

export default function CamposCobranca() {
  return (
      <fieldset className="grupo-download-lista">
        <legend>Cobrança</legend>
        <p className="instrucao-em-titulo">A lista retornará uma linha para cada parcela.</p>

        <Row>
          <Col md={ 6 }>
            <FormSection name="cobranca">
              <SelecaoTodosNfe nomes={ ["cobranca"] } titulo="Dados da Cobrança"/>

              <div className="grupo-checkbox">
                <Input name="faturaNumero" label="Número da Fatura" checkbox/>
                <Input name="faturaValorOriginal" label="Valor Original da Fatura" checkbox/>
                <Input name="faturaValorDesconto" label="Valor do Desconto" checkbox/>
                <Input name="faturaValorLiquido" label="Valor Líquido da Fatura" checkbox/>
              </div>
            </FormSection>
          </Col>
          <Col md={ 6 }>
            <FormSection name="parcela">
              <SelecaoTodosNfe nomes={ ["parcela"] } titulo="Parcelas"/>

              <div className="grupo-checkbox">
                <Input name="parcelaNumero" label="Número da Parcela" checkbox/>
                <Input name="parcelaDataVencimento" label="Data de Vencimento	" checkbox/>
                <Input name="parcelaValor" label="Valor da Parcela" checkbox/>
              </div>
            </FormSection>
          </Col>
        </Row>
      </fieldset>
  );
}