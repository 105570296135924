// @flow
'use strict';

import * as React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { COL_MD_3, COL_MD_4 } from '../constantes';

import SelecaoConta from './SelecaoConta';
import SelecaoEmpresa from './SelecaoEmpresa';
import Icone from 'components/common/icone';

type BuscaBasicaProps = {
  total : number,
  open : bool,
  handleSubmit : () => void,
  inicializaBuscaEstruturada : () => void,
  openInformacoes : bool,
  abreModalInformacoes : () => void,
};

export default function CamposBusca({ handleSubmit } : BuscaBasicaProps) {
  return (
      <div className="container-fluid filtro-dashboard">
        <Form onSubmit={ handleSubmit } horizontal>
          <Row>
            <Col { ...COL_MD_3 } className="busca-dashboard">
              <SelecaoConta/>
            </Col>
            <Col { ...COL_MD_4 } className="busca-dashboard">
              <SelecaoEmpresa/>
            </Col>
            <Button type="submit" bsStyle="primary">
              <Icone nome="filter" estilo="far">Filtrar</Icone>
            </Button>
          </Row>
        </Form>
      </div>
  );
}