// @flow
'use strict';

import { connect } from 'react-redux';

import { abreModal } from '../../../../../../reducers/nfce/modal_exportacao_lote';

import BotaoOpcaoPdf from '../../../../../common/botoes_exportacao_lote/botao_opcao_pdf';

const mapDispatchToProps = {
  abreModalPdf: () => abreModal('pdf'),
};

export default connect<*, *, *, *, *, *>(null, mapDispatchToProps)(BotaoOpcaoPdf);
