// @flow
'use strict';

import * as React from 'react';

import type { ItemProps } from '../types';
import { tipoDocumento } from './abas_informacoes';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../common/detalhes_documento/table_detalhes';
import TableDetalhesComLinhas from '../../../common/detalhes_documento/table_detalhes_com_linhas';
import DocumentosFiscaisReferenciados from './documentos_fiscais_referenciados';

export default function Nfe({ item } : ItemProps) {
  return (
      <div className="tab-pane active">
        <h3>Dados da NF-e</h3>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="numero" width="15%">Número</ColumnDetalhes>
          <ColumnDetalhes field="serie" width="15%">Série</ColumnDetalhes>
          <ColumnDetalhes field="modeloId" type="modelo" width="20%">Modelo</ColumnDetalhes>
          <ColumnDetalhes field="emitidoEm" type="dataHora" width="30%">Data de Emissão</ColumnDetalhes>
          <ColumnDetalhes field="valor" type="moeda" width="20%">Valor Total</ColumnDetalhes>
        </RowDetalhes>

        <h3>Emitente</h3>
        <RowDetalhes data={ item.emitente }>
          <ColumnDetalhes field="cnpj" type="documento" width="25%">{ tipoDocumento(item.emitente) }</ColumnDetalhes>
          <ColumnDetalhes field="nome">Nome / Razão Social</ColumnDetalhes>
          <ColumnDetalhes field="inscricaoEstadual" width="15%">IE</ColumnDetalhes>
          <ColumnDetalhes field="uf" width="8%">UF</ColumnDetalhes>
        </RowDetalhes>

        <h3>Destinatário</h3>
        <RowDetalhes data={ item.destinatario }>
          <ColumnDetalhes field="cnpj" type="documento" width="25%">{ tipoDocumento(item.destinatario) }</ColumnDetalhes>
          <ColumnDetalhes field="nome">Nome / Razão Social</ColumnDetalhes>
          <ColumnDetalhes field="inscricaoEstadual" width="15%">IE</ColumnDetalhes>
          <ColumnDetalhes field="uf" width="8%">UF</ColumnDetalhes>
        </RowDetalhes>

        <h3>Emissão</h3>
        <TableDetalhesComLinhas data={ item.emissao }>
          <tr>
            <ColumnDetalhes field="tipoOperacao" width="25%">Tipo de Operação</ColumnDetalhes>
            <ColumnDetalhes field="destinoOperacao" width="29%">Destino da Operação</ColumnDetalhes>
            <ColumnDetalhes field="tipoEmissao" width="21%">Tipo de Emissão</ColumnDetalhes>
            <ColumnDetalhes field="intermedMarket" width="25%">Intermediador / Marketplace</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="finalidade">Finalidade</ColumnDetalhes>
            <ColumnDetalhes field="processoEmissao">Processo de Emissão</ColumnDetalhes>
            <ColumnDetalhes field="versaoProcesso">Versão do Processo</ColumnDetalhes>
            <ColumnDetalhes field="digestValue"><i>Digest Value</i> da NF-e</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="naturezaOperacao" colSpan={ 4 }>Natureza da Operação</ColumnDetalhes>
          </tr>
        </TableDetalhesComLinhas>

        <h3>Eventos</h3>
        <TableDetalhes data={ item.eventos } noDataClass="padding-10" noDataMsg="Nenhum evento para a empresa.">
          <ColumnDetalhes field="data">Data</ColumnDetalhes>
          <ColumnDetalhes field="tipo">Tipo</ColumnDetalhes>
          <ColumnDetalhes field="protocolo">Protocolo</ColumnDetalhes>
        </TableDetalhes>

         <DocumentosFiscaisReferenciados docFiscaisReferenciados={ item.docFiscaisReferenciados }/>
      </div>
  );
}
