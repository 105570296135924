// @flow
'use strict';

import * as React from 'react';

import type { ICofinsQtde, ICofinsOutr } from '../../../../../types_imposto';

import RowDetalhes from '../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../common/detalhes_documento/column_detalhes';

type ValorCofinsProps = {
  item : ICofinsQtde | ICofinsOutr,
};

export default function ValorCofins({ item } : ValorCofinsProps) {
  return (
      <RowDetalhes data={ item }>
        <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
        <ColumnDetalhes field="valor" type="moeda" width="75%">Valor COFINS</ColumnDetalhes>
      </RowDetalhes>
  );
}
