// @flow

import type { PaginationProps } from './types';

export const DEFAULT_PROPS : PaginationProps = {
  page: 1, pageSize: 25, total: 0,
  showPageEntries: true, hasLastButton: true, label: 'registro',
};

/**
 * Calcula todos os valores para a utilização do componente Pagination.
 *
 * @returns {*} Objeto contendo os valores para o props do Pagination (React-bootstrap)
 */
export function calcPaginationProps({ page = 1, pageSize = 25, total = 0 } : { page? : number, pageSize? : number, total? : number }) {
  const pageCount   = Math.ceil(total / pageSize),
        firstOfPage = ((page - 1) * pageSize) + 1,
        lastOfPage  = Math.min(total, page * pageSize);

  return { page, pageSize, total, pageCount, firstOfPage, lastOfPage };
}
