// @flow
'use strict';

import * as React from 'react';

import type { IIssqn } from '../../../../types_imposto';

import RowDetalhes from '../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../common/detalhes_documento/column_detalhes';

type IssqnProps = {
  item : IIssqn,
};

export default function Issqn({ item } : IssqnProps) {
  return (
      <div id="table-issqn">
        <h4>ISSQN</h4>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="baseCalculo" type="moeda" width="25%">Base de Cálculo</ColumnDetalhes>
          <ColumnDetalhes field="aliquota" type="percentual" width="25%">Alíquota</ColumnDetalhes>
          <ColumnDetalhes field="valor" type="moeda" width="25%">Valor</ColumnDetalhes>
          <ColumnDetalhes field="munOcorrencia" width="25%">Município de Ocorrência</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="item" width="25%">Item</ColumnDetalhes>
          <ColumnDetalhes field="deducao" type="moeda" width="25%">Dedução</ColumnDetalhes>
          <ColumnDetalhes field="outrasRetencoes" type="moeda" width="25%">Outras Retenções</ColumnDetalhes>
          <ColumnDetalhes field="descontoIncond" type="moeda" width="25%">Desconto Incondicionado</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="descontoCond" type="moeda" width="25%">Desconto Condicionado</ColumnDetalhes>
          <ColumnDetalhes field="retencaoIss" type="moeda" width="25%">Retenção ISS</ColumnDetalhes>
          <ColumnDetalhes field="indicadorExibilidade" width="25%">Indicador da Exigibilidade</ColumnDetalhes>
          <ColumnDetalhes field="codServico" width="25%">Código do Serviço</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="munIncidencia" width="25%">Município de Incidência</ColumnDetalhes>
          <ColumnDetalhes field="pais" width="25%">País</ColumnDetalhes>
          <ColumnDetalhes field="processoSuspencaoIss" width="25%">Processo de Suspensão de ISS</ColumnDetalhes>
          <ColumnDetalhes field="incentivoFiscal" width="25%">Incentivo Fiscal</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
