// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfe from '../form_exportacao_nfe/campos_nfe/selecao_todos_nfe';
import { Input } from '../../../../../common/input';

type CamposTransporteProps = {
  tipoDocumento : string,
  component : React.ComponentType<*>,
}

export default function CamposTransporte({ tipoDocumento, component } : CamposTransporteProps) {
  const SelecaoTodos = component || SelecaoTodosNfe;

  return (
      <FormSection name="transporte">
        <SelecaoTodos nomes={ ["transporte"] } titulo="Dados do Transporte"/>

        <div className="grupo-checkbox">
          <Input name="modalidadeFrete" label="Modalidade do Frete" checkbox/>
          <Input name="cnpjTransportador" label="CNPJ do Transportador" checkbox/>
          <Input name="cpfTransportador" label="CPF do Transportador" checkbox/>
          <Input name="razaoSocialTransportador" label="Razão Social do Transportador" checkbox/>
          <Input name="ieTransportador" label="IE do Transportador" checkbox/>
          <Input name="enderecoTransportador" label="Endereço do Transportador" checkbox/>
          <Input name="municipioTransportador" label="Município do Transportador" checkbox/>
          <Input name="ufTransportador" label="UF do Transportador" checkbox/>

          { tipoDocumento === 'nfe' && <CamposRetencaoEVeiculo/> }
        </div>
      </FormSection>
  );
}

function CamposRetencaoEVeiculo() {
  return (
      <React.Fragment>
        <Input name="valorServicoRetencao" label="Valor do Serviço" checkbox/>
        <Input name="valorBcRetencao" label="Base Calculo Retenção do ICMS" checkbox/>
        <Input name="aliquotaRetencao" label="Alíquota da Retenção" checkbox/>
        <Input name="valorIcmsRetencao" label="ICMS Retido" checkbox/>
        <Input name="cfopTransporte" label="CFOP do Transporte" checkbox/>
        <Input name="codigoMunicipioFatoGerador" label="Cód. Município Fato Gerador" checkbox/>
        <Input name="placaVeiculo" label="Placa do Veículo" checkbox/>
        <Input name="ufVeiculo" label="UF do Veículo" checkbox/>
        <Input name="rntcVeiculo" label="RNTC" checkbox/>
        <Input name="identificacaoVagao" label="Identificação do Vagão" checkbox/>
        <Input name="identificacaoBalsa" label="Identificação da Balsa" checkbox/>
      </React.Fragment>
  );
}