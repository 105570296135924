// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfe from '../../form_exportacao_nfe/campos_nfe/selecao_todos_nfe';
import { Input } from '../../../../../../common/input';

type CamposItemProps = {
  component : React.ComponentType<*>,
}

export default function CamposOperacao({ component } : CamposItemProps) {
  const SelecaoTodos = component || SelecaoTodosNfe;
  const tipoNfe = (SelecaoTodos === SelecaoTodosNfe);

  return (
      <FormSection name="dadosOperacao">
        <SelecaoTodos nomes={ ['dadosOperacao'] } titulo="Dados da Operação"/>

        <div className="grupo-checkbox">
          { tipoNfe && <Input name="numeroItem" label="Nro. Item" checkbox/> }
          <Input name="cfopItem" label="CFOP" checkbox/>
          <Input name="beneficioFiscal" label="Benefício Fiscal" checkbox/>
          <Input name="qtdComercial" label="Quantidade Comercial" checkbox/>
          <Input name="valorUnitarioComercial" label="Valor Unitário Comercial" checkbox/>
          <Input name="valorTotalBruto" label="Valor Total" checkbox/>
          <Input name="qtdTributavel" label="Quantidade Tributável" checkbox/>
          <Input name="valorUnitarioTributavel" label="Valor Unitário Tributável" checkbox/>
          <Input name="valorFrete" label="Valor do Frete" checkbox/>
          <Input name="valorSeguro" label="Valor do Seguro" checkbox/>
          <Input name="valorDesconto" label="Valor Desconto" checkbox/>
          <Input name="outrasDespesas" label="Outras Despesas" checkbox/>
          <Input name="itemCompoeValorTotal" label="Compõe Valor Total da NFe" checkbox/>
          <Input name="pedidoNro" label="Pedido de Compra" checkbox/>

          { tipoNfe && <Input name="pedidoItem" label="Item do Pedido Compra" checkbox/> }

          <Input name="numeroFci" label="Número FCI" checkbox/>

          { tipoNfe && <Input name="informacoesAdicionaisProduto" label="Informações Adicionais" checkbox/> }
          { tipoNfe && <Input name="valorAproxTributos" label="Valor Aprox. Tributos" checkbox/> }
        </div>
      </FormSection>
  );
}