// @flow
'use strict';

import * as React from 'react';

import type { IIcms102 } from '../../../../../../types_imposto';

import ColumnDetalhes from '../../../../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../../../../common/detalhes_documento/row_detalhes';

type Icms102Props = {
  item : IIcms102,
};

export default function Icms102({ item } : Icms102Props) {
  return (
      <RowDetalhes data={ item }>
        <ColumnDetalhes field="origem" width="50%">Origem da Mercadoria</ColumnDetalhes>
        <ColumnDetalhes field="csosn" width="50%">CSOSN</ColumnDetalhes>
      </RowDetalhes>
  );
}
