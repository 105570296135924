// @flow
'use strict';

import * as React from 'react';
import { Table } from 'react-bootstrap';

type RowDetalhesProps = {
  data : any,
  children? : any,
  className? : string,
  headerRow? : bool,
}

const CLASSNAME_TABLE = 'table-condensed table-striped inner';

export default function RowDetalhes({ data, children, className = CLASSNAME_TABLE, headerRow = true } : RowDetalhesProps) {
  const colunas : Array<React.Element<any>> = (function() {
    if (!children)
      return [];
    if (!Array.isArray(children))
      return [children];

    return children;
  })();

  const classNameRow = className ? `${ CLASSNAME_TABLE } ${ className }` : CLASSNAME_TABLE;

  const header = headerRow ? <tr>{ colunas.map((c, i) => <th key={ i }>{ c.props.children }</th>) }</tr> : <tr></tr>;

  return (
      <Table className={ classNameRow }>
        <colgroup>
          { colunas.map((c, i) => <col key={ i } style={ { width: c.props.width || undefined } }/>) }
        </colgroup>
        <tbody>
        { header }
        <tr>
          { colunas.map((c, i) => React.cloneElement(c, { data, key: i })) }
        </tr>
        </tbody>
      </Table>
  );
}
