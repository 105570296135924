'use strict';
// @flow

import _ from 'lodash';
import { SubmissionError } from 'redux-form/immutable';

import { HttpError, ServerValidationError, UnauthorizedError } from './errors';

export type IFetchOptions = {
  credentials? : 'include',

  headers? : { [k : string] : string }
};

type HttpMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';

export function json(method : HttpMethod, body : any) {
  return {
    method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };
}

export async function fetchJSON<T>(uri : string, opts : IFetchOptions = {}) : Promise<T> {
  if (!opts.credentials)
    opts.credentials = 'include';

  if (!opts.headers)
    opts.headers = {};

  if (!opts.headers['Accept'])
    opts.headers['Accept'] = 'application/json';

  const r = await global.fetch(uri, opts);
  return await handleCrudResponse(r);
}

async function handleCrudResponse(r) : Promise<*> {
  // se a API não permite a operação, lança um erro
  if (r.status === 401)
    throw new UnauthorizedError('Usuário não autenticado ou sem permissão');

  // se o retorno não possui dados, retorna undefined
  if (r.status === 204)
    return;

  // se ocorreram erros de validação, lança um erro
  if (r.status === 422) {
    const json = await r.json();

    // se temos dados sobre a validação, repassa para o redux-form interpretar.
    // caso contrário, apenas lança um erro genérico
    if (json && json['errors'])
      throw new SubmissionError(_.mapValues(json['errors'], v => v.join(', ')));
    else
      throw new ServerValidationError('Ocorreram erros de validação', json);
  }

  // se ocorreu algum outro erro, lança um erro genérico
  if (r.status < 200 || r.status >= 300)
    throw new HttpError(`Erro ao comunicar com o servidor: ${r.status}`, r);

  // caso contrário, retorna o JSON recebido pela API
  return await r.json();
}

export const fetcher = (url : string) => fetchJSON(url).then((res) => res);
