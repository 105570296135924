// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfe from '../../../form_exportacao_nfe/campos_nfe/selecao_todos_nfe';
import { Input } from '../../../../../../../common/input';

type CamposCofinsProps = {
  component : React.ComponentType<*>,
}

export default function CamposCofins({ component } : CamposCofinsProps) {
  const SelecaoTodos = component || SelecaoTodosNfe;

  return (
      <FormSection name="dadosCofins">
        <SelecaoTodos nomes={ ['dadosCofins'] } titulo="Dados da COFINS"/>

        <div className="grupo-checkbox">
          <Input name="cstCofins" label="Situação Tributária" checkbox/>
          <Input name="valorBcCofins" label="Base Cálculo COFINS" checkbox/>
          <Input name="aliquotaPercentualCofins" label="Alíquota em Percentual" checkbox/>
          <Input name="qtdVendidaCofins" label="Quantidade Vendida" checkbox/>
          <Input name="aliquotaReaisCofins" label="Alíquota em Reais" checkbox/>
          <Input name="valorCofins" label="Valor da COFINS" checkbox/>
        </div>
      </FormSection>
  );
}
