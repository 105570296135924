'use strict';
// @flow

import * as React from 'react';
import { Button } from 'react-bootstrap';

import Icone from 'components/common/icone';
import type { IActions } from './tipos';

export default function BotaoAtualizarPaineis({ actions: { requestData } } : { actions : IActions }) {
  return (
      <Button bsStyle="success" block onClick={ requestData }>
        <Icone nome="sync-alt" estilo="fas">Atualizar Painéis</Icone>
      </Button>
  );
}
