// @flow
'use strict';

import * as React from 'react';

import { useEffectOnce } from 'lib/viewUtils';

import Formulario from '../formulario';

type FiltrosProps = {
  router : { location : { query : {} } },

  aplicaFiltro : (form : any) => void,
  carregaOpcoesSelect : () => void,
};

export default function Filtros({ carregaOpcoesSelect, router, aplicaFiltro } : FiltrosProps) {
  useEffectOnce(() => {
    carregaOpcoesSelect();
    if (router && router.location)
      aplicaFiltro(router.location.query);
  });

  return (
      <Formulario/>
  );
}