// @flow
'use strict';

import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { jsSelector } from '../../../../../../../lib/viewUtils';
import { fechaDetalhesCartao, modalOpenSelector, cartaoSelector } from '../../../../../../../reducers/nfe/detalhes_cartao';

import ColumnDetalhes from '../../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../../common/detalhes_documento/row_detalhes';

const cartaoJsSelector = jsSelector(cartaoSelector, {});

export default function ModalCartao() {
  const open       = useSelector(modalOpenSelector),
        cartao     = useSelector(cartaoJsSelector),
        dispatch   = useDispatch(),
        fechaModal = React.useCallback(() => dispatch(fechaDetalhesCartao()), [dispatch]);

  return (
      <Modal show={ open } onHide={ fechaModal } dialogClassName="modal-md">
        <Modal.Header closeButton>
          <Modal.Title>Cartões</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="visualizacao-documento">
            <RowDetalhes data={ cartao } className="no-margin-bottom">
              <ColumnDetalhes field="tipoIntegracao" width="26%">Tipo Integração</ColumnDetalhes>
              <ColumnDetalhes field="cnpjCredenciadora" type="documento" width="26%">CNPJ Instituição
                Pgto.</ColumnDetalhes>
              <ColumnDetalhes field="bandeira" width="26%">Bandeira</ColumnDetalhes>
              <ColumnDetalhes className="quebra-de-linha" field="numAut" width="22%">Núm. Aut.</ColumnDetalhes>
            </RowDetalhes>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={ fechaModal }>Fechar</Button>
        </Modal.Footer>
      </Modal>
  );
}
