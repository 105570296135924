// @flow
'use strict';

import * as React from 'react';

import { MenuItem } from 'react-bootstrap';

type BotaoExportacaoLoteProps = {
  abreModalPdf : () => void,
  abreModalXml : () => void,
}

export default function BotaoExportacaoLoteNfse({ abreModalPdf, abreModalXml } : BotaoExportacaoLoteProps) {
  return (
    <>
      <MenuItem onClick={ abreModalPdf }>DANFSE</MenuItem>
      <MenuItem onClick={ abreModalXml }>XML</MenuItem>
    </>
  );
}
