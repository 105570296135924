// @flow

import * as React from 'react';
import ReactSelect from 'react-select';
import { connect } from 'react-redux';
import { Button, Navbar } from 'react-bootstrap';
import { createStructuredSelector } from 'reselect';

import {
  CUSTODIADO, MODELO_SPED, POSSUI_CHAVE, SITUACAO_SEFAZ, SITUACAO_SPED, TIPO_EMISSAO, TIPO_OPERACOES, SITUACOES_ARQUIVO,
} from 'i18n/constants';
import {
  filtrosEstruturadosSelector, alternaParaBuscaAvancada, aplicaFiltro, limpaFiltro,
} from '../../../../reducers/sped/documentos/filtro';

import DatePicker from 'components/common/date_picker';
import CampoFiltro from '../campo_filtro';
import Icone from 'components/common/icone';

type date = any;

type BuscaBasicaProps = {
  filtros : {
    data_emissao_de : ?date, data_emissao_ate : ?date, chave : ?string, possui_chave : ?('sim' | 'nao'),
    empresa : ?string, participante : ?string, numero : ?string, serie : ?string,
    tipo_operacao : ?(number | string), ind_emissao : ?(number | string), cod_modelo : ?(number | string),
    data_sped_de : ?date, data_sped_ate : ?date, situacao_sped : ?string,
    situacao_sefaz_upload : ?string, situacao_sefaz : ?string,
    custodiado : ? bool, custodiado_upload : ? bool, custodiado_de : ?date, custodiado_ate : ?date,
    situacao_arquivo : ? string, situacao_arquivo_upload : ? string,
  },
  alternaParaBuscaAvancadaComBusca() : void,
  limpaBusca() : void,
};

export function BuscaBasica({ filtros, alternaParaBuscaAvancadaComBusca, limpaBusca } : BuscaBasicaProps) {
  const {
          data_emissao_de, data_emissao_ate, chave, possui_chave,
          empresa, participante, numero, serie,
          tipo_operacao, ind_emissao, cod_modelo, data_sped_de, data_sped_ate, situacao_sped,
          situacao_sefaz_upload, situacao_sefaz, custodiado, custodiado_upload, custodiado_de, custodiado_ate,
          situacao_arquivo, situacao_arquivo_upload,
        } = filtros || {};

  return (
      <Navbar fluid className="sped-busca-basica">
        <Navbar.Header><Navbar.Brand>Filtros</Navbar.Brand></Navbar.Header>

        <Navbar.Form>
          <CampoFiltro name="data_emissao_de" value={ data_emissao_de } label="Emissão de"
                       control={ DatePicker } width="8em"/>
          { ' ' }
          <CampoFiltro name="data_emissao_ate" value={ data_emissao_ate } label="Emissão até"
                       control={ DatePicker } width="8em"/>
          { ' ' }
          <CampoFiltro name="chave" value={ chave } type="text" label="Chave" style={ { width: '16em' } }/>
          { ' ' }
          <CampoFiltro name="possui_chave" value={ possui_chave } label="Possui Chave"
                       control={ ReactSelect } options={ POSSUI_CHAVE } style={ { width: '9em' } } simpleValue/>
          { ' ' }
          <CampoFiltro name="empresa" value={ empresa } type="text" label="Empresa"/>
          { ' ' }
          <CampoFiltro name="participante" value={ participante } type="text" label="Participante"/>
          { ' ' }
          <CampoFiltro name="numero" value={ numero } type="text" label="Número" style={ { width: '6em' } }/>
          { ' ' }
          <CampoFiltro name="serie" value={ serie } type="text" label="Série" style={ { width: '5em' } }/>
          { ' ' }
          <CampoFiltro name="tipo_operacao" value={ tipo_operacao } label="Tipo Operação"
                       control={ ReactSelect } options={ TIPO_OPERACOES } style={ { width: '10em' } } simpleValue/>
          { ' ' }
          <CampoFiltro name="ind_emissao" value={ ind_emissao } label="Tipo Emissão"
                       control={ ReactSelect } options={ TIPO_EMISSAO } style={ { width: '10em' } } simpleValue/>
          { ' ' }
          <CampoFiltro name="cod_modelo" value={ cod_modelo } label="Modelo"
                       control={ ReactSelect } options={ MODELO_SPED } style={ { width: '10em' } } simpleValue/>
          { ' ' }
          <CampoFiltro name="data_sped_de" value={ data_sped_de } label="SPED de"
                       control={ DatePicker }/>
          { ' ' }
          <CampoFiltro name="data_sped_ate" value={ data_sped_ate } label="SPED até"
                       control={ DatePicker }/>
          { ' ' }
          <CampoFiltro name="situacao_sped" value={ situacao_sped } label="Situação no SPED"
                       control={ ReactSelect } options={ SITUACAO_SPED } style={ { width: '15em' } } simpleValue/>
          { ' ' }
          <CampoFiltro name="situacao_sefaz_upload" value={ situacao_sefaz_upload } label="Situação SEFAZ no Upload"
                       control={ ReactSelect } options={ SITUACAO_SEFAZ } style={ { width: '15em' } } simpleValue/>
          { ' ' }
          <CampoFiltro name="situacao_sefaz" value={ situacao_sefaz } label="Situação SEFAZ Atual"
                       control={ ReactSelect } options={ SITUACAO_SEFAZ } style={ { width: '14em' } } simpleValue/>
          { ' ' }
          <CampoFiltro name="custodiado_upload" value={ custodiado_upload } label="Custodiado no Upload"
                       control={ ReactSelect } options={ CUSTODIADO } style={ { width: '14em' } } simpleValue/>
          { ' ' }
          <CampoFiltro name="custodiado" value={ custodiado } label="Custodiado Atual"
                       control={ ReactSelect } options={ CUSTODIADO } style={ { width: '12em' } } simpleValue/>
          { ' ' }
          <CampoFiltro name="custodiado_de" value={ custodiado_de } label="Custodiado de"
                       control={ DatePicker } width="10em"/>
          { ' ' }
          <CampoFiltro name="custodiado_ate" value={ custodiado_ate } label="Custodiado até"
                       control={ DatePicker } width="10em"/>
          { ' ' }
          <CampoFiltro name="situacao_arquivo" value={ situacao_arquivo } label="Situação do Arquivo Atual"
                       control={ ReactSelect } options={ SITUACOES_ARQUIVO } style={ { width: '15em' } } simpleValue/>
          { ' ' }
          <CampoFiltro name="situacao_arquivo_upload" value={ situacao_arquivo_upload }
                       label="Situação do Arquivo no Upload"
                       control={ ReactSelect } options={ SITUACOES_ARQUIVO } style={ { width: '17em' } } simpleValue/>
          { ' ' }
        </Navbar.Form>

        <Navbar.Form className="botoes">
          <div className="pull-left">
            <Button type="button" onClick={ alternaParaBuscaAvancadaComBusca }>Busca Avançada</Button>
          </div>
          <div className="pull-right">
            <Button type="reset" onClick={ limpaBusca }>
              <Icone nome="eraser" estilo="fas">Limpar</Icone>
            </Button> { ' ' }
            <Button type="submit" bsStyle="primary">
              <Icone nome="filter" estilo="far">Filtrar</Icone>
            </Button>
          </div>
          <div className="clearfix"/>
        </Navbar.Form>
      </Navbar>
  );
}

const mapStateToProps = createStructuredSelector({ filtros: filtrosEstruturadosSelector });

const mapDispatchToProps = (dispatch) => ({
  limpaBusca() {
    dispatch(limpaFiltro());
    dispatch(aplicaFiltro());
  },
  alternaParaBuscaAvancadaComBusca() {
    dispatch(alternaParaBuscaAvancada());
    dispatch(aplicaFiltro());
  },
});

export default connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(BuscaBasica);