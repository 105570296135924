// @flow
'use strict';

import * as React from 'react';
import { Button } from 'react-bootstrap';

import request from 'lib/request';
import { type IDocumento } from 'lib/api';
import Icone from 'components/common/icone';

type BotaoReconsultaProps = {
  documento? : ?IDocumento,
  reconsulta? : bool, // se verdadeiro, habilita o botão para reconsulta
}

export default function BotaoReconsulta({ documento, reconsulta } : BotaoReconsultaProps) {
  const [reconsultaUsado, setReconsultaUsado] = React.useState(false);

  const handleReconsulta = React.useCallback(() => {
    if (!documento)
      return;

    request.post(`${ documento.url }/reconsulta`)
        .accept('json')
        .then((r) => {
          if (r.body.alertas && r.body.alertas.length > 0)
            alert(r.body.alertas.join('\n\n'));
          else
            alert('O documento foi enviado para reconsulta.\n\nAguarde alguns minutos. Se houver mudança de status, a sua tela será atualizada automaticamente.');
        })
        // reabilita o botão após 30s
        .finally(() => setTimeout(() => setReconsultaUsado(false), 30000));

    // desabilita o botão, para evitar cliques multiplos e/ou muito frequentes
    setReconsultaUsado(true);
  }, [documento]);

  if (!reconsulta)
    return null;

  // o botão de reconsulta deve ser exibido, se necessário
  return (
      <Button bsSize="xsmall" bsStyle="link" onClick={ handleReconsulta }
              title="Solicita a reconsulta do documento." disabled={ reconsultaUsado }>
        <Icone nome="sync-alt" estilo="fas"/>
      </Button>
  );
}