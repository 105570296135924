// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfe from '../../../form_exportacao_nfe/campos_nfe/selecao_todos_nfe';
import { Input } from '../../../../../../../common/input';

type CamposIcmsProps = {
  component : React.ComponentType<*>,
}

export default function CamposIcms({ component } : CamposIcmsProps) {
  const SelecaoTodos = component || SelecaoTodosNfe,
        tipoNfe      = SelecaoTodos === SelecaoTodosNfe;

  return (
      <FormSection name="dadosIcms">
        <SelecaoTodos nomes={ ["dadosIcms"] } titulo="Dados do ICMS"/>

        <div className="grupo-checkbox">
          <Input name="origemMercadoria" label="Origem da Mercadoria" checkbox/>
          <Input name="cst" label="Situação Tributária (CST)" checkbox/>
          <Input name="csosn" label="Situação da Operação (CSOSN)" checkbox/>
          <Input name="modalidadeIcms" label="Modalidade" checkbox/>
          <Input name="percentualReducaoBcIcms" label="% Redução Base Cálculo" checkbox/>
          <Input name="valorBcIcms" label="Base de Cálculo" checkbox/>
          <Input name="aliquotaIcms" label="Alíquota" checkbox/>
          { tipoNfe &&
              <>
                <Input name="valorIcmsOperacao" label="Valor ICMS Operação" checkbox/>
                <Input name="percentualDiferimento" label="% Diferimento" checkbox/>
                <Input name="valorIcmsDiferido" label="Valor ICMS Diferido" checkbox/>
              </>
          }
          <Input name="valorIcms" label="Valor do ICMS" checkbox/>
          <Input name="baseCalculoFcp" label="Base de Cálculo FCP" checkbox/>
          <Input name="percentualFcp" label="Percentual FCP" checkbox/>
          <Input name="valorFcp" label="Valor FCP" checkbox/>
          <Input name="modalidadeIcmsSt" label="Modalidade ICMS ST" checkbox/>
          <Input name="percentualMargemValorAdicionadoIcmsSt" label="% Margem Valor Adicionado ICMS ST" checkbox/>
          <Input name="percentualReducaoBcIcmsSt" label="% Redução Base Cálculo (BC) ICMS ST" checkbox/>
          <Input name="valorBcIcmsSt" label="Base Cálculo ICMS ST" checkbox/>
          <Input name="aliquotaIcmsSt" label="Alíquota ICMS ST" checkbox/>
          <Input name="valorIcmsSt" label="Valor ICMS ST" checkbox/>
          { tipoNfe &&
              <>
                <Input name="valorBcFcpRetidoSt" label="Base Cálculo FCP ST" checkbox/>
                <Input name="percentualBcFcpRetidoSt" label="% FCP ST" checkbox/>
                <Input name="valorFcpRetidoSt" label="Valor FCP ST" checkbox/>
                <Input name="percentualBcOperacaoPropria" label="% Base Cálculo (BC) Operação Própria" checkbox/>
                <Input name="ufIcmsStDevido" label="UF do ICMS ST Devido" checkbox/>
              </>
          }
          <Input name="valorBcIcmsStRetido" label="Base Cálculo (BC) ICMS ST Retido" checkbox/>
          <Input name="aliquotaConsumidorFinal" label="Alíquota Suportada Cons. Final" checkbox/>
          <Input name="valorIcmsProprioSubstituto" label="Valor ICMS Próprio do Substituto" checkbox/>
          <Input name="valorIcmsStRetido" label="Valor ICMS ST Retido" checkbox/>
          <Input name="valorBcFcpRetidoAnteriormenteSt" label="Base Cálculo (BC) FCP Retido Anteriormente por ST" checkbox/>
          <Input name="percentualBcFcpRetidoAnteriormenteSt" label="% FCP Retido Anteriormente por ST" checkbox/>
          <Input name="valorFcpRetidoAnteriormenteSt" label="Valor FCP Retido Anteriormente por ST" checkbox/>
          <Input name="valorIcmsDesonerado" label="ICMS Desonerado" checkbox/>
          <Input name="motivoDesoneracaoIcms" label="Motivo Desoneração" checkbox/>
          { tipoNfe &&
              <>
                <Input name="aliquotaCreditoSimples" label="Alíquota Crédito Simples" checkbox/>
                <Input name="valorCreditoIcms" label="Valor Crédito ICMS" checkbox/>
                <Input name="valorBcIcmsStUfDestino" label="Base Cálculo (BC) ICMS ST UF Destino" checkbox/>
                <Input name="valorIcmsStUfDestino" label="Valor ICMS ST UF Destino" checkbox/>
              </>
          }
          <Input name="percentualReducaoBcEfetiva" label="% Redução Base Cálculo (BC) Efetiva" checkbox/>
          <Input name="valorBcEfetiva" label="Base Cálculo Efetiva" checkbox/>
          <Input name="aliquotaIcmsEfetiva" label="Alíquota ICMS Efetiva" checkbox/>
          <Input name="valorIcmsEfetivo" label="Valor ICMS Efetivo" checkbox/>
        </div>
      </FormSection>
  );
}