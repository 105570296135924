// @flow
'use strict';

import * as React from 'react';

import type { IInformacaoNfe } from '../../../types';

type ContainersInfNfesProps = {
  infNfes : IInformacaoNfe[],
};

export default function ContainersInfNfes({ infNfes } : ContainersInfNfesProps) {
  return (
      <>
        { infNfes.map((nfe, k) => (
            <tr key={ k }>
              <td colSpan={ 2 }>{ nfe.chaveNfe }</td>
              <td>{ nfe.unMedidaRateada }</td>
            </tr>
        )) }
      </>
  );
}
