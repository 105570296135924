// @flow
'use strict';

import * as React from 'react';

import Icone from '../../common/icone';
import ComTooltip from '../../common/com_tooltip';

export function iconeManifestacao(m : number) {
  if (!m)
    return null;

  let hint, cor, estilo, nome;

  switch (m) {
    case 0:
    case 210200:
      hint = 'Confirmada operação';
      cor = 'text-success';
      estilo = 'fal';
      nome = 'thumbs-up';
      break;
    case 1:
    case 210210:
      hint = 'Ciência';
      cor = 'text-primary';
      estilo = 'fas';
      nome = 'info-circle';
      break;
    case 2:
    case 210220:
      hint = 'Desconhecida';
      cor = 'text-danger';
      estilo = 'fas';
      nome = 'question-circle';
      break;
    case 4:
    case 210240:
      hint = 'Operação não realizada';
      cor = 'text-yellow';
      estilo = 'fal';
      nome = 'thumbs-down';
      break;
    default:
      return null;
  }

  return (
      <ComTooltip hint={ hint } posicao="right">
        <span className="fa-stack" style={ { margin: '-7px' } }>
          <Icone nome="square" estilo="fas" classe={ `icone-situacao fa-stack-2x ${ cor }` }/>
          <Icone nome={ nome } estilo={ estilo } classe="fa-stack-1x fa-inverse"/>
        </span>
      </ComTooltip>
  );
}

export function iconePortaria(p : string[]) {
  if (!p[0])
    return null;

  let icone;

  switch (p[0]) {
    case 'receber':
      icone = <>
        <Icone nome="square" estilo="fas" classe="icone-situacao fa-stack-2x text-success"/>
        <Icone nome="truck" estilo="fas" classe="fa-stack-1x fa-inverse" flip="horizontal"/>
      </>;
      break;
    case 'devolver':
      icone = <>
        <Icone nome="truck" estilo="fas" classe="fa-stack-1x" flip="horizontal"/>
        <Icone nome="ban" estilo="fas" classe="icone-situacao fa-stack-2x text-danger opacity-075"/>
      </>;
      break;
    case 'receber_com_pendencia':
      icone = <>
        <Icone nome="square" estilo="fas" classe="icone-situacao fa-stack-2x cor-decisao-portaria-com-pendencia"/>
        <Icone nome="truck" estilo="fas" classe="fa-stack-1x" flip="horizontal"/>
      </>;
      break;
    default:
      return null;
  }

  return (
      <ComTooltip hint={ p[1] || '' } posicao="right">
        <span className="fa-stack" style={ { margin: '-7px' } }>
          { icone }
        </span>
      </ComTooltip>
  );
}
