// @flow
'use strict';

import * as React from 'react';

import type { IIcms60 } from '../../../types_imposto';

import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';

type Icms60Props = {
  icms : IIcms60,
};

export default function Icms60({ icms } : Icms60Props) {
  return (
      <>
        <h4>ICMS - 60 = ICMS cobrado por ST</h4>
        <RowDetalhes data={ icms } className="no-margin-bottom">
          <ColumnDetalhes field="cst" width="50%">Classificação Tributária do Serviço</ColumnDetalhes>
          <ColumnDetalhes field="valorBcSt" type="moeda" width="50%">Valor BC ICMS ST Retido</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ icms } className="no-margin-bottom">
          <ColumnDetalhes field="valorRetidoSt" type="moeda" width="50%">Valor ICMS ST Retido</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaRetidoSt" type="percentual" width="50%">Alíquota ICMS ST Retido</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ icms }>
          <ColumnDetalhes field="valorCredito" type="moeda">Valor do Crédito</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
