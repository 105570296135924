// @flow
'use strict';

import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { finalizaBuscaEstruturada, modalOpenSelector } from '../../../../reducers/nfse/filtro_estruturado';

import Icone from '../../../common/icone';
import { InputForm } from '../../../common/input';
import CampoSituacao from './campo_situacao';
import CampoIntervaloData from '../../../common/campos_busca_estruturada/campo_intervalo_data';
import CampoIntervalo from '../../../common/campo_intervalo';
import CampoMunicipio from './campo_municipio';

type ModalProps = {
  handleSubmit : () => void,
}

export default function ModalBuscaEstruturada({ handleSubmit } : ModalProps) {
  const open     = useSelector(modalOpenSelector),
        dispatch = useDispatch();

  const fechaModal = React.useCallback(() => dispatch(finalizaBuscaEstruturada()), [dispatch]);

  return (
      <Modal show={ open } onHide={ fechaModal } dialogClassName="custom-modal" id="busca-avancada-nfse">
        <Modal.Header closeButton>
          <Modal.Title>Busca Avançada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={ handleSubmit }>
            <section className="busca-estruturada">
              <Row>
                <Col md={ 12 }>
                  <InputForm className="campo-input" sm={ 9 } name="prestador" label="Prestador" placeholder="Nome ou CPF ou CNPJ"/>
                  <InputForm className="campo-input" sm={ 9 } name="tomador" label="Tomador" placeholder="Nome ou CPF ou CNPJ"/>
                  <InputForm className="campo-input" sm={ 9 } name="intermediario" label="Intermediário" placeholder="Nome ou CPF ou CNPJ"/>
                  <CampoIntervaloData rotulo="Emissão" nome="emitido_em"/>
                  <CampoIntervalo rotulo="Número" nome="numero"/>
                  <CampoIntervalo rotulo="Valor" nome="valor"/>
                  <InputForm className="campo-input" sm={ 9 } name="codigo_verificacao" label="Cód. Verificação"/>
                  <InputForm className="campo-input" sm={ 9 } name="chave" label="Chave"/>
                </Col>
              </Row>
              <CampoMunicipio/>
              <CampoSituacao/>
            </section>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={ fechaModal }>Fechar</Button>
          <Button bsStyle="primary" onClick={ handleSubmit }><Icone nome="search" estilo="fas">Buscar</Icone></Button>
        </Modal.Footer>
      </Modal>
  );
}