// @flow
'use strict';

import * as React from 'react';

import type { ItemProps } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';

export default function Nfce({ item } : ItemProps) {
  return (
      <div className="tab-pane active">
        <h3>Dados da NFC-e</h3>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="numero" width="20%">Número</ColumnDetalhes>
          <ColumnDetalhes field="serie" width="20%">Série</ColumnDetalhes>
          <ColumnDetalhes field="modeloId" type="modelo" width="20%">Modelo</ColumnDetalhes>
          <ColumnDetalhes field="emitidoEm" type="data" width="20%">Data de emissão</ColumnDetalhes>
          <ColumnDetalhes field="valor" type="moeda" width="20%">Valor Total da NFC-e</ColumnDetalhes>
        </RowDetalhes>

        <h3>Emissão</h3>
        <RowDetalhes data={ item.emissao }>
          <ColumnDetalhes field="naturezaOperacao" width="25%">Natureza da Operação</ColumnDetalhes>
          <ColumnDetalhes field="tipoOperacao" width="25%">Tipo de Operação</ColumnDetalhes>
          <ColumnDetalhes field="destinoOperacao" width="25%">Destino da Operação</ColumnDetalhes>
          <ColumnDetalhes field="formatoImpressao" width="25%">Formato de Impressão DANFE </ColumnDetalhes>
        </RowDetalhes>

        <RowDetalhes data={ item.emissao }>
          <ColumnDetalhes field="tipoEmissao" width="25%">Tipo de Emissão</ColumnDetalhes>
          <ColumnDetalhes field="digitoVerificador" width="25%">Dígito Verificador da Chave de Acesso</ColumnDetalhes>
          <ColumnDetalhes field="ambiente" width="25%">Ambiente</ColumnDetalhes>
          <ColumnDetalhes field="finalidade" width="25%">Finalidade</ColumnDetalhes>
        </RowDetalhes>

        <RowDetalhes data={ item.emissao }>
          <ColumnDetalhes field="digestValue">Digest Value da NFC-e</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}