'use strict';
// @flow

import * as Immutable from 'immutable';
import { push } from 'react-router-redux';
import { createSelector } from 'reselect';

import type { Action, Dispatch, State } from 'lib/types';
import request from 'lib/request';
import { decamelizeObjectKeys } from 'lib/viewUtils';
import { fetchJSON, json } from 'lib/api/fetch';

import { ajaxError, alert } from 'reducers/alerts';

export const DETALHES_CARREGANDO = 'ferramentas/prestador/detalhes/DETALHES_CARREGANDO',
             DETALHES_CARREGADO  = 'ferramentas/prestador/detalhes/DETALHES_CARREGADO',
             CONTAS_CARREGANDO   = 'ferramentas/prestador/detalhes/CONTAS_CARREGANDO',
             CONTAS_CARREGADAS   = 'ferramentas/prestador/detalhes/CONTAS_CARREGADAS';

const INITIAL_STATE = Immutable.fromJS({
  carregando: false,
  item: {},
  contas: [],
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case DETALHES_CARREGANDO:
      return state.set('carregando', true).set('item', Immutable.fromJS({}));
    case DETALHES_CARREGADO:
      return state.set('carregando', false).set('item', action.body);
    case CONTAS_CARREGANDO:
      return state.set('contas', null);
    case CONTAS_CARREGADAS:
      return state.set('contas', Immutable.fromJS(action.body));
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['ferramentas', 'prestador', 'detalhes'], Immutable.Map());
export const itemSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('item'));
export const contasSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('contas'));
export const carregandoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('carregando', false));

// actions

export function detalhesCarregando() {
  return { type: DETALHES_CARREGANDO };
}

export function detalhesCarregado(item : any) {
  return { type: DETALHES_CARREGADO, body: Immutable.fromJS(item) };
}

// thunk actions

export function navigateToIndex() {
  return push('/app/ferramentas/prestadores');
}

export function carregaContas() {
  return async function(dispatch : Dispatch<any>) {
    dispatch({ type: CONTAS_CARREGANDO });

    await request.get('/app/ferramentas/prestadores/lista_contas')
        .accept('json')
        .then(r => dispatch({ type: CONTAS_CARREGADAS, body: r.body }))
        .catch(e => {
          dispatch({ type: CONTAS_CARREGADAS, body: [] });
          dispatch(ajaxError('Erro ao carregar lista de contas', e));
        });
  };
}

export function carregaDetalhes(id : string) {
  return async function(dispatch : Dispatch<any>) {
    dispatch(detalhesCarregando());

    const url = id ? `/app/ferramentas/prestadores/${ id }` : `/app/ferramentas/prestadores/new`;

    dispatch(push(url));

    await request.get(url)
        .accept('json')
        .then(r => dispatch(detalhesCarregado(r.body)))
        .catch(e => {
          dispatch(ajaxError('Erro ao carregar detalhes do prestador', e));
        });
  };
}

export function excluiFornecedor(id : string) {
  return async function(dispatch : Dispatch<*>) {
    await request.delete(`/app/ferramentas/prestadores/${ id }`)
        .accept('json')
        .query({ id })
        .then(async() => {
          await dispatch(push('/app/ferramentas/prestadores'));
          dispatch(alert('info', 'Prestador excluído.'));
        })
        .catch(e => dispatch(ajaxError('Erro ao excluir prestador', e)));
  };
}

export async function atualizaFornecedor(id : string, form : any) {
  return await fetchJSON(`/app/ferramentas/prestadores/${ id }`, json('PATCH', decamelizeObjectKeys(form)));
}

export async function criaFornecedor(form : any) {
  return await fetchJSON(`/app/ferramentas/prestadores`, json('POST', decamelizeObjectKeys(form)));
}
