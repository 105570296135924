// @flow
'use strict';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { abreModal } from 'reducers/nfe/modal_manifestacao_lote';
import { querySelector, tipoSelector } from 'reducers/nfe/filtro_livre';

import Icone from 'components/common/icone';

export default function BotaoOpcaoOperacaoNaoRealizada() {
  const dispatch                = useDispatch(),
        tipo                    = useSelector(tipoSelector),
        query                   = useSelector(querySelector),
        abreModalOpNaoRealizada = React.useCallback(() => dispatch(abreModal('operacao_nao_realizada', tipo, query)), [dispatch, tipo, query]);

  return (
      <span onClick={ abreModalOpNaoRealizada }>
        <Icone nome="thumbs-down" estilo="fas">Operação não realizada</Icone>
      </span>
  );
}
