// @flow
'use strict';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { abreModal } from 'reducers/nfe/modal_manifestacao_lote';
import { querySelector, tipoSelector } from 'reducers/nfe/filtro_livre';

import Icone from 'components/common/icone';

export default function BotaoOpcaoConfirmada() {
  const dispatch            = useDispatch(),
        tipo                = useSelector(tipoSelector),
        query               = useSelector(querySelector),
        abreModalConfirmada = React.useCallback(() => dispatch(abreModal('confirmada', tipo, query)), [dispatch, tipo, query]);

  return (
      <span onClick={ abreModalConfirmada }>
        <Icone nome="thumbs-up" estilo="fas">Confirmada</Icone>
      </span>
  );
}
