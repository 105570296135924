// @flow

import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { todosSelector } from '../../../../../reducers/dashboard/conta';

import SelecaoConta from './SelecaoConta';

const contasSelector = createSelector(todosSelector,
    (contas) =>
        contas
            .map(conta => ({
              value: conta.get('id'), label: conta.get('nome'),
            }))
            .toArray());

// seletor composto, substitui a função mapStateToProps
const mapStateToProps = createStructuredSelector({ opcoesContas: contasSelector });

export default connect<*, *, *, *, *, *>(mapStateToProps)(SelecaoConta);