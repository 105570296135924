// @flow
'use strict';

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { State, Action } from '../../lib/types';

export const ABRE_RELATORIO         = 'modelo_relatorio/modal_escolha_tipo_relatorio/ABRE_RELATORIO',
             FECHA_RELATORIO        = 'modelo_relatorio/modal_escolha_tipo_relatorio/FECHA_RELATORIO',
             ABRE_LISTA_RELATORIOS  = 'modelo_relatorio/modal_escolha_tipo_relatorio/ABRE_LISTA_RELATORIOS',
             FECHA_LISTA_RELATORIOS = 'modelo_relatorio/modal_escolha_tipo_relatorio/FECHA_LISTA_RELATORIOS';

const DEFAULT_STATE = Immutable.fromJS({ openRelatorio: false, listaRelatorios: false });

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action) {
  switch (action.type) {
    case ABRE_RELATORIO:
      return state.set('openRelatorio', true);
    case FECHA_RELATORIO:
      return state.set('openRelatorio', false);
    case ABRE_LISTA_RELATORIOS:
      return state.set('listaRelatorios', true);
    case FECHA_LISTA_RELATORIOS:
      return state.set('listaRelatorios', false);
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['modeloRelatorio', 'modalEscolhaTipoRelatorio'], DEFAULT_STATE);
export const openRelatorioSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('openRelatorio'));
export const listaRelatoriosSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('listaRelatorios'));

// action creators

export function abreRelatorio() {
  return { type: ABRE_RELATORIO };
}

export function fechaRelatorio() {
  return { type: FECHA_RELATORIO };
}

export function abreListaRelatorios() {
  return { type: ABRE_LISTA_RELATORIOS };
}

export function fechaListaRelatorios() {
  return { type: FECHA_LISTA_RELATORIOS };
}
