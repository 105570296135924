'use strict';
// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import * as novo from '../../../../reducers/sped/arquivos/novo';
import { arquivoNav } from '../arquivo_path';
import Icone from '../../../common/icone';

type OpcoesProps = {
  novoUpload : () => void,
}

export function Opcoes({ novoUpload } : OpcoesProps) {
  const preparaUpload = React.useCallback(() => {
    novoUpload();
    arquivoNav.novo();
  }, [novoUpload]);

  return (
      <div>
        <Button onClick={ preparaUpload } bsStyle="primary"><Icone nome="cloud-upload-alt" estilo="fas">Upload</Icone></Button>
      </div>
  );
}

const mapDispatchToProps = { novoUpload: novo.novoUpload };

export default connect<*, *, *, *, *, *>(null, mapDispatchToProps)(Opcoes);