'use strict';
// @flow

import * as React from 'react';

import type { ColumnProps } from './types';

import Cell from './cell';

export default function Column<T>({ cellComponent, ...props } : ColumnProps<T>) {
  const CC = cellComponent || Cell;
  return <CC { ...props } />;
}