'use strict';
// @flow

import * as React from 'react';

import { calcPaginationProps, DEFAULT_PROPS } from './util';
import PageEntries from './page_entries';
import Pagination from './pagination';
import type { PaginationProps } from './types';

type PaginationFooterProps = PaginationProps;

/**
 * Renderiza o componente de paginação.
 */
export default function PaginationFooter({ label, showPageEntries, hasLastButton, onPageChange, children, ...props } : PaginationFooterProps) {
  const handlePageSelect = React.useCallback((eventKey : number) => {
    if (onPageChange)
      onPageChange(eventKey);
  }, [onPageChange]);

  const p = calcPaginationProps(props);
  if (!p.total || p.total <= p.pageSize)
    return null;

  return (
      <section className="table-footer">
        { children }
        { showPageEntries && <PageEntries label={ label } { ...p }/> }
        <Pagination currentPage={ p.page } totalPages={ p.pageCount } onChange={ handlePageSelect }
                    hasLastButton={ hasLastButton } boundaryPagesRange={ 2 } siblingPagesRange={ 2 }/>
      </section>
  );
}

PaginationFooter.defaultProps = DEFAULT_PROPS;
