'use strict';
// @flow

import * as React from 'react';
import { MenuItem, Nav, NavDropdown } from 'react-bootstrap';

import api from 'lib/api';

import Icone from '../common/icone';

const logout = (e : Event) => {
  e.preventDefault();
  api.logout()
      .then((url) => location.href = url)
      .catch(e => console.error(e));
};

export default function UserLinks({ usuario } : { usuario : { nome : string } }) {
  const title = <Icone nome="user" estilo="fas"> { usuario.nome } </Icone>;

  return (
      <Nav navbar pullRight style={ { marginRight: 0, position: 'absolute', 'right': 0, zIndex: 999 } }>
        <NavDropdown id="dd-user-profile" className="user-menu" title={ title }>
          <MenuItem href="/app/perfil_usuario/editar"><Icone nome="cog" estilo="fas">Editar Meu Perfil</Icone></MenuItem>
          <MenuItem divider/>
          <MenuItem onClick={ logout }><Icone nome="power-off" estilo="fas">Desconectar do Sistema</Icone></MenuItem>
        </NavDropdown>
      </Nav>
  );
}