// @flow
'use strict';

import * as React from 'react';

import type { IPisQtde, IPisOutr } from '../../../../../types_imposto';

import RowDetalhes from '../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../common/detalhes_documento/column_detalhes';

type ValorPisProps = {
  item : IPisQtde | IPisOutr,
};

export default function ValorPis({ item } : ValorPisProps) {
  return (
      <RowDetalhes data={ item }>
        <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
        <ColumnDetalhes field="valorPis" type="moeda" width="75%">Valor do PIS</ColumnDetalhes>
      </RowDetalhes>
  );
}
