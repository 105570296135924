// @flow

import { combineReducers } from 'redux-immutable';

import lista from './lista';
import detalhes from './detalhes';
import historico from './historico';
import resumoLista from './resumo_lista';
import filtroLivre from './filtro_livre';
import manifestacao from './manifestacao';
import filtroEstruturado from './filtro_estruturado';

export default combineReducers({
  lista, detalhes, historico, resumoLista, filtroLivre, manifestacao, filtroEstruturado,
});
