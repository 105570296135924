'use strict';
// @flow

import _ from 'lodash';
import React from 'react';

import { Table } from 'react-bootstrap';

import type { IDataInicial, IEscriturado, IPessoa, ISerieNumero } from '../types';
import { formatHelper } from 'lib/viewUtils';
import { numberToString, obtemCodManifestacaoCd, obtemOpcaoDesacordo } from './functions';
import { toNumberBrDecimal2 } from '../../../../lib/toNumberBrDecimal';

import Icone from 'components/common/icone';
import ComTooltip from 'components/common/com_tooltip';
import Descricao, { DescricaoChave, DescricaoIcone } from 'components/common/descricao';

type DadosTabela = 'loading' | 'erro' | any[];

type TabelaProps = {
  dados : ?DadosTabela
}

export default class Tabela extends React.PureComponent<TabelaProps, *> {
  props : TabelaProps;

  render() {
    return (
        <Table striped hover>
          <colgroup>
            <col width={ 1 }/>
            <col width={ 10 }/>
            <col/>
            <col/>
            <col width={ 1 }/>
            <col/>
            <col width={ 1 }/>
            <col width={ 10 }/>
            <col width={ 10 }/>
            <col/>
            <col width={ 1 }/>
            <col/>
            <col/>
          </colgroup>
          <thead>
          <tr>
            <th><span/></th>
            <th><span>Emissão</span></th>
            <th><span>Série/Nro</span></th>
            <th><span>Emitente</span></th>
            <th><span/></th>
            <th><ComTooltip hint="Modelo" posicao="top"><span>Mod.</span></ComTooltip></th>
            <th><span/></th>
            <th><span>Situação SEFAZ</span></th>
            <th><span>Situação Arquivo</span></th>
            <th><span/></th>
            <th><span>Valor (R$)</span></th>
            <th><span/></th>
            <th><span>SPEDs</span></th>
          </tr>
          </thead>
          <tbody>
          { this.renderRows(this.props.dados, 13) }
          </tbody>
        </Table>
    );
  }

  renderRows(dados : ?DadosTabela, cols : number) {
    if (!dados) {
      return <tr className="nodata">
        <td colSpan={ cols }>Nenhum Documento encontrado.</td>
      </tr>;
    }
    else if (dados === 'loading') {
      return <tr className="nodata">
        <td colSpan={ cols }><Icone nome="sync-alt" estilo="fas" tamanho={2} spin /></td>
      </tr>;
    }
    else if (dados === 'erro') {
      return <tr className="nodata">
        <td colSpan={ cols }>Ocorreu um erro ao carregar a lista de documentos.</td>
      </tr>;
    }
    else if (dados.length === 0) {
      return <tr className="nodata">
        <td colSpan={ cols }>Nenhum registro encontrado.</td>
      </tr>;
    }
    else if (_.isArray(dados))
      return <> { dados.map(d => <TableRow key={ d.id } row={ d }/>) } </>;
    else
      throw new Error(`Dados inválidos: ${ JSON.stringify(dados) }`);
  }
}

/**
 * Uma linha da tabela de documentos.
 */
export function TableRow({ row } : { row : IEscriturado }) {
  return (
      <tr>
        <td><DescricaoChave className="key-button" chave={ row.chave }/></td>
        <td className="text-center">{ formatHelper.formatDate(row.emitido_em) }</td>
        <td><SerieNumero serie={ row.serie } numero={ row.numero }/></td>
        <td><DescricaoPessoa nome={ row.emit_nome } cnpj={ row.emit_cnpj }/></td>
        <td className="text-center"><DescricaoIcone tipo="tipo_operacao" codigo={ row.tipo_operacao_id }/></td>
        <td><Descricao tipo="modelo" codigo={ row.modelo }/></td>
        <td><DescricaoIcone tipo="custodiado_icone" codigo={ row.custodiado }/></td>
        <td className="text-center"><Descricao tipo="situacao_sefaz" codigo={ row.situacao_sefaz }/></td>
        <td className="text-center"><Descricao tipo="situacao_arquivo_sped" codigo={ row.situacao_arquivo }/></td>
        <td>
          { row.modelo === '55' && <DescricaoIcone tipo="manifestacao_cd_icone" codigo={ row.manifestacao_cd }
                                                   conversorCodigo={ obtemCodManifestacaoCd }/>
          }
          { ['57', '67'].includes(row.modelo) &&
          <DescricaoIcone tipo="desacordo_servico_icone" codigo={ row.desacordo_servico }
                          conversorCodigo={ obtemOpcaoDesacordo }/> }
        </td>
        <td className="text-center">{ toNumberBrDecimal2(row.valor) }</td>
        <td><DescricaoIcone tipo="em_sped" codigo={ row.escriturado }/></td>
        <td className="text-center">{ SpedDocumentos(row.sped_documentos) }</td>
      </tr>
  );
}

/**
 * Apresenta Dados da Pessoa.
 */
export function DescricaoPessoa({ nome, cnpj } : IPessoa) {
  const s = _([formatHelper.formatId(cnpj), nome]).compact().join(' - ');
  return <span>{ s }</span>;
}

export function SerieNumero({ serie, numero } : ISerieNumero) {
  const s = _([numberToString(serie), numberToString(numero)]).compact().join(' / ');
  return <span>{ s }</span>;
}

export function SpedDocumentos(a : ?IDataInicial[]) : string {
  if (!a || a.length === 0)
    return '-';

  return a.map((d) => formatHelper.formatDate(d.data_inicial)).join(', ');
}