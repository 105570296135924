// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfe from '../../form_exportacao_nfe/campos_nfe/selecao_todos_nfe';
import { Input } from '../../../../../../common/input';

type CamposItemProps = {
  component : React.ComponentType<*>,
}

export default function CamposBasicos({ component } : CamposItemProps) {
  const SelecaoTodos = component || SelecaoTodosNfe;

  return (
      <FormSection name="dadosBasicosItens">
        <SelecaoTodos nomes={ ["dadosBasicosItens"] } titulo="Dados Básicos dos Itens"/>

        <div className="grupo-checkbox">
          <Input name="codigo" label="Código" checkbox/>
          <Input name="descricao" label="Descrição" checkbox/>
          <Input name="ncm" label="Código NCM" checkbox/>
          <Input name="exTipi" label="EX TIPI" checkbox/>
          <Input name="unidadeComercial" label="Unidade Comercial" checkbox/>
          <Input name="gtin" label="GTIN" checkbox/>
          <Input name="unidadeTributavel" label="Unidade Tributável" checkbox/>
          <Input name="gtinTributavel" label="GTIN Tributável" checkbox/>
        </div>
      </FormSection>
  );
}