// @flow
'use strict';

import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

import { formatHelper, useEffectOnce } from 'lib/viewUtils';

import { Input } from '../../../common/input';

type ModalProps = {
  handleSubmit : () => void,
  url : string,
  open : bool,
  alteraUrl : (tipo : string, query : any) => void,
  fechaModal : () => void,
}

function removeAcentosTravessaoEEspacoNoInicio(value : any) {
  const strSemAcentos = formatHelper.removeDiacritics(value);

  return strSemAcentos.replace(/[—]/gi, '').trimLeft();
}


export default function ModalManifestaCte({ handleSubmit, fechaModal, alteraUrl, url, open } : ModalProps) {
  const sendForm = React.useCallback(() => {
    if (confirm('Deseja realmente informar Desacordo do Serviço para este CT-e?'))
      handleSubmit();
    fechaModal();
  }, [handleSubmit, fechaModal]);

  useEffectOnce(() => {
    alteraUrl(url);
  });

  return (
      <Modal show={ open } onHide={ fechaModal } dialogClassName="custom-modal">
        <Form onSubmit={ sendForm }>
          <Modal.Header closeButton>
            <Modal.Title>Desacordo com o Serviço</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Funcionalidade de uso exclusivo para o tomador informar ao fisco que o CT-e
              que o relaciona está em desacordo com a prestação de serviço.</p>
            <p>Informe o motivo do desacordo no campo abaixo.</p>

            <Row>
              <Col>
                <Input name="observacoesTomador" label="Observações do Tomador"
                       format={ removeAcentosTravessaoEEspacoNoInicio }/>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="default" onClick={ fechaModal }>Cancelar</Button>
            <Button type="button" bsStyle="primary" onClick={ sendForm }>Confirmar Desacordo com o
              Serviço</Button>
          </Modal.Footer>
        </Form>
      </Modal>
  );
}