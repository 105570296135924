// @flow
'use strict';

import * as React from 'react';

import { useEffectOnce } from 'lib/viewUtils';

import CamposBusca from '../campo_busca';

type FiltrosProps = {
  router : { location : { query : {} } },

  inicializaFiltros : (query : {}) => void,
  aplicaFiltro : () => void,
  carregaContas : () => void,
};

export default function Filtros({ aplicaFiltro, carregaContas, inicializaFiltros, router } : FiltrosProps) {
  useEffectOnce(() => {
    if (router && router.location) {
      inicializaFiltros(router.location.query);
      carregaContas();
    }
  });

  const handleFormSubmit = React.useCallback((e? : SyntheticEvent<*>) => {
    e && e.preventDefault();

    aplicaFiltro();
  }, [aplicaFiltro]);

  return (
      <form className="sped-filtros" method="post" role="search" onSubmit={ handleFormSubmit }>
        <CamposBusca/>
      </form>
  );
}
