// @flow
'use strict';

import * as React from 'react';

import type { IIntermediadorTransacao } from '../../types';

import ColumnDetalhes from '../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../common/detalhes_documento/row_detalhes';

type PagamentoProps = {
  intermediadorTransacao : IIntermediadorTransacao,
};

export default function IntermediadorTransacao({ intermediadorTransacao } : PagamentoProps) {
  return (
      <>
        <h3>Intermediador da Transação</h3>
        <RowDetalhes data={ intermediadorTransacao }>
          <ColumnDetalhes field="cnpj" type="documento" width="50%">CNPJ do Intermediador da Transação</ColumnDetalhes>
          <ColumnDetalhes field="identificadorCadastrado">Identificador Cadastrado</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
