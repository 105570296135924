// @flow
'use strict';

import * as React from 'react';

import type { IIcms500 } from '../../../../../../types_imposto';

import RowDetalhes from '../../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../../common/detalhes_documento/column_detalhes';

type Icms500Props = {
  item : IIcms500,
};

export default function Icms500({ item } : Icms500Props) {
  return (
      <>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="origem" width="25%">Origem da mercadoria</ColumnDetalhes>
          <ColumnDetalhes field="csosn" width="25%">CSOSN</ColumnDetalhes>
          <ColumnDetalhes field="bcIcmsStRetido" type="moeda" width="25%">Base de Cálculo ICMS ST Retido</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaSuportadaConsumidorFinal" type="percentual" width="25%">Alíquota Suportada pelo Consumidor Final</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="valorIcmsStRetido" type="moeda" width="25%">ICMS ST Retido</ColumnDetalhes>
          <ColumnDetalhes field="valorBcFcpRetidoAnteriormente" type="moeda" width="25%">BC do FCP Retido Anteriormente</ColumnDetalhes>
          <ColumnDetalhes field="percentualFcpRetidoAnteriormente" type="percentual" width="25%">FCP Retido Anteriormente por ST</ColumnDetalhes>
          <ColumnDetalhes field="valorFcpRetidoAnteriormente" type="moeda" width="25%">FCP Retido Anteriormente por ST</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="percentualReducaoBcEfetiva" type="percentual" width="25%">Redução BC efetiva</ColumnDetalhes>
          <ColumnDetalhes field="valorBcEfetiva" type="moeda" width="25%">Base de Cálculo efetiva</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaIcmsEfetiva" type="percentual" width="25%">Alíquota ICMS efetiva</ColumnDetalhes>
          <ColumnDetalhes field="valorIcmsEfetivo" type="moeda" width="25%">ICMS efetivo</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
