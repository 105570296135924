'use strict';

// @flow

import numeral from 'numeral';

/**
 * Função para converter valor '123456.00' ou '12345' para número no formato brasileiro '123.456,00'.
 * @param valor é o número em formatao '123456.00' ou '12345'.
 * @param numCasasDecimais define o número de digitos da parte decimal. Quando é nulo ou zero, usa 2.
 */

export function toNumberBrDecimal(valor : ?string | ?number, numCasasDecimais : number) {
  if (!valor && typeof valor !== 'number')
    return valor;

  if (valor === 0)
    return '0,00';

  const valorString = typeof valor === 'string' ? valor : String(valor),
        partes      = valorString.split('.');

  let parteInteira, parteDecimal;

  if (partes.length === 2) {     // verifica se número está no formato 123456.2345
    partes[0] = partes[0].replace(/\D/g, '');     // remove qualquer caracter que não seja dígito
    partes[1] = partes[1].replace(/\D/g, '');

    parteInteira = numeral(partes[0]).format('0,0'); // formata valores da parte inteira do número
    parteDecimal = ajustarDigitosParteDecimal(partes[1], numCasasDecimais);
  }
  else { // número sem parte decimal
    parteInteira = numeral(valorString).format('0,0'); // formata valores da parte inteira do número
    parteDecimal = '0'.repeat(numCasasDecimais);
  }

  if (numCasasDecimais === 0)
    return parteInteira.replace(/,/g, '.');

  return `${ parteInteira.replace(/,/g, '.') },${ parteDecimal }`;
}

export function ajustarDigitosParteDecimal(parteDecimal : string, numCasasDecimais : number) {
  if (parteDecimal.length < numCasasDecimais)
    parteDecimal += '0'.repeat(numCasasDecimais - parteDecimal.length);

  if (parteDecimal.length > numCasasDecimais)
    parteDecimal = parteDecimal.substring(0, numCasasDecimais);

  return parteDecimal;
}


/**
 * Função para converter valor '123456.00' ou '12345' para número no formato brasileiro '123.456' sem parte decimal.
 */

export function toNumberBr(num : ?string | ?number) {
  return toNumberBrDecimal(num, 0);
}

/**
 * Função para converter valor '123456.00' ou '12345' para número no formato brasileiro '123.456,00'.
 */

export function toNumberBrDecimal2(num : ?string | ?number) {
  return toNumberBrDecimal(num, 2);
}

/**
 * Função para converter valor '123456.00' ou '12345' para número no formato brasileiro '123.456,0000'.
 */

export function toNumberBrDecimal4(num : ?string | ?number) {
  return toNumberBrDecimal(num, 4);
}

/**
 * Função para converter números de casas decimais definidas ao chamar a função para o formato brasileiro.
 */

export function toNumberBrDecimalAny(num : ?number, min : number = 2, max : number = 4) {
  if (!num)
    return num;

  const valorDecimal = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: min, maximumFractionDigits: max,
  }).format(num);

  const numDeCasas = valorDecimal.indexOf(',') > 0 ? valorDecimal.toString().split(',')[1].length : 0;

  return toNumberBrDecimal(num, numDeCasas);
}
