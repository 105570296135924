// @flow
'use strict';

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { useEffectOnce } from 'lib/viewUtils';
import { inicializaFiltro } from 'reducers/nfe/filtro_livre';

import Formulario from '../formulario';
import ResumoLista from './resumo_lista';

type FiltrosProps = {
  router : {
    location : { pathname : string, query : { q? : string } },
    params : { d : ?string, s : ?string, sd : ?string, page : ?string, f : ?string },
  },
};

export default function Filtros({ router } : FiltrosProps) {
  const dispatch = useDispatch(),
        inicializarFiltro = React.useCallback((q, params) => dispatch(inicializaFiltro(q, params)), [dispatch]);

  useEffectOnce(() => {
    if (router && router.location)
      inicializarFiltro(router.location.query, router.params);
  });

  const pathname = router && router.location ? router.location.pathname : '';

  return (
      <Row>
        <Col lg={ 8 }>
          <Formulario/>
        </Col>
        <Col lg={ 4 }>
          <ResumoLista pathname={ pathname }/>
        </Col>
      </Row>
  );
}
