// @flow
'use strict';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';

import * as detalhesNoticia from 'reducers/ferramentas/noticia/detalhes';
import { withProvider } from 'lib/reduxTools';
import { alert } from 'reducers/alerts';

import Formulario from './formulario';

/**
 * Lista de campos do formulário que não devem ser enviados ao servidor.
 */
const FIELD_BLACKLIST = [
  'autor',           // não deve ser enviada porque este campo não deve ser alterado
  'createdAt',        // não devem ser enviados pois a atualização destes campos é automática
];

const mapStateToProps = createStructuredSelector({ initialValues: detalhesNoticia.itemSelector });

export default compose(
    withProvider(),
    connect(mapStateToProps, {
      navigateToIndex: detalhesNoticia.navigateToIndex, excluiNoticia: detalhesNoticia.excluiNoticia,
    }),
    reduxForm({
      form: 'noticia', enableReinitialize: true,
      async onSubmit(form) {
        const { id, ...noticia } = form.deleteAll(FIELD_BLACKLIST).toJS();
        if (id)
          return detalhesNoticia.atualizaNoticia(id, noticia);
        else
          return detalhesNoticia.criaNoticia(noticia);
      },
      onSubmitFail(syncErrors, dispatch, e) {
        let msg;
        if (syncErrors || (e instanceof SubmissionError))
          msg = 'Ocorreram erros de validação. Verifique os dados preenchidos e tente novamente';
        else {
          global.console.error(e);
          msg = 'Ocorreu um erro ao enviar o formulário. Se o problema persistir, contate o suporte.';
        }
        dispatch(alert('danger', msg));
      },
      onSubmitSuccess(r, dispatch) {
        dispatch(detalhesNoticia.navigateToIndex());
        dispatch(alert('info', 'Notícia salva.'));
      },
    }),
)(Formulario);
