// @flow
'use strict';

import * as React from 'react';

import type { ICofinsAliq } from '../../../../../types_imposto';

import TributoAliquota from './tributo_aliquota';

type CofinsAliqProps = {
  item : ICofinsAliq,
};

export default function CofinsAliq({ item } : CofinsAliqProps) {
  return <TributoAliquota item={ item }/>;
}