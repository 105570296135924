// @flow
'use strict';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { abreModal } from 'reducers/nfe/modal_manifestacao_lote';
import { querySelector, tipoSelector } from 'reducers/nfe/filtro_livre';

import Icone from 'components/common/icone';

export default function BotaoOpcaoCienciaOperacao() {
  const dispatch         = useDispatch(),
        tipo             = useSelector(tipoSelector),
        query            = useSelector(querySelector),
        abreModalCiencia = React.useCallback(() => dispatch(abreModal('ciencia', tipo, query)), [dispatch, tipo, query]);

  return (
      <span onClick={ abreModalCiencia }>
        <Icone nome="info-circle" estilo="fas">Ciência da operação</Icone>
      </span>
  );
}
