// @flow
'use strict';

import React from 'react';
import { Button } from 'react-bootstrap';

import Icone from '../../../common/icone';
import ModalManifestaCte from '../modal_manifesta_cte';

type ManifestaCteProps = {
  id : string,
  url? : string,
  open : bool,
  abreModal : () => void,
}

/**
 * O botão da manifestação com modal.
 *
 * @param url se estiver vazia, o botão ficará desabilitado e uma mensagem de orientação será exibida em um popover.
 * @param open indica se modal está aberto
 * @param abreModal função para apresentar modal
 * @param props restante das propriedades, serão repassadas ao componente de modal
 */
export default function ManifestaCte({ url, open, abreModal, ...props } : ManifestaCteProps) {
  if (url) {
    return (
        <div>
          <Button className="btn btn-action btn-block" type="button" onClick={ abreModal }>
            <Icone nome="gavel" estilo="fas" flip="horizontal"> Desacordo do Serviço </Icone>
          </Button>

          { open && <ModalManifestaCte { ...props } url={ url }/> }
        </div>);
  }

  return (
      <Button disabled block className="btn-action"
              title="O desacordo do serviço é para uso exclusivo do tomador indicado no documento CT-e.">
        <Icone nome="gavel" estilo="fas" flip="horizontal"> Desacordo do Serviço </Icone>
      </Button>
  );
}