// @flow
'use strict';

import * as _ from 'lodash';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import type { IRoute } from 'lib/types';
import { jsSelector, useEffectOnce } from 'lib/viewUtils';

import { carregaDetalhes, itemSelector } from '../../../reducers/nfe/detalhes';
import { modalOpenSelector } from '../../../reducers/nfe/historico';

import DadosBasicos from './dados_basicos';
import AbasInformacoes from './abas_informacoes';
import Voltar from './voltar';
import PainelLateral from '../../paineis';
import ModalHistorico from './modal_historico';
import BotaoHistorico from './botao_historico';
import AvisoNaoCustodiado from './aviso_nao_custodiado';
import BotoesDetalhes from '../../common/detalhes_documento/botoes_detalhes';
import { Breadcrumbs, Title } from '../../common/router_components';

type DetalhesProps = {
  routes : IRoute[],
  routeParams : { id : string };
}

const itemJsSelector = jsSelector(itemSelector, {});

export default function Detalhes({ routeParams, routes } : DetalhesProps) {
  const historicoOpen = useSelector(modalOpenSelector),
        item          = useSelector(itemJsSelector);

  const dispatch         = useDispatch(),
        carregarDetalhes = React.useCallback((id) => dispatch(carregaDetalhes(id)), [dispatch]);

  useEffectOnce(() => {
    // Caso os dados do item não estejam carregados, carrega detalhes do item
    if (_.isEmpty(item) && routeParams)
      carregarDetalhes(routeParams.id);
  });

  return (
      <>
        <Title routes={ routes }/>
        <Breadcrumbs routes={ routes }/>

        {/* Precisa desta comparação com false porque o campo inicia com o valor undefined, e exibe o aviso indevidamente */
          item.possuiXml === false && <AvisoNaoCustodiado manifestacao={ item.manifestacao }/> }

        <Row>
          <Col md={ 9 } className="r"><Voltar/></Col>
          <Col md={ 3 }><BotaoHistorico id={ item.id }/></Col>
        </Row>
        <Row>
          <div className="col-md-9" style={ { marginTop: '-.8em' } }>
            <section className="visualizacao-documento detalhes-documento">
              <DadosBasicos item={ item }/>
              <AbasInformacoes item={ item }/>
            </section>

            <BotoesDetalhes id={ item.id } tipo="nfe" possuiXml={ item.possuiXml } textoBotaoDownloadPdf="Download do DANFE" BotaoVoltar={ Voltar }/>
          </div>

          <Col md={ 3 }>
            <PainelLateral ignoreInitialize url={ item.url } env={ item.env } reconsulta={ item.possuiXml }/>
          </Col>
        </Row>

        { historicoOpen && <ModalHistorico/> }
      </>
  );
}
