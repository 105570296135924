// @flow
'use strict';

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { jsSelector } from '../../../../../../lib/viewUtils';
import { fechaDetalhesProdutoServico, itemSelector, modalOpenSelector } from '../../../../../../reducers/nfe/detalhes_produto_servico';

import DadosBasicos from './dados_basicos';
import ProdutosEspecificos from './produtos_especificos';
import InformacoesAdicionaisProduto from './informacoes_adicionais_produto';
import InformacoesEmbalagemProduto from './informacoes_embalagem_produto';
import Impostos from './impostos';
import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';
import TableDetalhes from '../../../../../common/detalhes_documento/table_detalhes';

const itemJsSelector = jsSelector(itemSelector, {});

export default function ModalDetalhes() {
  const open           = useSelector(modalOpenSelector),
        item           = useSelector(itemJsSelector),
        dispatch       = useDispatch(),
        fechaModal     = React.useCallback(() => dispatch(fechaDetalhesProdutoServico()), [dispatch]);

  const titulo = `Detalhes do Item ${ item.numero }: ${ item.descricao }`;

  return (
      <Modal show={ open } onHide={ fechaModal } dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>{ titulo }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="visualizacao-documento">
            <DadosBasicos item={ item }/>

            <h3>Pedido de Compra</h3>
            <RowDetalhes data={ item } className="no-margin-bottom">
              <ColumnDetalhes field="numeroPedido" width="50%">Número do Pedido</ColumnDetalhes>
              <ColumnDetalhes field="itemPedido" width="50%">Item do Pedido</ColumnDetalhes>
            </RowDetalhes>

            { item &&
            <>
              { item.numControleFci &&
              <>
                <h3>Grupos Diversos</h3>
                <RowDetalhes data={ item } className="no-margin-bottom">
                  <ColumnDetalhes field="numControleFci">Núm. controle FCI</ColumnDetalhes>
                </RowDetalhes>
              </>
              }

              { item.rastreabilidades && item.rastreabilidades.length > 0 &&
              <>
                <h3>Rastreabilidade</h3>
                <TableDetalhes data={ item.rastreabilidades } noDataClass="padding-10">
                  <ColumnDetalhes field="numLote" width="20%">Núm. Lote</ColumnDetalhes>
                  <ColumnDetalhes field="qtdProdLote" width="20%">Qtd. de Prod. Lote</ColumnDetalhes>
                  <ColumnDetalhes field="fabricacao" width="20%">Fabricação</ColumnDetalhes>
                  <ColumnDetalhes field="validade" width="20%">Validade</ColumnDetalhes>
                  <ColumnDetalhes field="codAgregacao" width="20%">Cód. Agregação</ColumnDetalhes>
                </TableDetalhes>
              </>
              }

              { item.informacoesAdicionaisProduto && <InformacoesAdicionaisProduto informacoesAdicionaisProduto={ item.informacoesAdicionaisProduto }/> }
              { item.informacoesEmbalagemProduto && <InformacoesEmbalagemProduto informacoesEmbalagemProduto={ item.informacoesEmbalagemProduto }/> }
            </>
            }

            { item.produtoEspecifico && <ProdutosEspecificos produtoEspecifico={ item.produtoEspecifico }/> }

            { item.impostos && <Impostos item={ item.impostos }/> }
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={ fechaModal }>Fechar</Button>
        </Modal.Footer>
      </Modal>
  );
}
