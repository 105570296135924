'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, Dispatch, State } from 'lib/types';
import request from 'lib/request';
import { ajaxError } from '../alerts';

export const FILTROS_CARREGANDO = 'cfe/resumo_lista/FILTROS_CARREGANDO',
             FILTROS_CARREGADO  = 'cfe/resumo_lista/FILTROS_CARREGADO';

const INITIAL_STATE = Immutable.fromJS({ carregando: false, filtros: [] });

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case FILTROS_CARREGANDO:
      return state.set('carregando', true).set('filtros', Immutable.fromJS([]));
    case FILTROS_CARREGADO:
      return state.set('carregando', false).set('filtros', action.filtros);
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['cfe', 'resumoLista'], Immutable.Map());
export const filtrosSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('filtros'));
export const carregandoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('carregando'));

// actions

export function filtrosCarregando() {
  return { type: FILTROS_CARREGANDO };
}

export function filtrosCarregado(filtros : Array<any>) {
  return { type: FILTROS_CARREGADO, filtros };
}

// thunk actions

export function carregaFiltrosResumoLista(d : ?string) {
  return async function(dispatch : Dispatch<any>) {
    dispatch(filtrosCarregando());

    await request.get(`/app/cfe/resumo_lista/${ d || 'recebidos' }`)
        .accept('json')
        .then(r => dispatch(filtrosCarregado(r.body.filtros)))
        .catch(e => {
          dispatch(ajaxError('Erro ao carregar os filtros do resumo da lista de CF-e', e));
        });
  };
}
