// @flow
'use strict';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';

import FormExportacaoNfe from './form_exportacao_nfe';
import { onSubmitForm } from '../functions';

import { rootSelector } from '../../../../../../reducers/lista_documentos/nfe';
import { openModalSelector } from '../../../../../../reducers/modal_exportacao_lista';

export default compose(
    connect<*, *, *, *, *, *>(createStructuredSelector({
      open: openModalSelector,
      initialValues: rootSelector,
    })),
    reduxForm({
      form: 'listaDocumentosNfe', enableReinitialize: true,
      onSubmit: onSubmitForm,
    }),
)(FormExportacaoNfe);