// @flow
'use strict';

import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import type { IDocumento } from '../../../../lib/api';
import AlertWithIcon from '../../../common/alert_with_icon';

import TabelaItens from './tabela_itens';
import InformacoesConsulta from './informacoes_consulta';
import InformacoesSituacaoDocumento from './informacoes_situacao_documento';

type ModalProps = {
  documento : ?IDocumento,
  open : bool,
  fechaModal : () => void,
}

const estiloChave = {
  fontSize: '15px',
  color: "#88888a",
  marginTop: "5px"
}

export default function ModalInformacoesPortaria({ documento, open, fechaModal } : ModalProps) {
  return (
      <Modal show={ open } onHide={ fechaModal } dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            { 'Consulta de Portaria para Documento ' }
            { documento && documento.chave ? <p style={ estiloChave }>{ documento.chave }</p> : '' }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { documento ? <InformacoesPortaria documento={ documento }/> : <InformacoesVazia/> }
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={ fechaModal }>Ok</Button>
        </Modal.Footer>
      </Modal>
  );
}

function InformacoesVazia() {
  return (
      <p>Documento não encontrado.</p>
  );
}

function SemConsulta() {
  return (
      <AlertWithIcon bsStyle="info" icon="info-circle">
        <p><strong>Nenhuma consulta realizada.</strong></p>
      </AlertWithIcon>
  );
}

function InformacoesPortaria({ documento } : { documento : IDocumento }) {
  const decisao = documento.portaria && documento.portaria.ultimaDecisao ? documento.portaria.ultimaDecisao.label : '';

  return (
      <div>
        <legend>Última consulta</legend>
        {
          documento.portaria.historico ?
            <React.Fragment>
              <InformacoesConsulta portaria={ documento.portaria }/>

              <legend>Como estava o documento</legend>
              <InformacoesSituacaoDocumento documento={ documento }/>

            </React.Fragment>
            : <SemConsulta/>
        }

        { decisao === 'Receber com Pendências' &&
        <div>
          <legend>Itens com Pendência</legend>
          <TabelaItens itensDecisaoPortaria={ documento.itensDecisaoPortaria }/>
        </div>
        }
      </div>
  );
}