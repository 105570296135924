'use strict';

import * as Immutable from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { createSelector } from 'reselect';

const INITIAL_STATE = Immutable.fromJS({
  locationBeforeTransitions: null
});

export default (state = INITIAL_STATE, action) => {
  if (action.type === LOCATION_CHANGE)
    return state.set('locationBeforeTransitions', Immutable.fromJS(action.payload));

  return state;
};

export const rootSelector = state => state.get('routing');

export const asJSSelector = createSelector(rootSelector, root => root.toJS());

export const currentRouteSelector = createSelector(rootSelector, root => root.get('locationBeforeTransitions'));