// @flow
'use strict';

import * as Immutable from 'immutable';

import { INICIALIZA_VALORES_NFE, REINICIA_VALORES_NFE } from './nfe';
import { INICIALIZA_VALORES_NFCE, REINICIA_VALORES_NFCE } from './nfce';
import { INICIALIZA_VALORES_NFSE, REINICIA_VALORES_NFSE } from './nfse';
import { INICIALIZA_VALORES_CTE, REINICIA_VALORES_CTE } from './cte';

export function inicializaCampos(state : Immutable.Map, camposExportacao : Object) {
  return state.mapEntries(([k, v]) => {
    if (Immutable.Map.isMap(v) && k !== 'query') {
      const newValue = v.mapEntries(([innerK, innerV]) => {
        if (camposExportacao['campos'])
          return [innerK, camposExportacao['campos'].includes(innerK)];

        return [innerK, innerV];
      });
      return [k, newValue];
    }

    if (camposExportacao[k])
      return [k, camposExportacao[k]];

    return [k, v];
  });
}

export function setValoresIniciais(campos : {}, tipoDocumento : string) {
  switch (tipoDocumento) {
    case 'cte':
      return { type: INICIALIZA_VALORES_CTE, campos };
    case 'nfce':
      return { type: INICIALIZA_VALORES_NFCE, campos };
    case 'nfse':
      return { type: INICIALIZA_VALORES_NFSE, campos };
    default:
      return { type: INICIALIZA_VALORES_NFE, campos };
  }
}

export function reiniciaCampos(tipoDocumento : string) {
  switch (tipoDocumento) {
    case 'cte':
      return { type: REINICIA_VALORES_CTE };
    case 'nfce':
      return { type: REINICIA_VALORES_NFCE };
    case 'nfse':
      return { type: REINICIA_VALORES_NFSE };
    default:
      return { type: REINICIA_VALORES_NFE };
  }
}

