// @flow
'use strict';

import * as React from 'react';
import { Col, ControlLabel, Row } from 'react-bootstrap';

import { Input } from '../input';

export default function CampoLido() {
  return (
      <Row className="campo-formulario campo-input">
        <ControlLabel className="col-sm-4 titulo-checkbox">Lido</ControlLabel>
        <Col sm={ 8 } className="opcao-checkbox margin-bottom-1">
          <Input name="true" label="Sim" checkbox/>
          <Input name="false" label="Não" checkbox/>
        </Col>
      </Row>
  );
}
