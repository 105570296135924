// @flow
'use strict';

import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import { withFormValues } from '../../../../../../../lib/forms';

import SelecaoTodosCte from './selecao_todos_cte';
import CamposBasicos from './campos_basicos';
import CamposTotais from './campos_totais';
import CamposSimplesCarga from './campos_simples_carga';
import CamposTransacoesConsyste from '../../common/campos_transacoes_consyste';
import CamposPessoa from '../../common/campos_pessoa';
import CamposCarga from './campos_carga';
import CamposDesacordoServico from './campos_desacordo_servico';
import CamposIcms from './campos_icms';
import CamposIcmsUfFim from './campos_icms_uf_fim';
import CamposTributosFederais from './campos_tributos_federais';

type CamposCteProps = {
  linhaPor? : number,
};

export function CamposCte({ linhaPor } : CamposCteProps) {

  const nomes = ['dadosBasicos', 'emit', 'dest', 'transacoesConsyste', 'toma', 'rem', 'receb', 'exped', 'desacordoServico',
                 'dadosTotais', 'dadosIcms', 'dadosIcmsUfFim', 'dadosTributosFederais', 'basicoCarga'];

  if (linhaPor === 4)
    nomes.push('docCargaNf', 'docCargaNfe', 'docCargaOutros');

  return (
      <section className="download-lista">
        <SelecaoTodosCte nomes={ nomes } selecionarTodos titulo="SELECIONAR TODOS"/>

        <fieldset className="grupo-download-lista">
          <legend>Dados do Documento</legend>

          <Row>
            <Col md={ 6 }>
              <CamposBasicos/>
              <CamposTransacoesConsyste component={ SelecaoTodosCte }/>
              <CamposDesacordoServico component={ SelecaoTodosCte }/>
              <CamposPessoa nome="emit" titulo="Dados do Emitente" component={ SelecaoTodosCte }/>
              <CamposPessoa nome="dest" titulo="Dados do Destinatário" component={ SelecaoTodosCte }/>
              <CamposPessoa nome="toma" titulo="Dados do Tomador" component={ SelecaoTodosCte }/>
            </Col>
            <Col md={ 6 }>
              <CamposPessoa nome="rem" titulo="Dados do Remetente" component={ SelecaoTodosCte }/>
              <CamposPessoa nome="receb" titulo="Dados do Recebedor" component={ SelecaoTodosCte }/>
              <CamposPessoa nome="exped" titulo="Dados do Expedidor" component={ SelecaoTodosCte }/>
              <CamposTotais component={ SelecaoTodosCte }/>
              <CamposIcms component={ SelecaoTodosCte }/>
              <CamposIcmsUfFim component={ SelecaoTodosCte }/>
              <CamposTributosFederais component={ SelecaoTodosCte }/>
              <CamposSimplesCarga component={ SelecaoTodosCte }/>
            </Col>
          </Row>
        </fieldset>

        { linhaPor === 4 && <CamposCarga/> }
      </section>
  );
}

export default withFormValues(true, 'linhaPor')(CamposCte);
