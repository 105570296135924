'use strict';

// @flow

import * as React from 'react';
import { Panel } from 'react-bootstrap';

import type { IDocumento } from 'lib/api';
import { formatHelper as fmt } from '../../lib/viewUtils';

import LabelSituacao from './label_situacao';
import ComTooltip from '../common/com_tooltip';
import ManifestaNfe from '../documento/manifestacao_nfe/manifesta_nfe';

type ManifestacaoProps = {
  documento : ?IDocumento
};

/**
 * Painel de informações de manifestação.
 */
export default function Manifestacao({ documento } : ManifestacaoProps) {
  if (!documento || !documento.manifestacao)
    return null;

  const manif = documento.manifestacao;

  // Bloco responável por mostrar a ultima consulta
  const ultimaConsulta = manif.ultimaManifestacao ?
                           <p>Último manifesto:{ '  ' } { fmt.formatDate(manif.ultimaManifestacao) }</p> :
                           'Sem manifestação.';

  return (
      <Panel className="situacoes">
        <Panel.Heading>Destinatário</Panel.Heading>
        <Panel.Body>
          <dl>
            <dt>
              <ComTooltip hint="Manifesto efetuado pelo destinatário através do Portal Consyste." posicao="top">
                <span>Manifesto na Consyst-e</span>
              </ComTooltip>
            </dt>
            <dd><LabelSituacao { ...manif.consyste } /></dd>
            <dt>
              <ComTooltip hint="Último valor recebido da SEFAZ." posicao="top">
                <span>Manifesto na SEFAZ</span>
              </ComTooltip>
            </dt>
            <dd><LabelSituacao { ...manif.sefaz } /></dd>
          </dl>
          <div className="ultima-consulta r">
            { ultimaConsulta }
          </div>
          <ManifestaNfe url={ manif.url }/>
        </Panel.Body>
      </Panel>
  );
}
