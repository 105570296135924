// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosCte from './selecao_todos_cte';
import { Input } from '../../../../../../common/input';

export default function CamposTotais() {
    return (
        <FormSection name="dadosTotais">
            <SelecaoTodosCte nomes={ ["dadosTotais"] } titulo="Dados dos Totais"/>

            <div className="grupo-checkbox">
                <Input name="valor" label="Total do CT-e" checkbox/>
                <Input name="totalTributos" label="Total Aprox. Tributos" checkbox/>
                <Input name="valorAReceber" label="Valor a Receber" checkbox/>
            </div>
        </FormSection>
    );
}