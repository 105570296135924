'use strict';
// @flow

import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import UpdateNag from 'components/common/update_nag';

import type { IDocumento } from 'lib/api';

let lastId = 0;

export default function PainelCustodia({ documento } : { documento? : ?IDocumento }) {
  if (!documento)
    return null;

  const { icone, cor, texto, info } = dadosParaPainelCustodia(documento);

  const popover = <Popover title={ texto } id={`popover-${++lastId}`}>{ info }</Popover>;

  return (
      <OverlayTrigger placement="left" overlay={ popover }>
        <span>
          <UpdateNag icone={ icone } tipo={ cor }>
            { texto }
          </UpdateNag>
        </span>
      </OverlayTrigger>
  );
}

function dadosParaPainelCustodia(documento : IDocumento) : { icone : string, cor : string, texto : string, info : string } {
  switch (documento.custodiado) {
    case null:
    case undefined:
      return {
        icone: 'question', cor: 'warning', texto: 'Custódia Indeterminada',
        info: `Contate nosso suporte técnico para mais informações.`
      };

    case false:
      return {
        icone: 'times', cor: 'danger', texto: 'Não Custodiado',
        info: `Não existe arquivo relacionado a esse documento no Consyste.
               Para obtê-lo, faça a manifestação do destinatário ou solicite a sua recuperação.`
      };

    default:
      return {
        icone: 'check', cor: 'success', texto: 'Custodiado',
        info: `Existe um arquivo para esse documento no Consyste.
               A validade fiscal desse arquivo é informada no quadro Situações.`
      };
  }
}