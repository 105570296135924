// @flow
'use strict';

import * as React from 'react';

import type { IInformacaoNf } from '../../../types';

type ContainersInfNfsProps = {
  infNfs : IInformacaoNf[],
};

export default function ContainersInfNfs({ infNfs } : ContainersInfNfsProps) {
  return (
      <>
        { infNfs.map((nf, k) => (
            <tr key={ k }>
              <td>{ nf.serie }</td>
              <td>{ nf.num }</td>
              <td>{ nf.unMedidaRateada }</td>
            </tr>
        )) }
      </>
  );
}
