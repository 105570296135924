// @flow
'use strict';

import * as React from 'react';

import type { IPisOutr } from '../../../../../types_imposto';

import ColumnDetalhes from '../../../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../../../common/detalhes_documento/row_detalhes';
import TributoQuantidade from './tributo_quantidade';
import ValorPis from './valor_pis';

type PisOutrProps = {
  item : IPisOutr,
};

export default function PisOutr({ item } : PisOutrProps) {
  if (item.valorBc && item.qtdVendida) {
    return (
        <>
          <RowDetalhes data={ item } className="no-margin-bottom">
            <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
            <ColumnDetalhes field="valorBc" type="moeda" width="25%">Valor da BC do PIS</ColumnDetalhes>
            <ColumnDetalhes field="percAliquota" type="percentual" width="25%">Alíquota do PIS</ColumnDetalhes>
            <ColumnDetalhes field="qtdVendida" width="25%">Quantidade Vendida</ColumnDetalhes>
          </RowDetalhes>
          <RowDetalhes data={ item }>
            <ColumnDetalhes field="valorAliquota" type="moeda" width="25%">Alíquota do PIS</ColumnDetalhes>
            <ColumnDetalhes field="valorPis" type="moeda" width="75%">Valor do PIS</ColumnDetalhes>
          </RowDetalhes>
        </>
    );
  }

  if (item.valorBc) {
    return (
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
          <ColumnDetalhes field="valorBc" type="moeda" width="25%">Valor da BC do PIS</ColumnDetalhes>
          <ColumnDetalhes field="percAliquota" type="percentual" width="25%">Alíquota do PIS</ColumnDetalhes>
          <ColumnDetalhes field="valorPis" type="moeda" width="25%">Valor do PIS</ColumnDetalhes>
        </RowDetalhes>
    );
  }

  if (item.qtdVendida)
    return <TributoQuantidade item={ item }/>;

  return <ValorPis item={ item }/>;
}
