// @flow
'use strict';

import * as React from 'react';
import { Button } from 'react-bootstrap';

import Icone from '../../common/icone';

type BotaoOrdenacaoProps = {
  campo : string,
  rotulo : string,
  ordenacao : ?string,
  onClick : () => void,
}

export default function OrdenacaoPorCampo({ campo, rotulo, ordenacao, onClick } : BotaoOrdenacaoProps) {
  return (
      <Button type="button" className="btn-block" bsStyle="link" onClick={ onClick }>
        <RotuloComSinalOrdenacao campo={ campo } rotulo={ rotulo } ordenacao={ ordenacao }/>
      </Button>
  );
}

function RotuloComSinalOrdenacao({ campo, rotulo, ordenacao } : { campo : string, rotulo : string, ordenacao : ?string }) {
  if (!deveApresentarSinalOrdenacao(campo, ordenacao))
    return <span>{ rotulo }</span>;

  const sentidoSinal = !ordenacao || ordenacao.includes('decresc') ? 'caret-up' : 'caret-down';

  return <span>{ rotulo }{ '  ' }<Icone nome={ sentidoSinal } estilo="fas"/></span>;
}

function deveApresentarSinalOrdenacao(campo : string, ordenacao : ? string) {
  if (!ordenacao)
    return campo === 'emitido_em';

  return ordenacao.includes(campo) && ordenacao.includes('cresc');
}
