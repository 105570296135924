// @flow
'use strict';

import * as React from 'react';

import type { IIcmsOutraUf } from '../../../types_imposto';

import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';

type IcmsOutraUfProps = {
  icms : IIcmsOutraUf,
};

export default function IcmsOutraUf({ icms } : IcmsOutraUfProps) {
  return (
      <>
        <h4>ICMS - 90 = ICMS outra UF</h4>
        <RowDetalhes data={ icms } className="no-margin-bottom">
          <ColumnDetalhes field="cst" width="50%">Classificação Tributária do Serviço</ColumnDetalhes>
          <ColumnDetalhes field="percReducaoBc" type="percentual" width="50%">Percentual Redução BC</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ icms } className="no-margin-bottom">
          <ColumnDetalhes field="valorBc" type="moeda" width="50%">Valor da Base de Cálculoo</ColumnDetalhes>
          <ColumnDetalhes field="aliquota" type="percentual" width="50%">Alíquota do ICMS</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ icms }>
          <ColumnDetalhes field="valorDevido" type="moeda">Valor do ICMS devido</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
