'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, Dispatch, GetState, State } from 'lib/types';
import request from '../../../lib/request';
import { ajaxError } from '../../alerts';

export const LISTA_CARREGANDO     = 'arquivo/download/lista/LISTA_CARREGANDO',
             LISTA_CARREGADA      = 'arquivo/download/lista/LISTA_CARREGADA',
             ALTERA_CARREGANDO    = 'arquivo/download/lista/ALTERA_CARREGANDO',
             ALTERA_ATUALIZA_TELA = 'arquivo/download/lista/ALTERA_ATUALIZA_TELA ';

const INITIAL_STATE = Immutable.fromJS({
  paginaAtual: { page: 1, items: null },
  carregando: false,
  atualizaTela: false,
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case LISTA_CARREGANDO:
      return state.set('carregando', true).set('paginaAtual', action.body);
    case LISTA_CARREGADA:
      return state.set('carregando', false).set('paginaAtual', action.body);
    case ALTERA_CARREGANDO:
      return state.set('carregando', action.body);
    case ALTERA_ATUALIZA_TELA:
      return state.set('atualizaTela', action.valor);

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['arquivo', 'download', 'lista'], Immutable.Map());
export const currentPage = createSelector<*, *, *, *>(rootSelector, (root) => root.get('paginaAtual'));
export const carregandoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('carregando'));
export const pageSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.getIn(['paginaAtual', 'page']));
export const atualizaTelaSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('atualizaTela'));

// actions

export function listaCarregando(page : number) {
  return { type: LISTA_CARREGANDO, body: Immutable.fromJS({ page, items: 'loading' }) };
}

export function listaCarregada(lista : any) {
  return { type: LISTA_CARREGADA, body: Immutable.fromJS(lista) };
}

export function alteraAtualizaTela(valor : bool) {
  return { type: ALTERA_ATUALIZA_TELA, valor };
}

// thunk actions

const DELAY_5_SEGUNDOS = 5000;
let requestTimeout;

export function agendaRequisicao(page : number, delay? : number) {
  return async function(dispatch : Dispatch<*>) {
    const fn = () => dispatch(carregaLista(page));
    requestTimeout = setTimeout(fn, delay || DELAY_5_SEGUNDOS);
  };
}

let req = null;

export function carregaLista(page : ?number) {
  return async function(dispatch : Dispatch<any>, getState : GetState) {
    if (requestTimeout)
      clearTimeout(requestTimeout);

    // cancela uma eventual solicitação em andamento
    if (req) {
      req.cancel();
      await dispatch({ type: ALTERA_CARREGANDO, body: false });
    }

    if (!carregandoSelector(getState())) {
      const paginaRequisicao = !page ? 1 : page;

      // aprensenta estágios de carregamento somente na primeira requisição
      if (!atualizaTelaSelector(getState()))
        await dispatch(listaCarregando(paginaRequisicao));

      req = request.get('/app/downloads')
          .accept('json')
          .query({ page: paginaRequisicao })
          .then(r => {
            dispatch(listaCarregada(r.body));
            dispatch(alteraAtualizaTela(true));
            dispatch(agendaRequisicao(paginaRequisicao)); // atualiza lista de downloads a cada 5 segundos
          })
          .catch(e => {
            dispatch(listaCarregada({ page: paginaRequisicao, items: 'erro' }));
            dispatch(ajaxError('Erro ao carregar lista de Download', e));
          });
    }
  };
}