'use strict';

export const LOADING = 'carregando…';

export const SITUACOES_CUSTODIA = [
  { value: 'expired', label: 'Download Expirado' },
  { value: 'notified', label: 'Notificado' },
  { value: 'cancelled', label: 'Cancelada ou Denegada' },
  { value: 'syntax_error', label: 'Erro de Sintaxe' },
  { value: 'invalid_schema', label: 'Schema Inválido' },
  { value: 'wrong_environment', label: 'Ambiente Incorreto' },
  { value: 'invalid_dsig', label: 'Assinatura Digital Inválida' },
  { value: 'wrong_dsig', label: 'Assinatura Digital Difere' },
  { value: 'ok', label: 'Custodiado' },
  { value: 'not_found', label: 'Não Encontrado' },
];

export const CUSTODIADO = [
  { value: 'false', label: 'Não' },
  { value: 'true', label: 'Sim' },
  { value: 'undefined', label: 'Nulo' },
];

export const ESCOLHA_BOOLEANA = [
  { value: 'false', label: 'Não' },
  { value: 'true', label: 'Sim' },
];

export const SALVAR_RELATORIO = [
  { value: false, label: 'Não' },
  { value: true, label: 'Sim' },
];

export const SITUACOES_MANIFESTACAO = [
  { value: 'confirmada', label: 'Operação Confirmada' },
  { value: 'ciencia', label: 'Ciência da Operação' },
  { value: 'desconhecimento', label: 'Desconhecimento' },
  { value: 'operacao_nao_realizada', label: 'Operação não Realizada' },
  { value: 'none', label: 'Não Informado' },
];

export const SITUACOES_ARQUIVO = [
  { value: 'ok', label: 'Válido' },
  { value: 'syntax_error', label: 'Erro na Sintaxe' },
  { value: 'invalid_schema', label: 'Schema Inválido' },
  { value: 'wrong_environment', label: 'Ambiente Incorreto' },
  { value: 'invalid_dsig', label: 'Assinatura Digital Inválida' },
  { value: 'not_found', label: 'Não Encontrado' },
];

export const TIPO_EMISSAO = [
  { value: '0', label: 'Própria' },
  { value: '1', label: 'Terceiros' },
];

export const TIPO_OPERACOES = [
  { value: '0', label: 'Entrada' },
  { value: '1', label: 'Saída' },
];

export const POSSUI_CHAVE = [
  { value: 'sim', label: 'Com Chave' },
  { value: 'nao', label: 'Sem Chave' },
];

export const MODELO_SPED = [
  { value: '55', label: 'NF-e' },
  { value: '65', label: 'NFC-e' },
  { value: '27', label: 'NF Ferroviário' },
  { value: '07', label: 'NF Transporte' },
  { value: '04', label: 'NF Produtor' },
  { value: '1B', label: 'NF Avulsa' },
  { value: '01', label: 'NF' },
  { value: '57', label: 'CT-e' },
  { value: '67', label: 'CT-e OS' },
  { value: '08', label: 'CT Rodoviário' },
  { value: '26', label: 'CT Multimodal' },
  { value: '11', label: 'CT Ferroviário' },
  { value: '8B', label: 'CT Avulso' },
  { value: '09', label: 'CT Aquaviário' },
  { value: '10', label: 'CA' },
  { value: '63', label: 'BP-e' },
];

export const SITUACAO_SPED = [
  { value: '00', label: 'Doc Reg' },
  { value: '01', label: 'Escr ext regular' },
  { value: '02', label: 'Documento cancelado' },
  { value: '03', label: 'Escr ext cancelado' },
  { value: '04', label: 'Doc denegado' },
  { value: '05', label: 'Doc Num inutil' },
  { value: '06', label: 'Doc Complementar' },
  { value: '07', label: 'Escr ext complementar' },
  { value: '08', label: 'Doc Regime Especial' },
];

export const SITUACAO_SEFAZ = [
  { value: '100', label: 'Autoriz. SEFAZ' },
  { value: '101', label: 'Cancelado' },
  { value: '102', label: 'Inutilizado' },
  { value: '110', label: 'Uso Denegado' },
  { value: '150', label: 'Autoriz. fora Prazo' },
  { value: '151', label: 'Canc. fora Prazo' },
  { value: '155', label: 'Canc. fora Prazo' },
  { value: '217', label: 'Não Consta' },
  { value: '301', label: 'Irr. Emissor' },
  { value: '302', label: 'Irr. Dest.' },
  { value: '526', label: 'Nota Antiga' },
  { value: '656', label: 'Cons. Ind.' },
  { value: '999', label: 'Erro SEFAZ' },
  { value: 'nao_consultada', label: 'Não Consultado' },
  { value: 'indeterminada', label: 'Indeterminada' },
];

export const MODELO_ESCRITURADO = [
  { value: '55', label: 'NF-e' },
  { value: '65', label: 'NFC-e' },
  { value: '57', label: 'CT-e' },
  { value: '67', label: 'CT-e OS' },
];

export const SITUACOES_DESACORDO = [
  { value: 'em_desacordo', label: 'Em Desacordo' },
  { value: 'desacordo_rejeitado', label: 'Desacordo Rejeitado' },
  { value: 'aguardando_retorno', label: 'Aguardando Retorno' },
  { value: 'nao_consta', label: 'Não Consta' },
];

export const SITUACOES_CERTIFICADO = [
  { value: 'ativo', label: 'Ativo', title: 'Certificado atualmente em uso' },
  { value: 'expirando', label: 'Expiração Próxima', title: 'Certificado próximo de expirar' },
  { value: 'quase_expirado', label: 'Expiração Muito Próxima', title: 'Certificado próximo de expirar e sem substituto' },
  { value: 'expirado', label: 'Expirado', title: 'Certificado expirado sem substituto' },
  { value: 'antes_vigencia', label: 'Vigência Futura', title: 'Certificado com vigência futura' },
  { value: 'inativo', label: 'Inativo', title: 'Certificado expirado inativo ou substituído' },
  { value: 'revogado', label: 'Revogado', title: 'Certificado revogado pelo emissor' },
];

export const TIPOS_CERTIFICADO = [
  { value: 'ecnpj', label: 'e-CNPJ' },
  { value: 'ecpf', label: 'e-CPF' },
];

export const VISIBILIDADE = [
  { value: 'publica', label: 'pública' },
  { value: 'individual', label: 'individual' },
];

export const CONTA_ATIVA = [
  { value: 'true', label: 'Ativa' },
  { value: 'false', label: 'Inativa' },
];
