// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { carregaDetalhes, carregandoSelector, itemSelector } from '../../../reducers/nfce/detalhes';
import { modalOpenSelector } from '../../../reducers/nfce/historico';
import { jsSelector } from 'lib/viewUtils';

import Detalhes from './detalhes';

const mapStateToProps = createStructuredSelector({
  item: jsSelector(itemSelector), carregando: carregandoSelector,
  historicoOpen: modalOpenSelector,
});

export default connect<*, *, *, *, *, *>(mapStateToProps, { carregaDetalhes })(Detalhes);