// @flow
'use strict';

import * as React from 'react';
// eslint-disable-next-line import/no-unresolved
import useSWR from 'swr';
import { useDispatch, useSelector } from 'react-redux';

import * as alerts from '../../../reducers/alerts';
import { jsSelector } from '../../../lib/viewUtils';
import { fetcher } from '../../../lib/api/fetch';

import { ordenaLista, tipoSelector, ordenacaoSelector } from '../../../reducers/cte/filtro_livre';
import { queryExportacaoSelector, trocaPagina, urlSelector, carregandoSelector } from '../../../reducers/cte/lista';
import { carregaDetalhes } from '../../../reducers/cte/detalhes';
import { iconeDesacordo } from './functions';
import { formatoValor, documentoNome, documentoComTooltip } from '../../common/lista_documentos/functions';

import PaginationFooter from '../../common/pagination_footer_notas';
import DataTable, { Column } from '../../common/data_table';
import OrdenacaoPorCampo from './ordenacao_por_campo';
import BotaoExportacaoLote from '../../common/botoes_exportacao_lote/botao_exportacao_lote';
import ExportacaoLista from '../../documento/exportacao_lista';

const ordenacaoJsSelector = jsSelector(ordenacaoSelector, { s: '', sd: '' });

const MSG_NENHUM_DOC               = 'Nenhum documento encontrado',
      MSG_NENHUM_DOC_COM_CRITERIOS = 'Nenhum documento encontrado com os critérios especificados';

export default function Lista() {
  const dispatch                    = useDispatch(),
        tipo                        = useSelector(tipoSelector),
        ordenacao                   = useSelector(ordenacaoJsSelector),
        queryExportacao             = useSelector(queryExportacaoSelector),
        carregando                  = useSelector(carregandoSelector),
        url                         = useSelector(urlSelector);

  const { data, error } = useSWR(url, fetcher);

  const { items = null, page, total, pageSize } = data || {},
        abaTomados                              = tipo === 'tomados';

  React.useEffect(() => {
    if (data && data.alerta && data.alerta.length > 0)
      dispatch(alerts.alert('danger', data.alerta, true));

    if (error)
      dispatch(alerts.ajaxError('Erro ao carregar lista de NF-e', error));
  }, [dispatch, data, total, error]);

  const ordenaPorEmitidoEm   = React.useCallback(() => dispatch(ordenaLista('emitido_em')), [dispatch]),
        ordenaPorSerieNumero = React.useCallback(() => dispatch(ordenaLista('serie,numero')), [dispatch]),
        ordenaPorValorTotal  = React.useCallback(() => dispatch(ordenaLista('valor')), [dispatch]),
        ordenaPorEmitente    = React.useCallback(() => dispatch(ordenaLista('emit_nome,emit_cnpj')), [dispatch]),
        ordenaPorTomador     = React.useCallback(() => dispatch(ordenaLista('toma_nome,toma_cnpj')), [dispatch]),
        trocarPagina         = React.useCallback((n : number) => dispatch(trocaPagina(n)), [dispatch]),
        handleRowClick       = React.useCallback((cte) => cte && dispatch(carregaDetalhes(cte.id)), [dispatch]);

  const possuiFiltro = !!(queryExportacao && queryExportacao.q);

  return (
      <>
        <DataTable className="table-documento cte link-button table-striped table-bordered table-hover notas"
                   noRecordsFoundMessage={ possuiFiltro ? MSG_NENHUM_DOC_COM_CRITERIOS : MSG_NENHUM_DOC }
                   data={ carregando ? null : items } onRowClick={ handleRowClick } striped hover>
          <Column expr="desacordoServico,desacordoCodRetornoSefaz" align="center" width={ 40 }
                  formatter={ iconeDesacordo }/>
          <Column expr="emitidoEm" align="center" width={ 85 }>
            <OrdenacaoPorCampo campo="emitidoEm" rotulo="Emissão" ordenacao={ ordenacao }
                               onClick={ ordenaPorEmitidoEm }/>
          </Column>
          <Column expr="serie,numero" align="center" colSpan={ 2 } widthCells={ [35, 65] }>
            <OrdenacaoPorCampo campo="serieNumero" rotulo="Série / Nº" ordenacao={ ordenacao }
                               onClick={ ordenaPorSerieNumero }/>
          </Column>
          <Column expr="valorTotal" align="center" formatter={ formatoValor } width={ 125 }>
            <OrdenacaoPorCampo campo="valorTotal" rotulo="Total Serviços" ordenacao={ ordenacao }
                               onClick={ ordenaPorValorTotal }/>
          </Column>
          <Column expr="emitCnpj,emitNome" formatter={ abaTomados ? documentoNome : documentoComTooltip }
                  width={ abaTomados ? null : 135 }
                  align={ abaTomados ? 'left' : 'center' }>
            <OrdenacaoPorCampo campo="emitente" rotulo="Emitente" ordenacao={ ordenacao }
                               onClick={ ordenaPorEmitente }/>
          </Column>
          <Column expr="tomaCnpj,tomaNome" formatter={ abaTomados ? documentoComTooltip : documentoNome }
                  width={ abaTomados ? 135 : null }>
            <OrdenacaoPorCampo campo="tomador" rotulo="Tomador" ordenacao={ ordenacao }
                               onClick={ ordenaPorTomador }/>
          </Column>
        </DataTable>

        <PaginationFooter page={ page } total={ total } pageSize={ pageSize } onPageChange={ trocarPagina }
                          hasLastButton={ false }>
          <BotaoExportacaoLote tipo="cte"/>
          <ExportacaoLista tipo="cte" query={ queryExportacao }/>
        </PaginationFooter>
      </>
  );
}
