// @flow
'use strict';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form, Button } from 'react-bootstrap';

import { formatHelper } from 'lib/viewUtils';

import {
  openSelector, totalDocumentosSelector, manifestacaoSelector, alertasSelector, fechaModal, fechaAlertas,
} from 'reducers/nfe/modal_manifestacao_lote';

import EsqueletoModal from '../../../../common/esqueleto_modal';
import Icone from 'components/common/icone';
import { InputField } from 'components/common/input';

type ModalManifestacaoLoteProps = {
  handleSubmit : () => void,
}

export default function ModalManifestacaoLote({ handleSubmit } : ModalManifestacaoLoteProps) {
  const open            = useSelector(openSelector),
        totalDocumentos = useSelector(totalDocumentosSelector),
        manifestacao    = useSelector(manifestacaoSelector),
        alertas         = useSelector(alertasSelector);

  const dispatch    = useDispatch(),
        fecharModal = React.useCallback(() => dispatch(fechaModal()), [dispatch]),
        sendForm    = React.useCallback(() => handleSubmit(), [handleSubmit]);

  return (
      <EsqueletoModal open={ open } onClose={ fecharModal } onAccept={ sendForm } acceptLabel="Sim, confirmo a manifestação"
                      cancelLabel="Não, irei verificar novamente" title="Manifestação">
        <Form onSubmit={ sendForm }>
          { alertas && <Alertas alertas={ alertas }/> }

          <Row>
            <Col xs={ 1 }>
              <Icone nome="exclamation-triangle" estilo="fas" large/>
            </Col>
            <Col xs={ 11 }>
              <p>Foi escolhida a
                opção <strong>{ exibeManifestacao(manifestacao) }</strong> para <strong>{ formatHelper.numberWithDelimiter(totalDocumentos) }</strong> notas
                fiscais.
              </p>
              { manifestacao === 'operacao_nao_realizada' &&
              <>
                <p>Para esta opção, é necessário informar uma justificativa. Ela será adicionada a todas as notas fiscais.</p>
                <InputField name="justificativa"/>
              </>
              }
              <br/>
              <p>Essa operação é irreversível. Tem certeza que deseja prosseguir?</p>
            </Col>
          </Row>
        </Form>
      </EsqueletoModal>
  );
}

type AlertasProps = {
  alertas : string,
}

function Alertas({ alertas } : AlertasProps) {
  const dispatch = useDispatch();

  const fecharAlertas = React.useCallback(() => dispatch(fechaAlertas()), [dispatch]);

  if (alertas.length === 0)
    return null;

  return (
      <div className="alert alert-danger">
        <Button className="close" onClick={ fecharAlertas }>
          <span aria-hidden="true">&times;</span>
        </Button>
        { alertas }
      </div>
  );
}

function exibeManifestacao(manifestacao : string) {
  switch (manifestacao) {
    case 'ciencia':
      return 'Ciência da operação';
    case 'confirmada':
      return 'Confirmada';
    case 'operacao_nao_realizada':
      return 'Operação não realizada';
    case 'desconhecida':
      return 'Desconhecida';
    default:
      return manifestacao;
  }
}
