// @flow
'use strict';

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import request from 'lib/request';
import { errorToImmutable } from 'lib/viewUtils';
import type { Action, Dispatch, GetState, State } from 'lib/types';

import * as consultaPortaria from './consultaPortaria';
import { adicionaItens } from './decisaoMercadoria';

const FECHA_MODAL_DECISAO     = 'decisaoPortaria/FECHA_MODAL_DECISAO',
      DECISAO_SELECIONADA     = 'decisaoPortaria/DECISAO_SELECIONADA',
      SALVANDO_DECISAO        = 'decisaoPortaria/SALVANDO_DECISAO',
      SALVAMENTO_DECISAO_OK   = 'decisaoPortaria/SALVAMENTO_DECISAO_OK',
      SALVAMENTO_DECISAO_ERRO = 'decisaoPortaria/SALVAMENTO_DECISAO_ERRO';

const DEFAULT_STATE = Immutable.fromJS({
  escolhendo: false,
  enviando: false,
  erro: undefined,
  opcaoSelecionada: {
    decisao: undefined,
    titulo: undefined,
  }
});

/* ===================== */
/* REDUCER FUNCTION      */
/* ===================== */

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action = {}) {
  switch (action.type) {
    case DECISAO_SELECIONADA:
      return state
          .set('escolhendo', true)
          .setIn(['opcaoSelecionada', 'decisao'], action.decisao)
          .setIn(['opcaoSelecionada', 'titulo'], action.titulo);

    case SALVANDO_DECISAO:
      return state.setIn(['enviando'], true);

    case SALVAMENTO_DECISAO_OK:
      return state
          .set('enviando', false)
          .set('escolhendo', false);

    case SALVAMENTO_DECISAO_ERRO:
      return state
          .set('erro', action.erro)
          .set('enviando', false);

    case FECHA_MODAL_DECISAO:
      return state.setIn(['escolhendo'], false);

    default:
      return state;
  }
}

/* ========= */
/* SELECTORS */
/* ========= */

export const rootSelector = (state : State) => state.get('decisaoPortaria');

export const decisaoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.getIn(['opcaoSelecionada', 'decisao']));

/* ===================== */
/* BASIC ACTION CREATORS */
/* ===================== */

export function fechaDecisao() {
  return { type: FECHA_MODAL_DECISAO };
}

export function salvandoDecisao() {
  return { type: SALVANDO_DECISAO };
}

export function salvamentoDecisaoOK() {
  return { type: SALVAMENTO_DECISAO_OK };
}

export function salvamentoDecisaoErro(erro : Error) {
  return { type: SALVAMENTO_DECISAO_ERRO, erro: errorToImmutable(erro) };
}

/* ===================== */
/* THUNK ACTION CREATORS */
/* ===================== */

export function salvaDecisao(obs : string, itens : Immutable.List<*, *>) {
  return function(dispatch : Dispatch<*>, getState : GetState) {
    const url     = consultaPortaria.urlSelector(getState()),
          decisao = decisaoSelector(getState());

    dispatch(salvandoDecisao());

    request.post(url)
        .type('json').accept('json')
        .set({ 'X-Http-Method-Override': 'patch' })
        .send({ consulta_portaria: { decisao_portaria: decisao, observacao_portaria: obs, itens_portaria: itens } })
        .then(r => {
          dispatch(consultaPortaria.consultaOK(r.body));
          dispatch(salvamentoDecisaoOK());
        })
        .catch(e => dispatch(salvamentoDecisaoErro(e)));
  };
}

export function decisaoSelecionada(decisao : string, titulo : string) {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    await dispatch({ type: DECISAO_SELECIONADA, decisao, titulo });

    if (decisao === 'receber_com_pendencia') {
      const itens = consultaPortaria.itensSelector(getState());
      await dispatch(adicionaItens(itens));
    }
  };
}