// @flow
'use strict';

import * as React from 'react';

import type { IRodoviario } from '../../types';

import RowDetalhes from '../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../../common/detalhes_documento/table_detalhes';

type RodoviarioProps = {
  rodoviario : IRodoviario,
};

export default function Rodoviario({ rodoviario } : RodoviarioProps) {
  return (
      <>
        <h3>Rodoviário</h3>
        <RowDetalhes data={ rodoviario }>
          <ColumnDetalhes field="rntrc">RNTRC</ColumnDetalhes>
        </RowDetalhes>

        <h4>Ordens de Coleta Associadas</h4>
        <TableDetalhes data={ rodoviario.ordensColeta } noDataClass="padding-10" noDataMsg="Nenhum registro." showFields>
          <ColumnDetalhes field="serie">Série</ColumnDetalhes>
          <ColumnDetalhes field="num">Núm.</ColumnDetalhes>
          <ColumnDetalhes field="emissao" type="data">Emissão</ColumnDetalhes>
          <ColumnDetalhes field="cnpj" type="documento">CNPJ</ColumnDetalhes>
          <ColumnDetalhes field="ie">IE</ColumnDetalhes>
          <ColumnDetalhes field="uf">Sigla UF</ColumnDetalhes>
        </TableDetalhes>
      </>
  );
}
