// @flow
'use strict';

import * as React from 'react';

import type { IPis } from '../../../../types_imposto';

import OpcoesPis from './opcoes_pis';

type PisProps = {
  item : IPis,
};

export default function Pis({ item } : PisProps) {
  const opcoesPis = [];

  Object.keys(item).forEach((i : string) => {
    const Opcao = OpcoesPis[i.replace('pis', 'Pis')];
    if (Opcao)
      opcoesPis.push(<Opcao key={ i } item={ item[i] }/>);
  });

  return (
      <div id="table-pis">
        <h4>PIS - Programa de Integração Social</h4>
        { opcoesPis }
      </div>
  );
}