// @flow
'use strict';

import * as React from 'react';

import type { ItemProps } from './types';

import RowDetalhes from '../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../common/detalhes_documento/column_detalhes';

export default function DadosBasicos({ item } : ItemProps) {
  return (
      <RowDetalhes className="table-condensed table-striped table-main" data={ item }>
        <ColumnDetalhes field="numero" width="15%" renderer={ hifenSeNulo }>Número</ColumnDetalhes>
        <ColumnDetalhes field="codigoVerificacao" width="25%" renderer={ hifenSeNulo }>Código de verificação</ColumnDetalhes>
        <ColumnDetalhes field="chave" width="40%" renderer={ hifenSeNulo }>Chave</ColumnDetalhes>
        <ColumnDetalhes field="municipio" width="20%" renderer={ hifenSeNulo }>Município</ColumnDetalhes>
      </RowDetalhes>
  );
}

function hifenSeNulo(v : string) {
  return v ? <>{ v }</> : <>&nbsp;-</>;
}
