// @flow
'use strict';

import * as Immutable from 'immutable';
import type { Action, Dispatch, State } from '../lib/types';

// //////////////////
// CONSTANTES

const ABRE_MODAL  = 'modal/ABRE_MODAL',
      FECHA_MODAL = 'modal/FECHA_MODAL';

const DEFAULT_STATE = Immutable.fromJS({
  open: false
});

// ////////////
// REDUCER

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action = {}) {
  switch (action.type) {
    case ABRE_MODAL:
      return state.set('open', true);
    case FECHA_MODAL:
      return state.set('open', false);
    default:
      return state;
  }
}

// ////////////////////////
// ATUALIZAÇÕES DA STORE

function abrirModal() {
  return { type: ABRE_MODAL };
}

function fecharModal() {
  return { type: FECHA_MODAL };
}

// //////////////////
// SELECTORS
export const openModalSelector = (state : State) => state.getIn(['modal', 'open'], false);

// //////////////////
// AÇÕES

export function abreModal() {
  return function(dispatch : Dispatch<*>) {
    dispatch(abrirModal());
  };
}

export function fechaModal() {
  return function(dispatch : Dispatch<*>) {
    dispatch(fecharModal());
  };
}