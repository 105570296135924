'use strict';
// @flow

import React from 'react';
import { autobind } from 'core-decorators';
import { Button } from 'react-bootstrap';

import ModalDownload from '../../modal_download';
import Icone from '../../../common/icone';

type ExportacaoProps = {
  limiteDownload : number,
  open : bool,
  total? : ?number,
  query : any,
  downloadArquivoXls : (params : any) => void,
  abreModal : () => void,
  fechaModal : () => void
}

export default class Funcoes extends React.PureComponent<ExportacaoProps, *> {
  props : ExportacaoProps;

  @autobind
  handleDownloadXls() {
    this.props.downloadArquivoXls(this.props.query);
    this.props.fechaModal();
  }

  render() {
    const numDocumentos = this.props.total;

    return (
        <div>
          <div className="navbar-form navbar-left" style={ { marginLeft: -14, marginTop: 0 } }>
            <Button bsStyle="primary" onClick={ this.props.abreModal }>
              <Icone nome="file-alt" estilo="fal">Exportar Dados (xls)</Icone>
            </Button> { ' ' }
          </div>

          { this.props.open && <ModalDownload limiteDownload={ this.props.limiteDownload } totalDocumentos={ numDocumentos }
                                              onConfirma={ this.handleDownloadXls }/> }
        </div>
    );
  }
}