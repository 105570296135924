// @flow

import * as React from 'react';

import { useEffectOnce } from 'lib/viewUtils';

export default function BackToNonSpa() {
  useEffectOnce(() => window.location.reload());

  return <div>Aguarde...</div>;
}
