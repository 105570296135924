// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, ControlLabel, FormControl, FormGroup, Navbar } from 'react-bootstrap';

import * as filtro from '../../../../reducers/sped/escriturados/filtro';

type BuscaAvancadaProps = {
  busca : string,

  mudaBuscaAvancada : (string) => void,
  alternaParaBuscaBasica : () => void,
  limpaBuscaAvancada : () => void,
};

export function BuscaAvancada({ busca, mudaBuscaAvancada, alternaParaBuscaBasica, limpaBuscaAvancada } : BuscaAvancadaProps) {
  const handleChange = React.useCallback(e => mudaBuscaAvancada(e.target.value), [mudaBuscaAvancada]);

  return (
      <Navbar fluid className="sped-busca-avancada">
        <Navbar.Header><Navbar.Brand>Busca Avançada</Navbar.Brand></Navbar.Header>

        <Navbar.Form>
          <FormGroup controlId="q">
            <ControlLabel srOnly>Busca Avançada</ControlLabel>
            <FormControl name="q" placeholder="Busca Avançada" type="search"
                         value={ busca } onChange={ handleChange }/>
          </FormGroup>
        </Navbar.Form>

        <Navbar.Form className="botoes">
          <div className="pull-left">
            <Button type="button" onClick={ alternaParaBuscaBasica }>Busca Estruturada</Button>
          </div>
          <div className="pull-right">
            <Button type="reset" onClick={ limpaBuscaAvancada }>Limpar</Button> { ' ' }
            <Button type="submit" bsStyle="primary">Filtrar</Button>
          </div>
          <div className="clearfix"/>
        </Navbar.Form>
      </Navbar>
  );
}

export default connect<*, *, *, *, *, *>(createStructuredSelector({ busca: filtro.buscaAvancadaSelector }), filtro)(BuscaAvancada);