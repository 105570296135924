'use strict';
// @flow

import * as React from 'react';

import ComTooltip from 'components/common/com_tooltip';

import type { TableHeaderProps } from './types';

/**
 * Componente padrão para montar o cabeçalho da tabela.
 *
 * Basicamente percorre as colunas especificadas, criando um cabeçalho para cada coluna.
 */
export default function TableHeader<T>({ columns } : TableHeaderProps<T>) {
  const headerCells = columns.map((c : React.Element<any>, i) => (
      <th key={ i } className={ c.props.className } colSpan={ c.props.colSpan }>
        <ComTooltip hint={ c.props.headerHint } posicao="top">
          <span>{ c.props.children }</span>
        </ComTooltip>
      </th>
  ));

  return (
      <thead>
      <tr>{ headerCells }</tr>
      </thead>
  );
}
