// @flow
'use strict';

import * as React from 'react';
import classnames from 'classnames';
import { Button } from 'react-bootstrap';

import { formatoTela } from './formato_tela';

import Icone from '../icone';

export type TipoFormatoTela =
    'botaoDetalhes'
    | 'data'
    | 'dataHora'
    | 'situacao'
    | 'modelo'
    | 'moeda'
    | 'percentual'
    | 'documento'
    | 'tributacao'
    | 'cep'
    | 'html';

type ColumnDetalhesProps<T> = {
  field : string,
  type? : TipoFormatoTela,
  align? : 'center' | 'left' | 'right',
  data? : T,
  width? : any,
  buttonClick? : (d : any) => void,
  className? : string,
  titleCelula? : bool,
  textoPreformatado? : bool,
  renderer? : (v : any, data : T) => React.Node,
  colSpan? : number,
  textoBotaoDetalhes? : string,
}

export default function ColumnDetalhes<T>({ field, type, align, data, buttonClick, className = '', titleCelula = false, textoPreformatado = false, renderer, colSpan = 1, textoBotaoDetalhes } : ColumnDetalhesProps<T>) {
  const handleClickData = data && field === 'cartao' && typeof data === 'object' ? data[field] : data;

  const handleClick = React.useCallback(() => buttonClick && buttonClick(handleClickData), [buttonClick, handleClickData]);

  if (type === 'botaoDetalhes' && buttonClick && handleClickData) {
    return (
        <td className="acoes">
          <Button bsSize="xs" bsStyle="default" onClick={ handleClick }>
            <Icone nome="search-plus" estilo="fas">{ textoBotaoDetalhes || 'detalhes' }</Icone>
          </Button>
        </td>
    );
  }

  let valor;

  if (type === 'html')
    valor = data && typeof data === 'object' ? <div dangerouslySetInnerHTML={{ __html: data[field] }}/> : '';
  else
    valor = data && typeof data === 'object' ? formatoTela(type, data[field]) : '';

  const configTitle = titleCelula ? { title: valor } : {};

  if (data && renderer)
    valor = renderer(valor, data);

  const apresentacaoValor = textoPreformatado && valor ? <pre>{ valor }</pre> : valor;

  return (
      <td className={ classnames(className, align && `text-${ align }`) } { ...configTitle } colSpan={ colSpan }
          style={ textoPreformatado ? { borderTop: 'none', padding: 0 } : {} }>{ apresentacaoValor }</td>
  );
}
