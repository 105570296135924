// @flow
'use strict';

import React from 'react';

import { useEffectOnce } from 'lib/viewUtils';

import type { IItem } from './types';

import Formulario from '../formulario';

type DetalhesProps = {
  routeParams : { id : string };
  carregaDetalhes : (id : string) => void;
  item : IItem,
  carregando : bool,
}

export default function Detalhes({ carregaDetalhes, carregando, routeParams } : DetalhesProps) {
  useEffectOnce(() => {
    // Atualiza dados na tela de detalhes
    if (!carregando && routeParams)
      carregaDetalhes(routeParams.id);
  });

  return (
      <Formulario id={ routeParams.id }/>
  );
}
