// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfse from './selecao_todos_nfse';
import { Input } from '../../../../../../common/input';

type CamposPessoaProps = {
  nome : string,
  titulo : string,
}

export default function CamposPessoa({ nome, titulo } : CamposPessoaProps) {
  return (
      <FormSection name={ nome }>
        <fieldset>
          <SelecaoTodosNfse nomes={ [ nome ] } titulo={ titulo }/>

          <div className="grupo-checkbox">
            <Input name={ `${nome}CpfCnpj` } label="CNPJ" checkbox/>
            <Input name={ `${nome}RazaoSocial` } label="Razão Social" checkbox/>
            <Input name={ `${nome}Im` } label="Inscrição Municipal (IM)" checkbox/>
          </div>
        </fieldset>
      </FormSection>
  );
}