// @flow
'use strict';

import * as React from 'react';
import { Col, ControlLabel, Row } from 'react-bootstrap';

import CampoTexto from './campo_texto';
import { Input } from './input';

type CampoIntervaloProps = {
  rotulo : string,
  nome : string,
  placeholderInicio? : string,
  placeholderFim? : string,
  columnClassName? : string,
}

export default function CampoIntervalo({ rotulo, nome, placeholderInicio = 'de', placeholderFim = 'até', columnClassName = '' } : CampoIntervaloProps) {
  return (
      <Row className="campo-formulario campo-input">
        <ControlLabel className="col-sm-3">{ rotulo }</ControlLabel>
        <Col sm={ 9 } className={ columnClassName }>
          <Col xs={ 6 } className="campo-intervalo-de">
            <Input name={ nome } label={ `${ rotulo } de` } placeholder={ placeholderInicio }
                   component={ CampoTexto }/>
          </Col>
          <Col xs={ 6 } className="campo-intervalo-ate">
            <Input name={ `${ nome }_ate` } label={ `${ rotulo } até` } placeholder={ placeholderFim }
                   component={ CampoTexto }/>
          </Col>
        </Col>
      </Row>
  );
}
