// @flow
'use strict';

import * as React from 'react';

import NoticiaTexto from './noticia_texto';
import NoticiaLink from './noticia_link';
import ModalNoticia from './modal_noticia';

export type Noticia = { data : string, tipo : string, titulo : string, link : string, texto : string };

type TabelaDocumentosProps = {
  ultimasNoticias : Noticia[],
  open : bool,
}

export default function TabelaNoticias({ ultimasNoticias, open } : TabelaDocumentosProps) {
  if (!ultimasNoticias || ultimasNoticias.length === 0)
    return <span/>;

  return (
      <div>
        <ul className="lista-noticia">
          { ultimasNoticias.map((noticia, index) => {
            if (noticia.tipo === 'texto')
              return <NoticiaTexto key={ index } noticia={ noticia } numeroNoticia={ index }/>;

            return <NoticiaLink key={ index } link={ noticia.link } titulo={ noticia.titulo } numeroNoticia={ index }/>;
          }) }
        </ul>

        { open && <ModalNoticia/> }
      </div>
  );
}