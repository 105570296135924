'use strict';
// @flow

import * as React from 'react';

import type { PaginationProps } from './types';

import { calcPaginationProps, DEFAULT_PROPS } from '../pagination_footer/util';
import Pagination from './pagination';

type PaginationFooterProps = PaginationProps;

/**
 * Renderiza o componente de paginação.
 */
export default function PaginationFooter({
                                           hasLastButton, onPageChange, children, boundaryPagesRange = 0, ...props
                                         } : PaginationFooterProps) {
  const handlePageSelect = React.useCallback((eventKey : number) => {
    if (onPageChange)
      onPageChange(eventKey);
  }, [onPageChange]);

  const p = calcPaginationProps(props);
  if (!p.total || p.total <= p.pageSize) {
    return (
        <section className="table-footer">
          { children }
        </section>
    );
  }

  return (
      <section className="table-footer">
        { children }
        <Pagination currentPage={ p.page } totalPages={ p.pageCount } onChange={ handlePageSelect }
                    hasLastButton={ hasLastButton } boundaryPagesRange={ boundaryPagesRange }
                    siblingPagesRange={ calcSiblingPagesRange(p.page) }/>
      </section>
  );
}

PaginationFooter.defaultProps = DEFAULT_PROPS;

function calcSiblingPagesRange(currentPage : number) {
  switch (currentPage) {
    case 1:
      return 0.5;
    case 2:
      return 1;
    case 3:
      return 1.5;
    default:
      return 2;
  }
}
