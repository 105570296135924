// @flow
'use strict';

import * as React from 'react';

import type { IInformacoesAdicionais } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../common/detalhes_documento/table_detalhes';

type InformacoesAdicionaisProps = {
  informacoesAdicionais : IInformacoesAdicionais,
};

export default function InformacoesAdicionais({ informacoesAdicionais } : InformacoesAdicionaisProps) {
  return (
      <div className="tab-pane active">
        <h3>Informações Adicionais</h3>
        { informacoesAdicionais && informacoesAdicionais.observacoesGerais &&
        <>
          <h4>Observações Gerais</h4>
          <RowDetalhes data={ informacoesAdicionais } className="no-margin-bottom" headerRow={ false }>
            <ColumnDetalhes field="observacoesGerais" textoPreformatado />
          </RowDetalhes>
        </>
        }

        { informacoesAdicionais && informacoesAdicionais.infInteresseFisco &&
        <>
          <h4>Informações Adicionais de Interesse do Fisco</h4>
          <RowDetalhes data={ informacoesAdicionais } className="no-margin-bottom" headerRow={ false }>
            <ColumnDetalhes field="infInteresseFisco" textoPreformatado/>
          </RowDetalhes>
        </>
        }

        <h4>Observações do Contribuinte</h4>
        { informacoesAdicionais &&
        <TableDetalhes data={ informacoesAdicionais.observacoesContribuinte } noDataClass="padding-10" noDataMsg="Nenhum registro." showFields>
          <ColumnDetalhes field="identificacao">Identificação</ColumnDetalhes>
          <ColumnDetalhes field="conteudo">Conteúdo</ColumnDetalhes>
        </TableDetalhes>
        }

        <h4>Observações do Fisco</h4>
        { informacoesAdicionais &&
        <TableDetalhes data={ informacoesAdicionais.observacoesFisco } noDataClass="padding-10" noDataMsg="Nenhum registro." showFields>
          <ColumnDetalhes field="identificacao">Identificação</ColumnDetalhes>
          <ColumnDetalhes field="conteudo">Conteúdo</ColumnDetalhes>
        </TableDetalhes>
        }
      </div>
  );
}
