'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, Dispatch, GetState, State } from 'lib/types';
import request from '../../../lib/request';
import { ajaxError } from '../../alerts';
import { formataQuery } from '../../../lib/formata_query';
import { arquivoPath } from '../../../components/sped/arquivos/arquivo_path';

export const LISTA_CARREGANDO  = 'sped/arquivos/lista/LISTA_CARREGANDO',
             LISTA_CARREGADA   = 'sped/arquivos/lista/LISTA_CARREGADA',
             ALTERA_LAST_QUERY = 'sped/arquivos/lista/ALTERA_LAST_QUERY';

const INITIAL_STATE = Immutable.fromJS({
  paginaAtual: { page: 1, items: null },
  carregando: false,
  lastQuery: null,
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case LISTA_CARREGANDO:
      return state.set('carregando', true).set('paginaAtual', action.body);
    case LISTA_CARREGADA:
      return state.set('carregando', false).set('paginaAtual', action.body);
    case ALTERA_LAST_QUERY:
      return state.set('lastQuery', action.body);

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['sped', 'arquivos', 'lista'], Immutable.Map());
export const currentPage = createSelector<*, *, *, *>(rootSelector, (root) => root.get('paginaAtual'));
export const carregandoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('carregando'));
export const lastQuerySelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('lastQuery', Immutable.Map()));

// actions

export function listaCarregando() {
  return { type: LISTA_CARREGANDO, body: Immutable.fromJS({ items: 'loading' }) };
}

export function listaCarregada(lista : any) {
  return { type: LISTA_CARREGADA, body: Immutable.fromJS(lista) };
}

// thunk actions

export function carregaLista(params : any = {}) {
  return async function(dispatch : Dispatch<any>, getState : GetState) {
    if (!carregandoSelector(getState())) {
      await dispatch(listaCarregando());

      const query = formataQuery(params);
      dispatch({ type: ALTERA_LAST_QUERY, body: Immutable.fromJS(query) });

      await request.get(arquivoPath.colecao(query))
          .accept('json')
          .query(query)
          .then(r => dispatch(listaCarregada(r.body)))
          .catch(e => {
            dispatch(listaCarregada({ items: 'erro' }));
            dispatch(ajaxError('Erro ao carregar lista de arquivos', e));
          });
    }
  };
}