// @flow
'use strict';

import * as React from 'react';

import { withFormValues } from '../../../../../lib/forms';
import { obterListaPorTipo } from './functions';

import { InputForm } from '../../../../common/input';

const FORMATOS_LISTA_DOWNLOAD = [
  { value: 'txt', label: ' Texto (txt)' },
  { value: 'excel', label: 'Excel (xlsx)' },
];

export const DELIMITADORES = [
  { value: ';', label: '; (ponto e vírgula)' },
  { value: ',', label: ', (vírgula)' },
  { value: '|', label: '| (barra vertical)' },
];

type FormularioArquivoProps = {
  tipo : string,
  formato? : string,
}

export function FormularioArquivo({ tipo, formato } : FormularioArquivoProps) {
  return (
      <section className="download-lista">
        <fieldset>
          <InputForm sm={ 9 } name="formato" label="Formato" optionsRadio={ FORMATOS_LISTA_DOWNLOAD }/>
          {
            formato === 'txt' &&
            <InputForm sm={ 9 } name="delimiter" label="Delimitador" options={ DELIMITADORES }/>
          }
          <InputForm sm={ 9 } name="linhaPor" label="Tipo de Relatório" options={ obterListaPorTipo(tipo) }/>
        </fieldset>
      </section>
  );
}

export default withFormValues(true, 'formato')(FormularioArquivo);