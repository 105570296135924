// @flow
'use strict';

import * as React from 'react';

import type { IIcms70 } from '../../../../../../types_imposto';

import RowDetalhes from '../../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../../common/detalhes_documento/column_detalhes';

type Icms70Props = {
  item : IIcms70,
};

export default function Icms70({ item } : Icms70Props) {
  return (
      <>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="origem" width="25%">Origem da Mercadoria</ColumnDetalhes>
          <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
          <ColumnDetalhes field="determinacaoBcIcms" width="25%">Determinação BC ICMS</ColumnDetalhes>
          <ColumnDetalhes field="percentualReducaoBc" type="percentual" width="25%">Percentual Redução BC</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="bcIcms" type="moeda" width="25%">Base de Cálculo ICMS</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaIcms" type="percentual" width="25%">Alíquota ICMS</ColumnDetalhes>
          <ColumnDetalhes field="valorIcms" type="moeda" width="25%">Valor ICMS</ColumnDetalhes>
          <ColumnDetalhes field="bcFcp" type="moeda" width="25%">BC FCP</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="percentualFcp" type="percentual" width="25%">Percentual FCP</ColumnDetalhes>
          <ColumnDetalhes field="valorFcp" type="moeda" width="25%">Valor FCP</ColumnDetalhes>
          <ColumnDetalhes field="determinacaoBcIcmsSt" width="25%">Determinação BC ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="margemValorAdicionadoIcmsSt" type="percentual" width="25%">Margem Valor Adicionado ICMS ST</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="reducaoBcIcmsSt" type="percentual" width="25%">Redução Base de Cálculo ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="bcIcmsSt" type="moeda" width="25%">Base de Cálculo ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaIcmsSt" type="percentual" width="25%">Alíquota ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="valorIcmsSt" type="moeda" width="25%">Valor ICMS ST</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="valorBcFcpRetido" type="moeda" width="25%">Valor BC FCP Retido por ST</ColumnDetalhes>
          <ColumnDetalhes field="percentualFcpRetido" type="percentual" width="25%">Percentual FCP Retido por ST</ColumnDetalhes>
          <ColumnDetalhes field="fcpRetido" type="moeda" width="25%">FCP retido por ST</ColumnDetalhes>
          <ColumnDetalhes field="icmsDesonerado" type="moeda" width="25%">ICMS Desonerado</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="motivoDesoneracao" width="25%">Motivo Desoneração</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
