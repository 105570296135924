// @flow
'use strict';

import * as React from 'react';

import type { ICofinsAliq, ICofinsOutr } from '../../../../../types_imposto';

import RowDetalhes from '../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../common/detalhes_documento/column_detalhes';

type TributoAliquotaProps = {
  item : ICofinsAliq | ICofinsOutr,
};

export default function TributoAliquota({ item } : TributoAliquotaProps) {
  return (
      <RowDetalhes data={ item }>
        <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
        <ColumnDetalhes field="valorBc" type="moeda" width="25%">Valor BC COFINS</ColumnDetalhes>
        <ColumnDetalhes field="percAliquota" type="percentual" width="25%">Alíquota COFINS (em percentual)</ColumnDetalhes>
        <ColumnDetalhes field="valor" type="moeda" width="25%">Valor COFINS</ColumnDetalhes>
      </RowDetalhes>
  );
}
