'use strict';
// @flow

import React from 'react';

import { formatHelper } from 'lib/viewUtils';

type MoneyProps = {
  valor : number | { centavos : number, moeda : string }
};

export default function Money({ valor } : MoneyProps) {
  if (typeof valor === 'number' || typeof valor === 'string' && Number.isFinite(valor))
    valor = { centavos: valor * 100, moeda: 'R$' };

  if (!valor)
    return <span>–</span>;

  const { centavos, moeda } = valor;

  return (
      <div className="text-right">
        <div className="pull-left">{moeda}</div>
        { formatHelper.numberWithDelimiter(centavos / 100, 2) }
      </div>
  );
}