// @flow
'use strict';

import * as React from 'react';

import type { IDocFiscaisReferenciados, INotaFiscalEletronica, IConhecimentoTranspEletronico } from '../types';

import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../common/detalhes_documento/table_detalhes';

type DocumentosFiscaisReferenciadosProps = {
  docFiscaisReferenciados : IDocFiscaisReferenciados,
};

export default function DocumentosFiscaisReferenciados({ docFiscaisReferenciados } : DocumentosFiscaisReferenciadosProps) {
  if (!docFiscaisReferenciados) {
    return <>
      <h3>Documentos Fiscais Referenciados</h3>
      <TableDetalhes data={ docFiscaisReferenciados } noDataClass="padding-10" noDataMsg="Nenhum documento referenciado."/>
    </>
  }

  return (
    <>
      <h3>Documentos Fiscais Referenciados</h3>
      { docFiscaisReferenciados.notasFiscaisEletronicas && docFiscaisReferenciados.notasFiscaisEletronicas.length > 0 &&
        <>
          <h4>Nota Fiscal Eletrônica (NF-e)</h4>
          <TableDetalhes data={ docFiscaisReferenciados.notasFiscaisEletronicas }>
            <ColumnDetalhes field="chave" renderer={ linkParaChave }>Chave</ColumnDetalhes>
          </TableDetalhes>
        </>
      }

      { docFiscaisReferenciados.notasFiscais && docFiscaisReferenciados.notasFiscais.length > 0 &&
        <>
          <h4>Nota Fiscal (Modelo 1/1A ou NF modelo 2)</h4>
          <TableDetalhes data={ docFiscaisReferenciados.notasFiscais }>
            <ColumnDetalhes field="emissao" width="25%">Emissão</ColumnDetalhes>
            <ColumnDetalhes field="cnpjEmitente" type="documento" width="25%">CNPJ Emitente</ColumnDetalhes>
            <ColumnDetalhes field="serie" width="25%">Série</ColumnDetalhes>
            <ColumnDetalhes field="numero" width="25%">Número</ColumnDetalhes>
          </TableDetalhes>
        </>
      }

      { docFiscaisReferenciados.notasFiscaisProdutorRural && docFiscaisReferenciados.notasFiscaisProdutorRural.length > 0 &&
        <>
          <h4>Nota Fiscal de Produtor Rural</h4>
          <TableDetalhes data={ docFiscaisReferenciados.notasFiscaisProdutorRural }>
            <ColumnDetalhes field="emissao" width="25%">Emissão</ColumnDetalhes>
            <ColumnDetalhes field="cnpjCpfEmitente" type="documento" width="25%">CNPJ / CPF Emitente</ColumnDetalhes>
            <ColumnDetalhes field="serie" width="25%">Série</ColumnDetalhes>
            <ColumnDetalhes field="numero" width="25%">Número</ColumnDetalhes>
          </TableDetalhes>
        </>
      }

      { docFiscaisReferenciados.conhecimentosTranspEletronico && docFiscaisReferenciados.conhecimentosTranspEletronico.length > 0 &&
        <>
          <h4>Conhecimento de Transporte Eletrônico (CT-e)</h4>
          <TableDetalhes data={ docFiscaisReferenciados.conhecimentosTranspEletronico }>
            <ColumnDetalhes field="chave" renderer={ linkParaChave }>Chave</ColumnDetalhes>
          </TableDetalhes>
        </>
      }

      { docFiscaisReferenciados.cuponsFiscais && docFiscaisReferenciados.cuponsFiscais.length > 0 &&
        <>
          <h4>Cupom Fiscal</h4>
          <TableDetalhes data={ docFiscaisReferenciados.cuponsFiscais }>
            <ColumnDetalhes field="modelo" width="50%">Modelo</ColumnDetalhes>
            <ColumnDetalhes field="numeroEcf" width="25%">Número ECF</ColumnDetalhes>
            <ColumnDetalhes field="numeroCoo" width="25%">Número COO</ColumnDetalhes>
          </TableDetalhes>
        </>
      }
    </>
  );
}

function linkParaChave(chave : string, { url } : INotaFiscalEletronica | IConhecimentoTranspEletronico) {
  return (
    !url
      ? <>{ chave }</>
      : <a href={ url } target="_blank" rel="noreferrer">{ chave }</a>
  );
}
