// @flow
'use strict';

import * as React from 'react';
import { IndexRedirect, Redirect, Route } from 'react-router';

import BaseApp from '../common/base_app';
import BackToNonSpa from '../common/back_to_non_spa';

import noticiasRoutes from './noticia/routes';
import prestadoresRoutes from './prestador/routes';

export default (
    <Route name="Ferramentas" path="app/ferramentas" component={ BaseApp }>
      <IndexRedirect to="recuperacoes"/>

      <Route name="Recuperações" path="recuperacoes" component={ BackToNonSpa }/>
      <Route name="Uploads" path="cargas_upload" component={ BackToNonSpa }/>
      <Route name="Emails" path="emails" component={ BackToNonSpa }/>

      { noticiasRoutes }

      <Redirect from="fornecedores" to="app/ferramentas/prestadores"/>
      <Redirect from="fornecedores/new" to="app/ferramentas/prestadores/new"/>
      <Redirect from="fornecedores/:id" to="app/ferramentas/prestadores/:id"/>

      { prestadoresRoutes }
    </Route>
);
