// @flow
'use strict';

import * as React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { fechaRelatorio } from '../../../../../reducers/modelo_relatorio/modal_escolha_tipo_relatorio';
import { alertasSelector, fechaModal, fechaAlertas } from '../../../../../reducers/modal_exportacao_lista';
import { useEffectOnce } from '../../../../../lib/viewUtils';

import FormularioArquivo from './formulario_arquivo';
import FormularioSalvarRelatorio from './formulario_salvar_relatorio';
import Alertas from '../common/alertas';

type FormProps = {
  handleSubmit : () => void,
  tipo : string,
  children? : React.Node,
}

export default function FormExportacao({ handleSubmit, tipo, children } : FormProps) {
  const alertas = useSelector(alertasSelector);

  const dispatch      = useDispatch(),
        sendForm      = React.useCallback(() => handleSubmit(), [handleSubmit]),
        fecharAlertas = React.useCallback(() => dispatch(fechaAlertas()), [dispatch]),
        fechaModais   = React.useCallback(() => {
          dispatch(fechaRelatorio());
          dispatch(fechaModal());
          dispatch(fechaAlertas());
        }, [dispatch]);

  useEffectOnce(() => {
    fecharAlertas();
  });

  return (
      <Form onSubmit={ handleSubmit }>
        <Modal.Header closeButton>
          <Modal.Title>Exportação de Lista</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { alertas && <Alertas alertas={ alertas } cor="danger" fechaAlertas={ fecharAlertas }/> }

          <p>Escolha como será o arquivo exportado.</p>

          <FormularioArquivo tipo={ tipo }/>

          <p className="instrucao-depois-campo-download-lista">
            Selecione, abaixo, os campos que deseja no seu arquivo.
          </p>

          { children }

          <FormularioSalvarRelatorio/>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={ fechaModais }>Cancelar</Button>
          <Button type="button" bsStyle="primary" onClick={ sendForm }>Gerar Relatório</Button>
        </Modal.Footer>
      </Form>
  );
}
