'use strict';

// @flow

import * as React from 'react';
import { Panel } from 'react-bootstrap';

import type { IDocumento } from 'lib/api';

import Timeago from 'components/common/timeago';
import LabelSituacao from './label_situacao';
import ManifestaCte from '../documento/manifestacao_cte/manifesta_cte';
import ComTooltip from '../common/com_tooltip';

type ManifestacaoCteProps = {
  documento : ?IDocumento
};

/**
 * Painel de informações de manifestação da CT-e.
 */
export default function ManifestacaoCte({ documento } : ManifestacaoCteProps) {
  if (!documento || !documento.manifestacaoCte)
    return <span/>;

  const manif = documento.manifestacaoCte;

  let dataDesacordo = '';
  if (manif.desacordoRealizadoEm) {
    dataDesacordo = <span>
        Desacordo realizado <Timeago time={ manif.desacordoRealizadoEm }/>
      </span>;
  }

  return (
      <Panel className="situacoes">
        <Panel.Heading>Tomador</Panel.Heading>
        <Panel.Body>
          <dl>
            <dt><ComTooltip hint="Informa se existe ou não desacordo do serviço para o CTe" posicao="top"><span>
            Desacordo do Servico
          </span></ComTooltip></dt>
            <dd><LabelSituacao { ...manif.desacordoServico } /></dd>
            <dt><ComTooltip hint="Justificativa do tomador em relação ao desacordo do serviço" posicao="top"><span>
            Observações do Tomador
          </span></ComTooltip></dt>
            <dd><LabelSituacao { ...manif.observacoesTomador } /></dd>
            <dt><ComTooltip
                hint="Resposta da SEFAZ em relação à informação de desacordo pelo tomador. No caso de 'autorização', retorna o número do Protocolo de Autorização."
                posicao="top"><span>
            Retorno da SEFAZ
          </span></ComTooltip></dt>
            <dd><LabelSituacao { ...manif.retornoSefaz } /></dd>
          </dl>
          <div className="ultima-consulta r">
            <p> { dataDesacordo } </p>
          </div>
          <ManifestaCte url={ manif.url }/>
        </Panel.Body>
      </Panel>
  );
}