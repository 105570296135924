// @flow
'use strict';

import * as React from 'react';

import type { ResumoFiltro } from './types';

type FiltroResumoProps = {
  onClick : () => void,
  filtroAtual : string,
  filtro? : ResumoFiltro | null,
}

export default function FiltroResumo({ onClick, filtroAtual, filtro } : FiltroResumoProps) {
  if (!filtro)
    return null;

  const ativaLinha = (!filtroAtual && filtro.codigo === 'total') || filtroAtual === filtro.codigo;

  return (
      <span onClick={ onClick } className="cursor-pointer">
        <div className={ `row ${ ativaLinha ? 'active' : '' }` }>
          <div className="col-xs-7">{ filtro.nome }</div>
          <div className="col-xs-5 filter-value">{ filtro.qtd }</div>
        </div>
      </span>
  );
}
