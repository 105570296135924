// @flow
'use strict';

import * as React from 'react';

import type { IIcmsSimplesNacional } from '../../../../../types_imposto';

import Icms101 from './opcoes_icms_simples_nacional/icms101';
import Icms102 from './opcoes_icms_simples_nacional/icms102';
import Icms201 from './opcoes_icms_simples_nacional/icms201';
import Icms202 from './opcoes_icms_simples_nacional/icms202';
import Icms500 from './opcoes_icms_simples_nacional/icms500';
import Icms900 from './opcoes_icms_simples_nacional/icms900';

type IcmsSimplesNacionalProps = {
  item : IIcmsSimplesNacional,
};

export default function IcmsSimplesNacional({ item } : IcmsSimplesNacionalProps) {
  return (
      <div id="table-icms-simples-nacional">
        <h4>ICMS tributado pelo Simples Nacional</h4>
        { item.icms101 && item.icms101.csosn.substr(0, 3) === '101' && <Icms101 item={ item.icms101 }/> }
        { item.icms102 && ['102', '103', '300', '400'].includes(item.icms102.csosn.substr(0, 3)) && <Icms102 item={ item.icms102 }/> }
        { item.icms201 && item.icms201.csosn.substr(0, 3) === '201' && <Icms201 item={ item.icms201 }/> }
        { item.icms202 && ['202', '203'].includes(item.icms202.csosn.substr(0, 3)) && <Icms202 item={ item.icms202 }/> }
        { item.icms500 && item.icms500.csosn.substr(0, 3) === '500' && <Icms500 item={ item.icms500 }/> }
        { item.icms900 && item.icms900.csosn.substr(0, 3) === '900' && <Icms900 item={ item.icms900 }/> }
      </div>
  );
}
