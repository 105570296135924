'use strict';
// @flow

import * as React from 'react';

import type { IPaginatedData } from 'lib/types';
import type { ISpedDocumento } from '../../types';
import { arquivoNav } from '../../arquivo_path';

import AlertWithIcon from '../../../../common/alert_with_icon';
import PaginationFooter from 'components/common/pagination_footer';
import { TableComponent } from './table_component';

type ListaDocumentosProps = {
  location : { query : {} },
  arquivo? : {
    id? : string,
    documentos? : IPaginatedData<ISpedDocumento>,
    carregado_em? : string,
  },
  carregaDetalhes : (id : string, params : any) => void,
};

export default function ListaDocumentos({ arquivo = {}, carregaDetalhes, location } : ListaDocumentosProps) {
  const handlePageChange = React.useCallback((page : number) => {
    const id = arquivo && arquivo.id ? arquivo.id : null;

    if (id) {
      const query = { ...location.query, page };

      arquivoNav.detalhes(id, query);
      carregaDetalhes(id, query);
    }
  }, [location, carregaDetalhes, arquivo]);

  const { documentos }                   = arquivo,
        { items, page, total, pageSize } = documentos || {};

  return (
      <fieldset>
        <legend>Documentos</legend>

        { (arquivo && !arquivo.carregado_em) && (
            <AlertWithIcon bsStyle="info" icon="info-circle">
              <p>
                Arquivo ainda não está carregado. Aguarde alguns instantes e
                { ' ' }<a href="javascript:window.location.reload(true)">clique aqui</a>{ ' ' }
                para verificar novamente.
              </p>
            </AlertWithIcon>
        )
        }

        <PaginationFooter label="Documentos" page={ page } total={ total } pageSize={ pageSize }
                          onPageChange={ handlePageChange }/>
        <TableComponent dados={ items }/>
        <PaginationFooter label="Documentos" page={ page } total={ total } pageSize={ pageSize }
                          onPageChange={ handlePageChange }/>
      </fieldset>
  );
}
