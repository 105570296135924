// @flow
'use strict';

import * as React from 'react';

import type { IIcmsSn } from '../../../types_imposto';

import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';

type IcmsSnProps = {
  icms : IIcmsSn,
};

export default function IcmsSn({ icms } : IcmsSnProps) {
  return (
      <>
        <h4>ICMS - 90 = ICMS Simples Nacional</h4>
        <RowDetalhes data={ icms }>
          <ColumnDetalhes field="cst" width="50%">Classificação Tributária do Serviço</ColumnDetalhes>
          <ColumnDetalhes field="indSn" width="50%">Contribuinte Simples Nacional</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
