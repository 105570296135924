// @flow
'use strict';

import { type Saga } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { actionTypes } from 'redux-form';
import { change, formValueSelector } from 'redux-form/immutable';

export default function* noticia() : Saga<*> {
  yield takeLatest(
      ac => ac.type === '@@router/LOCATION_CHANGE' && ac.payload.pathname.startsWith('/app/ferramentas/noticias'),
      sagaReiniciarCampo);
}

export function* sagaReiniciarCampo() : Saga<*> {
  // atribui falso ao campo quando ele é desabilitado do formulário.
  yield takeEvery(
      ac => ac.type === actionTypes.UNREGISTER_FIELD && ac.meta.form && ac.payload.name,
      function* (ac : { meta : { form : string }, payload : any }) {
        yield call(reiniciaCampo, ac.meta.form, ac.payload.name);
      },
  );
}

export function* reiniciaCampo(form : string, name : string) : Saga<*> {
  const campo = yield select(formValueSelector(form), name);

  if (campo || campo === 0)
    yield put(change(form, name, null));
}