// @flow

import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';

type Props = {
  open : bool,
  onClose() : void,
  onAccept() : void,
  acceptLabel : string,
  cancelLabel? : string,
  title? : string,
  hideCancel? : bool,
  children : React.Node,
}

export default function EsqueletoModal({ open, onClose, onAccept, acceptLabel, cancelLabel = 'Cancelar',
                                         title = 'Download de Documentos', hideCancel, children } : Props) {
  return (
      <Modal show={ open } onHide={ onClose }>
        <Modal.Header closeButton>
          <Modal.Title>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { children }
        </Modal.Body>
        <Modal.Footer>
          { !hideCancel &&
          <Button bsStyle="default" onClick={ onClose }>
            { cancelLabel }
          </Button>
          }
          <Button bsStyle="primary" onClick={ onAccept }>
            { acceptLabel }
          </Button>
        </Modal.Footer>
      </Modal>
  );
}
