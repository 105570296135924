// @flow
'use strict';

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import * as filtroLivre from './filtro_livre';
import * as alerts from 'reducers/alerts';
import request from 'lib/request';
import type { Action, Dispatch, State, GetState } from 'lib/types';
import { ajaxError } from '../alerts';
import { tipoSelector } from './filtro_livre';
import { formataQuery } from '../../lib/formata_query';

const ABRE_BUSCA_ESTRUTURADA  = 'nfe/filtroEstruturado/ABRE_BUSCA_ESTRUTURADA',
      FECHA_BUSCA_ESTRUTURADA = 'nfe/filtroEstruturado/FECHA_BUSCA_ESTRUTURADA';

const FiltrosRecord = new Immutable.Record({
  chave: '', emitente: '', destinatario: '', transportador: '', emitido_em: '', emitido_em_ate: '', recebido_em: '',
  recebido_em_ate: '', serie: '', serie_ate: '', numero: '', numero_ate: '', valor: '', valor_ate: '',
  tipo_operacao_id: { entrada: false, saida: false },
  itens: {
    descricao: '', codigo: '', ncm: '', cfop: '', valor_unitario: '', valor_unitario_ate: '', ean: '', ean_trib: '',
    pedido_nro: '',
  },
  fatura_numero: '', parcelas: { vencimento: '', vencimento_ate: '', valor: '', valor_ate: '' },
  lido: { true: false, false: false },
  situacao_arquivo: {
    ok: false, not_found: false, syntax_error: false, invalid_schema: false, wrong_environment: false,
    invalid_dsig: false,
  },
  custodiado: { true: false, false: false },
  situacao_sefaz: { autorizado: false, cancelado: false, denegado: false, nao_consta: false },
  manifestacao_cd: {
    nao_informado: false, ciencia: false, confirmada: false, operacao_nao_realizada: false, desconhecida: false,
  },
  manifestacao_realizada_em: '', manifestacao_realizada_em_ate: '', ultima_manifestacao_data: '',
  ultima_manifestacao_data_ate: '',
  ultima_manifestacao_cd: {
    nao_informado: false, ciencia: false, confirmada: false, operacao_nao_realizada: false, desconhecida: false,
  },
  decisao_portaria: { nao_informado: false, receber: false, devolver: false, receber_com_pendencia: false },
  data_decisao_portaria: '', data_decisao_portaria_ate: '', modal_open: false, d: '',
});

export default function reducer(state : any = new FiltrosRecord(), action : Action = {}) {
  switch (action.type) {
    case ABRE_BUSCA_ESTRUTURADA:
      return state.set('modal_open', true);
    case FECHA_BUSCA_ESTRUTURADA:
      return state = new FiltrosRecord();
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['nfe', 'filtroEstruturado'], Immutable.Map());
export const modalOpenSelector = createSelector<*, *, *, *>(rootSelector, root => root.get('modal_open', false));

// actions

export function inicializaBuscaEstruturada() {
  return { type: ABRE_BUSCA_ESTRUTURADA };
}

export function finalizaBuscaEstruturada() {
  return { type: FECHA_BUSCA_ESTRUTURADA };
}

// thunk actions

export function aplicaFiltro(form : any) {
  if (form.toJS)
    form = form.toJS();

  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const opcaoResumoAtual = filtroLivre.opcaoResumoSelector(getState());

    form['d'] = tipoSelector(getState());
    form = formataQuery(form, { camposObjetoComData: ['parcelas'] });

    dispatch(finalizaBuscaEstruturada());

    request.get(`/app/nfe/busca-avancada`)
        .accept('json')
        .query(form)
        .then(r => {
          if (r.body.alertas)
            dispatch(alerts.alert('danger', r.body.alertas));

          dispatch(filtroLivre.atualizaListaEFiltro({ q: r.body.q }, {
            sd: '', s: '', page: undefined, d: '', f: opcaoResumoAtual,
          }));
        })
        .catch(e => dispatch(ajaxError('Erro ao aplicar filtro de busca estruturada', e)));
  };
}
