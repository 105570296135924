// @flow
'use strict';

import * as React from 'react';

import Icone from '../../common/icone';
import ComTooltip from '../../common/com_tooltip';

export function iconeDesacordo(d : string[]) {
  if (!d || d.length < 2 || !d[0])
    return null;

  const desacordoCodigo = d[1];

  let hint, cor;

  if (desacordoCodigo === '135') {
    hint = 'Em desacordo';
    cor = 'text-desacordo';
  }
  else if (!desacordoCodigo) {
    hint = 'Aguardando Retorno';
    cor = 'text-desacordo';
  }
  else {
    hint = 'Desacordo Rejeitado';
    cor = 'text-desacordo-rejeitado';
  }

  return (
      <ComTooltip hint={ hint } posicao="right">
        <span className="fa-stack" style={ { margin: '-7px' } } title={ hint }>
          <Icone nome="square" estilo="fas" classe={ `icone-situacao fa-stack-2x ${ cor }` }/>
          <Icone nome="thumbs-down" estilo="fal" classe="fa-stack-1x fa-inverse"/>
        </span>
      </ComTooltip>
  );
}
