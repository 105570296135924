// @flow
'use strict';

import * as React from 'react';

import type { IFerroviario } from '../../types';

import RowDetalhes from '../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../../common/detalhes_documento/table_detalhes';

type FerroviarioProps = {
  ferroviario : IFerroviario,
};

export default function Ferroviario({ ferroviario } : FerroviarioProps) {
  return (
      <>
        <h3>Ferroviário</h3>
        <RowDetalhes data={ ferroviario }>
          <ColumnDetalhes field="tipoTrafego" width="50%">Tipo de Tráfego</ColumnDetalhes>
          <ColumnDetalhes field="fluxoFerroviario" width="50%">Fluxo Ferroviário</ColumnDetalhes>
        </RowDetalhes>

        <h4>Informações para o Tráfego Mútuo</h4>
        <RowDetalhes data={ ferroviario } className="no-margin-bottom">
          <ColumnDetalhes field="responsavelFaturamento" width="50%">Responsável pelo Faturamento</ColumnDetalhes>
          <ColumnDetalhes field="ferroviaEmitenteCte" width="50%">Ferrovia Emitente do CT-e</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ ferroviario }>
          <ColumnDetalhes field="valorFreteTrafegoMutuo" type="moeda" width="50%">Valor do Frete Tráfego Mútuo</ColumnDetalhes>
          <ColumnDetalhes field="chaveCteEmitidoFerrOrigem" width="50%">Chave CT-e emitido pela Ferr. Origem</ColumnDetalhes>
        </RowDetalhes>

        <h4>Informações das Ferrovias Envolvidas</h4>
        <TableDetalhes data={ ferroviario.infFerroviasEnvolvidas } noDataClass="padding-10">
          <ColumnDetalhes field="cnpj" type="documento">CNPJ</ColumnDetalhes>
          <ColumnDetalhes field="codInterno">Cód. Interno</ColumnDetalhes>
          <ColumnDetalhes field="ie">IE</ColumnDetalhes>
          <ColumnDetalhes field="razaoSocial">Razão Social</ColumnDetalhes>
          <ColumnDetalhes field="cep" type="cep">CEP</ColumnDetalhes>
          <ColumnDetalhes field="uf">UF</ColumnDetalhes>
        </TableDetalhes>
      </>
  );
}
