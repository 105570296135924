// @flow
'use strict';

import * as React from 'react';

import type { ItemProps } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';
import TableDetalhesComLinhas from '../../../common/detalhes_documento/table_detalhes_com_linhas';

export default function Cfe({ item } : ItemProps) {
  return (
      <div className="tab-pane active">
        <h3>Dados do CF-e</h3>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="numero" width="15%">Número</ColumnDetalhes>
          <ColumnDetalhes field="serie" width="15%">Série</ColumnDetalhes>
          <ColumnDetalhes field="modeloId" type="modelo" width="20%">Modelo</ColumnDetalhes>
          <ColumnDetalhes field="emitidoEm" type="dataHora" width="30%">Data de Emissão</ColumnDetalhes>
          <ColumnDetalhes field="valor" type="moeda" width="20%">Valor Total do CF-e</ColumnDetalhes>
        </RowDetalhes>

        <h3>Emissão</h3>
        <TableDetalhesComLinhas data={ item.emissao }>
          <tr>
            <ColumnDetalhes field="naturezaOperacao" width="28%">Natureza da Operação</ColumnDetalhes>
            <ColumnDetalhes field="tipoOperacao" colSpan={ 2 }>Tipo de Operação</ColumnDetalhes>
            <ColumnDetalhes field="destinoOperacao" width="25%">Destino da Operação</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="formatoImpressao">Formato de Impressão DANFE</ColumnDetalhes>
            <ColumnDetalhes field="digitoVerificador" colSpan={ 2 }>Dígito Verificador da Chave de Acesso</ColumnDetalhes>
            <ColumnDetalhes field="tipoEmissao">Tipo de Emissão</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="finalidade">Finalidade</ColumnDetalhes>
            <ColumnDetalhes field="digestValue" colSpan={ 2 }><i>Digest Value</i> da CF-e</ColumnDetalhes>
            <ColumnDetalhes field="ambiente">Ambiente</ColumnDetalhes>
          </tr>
        </TableDetalhesComLinhas>
      </div>
  );
}
