// @flow
'use strict';

import * as React from 'react';

import type { IDetalheContainer } from '../../../types';

import InformacaoContainer from './informacao_container';

type DetalheContainerProps = {
  detalhesContainers : IDetalheContainer[],
};

export default function DetalhamentoContainer({ detalhesContainers } : DetalheContainerProps) {
  let dadosContainers;

  if (!detalhesContainers || detalhesContainers.length === 0)
    dadosContainers = <InformacaoContainer container={ null } i={ 0 }/>;
  else {
    dadosContainers = <>{ detalhesContainers.map((container, i) => <InformacaoContainer container={ container }
                                                                                        key={ i }/>) }</>;
  }

  return (
      <>
        <h4>Detalhamento dos Containers</h4>
        { dadosContainers }
      </>
  );
}
