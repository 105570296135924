'use strict';
// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as lista from '../../../../reducers/sped/arquivos/lista';

import Filtros from './filtros';

const mapDispatchToProps = { carregaLista: lista.carregaLista };

export default compose(
    connect<*, *, *, *, *, *>(null, mapDispatchToProps),
    withRouter,
)(Filtros);