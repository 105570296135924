'use strict';
// @flow

import { removeNullProperties } from './remove_null_properties';
import { formatHelper as fmt } from './viewUtils';

export function formataQuery(params : any, config : { camposObjetoComData : string[] } = { camposObjetoComData: [] }) {
  const q = removeNullProperties(params);

  return converteDataEmMomentParaString(q, config);
}

function converteDataEmMomentParaString(params : any, config : { camposObjetoComData : string[] } = { camposObjetoComData: [] }) {
  const datasFormatadas = {};

  Object.keys(params).forEach((nomeCampo) => {
    const campo = params[nomeCampo];

    if (campo && typeof campo === 'object' && campo._isAMomentObject)
      datasFormatadas[nomeCampo] = campo.format('YYYY-MM-DD');
    else if (campo && campo instanceof Date)
      datasFormatadas[nomeCampo] = fmt.formatDateSped(campo);
    else if (campo && typeof campo === 'object' && config && config.camposObjetoComData && config.camposObjetoComData.includes(nomeCampo))
      params[nomeCampo] = formataQuery(campo);
  });

  return { ...params, ...datasFormatadas };
}
