'use strict';
// @flow

import _ from 'lodash';
import React from 'react';

import type { ISpedParticipante } from './documentos/types';
import { formatHelper } from 'lib/viewUtils';

import Money from '../common/money';
import Descricao, { DescricaoIcone } from '../common/descricao';

export function serieNumero(sn : string[]) { return _(sn).compact().join(' / '); }

export function serieNumeroSped(sn : string[]) { return <p style={ { whiteSpace: 'pre-wrap' } }>{ _(sn).compact().join('\n') }</p>; }

export function moeda(v : any) { return <Money valor={ v }/>; }

export function participante(p : ISpedParticipante) { return p && _([formatHelper.formatId(p.cnpj), p.nome]).compact().join(' - ') || '–'; }

export function emissao(e : any) { return <DescricaoIcone tipo="tipo_emissao" codigo={ e } large/>; }

export function operacao(o : any) { return <DescricaoIcone tipo="tipo_operacao" codigo={ o } large/>; }

export function modelo(m : any) { return <Descricao tipo="modelo_sped" codigo={ m }/>; }

export function situacaoSped(s : any) { return <Descricao tipo="situacao_sped" codigo={ s }/>; }

export function custodiado(c : any) { return <DescricaoIcone tipo="custodiado_icone" codigo={ c } large/>; }

export function custodiadoOrig(c : any) { return <DescricaoIcone tipo="custodiado_orig_icone" codigo={ c } large/>; }

export function situacaoArquivo([atual, upload] : any) {
  return <div>
    <Descricao tipo="situacao_arquivo_sped" codigo={ atual }/> { ' / ' }
    <Descricao tipo="situacao_arquivo_sped" codigo={ upload }/>
  </div>;
}

export function situacaoSefaz([atual, upload] : any) {
  return <div>
    <Descricao tipo="situacao_sefaz" codigo={ atual }/> { ' / ' }
    <Descricao tipo="situacao_sefaz" codigo={ upload }/>
  </div>;
}