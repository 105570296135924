// @flow
'use strict';

import * as React from 'react';
import { Button } from 'react-bootstrap';

import Icone from '../../common/icone';

type BotaoHistoricoProps = {
  id : string,
  abreHistorico : (id : string) => void;
}

export default function BotaoHistorico({ id, abreHistorico } : BotaoHistoricoProps) {
  const abrirModal = React.useCallback(() => abreHistorico(id), [abreHistorico, id]);

  return (
      <Button className="botao-historico" block bsStyle="default" onClick={ abrirModal }>
        <Icone nome="th-list" estilo="fas"/>
        <span style={ { marginLeft: 10 } }>Ver Histórico</span>
      </Button>
  );
}
