// @flow
'use strict';

import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import type { ItemProps } from '../types';

import Nfse from './nfse';
import Prestador from './prestador';
import Tomador from './tomador';
import Intermediario from './intermediario';
import Servico from './servico';
import Rps from './rps';

export default function AbasInformacoes({ item } : ItemProps) {
  return (
      <Tabs id="tab-detalhes-nfse" defaultActiveKey="nfse">
        <Tab eventKey="nfse" title="NFS-e">
          <Nfse item={ item }/>
        </Tab>
        <Tab eventKey="prestador" title="Prestador">
          <Prestador item={ item }/>
        </Tab>
        <Tab eventKey="tomador" title="Tomador">
          <Tomador item={ item }/>
        </Tab>
        <Tab eventKey="intermediario" title="Intermediário">
          <Intermediario item={ item }/>
        </Tab>
        <Tab eventKey="servico" title="Serviço">
          <Servico item={ item }/>
        </Tab>
        <Tab eventKey="rps" title="RPS">
          <Rps item={ item.rps }/>
        </Tab>
      </Tabs>
  );
}