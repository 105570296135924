'use strict';
// @flow

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as modal from 'reducers/modal';
import * as detalhes from '../../../../../reducers/sped/arquivos/detalhes';

import Opcoes from './opcoes';

const mapStateToProps    = createStructuredSelector({
        open: modal.openModalSelector,
      }),
      mapDispatchToProps = {
        abreModal: modal.abreModal,
        fechaModal: modal.fechaModal,
        excluirArquivo: detalhes.excluirArquivo,
        atualizaArquivo: detalhes.atualizaArquivo,
        downloadArquivoXls: detalhes.downloadArquivoXls,
      };

export default connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(Opcoes);