// @flow
'use strict';

import * as React from 'react';

import type { IPartilhaIcms } from '../../../../../types_imposto';

import RowDetalhes from '../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../common/detalhes_documento/column_detalhes';

type PartilhaIcmsProps = {
  item : IPartilhaIcms,
};

export default function PartilhaIcms({ item } : PartilhaIcmsProps) {
  return (
      <div id="table-partilha-icms">
        <h4>Partilha do ICMS</h4>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="origem" width="25%">Origem da mercadoria</ColumnDetalhes>
          <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
          <ColumnDetalhes field="determinacaoBcIcms" width="25%">Determinação BC do ICMS</ColumnDetalhes>
          <ColumnDetalhes field="bcIcms" type="moeda" width="25%">Base de Cálculo ICMS</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="percentualReducaoBcIcms" type="percentual" width="25%">Redução Base de Cálculo</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaIcms" type="percentual" width="25%">Alíquota ICMS</ColumnDetalhes>
          <ColumnDetalhes field="valorIcms" type="moeda" width="25%">Valor ICMS</ColumnDetalhes>
          <ColumnDetalhes field="determinacaoBcIcmsSt" width="25%">Determinação BC ICMS ST</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="margemValorAdicionadoIcmsSt" type="percentual" width="25%">Margem Valor Adicionado ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="reducaoBcIcmsSt" type="percentual" width="25%">Redução Base de Cálculo ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="bcIcmsSt" type="moeda" width="25%">Base de Cálculo ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaIcmsSt" type="percentual" width="25%">Alíquota ICMS ST</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="valorIcmsSt" type="moeda" width="25%">Valor ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="percentualBcOperacaoPropria" type="percentual" width="25%">Percentual BC Operação Própria</ColumnDetalhes>
          <ColumnDetalhes field="ufDevidoIcmsSt" width="50%">UF onde é devido ICMS ST</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
