// @flow
'use strict';

import * as React from 'react';

import type { IPisQtde } from '../../../../../types_imposto';

import TributoQuantidade from './tributo_quantidade';

type PisQtdeProps = {
  item : IPisQtde,
};

export default function PisQtde({ item } : PisQtdeProps) {
  return <TributoQuantidade item={ item }/>;
}