// @flow
'use strict';

import * as React from 'react';

import type { ResumoFiltro } from './types';
import { filtroPorCodigo, obterTipoNota } from './functions';

import FiltroResumo from './filtro_resumo';

type ResumoListaProps = {
  pathname : string,
  carregando : bool,
  filtros : ResumoFiltro[],
  filtroAtual : string,
  filtrarResumoLista : (opcao : string) => void,
};

export default function ResumoLista({
                                      pathname, carregando, filtros, filtroAtual, filtrarResumoLista,
                                    } : ResumoListaProps) {
  const tipoNota = obterTipoNota(pathname);

  const filtrarTodos                = React.useCallback(() => filtrarResumoLista(''), [filtrarResumoLista]),
        filtrarCancelados           = React.useCallback(() => filtrarResumoLista('cancelados'), [filtrarResumoLista]),
        filtrarProblemasXml         = React.useCallback(() => filtrarResumoLista('problemas-xml'), [filtrarResumoLista]),
        filtrarProblemasSefaz       = React.useCallback(() => filtrarResumoLista('problemas-sefaz'), [filtrarResumoLista]),
        filtrarManifestacaoPendente = React.useCallback(() => filtrarResumoLista('manifestacao-pendente'), [filtrarResumoLista]),
        filtrarNaoLidos             = React.useCallback(() => filtrarResumoLista('nao-lidos'), [filtrarResumoLista]);

  if (carregando)
    return null;

  return (
      <div className={ tipoNota === 'nfe' ? 'search-filters nfe' : 'search-filters' }>
        <div className="block">
          <FiltroResumo onClick={ filtrarTodos } filtro={ filtroPorCodigo(filtros, 'total') }
                        filtroAtual={ filtroAtual }/>
        </div>

        <div className="block">
          <FiltroResumo onClick={ filtrarCancelados } filtro={ filtroPorCodigo(filtros, 'cancelados') }
                        filtroAtual={ filtroAtual }/>
          <FiltroResumo onClick={ filtrarProblemasXml } filtro={ filtroPorCodigo(filtros, 'problemas-xml') }
                        filtroAtual={ filtroAtual }/>
          <FiltroResumo onClick={ filtrarProblemasSefaz } filtro={ filtroPorCodigo(filtros, 'problemas-sefaz') }
                        filtroAtual={ filtroAtual }/>
          { tipoNota === 'nfe' &&
          <FiltroResumo onClick={ filtrarManifestacaoPendente }
                        filtro={ filtroPorCodigo(filtros, 'manifestacao-pendente') }
                        filtroAtual={ filtroAtual }/>
          }
        </div>

        <div className="block">
          <FiltroResumo onClick={ filtrarNaoLidos } filtro={ filtroPorCodigo(filtros, 'nao-lidos') }
                        filtroAtual={ filtroAtual }/>
        </div>
      </div>
  );
}
