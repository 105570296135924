// @flow
'use strict';

import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import { fechaAlertas } from '../../../../reducers/nfe/manifestacao';

type AlertasProps = {
  alertas : string,
}

export default function Alertas({ alertas } : AlertasProps) {
  const dispatch      = useDispatch(),
        fecharAlertas = React.useCallback(() => dispatch(fechaAlertas()), [dispatch]);

  if (alertas.length === 0)
    return null;

  return (
      <div className="alert alert-danger">
        <Button className="close" onClick={ fecharAlertas }>
          <span aria-hidden="true">&times;</span>
        </Button>
        { alertas }
      </div>
  );
}
