// @flow
'use strict';

import * as React from 'react';
import { Button } from 'react-bootstrap';

import Icone from '../../common/icone';

type BotaoOrdenacaoProps = {
  campo : string,
  rotulo : string,
  ordenacao : { sd : string, s : string },
  onClick : () => void,
}

const CAMPOS_ORDENACAO = {
  emitido_em: 'emitido_em', serie_numero: 'serie,numero', valor_total: 'valor',
  emitente: 'emit_nome,emit_cnpj', destinatario: 'dest_nome,dest_cnpj',
};

export default function OrdenacaoPorCampo({ campo, rotulo, ordenacao, onClick } : BotaoOrdenacaoProps) {
  return (
      <Button type="button" className="btn-block" bsStyle="link" onClick={ onClick }>
        <RotuloComSinalOrdenacao campo={ campo } rotulo={ rotulo } ordenacao={ ordenacao }/>
      </Button>
  );
}

function RotuloComSinalOrdenacao({ campo, rotulo, ordenacao } : { campo : string, rotulo : string, ordenacao : { sd : string, s : string } }) {
  if (!deveApresentarSinalOrdenacao(campo, ordenacao))
    return <span>{ rotulo }</span>;

  const sentidoSinal = !ordenacao.s || ordenacao.sd === 'desc' ? 'caret-up' : 'caret-down';

  return <span>{ rotulo }{ '  ' }<Icone nome={ sentidoSinal } estilo="fas"/></span>;
}

function deveApresentarSinalOrdenacao(campo : string, ordenacao : { sd : string, s : string }) {
  if (!ordenacao || !ordenacao.s)
    return campo === 'emitido_em';

  return ordenacao.s === CAMPOS_ORDENACAO[campo];
}
