// @flow
'use strict';

import * as React from 'react';

import type { IImpostoImportacao } from '../../../../types_imposto';

import RowDetalhes from '../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../common/detalhes_documento/column_detalhes';

type ImpostoImportacaoProps = {
  item : IImpostoImportacao,
};

export default function ImpostoImportacao({ item } : ImpostoImportacaoProps) {
  return (
      <div id="table-imposto-importacao">
        <h4>II - Imposto de Importação</h4>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="bcIi" type="moeda" width="50%">BC do Imposto de Importação</ColumnDetalhes>
          <ColumnDetalhes field="despesaAduaneira" type="moeda" width="50%">Despesas aduaneiras</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="ii" type="moeda" width="50%">Imposto de Importação</ColumnDetalhes>
          <ColumnDetalhes field="iof" type="moeda" width="50%">Imposto sobre Operações Financeiras</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
