// @flow
'use strict';

import React from 'react';

type CampoDetalhesProps = {
  label : string,
  texto : ?string,
}

export default function CampoDetalhes({ label, texto } : CampoDetalhesProps) {
  if (!texto)
    texto = '';

  return (
      <p className="campo-detalhes">
        <b>{ label }:</b> { ' ' }
        { texto }
      </p>
  );
}