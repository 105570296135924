'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';
import { push } from 'react-router-redux';

import type { Action, Dispatch, GetState, State } from 'lib/types';
import { decamelizeObjectKeys } from 'lib/viewUtils';
import request from 'lib/request';

import * as Paths from '../../../paths';
import { ajaxError } from 'reducers/alerts';

export const LISTA_CARREGANDO   = 'ferramentas/prestador/lista/LISTA_CARREGANDO',
             LISTA_CARREGADA    = 'ferramentas/prestador/lista/LISTA_CARREGADA',
             INICIALIZA_FILTROS = 'ferramentas/prestador/lista/INICIALIZA_FILTROS',
             MUDA_FILTRO        = 'ferramentas/prestador/lista/MUDA_FILTRO',
             LIMPA_FILTRO       = 'ferramentas/prestador/lista/LIMPA_FILTRO';

const FiltrosRecord = new Immutable.Record({
  cnpj: '', contaId: '', nome: '', municipio: '', ativo: '',
});

const INITIAL_STATE = Immutable.fromJS({
  paginaAtual: {},
  carregando: false,
  filtro: new FiltrosRecord(),
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case LISTA_CARREGANDO:
      return state.set('carregando', true);
    case LISTA_CARREGADA:
      return state.set('carregando', false).set('paginaAtual', action.body);
    case MUDA_FILTRO:
      return state.setIn(['filtro', action.nome], action.valor);
    case LIMPA_FILTRO:
      return state.set('filtro', new FiltrosRecord());

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['ferramentas', 'prestador', 'lista'], Immutable.Map());
export const currentPage = createSelector<*, *, *, *>(rootSelector, (root) => root.get('paginaAtual'));
export const carregandoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('carregando'));
export const filtroSelector = createSelector<*, *, *, *>(rootSelector, (root) : typeof FiltrosRecord => root.get('filtro'));

// actions

export function mudaFiltro(nome : string, valor : any) {
  return { type: MUDA_FILTRO, nome, valor };
}

export function limpaFiltro() {
  return { type: LIMPA_FILTRO };
}

export function inicializaFiltros(query : typeof FiltrosRecord) {
  return { type: INICIALIZA_FILTROS, valores: new FiltrosRecord(query) };
}

// thunk actions

export function aplicaFiltro() {
  return function(dispatch : Dispatch<*>, getState : GetState) {
    const params = filtroSelector(getState()).toJS();

    dispatch(carregaLista({ ...params, page: undefined }));
  };
}

export function trocaPagina(page : number) {
  return function(dispatch : Dispatch<*>, getState : GetState) {
    const params = filtroSelector(getState()).toJS();

    dispatch(carregaLista({ ...params, page: String(page) }));
  };
}

export function carregaLista(query : any = {}) {
  return async function(dispatch : Dispatch<any>, getState : GetState) {
    if (carregandoSelector(getState()))
      return;

    const url = Paths.ferramentas.prestadores.colecao(decamelizeObjectKeys(query));
    dispatch(push(url));
    dispatch({ type: LISTA_CARREGANDO });

    await request.get(url)
        .accept('json')
        .then(r => dispatch({ type: LISTA_CARREGADA, body: Immutable.fromJS(r.body) }))
        .catch(e => {
          dispatch({ type: LISTA_CARREGADA, body: Immutable.fromJS({}) });
          dispatch(ajaxError('Erro ao carregar lista de prestadores', e));
        });
  };
}
