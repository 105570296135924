// @flow
'use strict';

import * as React from 'react';
import { Col, ControlLabel, Row } from 'react-bootstrap';

import { Input } from '../../../common/input';

export default function CampoSituacaoDesacordo() {
  return (
      <Row className="campo-formulario campo-input">
        <ControlLabel className="col-sm-4 titulo-checkbox">Situação de Desacordo</ControlLabel>
        <Col sm={ 8 } className="opcao-checkbox">
          <Input name="em_desacordo" label="Em Desacordo" checkbox/>
          <Input name="desacordo_rejeitado" label="Desacordo Rejeitado" checkbox/>
          <Input name="aguardando_retorno" label="Aguardando Retorno" checkbox/>
          <Input name="nao_consta" label="Não consta" checkbox/>
        </Col>
      </Row>
  );
}
