'use strict';

import * as Immutable from 'immutable';
import thunk from 'redux-thunk';

import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { browserHistory } from 'react-router';
import { routerMiddleware, syncHistoryWithStore } from 'react-router-redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import reducers from '../reducers';
import sagas from '../sagas';
import * as routing from '../reducers/routing';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk, sagaMiddleware, routerMiddleware(browserHistory)];

if (process.env.USE_REDUX_LOGGER) {
  const logger = createLogger({ stateTransformer: (state) => state.toJS() });

  middlewares.push(logger);
}

const store = createStore(
    reducers,
    Immutable.Map(),
    composeWithDevTools(
        applyMiddleware(...middlewares),
    ),
);

sagaMiddleware.run(sagas);

export default store;

// Create an enhanced history that syncs navigation events with the store
export const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: routing.asJSSelector,
});