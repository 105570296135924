// @flow
'use strict';

import * as React from 'react';

import type { IDocumentosFiscais, INotaFiscalEletronica } from '../types';

import TableDetalhes from '../../../common/detalhes_documento/table_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';

type DocFiscaisProps = {
  docFiscais : IDocumentosFiscais,
};

export default function DocFiscais({ docFiscais } : DocFiscaisProps) {
  return (
      <>
        <h4>Notas Fiscais</h4>
        <TableDetalhes data={ docFiscais.notasFiscais } noDataClass="padding-10" noDataMsg="Nenhum registro." showFields>
          <ColumnDetalhes field="romaneio">Romaneio</ColumnDetalhes>
          <ColumnDetalhes field="pedido">Pedido</ColumnDetalhes>
          <ColumnDetalhes field="modelo">Modelo</ColumnDetalhes>
          <ColumnDetalhes field="serie">Série</ColumnDetalhes>
          <ColumnDetalhes field="numero">Número</ColumnDetalhes>
          <ColumnDetalhes field="emissao" type="data">Emissão</ColumnDetalhes>
          <ColumnDetalhes field="valorTotal" type="moeda">Valor Total</ColumnDetalhes>
        </TableDetalhes>

        <h4>Notas Fiscais Eletrônicas</h4>
        <TableDetalhes data={ docFiscais.notasFiscaisEletronicas } noDataClass="padding-10" noDataMsg="Nenhum registro." showFields>
          <ColumnDetalhes field="chave" renderer={ linkParaChave }>Chave</ColumnDetalhes>
          <ColumnDetalhes field="numero">Número</ColumnDetalhes>
          <ColumnDetalhes field="serie">Série</ColumnDetalhes>
          <ColumnDetalhes field="emitente" type="documento">Emitente</ColumnDetalhes>
          <ColumnDetalhes field="entrega" type="data">Entrega</ColumnDetalhes>
        </TableDetalhes>

        <h4>Demais Documentos</h4>
        <TableDetalhes data={ docFiscais.outrosDocumentos } noDataClass="padding-10" noDataMsg="Nenhum registro." showFields>
          <ColumnDetalhes field="tipo">Tipo de Documento</ColumnDetalhes>
          <ColumnDetalhes field="descricao">Descrição</ColumnDetalhes>
          <ColumnDetalhes field="numero">Número</ColumnDetalhes>
          <ColumnDetalhes field="emissao" type="data">Emissão</ColumnDetalhes>
          <ColumnDetalhes field="valor" type="moeda">Valor</ColumnDetalhes>
        </TableDetalhes>
      </>
  );
}

function linkParaChave(chave : string, { url } : INotaFiscalEletronica) {
  return (
      !url
          ? <>{ chave }</>
          : <a href={ url } target="_blank" rel="noreferrer">{ chave }</a>
  );
}
