// @flow
'use strict';

import * as React from 'react';

import CampoItemNfe from './campos_item_nfe';
import CampoItemNfce from './campos_item_nfce';

type CamposItemProps = {
  tipoDocumento : string,
  component : React.ComponentType<*>,
}

export default function CamposItem({ tipoDocumento, component } : CamposItemProps) {
  return (
      <fieldset className="grupo-download-lista">
        <legend>Itens do Documento</legend>
        <p className="instrucao-em-titulo">A lista retornará uma linha para cada item.</p>
        { tipoDocumento === 'nfe' ? <CampoItemNfe component={ component }/> : <CampoItemNfce component={ component }/> }
      </fieldset>
  );
}