// @flow

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import * as lista from '../../../../reducers/sped/arquivos/lista';
import { jsSelector } from '../../../../lib/viewUtils';

import Modal from './modal';

const mapStateToProps = createStructuredSelector({
  lastQuery: jsSelector(lista.lastQuerySelector),
});

export default connect<*, *, *, *, *, *>(mapStateToProps)(Modal);