// @flow
'use strict';

import * as React from 'react';

import type { IImpostos } from '../../../../types_imposto';

import ColumnDetalhes from '../../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../../common/detalhes_documento/row_detalhes';
import Issqn from './issqn';
import Icms from './icms';
import IcmsUfDestino from './icms_uf_destino';
import ImpostoImportacao from './imposto_importacao';
import Ipi from './ipi';
import Pis from './pis';
import Cofins from './cofins';

type ImpostoProps = {
  item : IImpostos,
};

export default function Impostos({ item } : ImpostoProps) {
  if (!item)
    return null;

  return (
      <div id="table-imposto">
        <h3>Tributos Incidentes</h3>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="valorAproxTotalTrib" type="moeda">Valor Aproximado Total de Tributos</ColumnDetalhes>
        </RowDetalhes>

        { item.issqn && <Issqn item={ item.issqn }/> }
        { !item.issqn && item.icms && <Icms item={ item.icms }/> }
        { item.icmsUFDestino && <IcmsUfDestino item={ item.icmsUFDestino }/> }
        { !item.issqn && item.ii && <ImpostoImportacao item={ item.ii }/> }
        { item.ipi && <Ipi item={ item.ipi }/> }
        { item.pis && <Pis item={ item.pis }/> }
        { item.cofins && <Cofins item={ item.cofins }/> }
      </div>
  );
}
