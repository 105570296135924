// @flow
'use strict';

import * as React from 'react';

import FormExportacao from '../form_exportacao';
import CamposCte from './campos_cte';

export type FormProps = {
  handleSubmit : () => void,
}

export default function FormExportacaoCte(props : FormProps) {
  return (
      <FormExportacao { ...props } tipo="cte">
        <CamposCte/>
      </FormExportacao>
  );
}