// @flow
'use strict';

import * as React from 'react';

import type { IProdutoServico } from '../../../types';

import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';

type DadosBasicosProps = {
  item : IProdutoServico,
};

export default function DadosBasicos({ item } : DadosBasicosProps) {
  return (
      <div id="table-dados-basicos">
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="descricao">Descrição</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="codigo" width="25%">Código</ColumnDetalhes>
          <ColumnDetalhes field="ncm" width="25%">NCM</ColumnDetalhes>
          <ColumnDetalhes field="cfop" width="25%">CFOP</ColumnDetalhes>
          <ColumnDetalhes field="valorTotal" type="moeda" width="25%">Valor Total</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="ean" width="25%">EAN Comercial</ColumnDetalhes>
          <ColumnDetalhes field="un" width="25%">Und. Comercial</ColumnDetalhes>
          <ColumnDetalhes field="quantidade" width="25%">Qtd. Comercial</ColumnDetalhes>
          <ColumnDetalhes field="valorUnitario" type="moeda" width="25%">Valor Unit. Com.</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}