// @flow
'use strict';

import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import PaginationFooter from 'components/common/pagination_footer';
import Contador from 'components/common/contador';

import * as lista from 'reducers/sped/escriturados/lista';
import * as filtro from 'reducers/sped/escriturados/filtro';
import { jsSelector } from 'lib/viewUtils';

import Filtros from '../filtros';
import Tabela from './tabela';
import Funcoes from '../funcoes';

type ListagemEscrituradosSpedProps = {
  escriturados : ?any,
  trocaPagina : () => void,
}

export function ListagemEscrituradosSped({ escriturados, trocaPagina } : ListagemEscrituradosSpedProps) {
  const { page, total, items, pageSize, limiteDownload } = escriturados && _.isObject(escriturados) ? escriturados : {};

  return (
      <div>
        <Filtros/>
        <Contador total={ total } prefix="Documentos encontrados:"/>
        <Tabela dados={ items }/>
        <div className="pull-left">
          <Funcoes total={ total } limiteDownload={ limiteDownload }/>
        </div>
        <PaginationFooter label="Escriturados" page={ page } total={ total } pageSize={ pageSize }
                          showPageEntries={ false }
                          onPageChange={ trocaPagina }>
        </PaginationFooter>
      </div>
  );
}

const mapStateToProps    = createStructuredSelector({
        escriturados: jsSelector(lista.currentPage),
      }),
      mapDispatchToProps = {
        trocaPagina: filtro.trocaPagina,
      };

export default connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(ListagemEscrituradosSped);