// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { jsSelector } from 'lib/viewUtils';
import { fechaDetalhesProdutoServico, itemSelector, modalOpenSelector } from '../../../../../../reducers/nfce/detalhes_produto_servico';

import ModalDetalhes from './modal_detalhes';

const mapStateToProps    = createStructuredSelector({
        open: modalOpenSelector, item: jsSelector(itemSelector),
      }),
      mapDispatchToProps = {
        fechaModal: fechaDetalhesProdutoServico,
      };

export default connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(ModalDetalhes);