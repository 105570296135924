// @flow
'use strict';

import { put, takeLatest } from 'redux-saga/effects';
import { CHANGE } from 'redux-form/lib/actionTypes';

import { atualizaOpcoesEmpresas } from '../reducers/dashboard/empresa';

export default function* dashboard() : Generator<*, *, *> {
  yield takeLatest(
      ac => ac.type === '@@router/LOCATION_CHANGE' && ac.payload.pathname === '/app/dashboard/paineis',
      sagaAtualizaOpcoesEmpresas);
}

export function* sagaAtualizaOpcoesEmpresas() : Generator<*, *, *> {
  yield takeLatest(
      ac => ac.type === CHANGE && ac.meta.form === 'dashboardFiltro' && ac.meta.field === 'conta_id',
      function* (ac : { meta : { form : string }, payload : any }) {
        yield put(atualizaOpcoesEmpresas(ac.payload));
      },
  );
}