// @flow
'use strict';

import * as React from 'react';

import type { IDestinatario } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';
import Endereco from './endereco';

type DestinatarioProps = {
  destinatario : IDestinatario,
};

export default function Destinatario({ destinatario } : DestinatarioProps) {
  return (
      <div className="tab-pane active">
        <h3>Destinatário</h3>
        <RowDetalhes data={ destinatario }>
          <ColumnDetalhes field="nome" width="50%">Nome / Razão Social</ColumnDetalhes>
          <ColumnDetalhes field="cnpjCpfEstrangeiro" type="documento" width="50%">
            { tipoDocumento(destinatario) }
          </ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ destinatario }>
          <ColumnDetalhes field="inscricaoEstadual" width="50%">Inscrição Estadual</ColumnDetalhes>
          <ColumnDetalhes field="inscricaoMunicipal" width="50%">Inscrição Municipal</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ destinatario }>
          <ColumnDetalhes field="tipoContribuinte" width="50%">Tipo de Contribuinte</ColumnDetalhes>
          <ColumnDetalhes field="email" width="50%">E-mail</ColumnDetalhes>
        </RowDetalhes>

        { destinatario && <Endereco endereco={ destinatario.endereco }/> }
      </div>
  );
}

function tipoDocumento(destinatario : IDestinatario) {
  if (!destinatario || !destinatario.cnpjCpfEstrangeiro || destinatario.cnpjCpfEstrangeiro.length === 14)
    return 'CNPJ';

  if (destinatario.cnpjCpfEstrangeiro.length === 11)
    return 'CPF';

  return 'Documento Estrangeiro';
}