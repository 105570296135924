// @flow
'use strict';

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, Dispatch, State, GetState } from 'lib/types';
import { carregaLista } from './lista';
import { currentRouteSelector } from '../routing';

const BUSCA_LIVRE      = 'nfse/filtroLivre/BUSCA_LIVRE',
      ALTERA_ORDENACAO = 'nfse/filtroLivre/ALTERA_ORDENACAO';

const INITIAL_STATE = Immutable.fromJS({ q: '', ordenacao: '' });

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case BUSCA_LIVRE:
      return state.set('q', action.busca);
    case ALTERA_ORDENACAO:
      return state.set('ordenacao', action.ordenacao);

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['nfse', 'filtroLivre'], Immutable.Map());
export const ordenacaoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('ordenacao'));

// thunk actions

export function inicializaBuscaLivre(query : { q? : string }) {
  if (query && query.q)
    return { type: BUSCA_LIVRE, busca: query.q };
  else
    return { type: BUSCA_LIVRE, busca: '' };
}

export function atualizaListaEFiltro(query : { q? : string, ordenacao? : string }) {
  // Como ocorre erro com q vazia ao realizar a busca a partir do formulário,
  // remove propriedade q se está for indefinida.
  if (query && (!query.q || query.q.length === 0))
    delete query.q;

  return async function(dispatch : Dispatch<*>) {
    await dispatch(inicializaBuscaLivre(query));
    await dispatch(carregaLista(query));

    if (query.ordenacao)
      await dispatch({ type: ALTERA_ORDENACAO, ordenacao: query.ordenacao });
  };
}

export function limpaFiltro() {
  return async function(dispatch : Dispatch<*>) {
    await dispatch(atualizaListaEFiltro({}));
  };
}

export function aplicaFiltro(form : any) {
  if (form.toJS)
    form = form.toJS();

  return async function(dispatch : Dispatch<*>) {
    await dispatch(atualizaListaEFiltro(form));
  };
}

export function ordenaLista(paramOrdem : string) {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const query = currentRouteSelector(getState()).get('query').toJS();

    const ordenacao = ordenacaoSelector(getState());

    let ord = '';
    if (ordenacao && ordenacao.startsWith(paramOrdem))
      ord = ordenacao.endsWith('_decresc') ? `${paramOrdem}_cresc` : `${paramOrdem}_decresc`;
    else
      ord = `${paramOrdem}_cresc`;

    await dispatch(atualizaListaEFiltro({ ...query, ordenacao: ord, page: undefined }));
  };
}