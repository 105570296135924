// @flow
'use strict';

import * as React from 'react';

import type { IIcmsUfDest } from '../../../../types_imposto';

import RowDetalhes from '../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../common/detalhes_documento/column_detalhes';

type IcmsUfDestProps = {
  item : IIcmsUfDest,
};

export default function IcmsUfDestino({ item } : IcmsUfDestProps) {
  return (
      <div id="table-icms-uf-destino">
        <h4>ICMS para UF de destino</h4>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="bcIcmsUFDest" type="moeda" width="25%">BC ICMS UF de destino</ColumnDetalhes>
          <ColumnDetalhes field="bcFcpUFDest" type="moeda" width="33%">BC FCP na UF de destino</ColumnDetalhes>
          <ColumnDetalhes field="icmsRelativoFcpUFDest" type="percentual" width="42%">ICMS relativo ao FCP na UF de destino</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="aliquotaInternaUFDest" type="percentual" width="25%">Alíquota interna da UF de destino</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaInterstUFEnvolvida" type="percentual" width="33%">Alíquota interestadual das UF envolvidas</ColumnDetalhes>
          <ColumnDetalhes field="percProvPartIcmsInteres" type="percentual" width="42%">Percentual provisório de partilha do ICMS Interestadual</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="icmsFcpUFDest" type="moeda" width="25%">ICMS FCP da UF de destino</ColumnDetalhes>
          <ColumnDetalhes field="icmsInteresUFDest" type="moeda" width="33%">ICMS Interestadual para UF de destino</ColumnDetalhes>
          <ColumnDetalhes field="icmsInterestUFRemet" type="moeda" width="42%">ICMS Interestadual para UF do remetente</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
