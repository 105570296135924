'use strict';
// @flow

import _ from 'lodash';
import React from 'react';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import type { Upload } from './types';

import UploadProgress from 'components/common/upload_progress';

const MSG_ERRO_NAO_PREVISTO = 'Verifique se o arquivo está no formato correto. Em caso de dúvidas, entre em contato com o nosso suporte.';

export default function ModalBody({ uploading, progress, error, uploadResult } : Upload) {
  if (error) {
    let msg;
    if (!_.get(error, 'response.body.error.tratado'))
      msg = MSG_ERRO_NAO_PREVISTO;
    else
      msg = _.get(error, 'response.body.error.message') || error.message;

    return (
        <Modal.Body>
          <p>Ocorreu um erro ao enviar o arquivo:</p>
          <p className="text-danger">{ msg }</p>
        </Modal.Body>
    );
  }
  else if (progress !== 'done') {
    return (
        <Modal.Body>
          <p>Inclua novo arquivo EFD ICMS IPI.
            O arquivo deve ser no formato texto (.TXT).</p>
          <FormGroup controlId="novoArquivo">
            <ControlLabel srOnly>Arquivo</ControlLabel>
            <FormControl type="file" required multiple disabled={ uploading }/>
          </FormGroup>
          { uploading && <UploadProgress progress={ progress ? progress : undefined }/> }
        </Modal.Body>
    );
  }

  return (
      <Modal.Body>
        <p>
          { uploadResult && uploadResult.length > 1
              ? 'Seus arquivos estão sendo processados'
              : 'Seu arquivo está sendo processado' },
          e todos os seus dados estarão disponíveis em alguns minutos.
        </p>
        <p>
          Você pode consultar os detalhes, selecionando o arquivo desejado na lista.
        </p>
      </Modal.Body>
  );
}