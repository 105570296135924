'use strict';
// @flow

import React from 'react';

type NoDataRowProps = {
  span : number,
  children? : any,
}

export default function NoDataRow({ span, children } : NoDataRowProps) {
  return (
      <tr className="nodata">
        <td colSpan={span}>{ children }</td>
      </tr>
  );
}
