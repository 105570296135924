// @flow
'use strict';

import * as React from 'react';

import { formatHelper } from '../../../../../lib/viewUtils';

import EsqueletoModal from '../../../../common/esqueleto_modal';

type ModalExportacaoLoteProps = {
  open : bool,
  fechaModal : () => void,
  totalDocumentos : number,
  exportaLoteNfce : () => void,
}

export default function ModalExportacaoLote({ open, fechaModal, totalDocumentos, exportaLoteNfce } : ModalExportacaoLoteProps) {
  return (
      <EsqueletoModal open={ open } onClose={ fechaModal } onAccept={ exportaLoteNfce } acceptLabel="Confirmar">
        <p>Você solicitou o download dos documentos da busca atual,
          que compreende { formatHelper.numberWithDelimiter(totalDocumentos) } documentos.</p>
        <p>O progresso da operação poderá ser acompanhado pela opção <q>Downloads</q>, no menu lateral.</p>
        <p>Confirma a solicitação?</p>
      </EsqueletoModal>
  );
}