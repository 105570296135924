// @flow
'use strict';

import * as React from 'react';
import { Button, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import * as Paths from '../../../paths';

import Icone from '../../common/icone';
import QuantidadeLinkButton from './common/quantidade_link_button';
import QuantidadeSemLink from './common/quantidade_sem_link';

const STYLE_TRANSPARENTE_TABELA = { backgroundColor: 'rgba(255,255,255,0.0)', fontSize: 15 },
      STYLE_COLUMN_LINK         = { paddingBottom: 0, paddingTop: 0, fontSize: 15 };

export type Documentos = {
  nfe : RecebidosHoje,
  nfce : RecebidosHoje,
  cte : RecebidosHoje,
  cteos : RecebidosHoje,
  nfse : RecebidosHoje,
}

type RecebidosHoje = {
  quantidade : number,
  query : ?string,
}

type TabelaDocumentosProps = {
  documentos : ?Documentos
}

export default function TabelaDocumentos({ documentos } : TabelaDocumentosProps) {
  if (!documentos || !documentos.nfe)
    return <div/>;

  return (
      <Table condensed style={ { marginBottom: '10px' } }>
        <colgroup>
          <col width="365px"/>
          <col/>
        </colgroup>
        <tbody>
        <tr style={ STYLE_TRANSPARENTE_TABELA }>
          <DocumentoRecebido nome="file-alt" titulo="NF-e"/>
          <QuantidadeLinkButton styleCelula={ STYLE_COLUMN_LINK }
                                className="botao-link-dashboard"
                                quantidade={ documentos.nfe.quantidade }
                                path={ documentos.nfe.query ? Paths.nfes.lista.recebidos({ q: documentos.nfe.query }) : null }/>
        </tr>
        <tr style={ STYLE_TRANSPARENTE_TABELA }>
          <DocumentoRecebido nome="shopping-cart" titulo="NFC-e"/>
          <QuantidadeLinkContainer quantidade={ documentos.nfce.quantidade }
                                   path={ documentos.nfce.query ? Paths.nfces.lista.recebidos({ q: documentos.nfce.query },
                                       { s: '', sd: '', f: '' }) : null }/>
        </tr>
        <tr style={ STYLE_TRANSPARENTE_TABELA }>
          <DocumentoRecebido nome="road" titulo="CT-e"/>
          <QuantidadeLinkButton styleCelula={ STYLE_COLUMN_LINK }
                                className="botao-link-dashboard"
                                quantidade={ documentos.cte.quantidade }
                                path={ documentos.cte.query ? Paths.ctes.lista.tomados({ q: documentos.cte.query }) : null }/>
        </tr>
        <tr style={ STYLE_TRANSPARENTE_TABELA }>
          <DocumentoRecebido nome="road" titulo="CT-e OS"/>
          <QuantidadeLinkButton styleCelula={ STYLE_COLUMN_LINK }
                                className="botao-link-dashboard"
                                quantidade={ documentos.cteos.quantidade }
                                path={ documentos.cteos.query ? Paths.ctes.lista.tomados({ q: documentos.cteos.query }) : null }/>
        </tr>
        <tr style={ STYLE_TRANSPARENTE_TABELA }>
          <DocumentoRecebido nome="building" titulo="NFS-e"/>
          <QuantidadeLinkContainer quantidade={ documentos.nfse.quantidade }
                                   path={ documentos.nfse.query ? Paths.nfses.lista({ q: documentos.nfse.query }) : null }/>
        </tr>
        </tbody>
      </Table>
  );
}

function DocumentoRecebido({ nome, titulo } : { nome : string, titulo : string }) {
  return (
      <td>
        <Icone nome={ nome } estilo="fas" cor="text-muted" small />
        <span className="espaco-icon-recebidos"/>
        { titulo }
      </td>
  );
}

function QuantidadeLinkContainer({ path, quantidade } : { path : ?string, quantidade : number }) {
  if (!path)
    return <QuantidadeSemLink quantidade={ quantidade }/>;

  return (
      <td style={ STYLE_COLUMN_LINK }>
        <LinkContainer to={ path }>
          <Button className="botao-link-dashboard" bsStyle="link">
            { quantidade }
          </Button>
        </LinkContainer>
      </td>
  );
}
