// @flow
'use strict';

import * as React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router';

/**
 * Este componente foi criado devido a um bug no react-router, que não consegue identificar a URL
 * como ativa quando o caminho possui parâmetros.
 */
export default withRouter(function LinkContainerForPathParams({ router, children, ...props } : { router : any, children : React.MixedElement, to : string, pathname : string }) {
  const active = props.pathname.startsWith(props.to);

  if (!router)
    return React.cloneElement(children, { href: props.to, active });

  return <LinkContainer { ...props } active={ active }>{children}</LinkContainer>;
})