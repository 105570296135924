// @flow

import * as React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

type CampoFiltroProps = {
  control? : React.ElementType,
  name : string,
  label : string,
}

export default function CampoFiltro({ control, name, label, ...props } : CampoFiltroProps) {
  const ControlField : any = control || FormControl;

  return (
      <FormGroup controlId={ name }>
        <ControlLabel srOnly>{ label }</ControlLabel>
        <ControlField { ...props } name={ name } placeholder={ label }/>
      </FormGroup>
  );
}