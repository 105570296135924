// @flow
'use strict';

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, Dispatch, State, GetState } from 'lib/types';
import * as filtroLivre from './filtro_livre';
import request from 'lib/request';
import { ajaxError } from '../alerts';

const ABRE_BUSCA_ESTRUTURADA  = 'nfce/filtroEstruturado/ABRE_BUSCA_ESTRUTURADA',
      FECHA_BUSCA_ESTRUTURADA = 'nfce/filtroEstruturado/FECHA_BUSCA_ESTRUTURADA';

const FiltrosRecord = new Immutable.Record({
  chave: '', emitente: '', destinatario: '', emitido_em: '', emitido_em_ate: '',
  recebido_em: '', recebido_em_ate: '', serie: '', serie_ate: '',
  numero: '', numero_ate: '', valor: '', valor_ate: '', versao: '',
  situacao_custodia: {
    expired: false, notified: false, cancelled: false, syntax_error: false, invalid_schema: false,
    wrong_environment: false, invalid_dsig: false, wrong_dsig: false, ok: false, not_found: false,
  },
  situacao_sefaz: {
    autorizado: false, cancelado: false, uso_denegado: false, nao_consta: false,
    irregular: false, sem_situacao: false, nao_consultado: false,
  },
  situacao_arquivo: {
    syntax_error: false, invalid_schema: false, wrong_environment: false, invalid_dsig: false, ok: false,
    not_found: false,
  },
  lido: '', custodiado: '', modal_open: false,
  itens: { descricao: '', codigo: '', ncm: '', valor_unitario: '', valor_unitario_ate: '', ean: '' },
});

export default function reducer(state : any = new FiltrosRecord(), action : Action = {}) {
  switch (action.type) {
    case ABRE_BUSCA_ESTRUTURADA:
      return state
          .set('modal_open', true);
    case FECHA_BUSCA_ESTRUTURADA:
      return state = new FiltrosRecord();

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['nfce', 'filtroEstruturado'], Immutable.Map());
export const modalOpenSelector = createSelector<*, *, *, *>(rootSelector, root => root.get('modal_open', false));

// thunk actions

export function inicializaBuscaEstruturada() {
  return async function(dispatch : Dispatch<*>) {
    dispatch({ type: ABRE_BUSCA_ESTRUTURADA });
  };
}

export function finalizaBuscaEstruturada() {
  return async function(dispatch : Dispatch<*>) {
    dispatch({ type: FECHA_BUSCA_ESTRUTURADA });
  };
}

export function aplicaFiltro(form : any) {
  if (form.toJS)
    form = form.toJS();

  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const opcaoResumoAtual = filtroLivre.opcaoResumoSelector(getState());

    dispatch(finalizaBuscaEstruturada());

    request.get(`/app/nfce/busca-avancada`)
        .accept('json')
        .query(form)
        .then(r => dispatch(filtroLivre.atualizaListaEFiltro(r.body, { sd: '', s: '', page: undefined, d: '', f: opcaoResumoAtual })))
        .catch(e => dispatch(ajaxError('Erro ao aplicar filtro de busca estruturada', e)));
  };
}
