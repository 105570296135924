// @flow

export const CAMPOS_SITUACAO_SEFAZ_CFE = [
  { label: 'Autorizada', name: 'autorizado' },
  { label: 'Cancelada', name: 'cancelado' },
  { label: 'Uso Denegado', name: 'denegado' },
  { label: 'Não Consta na SEFAZ', name: 'nao_consta' },
  { label: 'Irregular', name: 'irregular' },
  { label: 'Sem Situação', name: 'sem_situacao' },
  { label: 'Não Consultado', name: 'nao_consultado' },
];

export const CAMPOS_SITUACAO_CUSTODIA_CFE = [
  { label: 'Download Expirado', name: 'expired' },
  { label: 'Notificado', name: 'notified' },
  { label: 'Cancelada ou Denegada', name: 'cancelled' },
  { label: 'Erro de Sintaxe', name: 'syntax_error' },
  { label: 'Schema Inválido', name: 'invalid_schema' },
  { label: 'Ambiente Incorreto', name: 'wrong_environment' },
  { label: 'Assinatura Digital Inválida', name: 'invalid_dsig' },
  { label: 'Assinatura Digital Difere', name: 'wrong_dsig' },
  { label: 'Custodiado', name: 'ok' },
  { label: 'Não Encontrado', name: 'not_found' },
];
