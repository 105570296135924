// @flow

export const CAMPOS_TRANSACOES_CONSYSTE = {
  transacoesConsyste: {
    recebidoEm: false, dataDecisaoPortaria: false, decisaoPortariaDescricao: false,
    custodiado: false, situacaoArquivo: false,
  },
};

export const CAMPOS_DESACORDO_SERVICO = {
  desacordoServico: {
    desacordoRealizadoEm: false, desacordoServico: false,
  },
};

export const CAMPOS_EMITENTE = {
  emit: {
    emitCnpj: false, emitNome: false, emitFantasia: false, emitBairro: false,
    emitMunicipio: false, emitIe: false, emitEndUf: false, emitPais: false, emitIest: false,
    emitCnae: false, emitCrt: false, emitIm: false,
  },
};

export const CAMPOS_MANIFESTACAO_DESTINATARIO = {
  manifestacaoDestinatario: {
    manifestacao: false, manifestacaoRealizadaPor: false,
    manifestacaoJustificativa: false, manifestacaoRealizadaEm: false,
    },
};

export const CAMPOS_DESTINATARIO = {
  dest: {
    destCnpj: false, destIdEstrangeiro: false, destNome: false, destLogr: false, destNum: false, destCompl: false,
    destIe: false, destEndUf: false, destCep: false, destBairro: false, destMunicipio: false, destPais: false,
    destFone: false, destIndIe: false, destIsuframa: false, destIsuf: false, destIm: false, destEmail: false,
  },
};

export const CAMPOS_DADOS_ITENS = {
  dadosBasicosItens: {
    codigo: false, descricao: false, ncm: false, exTipi: false, unidadeComercial: false,
    gtin: false, unidadeTributavel: false, gtinTributavel: false,
  },
};

export const CAMPOS_DADOS_OPERACAO = {
  dadosOperacao: {
    numeroItem: false, cfopItem: false, beneficioFiscal: false, qtdComercial: false, valorUnitarioComercial: false,
    valorTotalBruto: false, qtdTributavel: false, valorUnitarioTributavel: false, valorFrete: false,
    valorSeguro: false, valorDesconto: false, outrasDespesas: false, itemCompoeValorTotal: false,
    pedidoNro: false, pedidoItem: false, numeroFci: false, informacoesAdicionaisProduto: false, valorAproxTributos: false,
  },
};

export const CAMPOS_DADOS_CEST = {
  dadosCest: {
    cest: false, escalaRelevante: false, cnpjFabricante: false,
  },
};

export const CAMPOS_CARGA = {
  basicoCarga: {
    valorTotalCarga: false, produtoPredominante: false, outrasCaracteristicas: false,
  },
  docCargaNf: {
    romaneioNf: false, pedidoNf: false, modeloNf: false, serieNf: false, numeroNf: false,
    emissaoNf: false, valorBcIcmsNf: false, valorIcmsNf: false, valorBcIcmsStNf: false,
    valorIcmsStNf: false, valorProdutosNf: false, valorTotalNf: false, cfopNf: false, pesoNf: false,
  },
  docCargaNfe: {
    chaveNfe: false, entregaNfe: false,
  },
  docCargaOutros: {
    tipoDoc: false, descricaoDoc: false, numeroDoc: false, emissaoDoc: false, valorDoc: false,
  },
};

export const CAMPOS_DOCUMENTOS_REFERENCIADOS = {
  docReferenciadoNfe: {
    chaveNfe: false,
  },
  docReferenciadoNf: {
    ufEmitenteNf: false, dataEmissaoNf: false, emitenteNf: false, serieNf: false, numeroNf: false,
  },
  docReferenciadoCte: {
    chaveCte: false,
  },
  docReferenciadoCupom: {
    modeloCupom: false, numeroEcfCupom: false, numeroContadorCupom: false,
  },
};

const CAMPOS_TRANSPORTE = {
  modalidadeFrete: false, cnpjTransportador: false, cpfTransportador: false, razaoSocialTransportador: false,
  ieTransportador: false, enderecoTransportador: false, municipioTransportador: false, ufTransportador: false,
};

export const CAMPOS_TRANSPORTE_NFE = {
  transporte: {
    ...CAMPOS_TRANSPORTE,
    valorServicoRetencao: false, valorBcRetencao: false, aliquotaRetencao: false, valorIcmsRetencao: false,
    cfopTransporte: false, codigoMunicipioFatoGerador: false, placaVeiculo: false, ufVeiculo: false,
    rntcVeiculo: false, identificacaoVagao: false, identificacaoBalsa: false,
  },
};

export const CAMPOS_TRANSPORTE_NFCE = {
  transporte: CAMPOS_TRANSPORTE,
};

export const CAMPOS_DADOS_TOTAIS = {
  dadosTotais: {
    totalBcIcms: false, totalIcms: false, totalIcmsDeson: false, totalIcmsFcpUfDest: false,
    totalIcmsInterUfDest: false, totalIcmsInterUfRemet: false, totalFcp: false, totalBcIcmsSt: false,
    totalIcmsSt: false, totalFcpRetidoSt: false, totalFcpRetidoAntSt: false, totalProd: false,
    totalFrete: false, totalSeguro: false, totalDesconto: false, totalImpostoImportacao: false,
    totalIpi: false, totalIpiDevolvido: false, totalPis: false, totalCofins: false,
    totalOutras: false, totalValor: false, valor: false, totalTributos: false, valorAReceber: false,
  },
};

export const CAMPOS_DADOS_ICMS_CTE = {
  dadosIcms: {
    cst: false, valorBc: false, aliquota: false, valorIcms: false, percReducaoBc: false, bcIcmsStRet: false,
    valorIcmsStRet: false, aliquotaIcmsStRet: false, valorCredIcms: false, percRedBcOutraUf: false, bcIcmsOutraUf: false,
    aliquotaIcmsOutraUf: false, valorIcmsOutraUf: false,
  },
};

export const CAMPOS_DADOS_ICMS_UF_FIM = {
  dadosIcmsUfFim: {
    bcUfFim: false, percIcmsFcpUfFim: false, aliquotaInternaUfFim: false, aliquotaInterestUfEnvolvidas: false,
    valorIcmsFcpUfFim: false, valorIcmsUfFim: false, valorIcmsUfInicio: false,
  },
};

export const CAMPOS_DADOS_TRIBUTOS_FEDERAIS = {
  dadosTributosFederais: {
    pis: false, cofins: false, ir: false, inss: false, csll: false,
  },
};

export const CAMPOS_DADOS_ICMS = {
  dadosIcms: {
    origemMercadoria: false, cst: false, csosn: false, modalidadeIcms: false, percentualReducaoBcIcms: false,
    valorBcIcms: false, aliquotaIcms: false, valorIcmsOperacao: false, percentualDiferimento: false,
    valorIcmsDiferido: false, valorIcms: false, baseCalculoFcp: false, percentualFcp: false,
    valorFcp: false, modalidadeIcmsSt: false, percentualMargemValorAdicionadoIcmsSt: false,
    percentualReducaoBcIcmsSt: false, valorBcIcmsSt: false, aliquotaIcmsSt: false, valorIcmsSt: false,
    valorBcFcpRetidoSt: false, percentualBcFcpRetidoSt: false, valorFcpRetidoSt: false, percentualBcOperacaoPropria: false,
    ufIcmsStDevido: false, valorBcIcmsStRetido: false, aliquotaConsumidorFinal: false, valorIcmsProprioSubstituto: false,
    valorIcmsStRetido: false, valorBcFcpRetidoAnteriormenteSt: false, percentualBcFcpRetidoAnteriormenteSt: false,
    valorFcpRetidoAnteriormenteSt: false, valorIcmsDesonerado: false, motivoDesoneracaoIcms: false,
    aliquotaCreditoSimples: false, valorCreditoIcms: false, valorBcIcmsStUfDestino: false, valorIcmsStUfDestino: false,
    percentualReducaoBcEfetiva: false, valorBcEfetiva: false, aliquotaIcmsEfetiva: false, valorIcmsEfetivo: false,
  },
};

export const CAMPOS_DADOS_ICMS_INTERESTADUAL = {
  dadosIcmsInterestadual: {
    valorBcIcmsUfDestino: false, valorBcFcpUfDestino: false, percentualIcmsFcpUfDestino: false,
    aliquotaInternaUfDestino: false, aliquotaInterestadualUfsEnvolvidas: false, percentualPartilhaIcmsInterestadual: false,
    valorIcmsFcpUfDestino: false, valorIcmsInterestadualUfDestino: false, valorIcmsInterestadualUfRemetente: false,
  },
};

export const CAMPOS_DADOS_IPI = {
  dadosIpi: {
    cnpjProdutor: false, codigoSeloControle: false, qtdSeloControle: false,
    codigoEnquadramento: false, cstIpi: false, valorBcIpi: false, qtdUnidadePadrao: false,
    valorUnidadeTributavelIpi: false, aliquotaIpi: false, valorIpi: false,
  },
};

export const CAMPOS_DADOS_PIS = {
  dadosPis: {
    cstPis: false, valorBcPis: false, aliquotaPercentualPis: false,
    valorPis: false, qtdVendidaPis: false, aliquotaReaisPis: false,
  },
};

export const CAMPOS_DADOS_COFINS = {
  dadosCofins: {
    cstCofins: false, valorBcCofins: false, aliquotaPercentualCofins: false,
    qtdVendidaCofins: false, aliquotaReaisCofins: false, valorCofins: false,
  },
};

export const CAMPOS_COBRANCA = {
  cobranca: {
    faturaNumero: false, faturaValorOriginal: false, faturaValorDesconto: false, faturaValorLiquido: false,
  },
  parcela: {
    parcelaNumero: false, parcelaDataVencimento: false, parcelaValor: false,
  },
};

export const CAMPOS_PAGAMENTO = {
  pagamento: {
    forma: false, meio: false, valorPagamento: false, troco: false,
  },
  cartao: {
    tipoIntegracao: false, cnpjCredenciadora: false, bandeira: false, numAutorizacao: false,
  },
};

export const CAMPOS_LOCAL_RETIRADA = {
  localRetirada: {
    localRetiradaCnpj: false, localRetiradaCpf: false, localRetiradaRazaoSocial: false, localRetiradaLogr: false,
    localRetiradaNum: false, localRetiradaCompl: false, localRetiradaBairro: false, localRetiradaMun: false,
    localRetiradaUf: false, localRetiradaCep: false, localRetiradaPais: false, localRetiradaFone: false,
    localRetiradaEmail: false, localRetiradaIe: false,
  },
};

export const CAMPOS_LOCAL_ENTREGA = {
  localEntrega: {
    localEntregaCnpj: false, localEntregaCpf: false, localEntregaRazaoSocial: false, localEntregaLogr: false,
    localEntregaNum: false, localEntregaCompl: false, localEntregaBairro: false, localEntregaMun: false,
    localEntregaUf: false, localEntregaCep: false, localEntregaPais: false, localEntregaFone: false,
    localEntregaEmail: false, localEntregaIe: false,
  },
};
