// @flow
'use strict';

import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfe from '../form_exportacao_nfe/campos_nfe/selecao_todos_nfe';
import { Input } from '../../../../../common/input';

type CamposItemProps = {
  component : React.ComponentType<*>,
}

export default function CamposPagamento({ component } : CamposItemProps) {
  const SelecaoTodos = component || SelecaoTodosNfe;

  return (
    <fieldset className="grupo-download-lista">
      <legend>Pagamentos</legend>
      <p className="instrucao-em-titulo">A lista retornará uma linha para cada pagamento.</p>

      <Row>
        <Col md={ 6 }>
          <FormSection name="pagamento">
            <SelecaoTodos nomes={ ["pagamento"] } titulo="Dados dos Pagamentos"/>

            <div className="grupo-checkbox">
              <Input name="forma" label="Forma de Pagamento" checkbox/>
              <Input name="meio" label="Meio de Pagamento" checkbox/>
              <Input name="valorPagamento" label="Valor do Pagamento" checkbox/>
              <Input name="troco" label="Valor do Troco" checkbox/>
            </div>
          </FormSection>
        </Col>
        <Col md={ 6 }>
          <FormSection name="cartao">
            <SelecaoTodos nomes={ ["cartao"] } titulo="Dados dos Cartões"/>

            <div className="grupo-checkbox">
              <Input name="tipoIntegracao" label="Tipo Integração" checkbox/>
              <Input name="cnpjCredenciadora" label="CNPJ Credenciadora" checkbox/>
              <Input name="bandeira" label="Bandeira" checkbox/>
              <Input name="numAutorizacao" label="Núm. Autorização" checkbox/>
            </div>
          </FormSection>
        </Col>
      </Row>
    </fieldset>
  );
}
