// @flow
'use strict';

import * as React from 'react';

import type { IIcms90 } from '../../../types_imposto';

import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';

type Icms90Props = {
  icms : IIcms90,
};

export default function Icms90({ icms } : Icms90Props) {
  return (
      <>
        <h4>ICMS - 90 = Outros</h4>
        <RowDetalhes data={ icms } className="no-margin-bottom">
          <ColumnDetalhes field="cst" width="50%">Classificação Tributária do Serviço</ColumnDetalhes>
          <ColumnDetalhes field="percReducaoBc" type="percentual" width="50%">Percentual da Redução da Base de Cálculo</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ icms } className="no-margin-bottom">
          <ColumnDetalhes field="valorBc" type="moeda" width="50%">Valor da Base de Cálculoo</ColumnDetalhes>
          <ColumnDetalhes field="aliquota" type="percentual" width="50%">Alíquota do ICMS</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ icms }>
          <ColumnDetalhes field="valor" type="moeda" width="50%">Valor do ICMS</ColumnDetalhes>
          <ColumnDetalhes field="valorCredito" type="moeda">Valor do Crédito</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
