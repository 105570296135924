// @flow

import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ReactHtmlParser from 'react-html-parser';

import type { Noticia } from './tabela_noticias';
import dateFormat from '../../../../lib/dateFormat';
import { fechaNoticiaNoModal, openModalSelector, itemSelector } from '../../../../reducers/dashboard/modal_noticia';
import { jsSelector } from '../../../../lib/viewUtils';

type Props = {
  open : bool,
  fechaNoticiaNoModal : () => void,
  noticia : ?Noticia,
}

export function ModalNoticia({ open, fechaNoticiaNoModal, noticia } : Props) {
  if (!noticia)
    return <span/>;

  return (
      <Modal show={ open } onHide={ fechaNoticiaNoModal }>
        <Modal.Header closeButton>
          <Modal.Title className="titulo-noticia">{ noticia.titulo }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { noticia.texto && <p className="texto-noticia">{ ReactHtmlParser(noticia.texto) }</p> }
          { noticia.data &&
          <React.Fragment>
            <p className="data-noticia pull-right">{ `Data: ${ dateFormat(noticia.data) }` }</p>
            <div className="clearfix"/>
          </React.Fragment> }
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={ fechaNoticiaNoModal }>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

const mapStateToProps = createStructuredSelector({
  open: openModalSelector,
  noticia: jsSelector(itemSelector),
});

export default connect<*, *, *, *, *, *>(mapStateToProps, { fechaNoticiaNoModal })(ModalNoticia);