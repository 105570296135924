// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosCte from './selecao_todos_cte';
import { Input } from '../../../../../../common/input';

export default function CamposDesacordoServico() {
    return (
        <FormSection name="desacordoServico">
            <SelecaoTodosCte nomes={ ["desacordoServico"] } titulo="Desacordo do Serviço"/>

            <div className="grupo-checkbox">
                <Input name="desacordoRealizadoEm" label="Data" checkbox/>
                <Input name="desacordoServico" label="Situação" checkbox/>
            </div>
        </FormSection>
    );
}