// @flow
'use strict';

import * as React from 'react';
import * as Immutable from 'immutable';

import { applyRouterMiddleware, Router } from 'react-router';
import { useScroll } from 'react-router-scroll';
import { connect, Provider } from 'react-redux';

import store, { history } from '../stores/redux_store';

/**
 * Monta um componente "raiz", que pode ser inserido diretamente na página
 * e já fica configurado com o react-redux e o react-router.
 */
export function makeRootComponent(routes : any) {
  // $FlowIgnore
  return withProvider()(() => <Router history={ history } render={ applyRouterMiddleware(useScroll()) } routes={ routes }/>);
}

/**
 * Monta um componente vinculado à store do Redux.
 */
export function withProvider<Config: {}>() : any {
  return function(Wrapped : React.AbstractComponent<Config>) : React.AbstractComponent<Config> {
    return function Wrapper(props : Config) {
      return <Provider store={ store }><Wrapped { ...props }/></Provider>;
    };
  };
}

/**
 * Similar ao "connect" do react-redux, com 2 diferenças:
 * * se o "mapStateToProps" for string, array ou um objeto, as chaves do estado informadas
 *   serão adicionadas aos props do componente; e
 * * a implementação padrão de "mergeProps" agrupa as actions em uma prop "actions".
 */
export function connectEx<T, U>(mapStateToProps : string | string[] | null | (t : T) => U, mapDispatchToProps : *, mergeProps : * = DEFAULT_MERGE) {
  if (typeof mapStateToProps === 'string')
    mapStateToProps = [mapStateToProps];
  if (Array.isArray(mapStateToProps))
    mapStateToProps = makeStoreExtractor(...mapStateToProps);

  return connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps, mergeProps);
}

function makeStoreExtractor<T : Immutable.Map, U = {}>(...keys : string[]) : (store : T) => U {
  return store => Object.assign({}, ...keys.map(k => store.get(k, Immutable.Map()).toJS()));
}

export function DEFAULT_MERGE(stateProps : *, dispatchProps : *, ownProps : *) {
  return { ...ownProps, ...stateProps, actions: dispatchProps };
}
