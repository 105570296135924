'use strict';
// @flow

import React from 'react';
import classnames from 'classnames';

import { Alert } from 'react-bootstrap';

import Icone from './icone';
import Markdown from './markdown';

type AlertWithIconProps = {
  bsStyle? : string,
  icon : string,
  className? : string,
  children? : any,
  markdown? : string
};

export default function AlertWithIcon({ icon, children, markdown, className, ...more } : AlertWithIconProps) {
  const body = children || (markdown && <Markdown text={ markdown } breaks/>);

  const alertProps = { ...more, className: classnames('alert-with-icon', className) };

  return (
      <Alert { ...alertProps } >
        <table>
          <tbody>
          <tr>
            <td width={ 45 }>
              <Icone nome={ icon } estilo="fas" tamanho={2}/>
            </td>
            <td>{ body }</td>
          </tr>
          </tbody>
        </table>
      </Alert>
  );
}
