// @flow
'use strict';

import * as React from 'react';

import type { ICofinsOutr } from '../../../../../types_imposto';

import RowDetalhes from '../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../common/detalhes_documento/column_detalhes';
import TributoAliquota from './tributo_aliquota';
import TributoQuantidade from './tributo_quantidade';
import ValorCofins from './valor_cofins';

type CofinsOutrProps = {
  item : ICofinsOutr,
};

export default function CofinsOutr({ item } : CofinsOutrProps) {
  if (item.valorBc && item.qtdVendida) {
    return (
        <>
          <RowDetalhes data={ item }>
            <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
            <ColumnDetalhes field="valorBc" type="moeda" width="25%">Valor da BC COFINS</ColumnDetalhes>
            <ColumnDetalhes field="percAliquota" type="percentual" width="25%">Alíquota COFINS (em percentual)</ColumnDetalhes>
            <ColumnDetalhes field="qtdVendida" width="25%">Quantidade Vendida</ColumnDetalhes>
          </RowDetalhes>
          <RowDetalhes data={ item }>
            <ColumnDetalhes field="valorAliquota" type="moeda" width="25%">Alíquota COFINS (em reais)</ColumnDetalhes>
            <ColumnDetalhes field="valor" type="moeda" width="75%">Valor COFINS</ColumnDetalhes>
          </RowDetalhes>
        </>
    );
  }

  if (item.valorBc)
    return <TributoAliquota item={ item }/>;

  if (item.qtdVendida)
    return <TributoQuantidade item={ item }/>;

  return <ValorCofins item={ item }/>;
}
