// @flow
'use strict';

import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import type { IDestinatario, IEmitente, ItemProps } from '../types';

import Cfe from './cfe';
import Emitente from './emitente';
import Destinatario from './destinatario';
import Produto from './produto';
import Totais from './totais';
import InformacoesAdicionais from './informacoes_adicionais';

export default function AbasInformacoes({ item } : ItemProps) {
  if (item.possuiXml) {
    return (
        <Tabs id="tab-detalhes-cfe" defaultActiveKey="cfe">
          <Tab eventKey="cfe" title="CF-e">
            <Cfe item={ item }/>
          </Tab>
          <Tab eventKey="emitente" title="Emit.">
            <Emitente emitente={ item.emitente }/>
          </Tab>
          <Tab eventKey="destinatario" title="Dest.">
            <Destinatario destinatario={ item.destinatario }/>
          </Tab>
          <Tab eventKey="produto-servico" title="Produtos">
            <Produto produto={ item.produto }/>
          </Tab>
          <Tab eventKey="totais" title="Totais">
            <Totais totais={ item.totais }/>
          </Tab>
          <Tab eventKey="informacoes-adicionais" title="Inf. Adicionais">
            <InformacoesAdicionais informacoesAdicionais={ item.informacoesAdicionais }/>
          </Tab>
        </Tabs>
    );
  }

  return (
      <Tabs id="tab-detalhes-cfe" defaultActiveKey="cfe">
        <Tab eventKey="cfe" title="CF-e">
          <Cfe item={ item }/>
        </Tab>
      </Tabs>
  );
}

export function tipoDocumento(ator : IDestinatario | IEmitente) : string {
  if (!ator || !ator.cnpj)
    return 'CNPJ';

  if (ator.cnpj.length === 11)
    return 'CPF';

  if (ator.cnpj.length === 14)
    return 'CNPJ';

  return 'ID Estrangeiro';
}
