// @flow
'use strict';

import * as React from 'react';

import type { ItemProps } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';

export default function Nfse({ item } : ItemProps) {
  return (
      <div className="tab-pane active">
        <h3>Dados da NFS-e</h3>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="numero" width="25%">Número</ColumnDetalhes>
          <ColumnDetalhes field="codigoVerificacao" width="25%">Código de verificação</ColumnDetalhes>
          <ColumnDetalhes field="emitidoEm" type="data" width="25%">Data de emissão</ColumnDetalhes>
          <ColumnDetalhes field="statusSefaz" type="situacao" width="25%">Situação</ColumnDetalhes>
        </RowDetalhes>

        { item.canceladoEm &&
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="canceladoEm" type="data">Cancelado em</ColumnDetalhes>
        </RowDetalhes> }

        <RowDetalhes data={ item }>
          <ColumnDetalhes field="discriminacao" width="75%">Discriminação do serviço</ColumnDetalhes>
          <ColumnDetalhes field="valor" type="moeda" width="25%">Valor do serviço</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}