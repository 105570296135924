'use strict';
// @flow

import _ from 'lodash';
import React from 'react';
import { Panel } from 'react-bootstrap';

import DefinitionList from 'components/common/definition_list';

import { formatHelper } from 'lib/viewUtils';
import { LOADING } from 'i18n/constants';

import type { IEmpresa } from '../types';

const EMPTY = { nome: LOADING, cnpj: LOADING, ie: LOADING, uf: LOADING };

export default function DadosEmpresa({ empresa } : { empresa? : IEmpresa }) {
  if (!empresa)
    empresa = EMPTY;

  return (
      <Panel className="form-horizontal">
        <Panel.Heading>Empresa</Panel.Heading>
        <Panel.Body>
          <DefinitionList>
            { { label: 'Nome', value: empresa.nome } }
            { { label: 'CNPJ', value: formatHelper.formatId(empresa.cnpj) } }
            { { label: 'Cidade/UF', value: _([_.get(empresa, 'municipio.nome'), empresa.uf]).compact().join(' / ') } }
            { { label: 'IE', value: empresa.ie } }
          </DefinitionList>
        </Panel.Body>
      </Panel>
  );
}
