// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fechaHistorico, itemSelector, modalOpenSelector } from '../../../../reducers/cfe/historico';
import { jsSelector } from 'lib/viewUtils';

import ModalHistorico from '../../../common/detalhes_documento/modal_historico';

const mapStateToProps    = createStructuredSelector({
        open: modalOpenSelector, item: jsSelector(itemSelector),
      }),
      mapDispatchToProps = {
        fechaModal: fechaHistorico,
      };

export default connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(ModalHistorico);
