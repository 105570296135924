// @flow
'use strict';

import * as Immutable from 'immutable';
import url from 'url';
import { push } from 'react-router-redux';

import { prepareQuery } from 'lib/viewUtils';
import type { Action } from 'lib/types';

// //////////////////
// CONSTANTES

export const LISTA_CARREGANDO = 'emails/LISTA_CARREGANDO',
             LISTA_CARREGADA  = 'emails/LISTA_CARREGADA',
             ATUALIZA_LISTA   = 'emails/ATUALIZA_LISTA',
             ATUALIZA_CAIXAS  = 'emails/ATUALIZA_CAIXAS';

export const BASE_PATH = '/app/ferramentas/emails';

const DEFAULT_STATE = Immutable.fromJS({
  listagem: {},
  caixas: [],
  carregando: false,
  erro: undefined,
});

// ////////////
// REDUCER

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action = {}) {
  switch (action.type) {
    case LISTA_CARREGANDO:
      return state.set('carregando', true);
    case LISTA_CARREGADA:
      return state.set('carregando', false);
    case ATUALIZA_LISTA:
      return state.set('listagem', Immutable.fromJS(action.listagem));
    case ATUALIZA_CAIXAS:
      return state.set('caixas', Immutable.fromJS(action.payload));

    default:
      return state;
  }
}

// //////////////////
// AÇÕES

export function listagem(query : {}) {
  return push(url.format({ pathname: BASE_PATH, query: prepareQuery(query) }));
}

export function detalhes(id : string, params : {}) {
  return function() {
    if (!id)
      throw new Error('ID é obrigatório');

    // necessário usar window.location, pois a tela de detalhes não está ainda em React
    window.location = url.format({ pathname: `${ BASE_PATH }/${ id }`, query: prepareQuery(params) });
  };
}
