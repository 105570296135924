// @flow
'use strict';

import * as React from 'react';

import type { IEndereco } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';

export default function Endereco({ item } : { item : IEndereco }) {
  return (
      <React.Fragment>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="endereco" width="45%">Endereço</ColumnDetalhes>
          <ColumnDetalhes field="bairro" width="20%">Bairro</ColumnDetalhes>
          <ColumnDetalhes field="cidade" width="20%">Cidade</ColumnDetalhes>
          <ColumnDetalhes field="uf" width="5%">UF</ColumnDetalhes>
          <ColumnDetalhes field="cep" type="cep" width="10%">CEP</ColumnDetalhes>
        </RowDetalhes>
      </React.Fragment>
  );
}