// @flow
'use strict';

import * as React from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { openSelector } from 'reducers/nfe/modal_manifestacao_lote';

import ModalManifestacaoLote from '../modal_manifestacao_lote';
import BotaoOpcaoCienciaOperacao from './botao_opcao_ciencia_operacao';
import BotaoOpcaoConfirmada from './botao_opcao_confirmada';
import BotaoOpcaoOperacaoNaoRealizada from './botao_opcao_operacao_nao_realizada';
import BotaoOpcaoDesconhecida from './botao_opcao_desconhecida';
import Icone from '../../../../common/icone';

export default function BotaoManifestacaoLote() {
  const open = useSelector(openSelector);

  return (
      <>
        <Dropdown dropup id="botao-manifestacao-lote">
          <Dropdown.Toggle bsStyle="primary" id="dropdownMenuButton">
            <Icone nome="gavel" estilo="fas" flip="horizontal">Manifestação</Icone>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <MenuItem><BotaoOpcaoCienciaOperacao/></MenuItem>
            <MenuItem><BotaoOpcaoConfirmada/></MenuItem>
            <MenuItem><BotaoOpcaoOperacaoNaoRealizada/></MenuItem>
            <MenuItem><BotaoOpcaoDesconhecida/></MenuItem>
          </Dropdown.Menu>
        </Dropdown> { ' ' }

        { open && <ModalManifestacaoLote/> }
      </>
  );
}
