// @flow

import * as Immutable from 'immutable';

import type { State, Action } from '../../lib/types';

export const ALTERA_URL = 'cte/manifestacao/ALTERA_URL';

const DEFAULT_STATE = Immutable.fromJS({ url: '', observacoesTomador: '' });

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action) {
  switch (action.type) {
    case ALTERA_URL:
      return state.set('url', action.url);
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['cte', 'manifestacao'], DEFAULT_STATE);

// action creators

export function alteraUrl(url : string) {
  return { type: ALTERA_URL, url };
}