'use strict';
// @flow

import url from 'url';
import { browserHistory } from 'react-router';

import { prepareQuery } from 'lib/viewUtils';

export const uploadPath = {
  base: `/app/ferramentas/cargas_upload`,
  colecao: (params : {} = {}) => url.format({ pathname: uploadPath.base, query: prepareQuery(params) }),
  entidade: (id : string, params : {} = {}) => {
    if (!id)
      throw new Error('ID é obrigatório');
    return url.format({ pathname: `${uploadPath.base}/${id}`, query: prepareQuery(params) });
  },
  download: (id : string, query? : {}) => {
    if (!id)
      throw new Error('ID é obrigatório');
    return url.format({ pathname: `${uploadPath.base}/${id}/download`, query });
  }
};

class UploadNav {
  listagemParams = {};
  detalhesParams = {};

  listagem = (params : {} = {}) => {
    const p = Object.assign(this.listagemParams, params);

    browserHistory.push(uploadPath.colecao(p));
  };

  detalhes = (id : string, params? : {}) => {
    const p = this.detalhesParams[id] = Object.assign(this.detalhesParams[id] || {}, params || {});

    browserHistory.push(uploadPath.entidade(id, p));
  };
}

export const uploadNav = new UploadNav();
