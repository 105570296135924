'use strict';
// @flow

import * as React from 'react';

import { useEffectOnce } from 'lib/viewUtils';

import BuscaBasica from './busca_basica';
import BuscaAvancada from './busca_avancada';

type FiltrosProps = {
  router : { location : { query : {} } },
  filtroAtual : 'estruturado' | 'livre',

  inicializaFiltros : (query : {}) => void,
  aplicaFiltro : () => void,
};

export default function Filtros({ filtroAtual, router, aplicaFiltro, inicializaFiltros } : FiltrosProps) {
  useEffectOnce(() => {
    if (router)
      inicializaFiltros(router.location.query);
  });

  const handleSubmit = React.useCallback((e : SyntheticEvent<*>) => {
    e.preventDefault();
    aplicaFiltro();
  }, [aplicaFiltro]);

  return (
      <form className="sped-filtros" method="get" role="search" onSubmit={ handleSubmit }>
        { filtroAtual === 'livre' ? <BuscaAvancada/> : <BuscaBasica/> }
      </form>
  );
}