// @flow
'use strict';

import React from 'react';
import { Modal } from 'react-bootstrap';

import request from 'lib/request';
import { useEffectOnce } from 'lib/viewUtils';
import { ajaxError } from 'reducers/alerts';

import Alerta from './alerta';
import ModalTipoExportacao from './modal_tipo_exportacao';

type ModalProps = {
  tipo : string,
  query : any,
  open : bool,
  fechaModal : () => void,
  ajaxError : typeof ajaxError,
}

const NUM_MAXIMO_DOCS = 1000000;

export default function ModalDownloadLista({ open, query, fechaModal, tipo, ajaxError } : ModalProps) {
  const [totalDocumentos, setTotalDocumentos] = React.useState(0),
        [carregado, setCarregado]             = React.useState(false);

  useEffectOnce(() => {
    setCarregado(false);
    request.get(`/app/${ tipo }/exporta`)
        .accept('json')
        .query(query)
        .then(r => {
          setTotalDocumentos(r.body.totalDocumentos);
          setCarregado(true);
        })
        .catch(e => {
          ajaxError('Erro na consulta de documentos para exportação', e);
          fechaModal();
        });
  });

  return (
      <Modal show={ open } onHide={ fechaModal } dialogClassName="custom-modal">
        { (!carregado || totalDocumentos > NUM_MAXIMO_DOCS) ?
            <Alerta fechaModal={ fechaModal } carregado={ carregado }/> :
            <ModalTipoExportacao totalDocumentos={ totalDocumentos } tipo={ tipo }/> }
      </Modal>
  );
}
