// @flow
'use strict';

import * as React from 'react';
import { Col, ControlLabel, Row } from 'react-bootstrap';
import { FormSection } from 'redux-form/immutable';

import CampoIntervalo from '../../../common/campo_intervalo';
import CampoIntervaloData from '../../../common/campos_busca_estruturada/campo_intervalo_data';
import CampoTexto from '../../../common/campo_texto';
import { InputForm, Input } from '../../../common/input';

export default function CamposDocumentos() {
  return (
      <>
        <legend>Documentos</legend>
        <Row className="campo-formulario">
          <ControlLabel className="col-sm-3">Chave</ControlLabel>
          <Input className="col-sm-9 no-padding-left" style={ { height: '34px' } } name="chave" label="Chave"
                 placeholder="Insira uma chave por linha" component={ CampoTexto } livre/>
        </Row>
        <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="emitente" label="Emitente"
                   placeholder="nome ou CNPJ"/>
        <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="destinatario"
                   label="Destinatário" placeholder="nome ou CNPJ"/>
        <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="transportador"
                   label="Transportador" placeholder="nome ou CNPJ"/>
        <CampoIntervaloData columnClassName="no-padding-left" rotulo="Emissão" nome="emitido_em"/>
        <CampoIntervaloData columnClassName="no-padding-left" rotulo="Recepção" nome="recebido_em"/>
        <CampoIntervalo columnClassName="no-padding-left" rotulo="Série" nome="serie"/>
        <CampoIntervalo columnClassName="no-padding-left" rotulo="Número" nome="numero"/>
        <CampoIntervalo columnClassName="no-padding-left" rotulo="Valor" nome="valor"/>

        <FormSection name="tipo_operacao_id">
          <Row className="campo-formulario">
            <ControlLabel className="col-sm-3">Operação</ControlLabel>
            <Col sm={ 9 } className="opcao-checkbox no-padding-left">
              <Row style={ { paddingTop: '.6em', paddingBottom: '.5em' } }>
                <Col xs={ 3 } style={ { width: 'auto' } }><Input name="entrada" label="Entrada" checkbox/></Col>
                <Col xs={ 3 } style={ { width: 'auto' } }><Input name="saida" label="Saída" checkbox/></Col>
              </Row>
            </Col>
          </Row>
        </FormSection>
      </>
  );
}
