// @flow
'use strict';

import * as React from 'react';

import type { ICobranca } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../common/detalhes_documento/table_detalhes';

type CobrancaProps = {
  cobranca : ICobranca,
};

export default function Cobranca({ cobranca } : CobrancaProps) {
  return (
      <div className="tab-pane active">
        <h3>Informações de Pagamento</h3>
        <RowDetalhes data={ cobranca }>
          <ColumnDetalhes field="formaPagamento">Forma de Pagamento</ColumnDetalhes>
          <ColumnDetalhes field="meioPagamento">Meio de Pagamento</ColumnDetalhes>
          <ColumnDetalhes field="valorPagamento" type="moeda">Valor do Pagamento</ColumnDetalhes>
        </RowDetalhes>

        { cobranca && cobranca.cartoes && cobranca.cartoes.length > 0 &&
        <div>
          <h3>Cartões</h3>
          <TableDetalhes data={ cobranca.cartoes }>
            <ColumnDetalhes field="tipoIntegracao" width="20%">Tipo de Integração Pagamento</ColumnDetalhes>
            <ColumnDetalhes field="cnpjCredenciadora" type="documento">CNPJ da Credenciadora</ColumnDetalhes>
            <ColumnDetalhes field="bandeira">Bandeira da Operadora</ColumnDetalhes>
            <ColumnDetalhes field="numeroAutorizacao">Nº Autorização da Operação</ColumnDetalhes>
            <ColumnDetalhes field="valorTroco" type="moeda">Valor do Troco</ColumnDetalhes>
          </TableDetalhes>
        </div>
        }
      </div>
  );
}