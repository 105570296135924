// @flow
'use strict';

import * as React from 'react';

import type { IIcms00 } from '../../../../../../types_imposto';

import RowDetalhes from '../../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../../common/detalhes_documento/column_detalhes';

type Icms00Props = {
  item : IIcms00,
};

export default function Icms00({ item } : Icms00Props) {
  return (
      <div>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="origem" width="25%">Origem da Mercadoria</ColumnDetalhes>
          <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
          <ColumnDetalhes field="determinacaoBcIcms" width="25%">Determinação da BC</ColumnDetalhes>
          <ColumnDetalhes field="valorBcIcms" type="moeda" width="25%">Base de Cálculo</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="aliquotaIcms" type="percentual" width="25%">Alíquota</ColumnDetalhes>
          <ColumnDetalhes field="valorIcms" type="moeda" width="25%">Valor do ICMS</ColumnDetalhes>
          <ColumnDetalhes field="percentualFcp" type="percentual" width="25%">Percentual FCP</ColumnDetalhes>
          <ColumnDetalhes field="valorFcp" type="moeda" width="25%">Valor FCP</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
