// @flow
'use strict';

import * as React from 'react';

import type { IDestinatario, IPessoaAutorizada } from '../types';
import { tipoDocumento } from './abas_informacoes';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../common/detalhes_documento/table_detalhes';

type DestinatarioProps = {
  destinatario : IDestinatario,
  pessoasAutorizadas : IPessoaAutorizada[],
};

export default function Destinatario({ destinatario, pessoasAutorizadas } : DestinatarioProps) {
  return (
      <div className="tab-pane active">
        <h3>Destinatário</h3>
        <RowDetalhes data={ destinatario } className="no-margin-bottom">
          <ColumnDetalhes field="cnpj" type="documento" width="50%">{ tipoDocumento(destinatario) }</ColumnDetalhes>
          <ColumnDetalhes field="nome" width="50%">Nome / Razão Social</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ destinatario } className="no-margin-bottom">
          <ColumnDetalhes field="endereco" width="50%">Endereço</ColumnDetalhes>
          <ColumnDetalhes field="bairro" width="50%">Bairro</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ destinatario } className="no-margin-bottom">
          <ColumnDetalhes field="municipio" width="50%">Município</ColumnDetalhes>
          <ColumnDetalhes field="uf" width="50%">UF</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ destinatario } className="no-margin-bottom">
          <ColumnDetalhes field="cep" type="cep" width="50%">CEP</ColumnDetalhes>
          <ColumnDetalhes field="pais" width="50%">País</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ destinatario } className="no-margin-bottom">
          <ColumnDetalhes field="telefone" width="50%">Telefone</ColumnDetalhes>
          <ColumnDetalhes field="indIeDest" width="50%">Indicador da IE</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ destinatario } className="no-margin-bottom">
          <ColumnDetalhes field="inscricaoEstadual" width="50%">Inscrição Estadual</ColumnDetalhes>
          <ColumnDetalhes field="inscricaoSuframa" width="50%">Inscrição SUFRAMA</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ destinatario }>
          <ColumnDetalhes field="inscricaoMunicipal" width="50%">Inscrição Municipal</ColumnDetalhes>
          <ColumnDetalhes field="email" width="50%" className="quebra-de-linha">E-mail</ColumnDetalhes>
        </RowDetalhes>

        <h3>Pessoas Autorizadas</h3>
        <h4>Pessoas autorizadas a receber o XML da NF-e.</h4>
        <TableDetalhes data={ pessoasAutorizadas } noDataClass="padding-10" noDataMsg="Nenhuma pessoa autorizada informada na NF-e.">
          <ColumnDetalhes field="documento">CNPJ/CPF Autorizado</ColumnDetalhes>
        </TableDetalhes>
      </div>
  );
}
