// @flow
'use strict';

import * as Immutable from 'immutable';
import * as Url from 'url';
import { type Saga } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import noCache from 'superagent-no-cache';
import { push } from 'react-router-redux';

import request from 'lib/request';
import { currentRouteSelector } from 'reducers/routing';

export default function* crud() : Saga<*> {
  yield takeLatest(filtroCarrega, carrega);
  yield takeLatest(filtroTrocaUrl, trocaUrl);
  yield takeLatest(filtroSalva, salva);
}

function* carrega({ type } : { type : string }) : Generator<*, *, *> {
  try {
    // obtém o JSON para a URL atual
    const body = yield call(jsonParaUrlAtual);

    // faz o dispatch de uma ação com o mesmo nome, mas com o sufixo '_OK'
    // e com uma propriedade 'body' contendo o JSON retornado convertido em Immutable
    yield put({ type: `${ type }_OK`, body });
  }
  catch (error) {
    yield put({ type: `${ type }_ERRO`, error });
  }
}

/**
 * Filtro aplicado às ações, para detectar quais devem ser carregadas através da URL.
 *
 * Atualmente filtra apenas as ações prefixada com 'admin/'
 */
function filtroCarrega({ type } : { type : string }) {
  return /^admin\/.*\/FETCH_(LIST|RESOURCE)$/.test(type) && !/_(OK|ERRO)$/.test(type);
}

/**
 * Filtro aplicado às ações, para detectar quais devem trocar a página atual.
 */
function filtroTrocaUrl({ type } : { type : string }) {
  return /^admin\/.*\/(PAGINATE|QUICK_SEARCH)$/.test(type);
}

/**
 * Filtro aplicado às ações, para detectar quais devem salvar o recurso atual.
 */
function filtroSalva({ type } : { type : string }) {
  return /^admin\/.*\/SAVE$/.test(type);
}

/**
 * Faz uma solicitação da URL atual, mas pedindo dados JSON.
 */
async function jsonParaUrlAtual() {
  const r = await request.get(window.location.href).use(noCache).accept('json');
  return Immutable.fromJS(r.body);
}

// noinspection JSUnusedLocalSymbols
function* trocaUrl({ type, then, ...query }) : Generator<*, *, *> { // eslint-disable-line no-unused-vars
  const currRoute    = yield select(currentRouteSelector),
        pathname     = currRoute.get('pathname'),
        currentQuery = currRoute.get('query').toJS();
  yield put(push(Url.format({ pathname, query: { ...currentQuery, ...query } })));
  if (then)
    yield put({ type: then });
}

/**
 * Faz o salvamento da página atual. A URL utilizada para o salvamento é baseada em 2 testes:
 * - se o payload tem "id", é utilizado o método PATCH - caso contrário, é utilizado POST;
 * - a URL é sempre a mesma atual, removendo o último segmento.
 *
 *     /resource/1/edit => PATCH /resource/1
 *     /resource/new    =>   GET /resource
 */
function* salva({ type, payload }) : Generator<*, *, *> {
  try {
    const url = window.location.pathname.replace(/\/[^/]+$/, '');
    const r = yield call(apiSave, url, payload);

    yield put({ type: `${ type }_OK`, result: r.body });
  }
  catch (e) {
    yield put({ type: `${ type }_ERROR`, result: e });
  }
}

function apiSave(url, payload) {
  if (typeof payload.toJS === 'function')
    payload = payload.toJS();

  let req = request.post(url);
  if (payload.id)
    req = req.set({ 'X-Http-Method-Override': 'patch' });

  return req.type('json').accept('json').send(payload);
}