'use strict';
// @flow

export class UnauthorizedError extends Error {
  constructor(message : string) {
    super(message);
    this.name = 'UnauthorizedError';
  }
}

export class ServerValidationError extends Error {
  body : any;

  constructor(message : string, body : any) {
    super(message);
    this.name = 'ServerValidationError';
    this.body = body;
  }
}

export class UnexpectedApiResponseError extends Error {
  body : any;

  constructor(message : string, body : any) {
    super(message);
    this.name = 'UnexpectedApiResponseError';
    this.body = body;
  }
}

export class HttpError extends Error {
  response : any;

  constructor(message : string, response : any) {
    super(message);
    this.name = 'HttpError';
    this.response = response;
  }
}

export class PopupBlockedError extends Error {
  constructor(message : string) {
    super(message);
    this.name = 'PopupBlockedError';
  }
}