'use strict';
// @flow

import * as React from 'react';
import { Table } from 'react-bootstrap';

import type { DataTableProps } from './types';

import TableBody from './table_body';
import DataRow from './data_row';
import TableHeader from './table_header';

/**
 * Uma tabela de dados.
 */
export default function DataTable({ data, children, headerComponent, rowComponent, bodyComponent, onRowClick, noRecordsFoundMessage, ...props } : DataTableProps<*>) {
  const columns : Array<React.Element<any>> = (function() {
    if (!children)
      return [];
    if (!Array.isArray(children))
      return [children];

    return children;
  })();

  const HC = headerComponent || TableHeader,
        BC = bodyComponent || TableBody;

  let key = 0;

  return (
      <Table { ...props }>
        <colgroup>
          { columns.map((c, i) => {
                if (c.props.colSpan > 1) {
                  key += c.props.colSpan;
                  return c.props.widthCells.map((w, j) => <col key={ i + j } style={ { width: w || undefined } }/>);
                }

                return <col key={ i + key } style={ { width: c.props.width || undefined } }/>;
              },
          ) }
        </colgroup>
        <HC columns={ columns }/>
        <BC columns={ columns } rowComponent={ rowComponent || DataRow } data={ data } onRowClick={ onRowClick }
            noRecordsFoundMessage={ noRecordsFoundMessage }/>
      </Table>
  );
}
DataTable.defaultProps = {
  headerComponent: TableHeader,
  rowComponent: DataRow,
  bodyComponent: TableBody,
};
