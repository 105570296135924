// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { jsSelector } from 'lib/viewUtils';
import { withProvider } from 'lib/reduxTools';

import * as listaFornecedor from 'reducers/ferramentas/prestador/lista';
import { carregaDetalhes } from 'reducers/ferramentas/prestador/detalhes';

import Lista from './lista';

const mapStateToProps    = createStructuredSelector({
        dados: jsSelector(listaFornecedor.currentPage),
      }),
      mapDispatchToProps = {
        trocaPagina: listaFornecedor.trocaPagina,
        carregaLista: listaFornecedor.carregaLista,
        handleRowClick: fornecedor => fornecedor && carregaDetalhes(fornecedor.id),
      };

export default compose(
    withProvider(),
    connect(mapStateToProps, mapDispatchToProps),
)(Lista);
