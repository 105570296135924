// @flow
'use strict';

import * as React from 'react';

import type { IRepasseIcmsSt } from '../../../../../types_imposto';

import RowDetalhes from '../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../common/detalhes_documento/column_detalhes';

type RepasseIcmsStProps = {
  item : IRepasseIcmsSt,
};

export default function RepasseIcmsSt({ item } : RepasseIcmsStProps) {
  return (
      <div id="table-repasse-icms-st">
        <h4>Repasse do ICMS ST</h4>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="origem" width="25%">Origem da mercadoria</ColumnDetalhes>
          <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
          <ColumnDetalhes field="bcIcmsStRetidoUFRemetente" type="moeda" width="25%">BC ICMS ST retido na UF remetente</ColumnDetalhes>
          <ColumnDetalhes field="icmsStRetidoUFRemetente" type="moeda" width="25%">ICMS ST retido na UF remetente</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="bcIcmsStUFDestino" type="moeda" width="25%">BC ICMS ST da UF de destino</ColumnDetalhes>
          <ColumnDetalhes field="icmsStUFDestino" type="moeda" width="75%">ICMS ST da UF de destino</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
