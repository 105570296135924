// @flow
'use strict';

import * as React from 'react';

import type { IEndereco } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';

type EnderecoProps = {
  endereco : IEndereco,
};

export default function Endereco({ endereco } : EnderecoProps) {
  return (
      <div>
        <h3>Endereço</h3>
        <RowDetalhes data={ endereco }>
          <ColumnDetalhes field="logradouro" width="50%">Logradouro</ColumnDetalhes>
          <ColumnDetalhes field="numero" width="50%">Número</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ endereco }>
          <ColumnDetalhes field="complemento" width="50%">Complemento</ColumnDetalhes>
          <ColumnDetalhes field="bairro" width="50%">Bairro</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ endereco }>
          <ColumnDetalhes field="municipio" width="50%">Município</ColumnDetalhes>
          <ColumnDetalhes field="uf" width="50%">UF</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ endereco }>
          <ColumnDetalhes field="cep" type="cep" width="50%">CEP</ColumnDetalhes>
          <ColumnDetalhes field="pais" width="50%">País</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ endereco }>
          <ColumnDetalhes field="telefone">Telefone</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}