// @flow
'use strict';

import * as Immutable from 'immutable';
import { reset } from 'redux-form/immutable';

import type { Dispatch } from 'lib/types';
import request from 'lib/request';
import { flattenForm, formToQuery } from 'lib/viewUtils';
import { ajaxError, alert } from 'reducers/alerts';
import { exibeAlertas as exibeAlertasModeloRelatorio } from '../../../../../reducers/modelo_relatorio/salvar_relatorio';
import { fechaRelatorio } from '../../../../../reducers/modelo_relatorio/modal_escolha_tipo_relatorio';
import { fechaModal, exibeAlertas, fechaAlertas } from '../../../../../reducers/modal_exportacao_lista';

export function onSubmitForm(form : Immutable.Map, dispatch : Dispatch<*>) {
  const { query, ...rest } = form.toJS(),
        formExportacao     = { query, ...flattenForm(rest) };

  request.post(`/app/${ form.get('tipoDocumento') }/exporta`)
      .type('json').accept('json')
      .send(formToQuery(formExportacao))
      .then((r) => {
        if (r.body.alertas_exportacao_lista)
          dispatch(exibeAlertas(r.body.alertas_exportacao_lista));

        if (r.body.alertas_modelo_relatorio)
          dispatch(exibeAlertasModeloRelatorio(r.body.alertas_modelo_relatorio));

        if (!r.body.possui_alertas) {
          dispatch(fechaModal());
          dispatch(fechaRelatorio());
          dispatch(fechaAlertas());
          dispatch(alert('info', 'Seu download foi adicionado à fila de processamento. Você pode acompanhar o andamento através da opção “Downloads”, no menu lateral.'));
          dispatch(reset('listaDocumentos'));

          if (formExportacao && formExportacao.salvarRelatorio === 'true' && r.body.salvo)
            dispatch(alert('info', 'Seu modelo de relatório foi salvo com sucesso.'));
        }
      })
      .catch(e => dispatch(ajaxError('Erro ao enviar solicitação de download', e)));
}

const LINHAS_NFSE = [
  { value: 1, label: 'Simples - somente dados do documento' },
];

const LINHAS_CTE = [
  { value: 1, label: 'Simples - somente dados do documento' },
  { value: 4, label: 'Documentos da Carga - lista dos documentos relacionados.' },
];

const LINHAS_NFCE = [
  { value: 1, label: 'Simples - somente dados do documento' },
  { value: 2, label: 'Produtos - dados dos itens' },
  { value: 6, label: 'Pagamentos' },
];

const LINHAS_NFE = [
  { value: 1, label: 'Simples - somente dados do documento' },
  { value: 2, label: 'Produtos - dados dos itens' },
  { value: 3, label: 'Documentos Referenciados' },
  { value: 5, label: 'Cobrança - dados de cobrança e parcelas' },
  { value: 6, label: 'Pagamentos' },
];

export function obterListaPorTipo(tipo : string) {
  if (tipo === 'nfe')
    return LINHAS_NFE;

  if (tipo === 'nfce')
    return LINHAS_NFCE;

  if (tipo === 'cte')
    return LINHAS_CTE;

  return LINHAS_NFSE;
}
