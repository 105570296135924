'use strict';
// @flow

import React from 'react';
import { OverlayTrigger, Button, Tooltip } from 'react-bootstrap';

import type { IDocumento, IPortaria, IUsuario } from 'lib/api';

import Timeago from 'components/common/timeago';
import Icone from 'components/common/icone';
import LabelSituacao from '../label_situacao';
import ModalInformacoesPortaria from './modal_informacoes_portaria';

type PortariaProps = {
  documento : ?IDocumento,
  open : bool,
  abreModal : () => void,
}

export default function Portaria({ documento, open, abreModal } : PortariaProps) {
  if (!documento || !documento.portaria)
    return null;

  return (
      <div>
        <div className="panel panel-default situacoes">
          <div className="panel-heading">
            Portaria
            <div className="pull-right"><Button bsSize="xsmall" bsStyle="link" onClick={ abreModal }
                                                title="Mais Informações."><Icone nome="plus" estilo="fas"/></Button></div>
          </div>

          <div className="panel-body">
            <UltimaDecisao portaria={ documento.portaria }/>
            <a className="btn btn-action btn-block" href={ documento.portaria.url }>
              <Icone nome="truck" estilo="fas" flip="horizontal">
                <span style={{ marginLeft: 5 }}>Consultar Portaria</span>
              </Icone>
            </a>
          </div>
        </div>
        { open && <ModalInformacoesPortaria documento={ documento }/> }
      </div>
  );
}

function UltimaDecisao({ portaria } : { portaria : IPortaria }) {
  if (!portaria.ultimaDecisao)
    return <p> Não há decisão registrada. </p>;

  return (
      <div>
        <dl>
          <dt>Última Decisão</dt>
          <dd><LabelSituacao { ...portaria.ultimaDecisao } /></dd>
        </dl>
        <div className="ultima-consulta r">
          <DataDecisao portaria={ portaria }/>
        </div>
      </div>
  );
}

function DataDecisao({ portaria } : { portaria : IPortaria }) {
  const h = portaria.historico;

  if (!h)
    return null;

  return (
      <p>
        Decisão tomada { ' ' }
        <Timeago time={ h.createdAt }/>
        <UsuarioDecisao usuario={ h.usuario }/>
      </p>
  );
}

function UsuarioDecisao({ usuario } : { usuario? : IUsuario }) {
  if (!usuario)
    return null;

  const tt = <Tooltip id="tooltio-usuario-decisao-portaria">{ usuario.email }</Tooltip>;

  return (
      <span>
        { ' por ' }
        <OverlayTrigger placement="bottom" overlay={ tt }>
          <span>{ usuario.name }</span>
        </OverlayTrigger>
      </span>
  );
}
