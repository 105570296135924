// @flow
'use strict';

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import request from 'lib/request';
import { errorToImmutable } from 'lib/viewUtils';
import type { Action, Dispatch, GetState, State } from 'lib/types';

import type { IConsulta, IRecuperacao } from '../components/portaria/types';

const SET_CHAVE             = 'consultaPortaria/SET_CHAVE',
      TRANSFERE_FOCO_CHAVE  = 'consultaPortaria/TRANSFERE_FOCO_CHAVE',
      CHAVE_RECEBEU_FOCO    = 'consultaPortaria/CHAVE_RECEBEU_FOCO',

      INICIA_CONSULTA       = 'consultaPortaria/INICIA_CONSULTA',
      CONSULTA_OK           = 'consultaPortaria/CONSULTA_OK',
      CONSULTA_ERRO         = 'consultaPortaria/CONSULTA_ERRO',

      CONSULTA_SEFAZ        = 'consultaPortaria/CONSULTA_SEFAZ',
      CONSULTA_SEFAZ_OK     = 'consultaPortaria/CONSULTA_SEFAZ_OK',
      CONSULTA_SEFAZ_ERRO   = 'consultaPortaria/CONSULTA_SEFAZ_ERRO',

      DOWNLOAD_WEBSITE      = 'consultaPortaria/DOWNLOAD_WEBSITE',
      DOWNLOAD_WEBSITE_OK   = 'consultaPortaria/DOWNLOAD_WEBSITE_OK',
      DOWNLOAD_WEBSITE_ERRO = 'consultaPortaria/DOWNLOAD_WEBSITE_ERRO',

      ABRE_RECUPERACAO      = 'consultaPortaria/ABRE_RECUPERACAO',
      FECHA_RECUPERACAO     = 'consultaPortaria/FECHA_RECUPERACAO',
      ATUALIZA_RECUPERACAO  = 'consultaPortaria/ATUALIZA_RECUPERACAO',

      ABRE_SOLICITACAO_XML  = 'consultaPortaria/ABRE_SOLICITACAO_XML',
      FECHA_SOLICITACAO_XML = 'consultaPortaria/FECHA_SOLICITACAO_XML';

const DEFAULT_STATE = Immutable.fromJS({
  chave: '',
  consulta: undefined,

  consultaDocumento: {
    carregando: false,
    erro: undefined,
  },
  consultaSefaz: {
    carregando: false,
    erro: undefined,
  },
  recuperacao: {
    aberta: false,
  },
  solicitacao: {
    aberta: false,
  },
});

/* ===================== */
/* REDUCER FUNCTION      */
/* ===================== */

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action = {}) {
  switch (action.type) {
    case SET_CHAVE:
      return state.set('chave', action.chave);
    case TRANSFERE_FOCO_CHAVE:
      return state.set('focarChave', true);
    case CHAVE_RECEBEU_FOCO:
      return state.set('focarChave', false);

    case INICIA_CONSULTA:
      return state
          .removeIn(['consultaDocumento', 'erro'])
          .setIn(['consultaDocumento', 'carregando'], true);
    case CONSULTA_OK:
      return state
          .set('consulta', action.consulta)
          .setIn(['consultaDocumento', 'carregando'], false);
    case CONSULTA_ERRO:
      return state
          .remove('consulta')
          .setIn(['consultaDocumento', 'erro'], action.erro)
          .setIn(['consultaDocumento', 'carregando'], false);

    case CONSULTA_SEFAZ:
      return state
          .removeIn(['consultaSefaz', 'erro'])
          .setIn(['consultaSefaz', 'carregando'], true);
    case CONSULTA_SEFAZ_OK:
      return state
          .set('consulta', action.consulta)
          .setIn(['consultaSefaz', 'carregando'], false);
    case CONSULTA_SEFAZ_ERRO:
      return state
          .setIn(['consultaSefaz', 'erro'], action.erro)
          .setIn(['consultaSefaz', 'carregando'], false);

    case DOWNLOAD_WEBSITE:
      return state.set('passoRecuperacao', action.passo);
    case DOWNLOAD_WEBSITE_OK:
      return state.set('consulta', action.consulta).set('consultandoSefaz', false);
    case DOWNLOAD_WEBSITE_ERRO:
      return state.set('erro', action.erro).set('consultandoSefaz', false);

    case ABRE_RECUPERACAO:
      return state
          .setIn(['recuperacao', 'aberta'], true)
          .setIn(['recuperacao', 'passo'], 0)
          .removeIn(['recuperacao', 'erro']);
    case FECHA_RECUPERACAO:
      return state
          .setIn(['recuperacao', 'aberta'], false)
          .setIn(['recuperacao', 'passo'], 0)
          .removeIn(['recuperacao', 'erro']);
    case ATUALIZA_RECUPERACAO:
      return state.mergeIn(['recuperacao'], action.recuperacao);

    case ABRE_SOLICITACAO_XML:
      return state.setIn(['solicitacao', 'aberta'], true);

    default:
      return state;
  }
}

/* ========= */
/* SELECTORS */
/* ========= */

export const rootSelector = (state : State) => state.get('consultaPortaria');

export const urlSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.getIn(['consulta', 'url']));
export const destSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.getIn(['consulta', 'dest_cnpj']));

export const itensSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.getIn(['consulta', 'itens']));

/* ===================== */
/* BASIC ACTION CREATORS */
/* ===================== */

export function setChave(chave : string) {
  return { type: SET_CHAVE, chave };
}

export function transfereFocoChave() {
  return { type: TRANSFERE_FOCO_CHAVE };
}

export function chaveRecebeuFoco() {
  return { type: CHAVE_RECEBEU_FOCO };
}

export function iniciaConsulta() {
  return { type: INICIA_CONSULTA };
}

export function consultaOK(consulta : IConsulta) {
  return { type: CONSULTA_OK, consulta: Immutable.fromJS(consulta) };
}

export function consultaErro(erro : Error) {
  return { type: CONSULTA_ERRO, erro: errorToImmutable(erro) };
}

export function iniciaConsultaSEFAZ() {
  return { type: CONSULTA_SEFAZ };
}

export function consultaSefazOK(consulta : IConsulta) {
  return { type: CONSULTA_SEFAZ_OK, consulta: Immutable.fromJS(consulta) };
}

export function consultaSefazErro(erro : Error) {
  return { type: CONSULTA_SEFAZ_ERRO, erro: errorToImmutable(erro) };
}

export function abreRecuperacao() {
  return { type: ABRE_RECUPERACAO };
}

export function fechaRecuperacao() {
  return { type: FECHA_RECUPERACAO };
}

export function abreSolicitacaoXml() {
  return { type: ABRE_SOLICITACAO_XML };
}

export function fechaSolicitacaoXml() {
  return { type: FECHA_SOLICITACAO_XML };
}

export function atualizaRecuperacao(recuperacao : $Shape<IRecuperacao>) {
  return { type: ATUALIZA_RECUPERACAO, recuperacao: Immutable.fromJS(recuperacao) };
}

/* ===================== */
/* THUNK ACTION CREATORS */
/* ===================== */

export function consultaChave(chave : string) {
  return function(dispatch : Dispatch<*>) {
    dispatch(setChave(chave));
    dispatch(iniciaConsulta());

    request.post('/app/portaria')
        .type('json').accept('json')
        .send({ consulta_portaria: { chave } })
        .then(r => {
          const consulta = r.body;
          window.history.pushState('', window.title, consulta.url);
          dispatch(consultaOK(consulta));
          dispatch(consultaSEFAZ());
          return null;
        })
        .catch(e => dispatch(consultaErro(e)));
  };
}

export function consultaSEFAZ() {
  return function(dispatch : Dispatch<*>, getState : GetState) {
    const url = urlSelector(getState());

    if (!url)
      throw new Error('Necessário carregar a consulta antes de consultar na SEFAZ');

    dispatch(iniciaConsultaSEFAZ());

    request.post(`${url}/consulta_sefaz`)
        .type('json').accept('json')
        .set({ 'X-Http-Method-Override': 'patch' })
        .then(r => dispatch(consultaSefazOK(r.body)))
        .catch(e => dispatch(consultaSefazErro(e)));
  };
}

export function iniciaRecuperacao() {
  return function(dispatch : Dispatch<*>, getState : GetState) {
    const url = urlSelector(getState());
    const destCnpj = destSelector(getState());

    if (!url)
      throw new Error('Necessário carregar a consulta antes de recuperar o XML');

    dispatch(atualizaRecuperacao({ passo: 1, download: undefined, erro: undefined }));

    request.post(`${url}/recuperar_xml`)
        .send({ dest_cnpj: destCnpj })
        .type('json').accept('json')
        .then(r => dispatch(atualizaRecuperacao({ passo: 2, download: r.body })))
        .catch(e => dispatch(atualizaRecuperacao({ passo: 2, erro: errorToImmutable(e) })));
  };
}