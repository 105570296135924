// @flow
'use strict';

import * as Immutable from 'immutable';
import * as _ from 'lodash';
import Url from 'url';
import { autofill } from 'redux-form/immutable';
import { push } from 'react-router-redux';

import type { Action, Dispatch, State } from 'lib/types';
import * as conta from './conta';
import * as empresa from './empresa';
import request from 'lib/request';
import { ajaxError } from '../alerts';
import { atualizaPaineisEGraficos } from './paineis';
import { prepareQuery } from '../../lib/viewUtils';

const INITIAL_STATE = Immutable.fromJS({ conta_id: '', empresa_id: '' });

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['dashboard', 'filtro'], Immutable.Map());

// thunk actions

export function aplicaFiltro(form : any) {
  const formInJS = _.omitBy(Immutable.Map.isMap(form) ? form.toJS() : form, (v) => v === null || v === undefined || v === '');

  return async function(dispatch : Dispatch<*>) {
    const url = Url.format({ pathname: `/app/dashboard/paineis`, query: prepareQuery(formInJS) });

    dispatch(push(url));

    await dispatch(atualizaPaineisEGraficos(formInJS));

    dispatch(atualizaFiltros(formInJS));
  };
}

export function atualizaFiltros(form : { empresa_id : ?string, conta_id : ?string }) {
  return async function(dispatch : Dispatch<*>) {
    const contaId = form ? form.conta_id : null;
    dispatch(autofill('dashboardFiltro', 'conta_id', contaId));

    if (contaId)
      dispatch(empresa.atualizaOpcoesEmpresas(contaId));

    dispatch(autofill('dashboardFiltro', 'empresa_id', form ? form.empresa_id : null));
  };
}

export function carregaOpcoesSelect() {
  return async function(dispatch : Dispatch<*>) {
    await request.get(`/app/dashboard/opcoes_filtros`)
        .accept('json')
        .then(r => {
          dispatch(conta.dadosSelectRecebidos(r.body.contas));
          dispatch(empresa.dadosSelectRecebidos(r.body.empresas));
        })
        .catch(e => dispatch(ajaxError('Erro ao carregar opções de filtros', e)));
  };
}