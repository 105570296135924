'use strict';
// @flow

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as novo from '../../../../../reducers/sped/arquivos/novo';
import { jsSelector } from '../../../../../lib/viewUtils';

import Form from './form';

const mapStateToProps    = createStructuredSelector({
        upload: jsSelector(novo.rootSelector),
      }),
      mapDispatchToProps = {
        iniciaUpload: novo.iniciaUpload,
        uploadArquivos: novo.uploadArquivos,
      };

export default connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(Form);