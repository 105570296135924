// @flow
'use strict';

import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfe from '../form_exportacao_nfe/campos_nfe/selecao_todos_nfe';
import { Input } from '../../../../../common/input';

type CamposDocumentoReferenciadoProps = {
  component : React.ComponentType<*>,
}

export default function CamposDocumentoReferenciado({ component } : CamposDocumentoReferenciadoProps) {
  const SelecaoTodos = component || SelecaoTodosNfe;

  return (
      <fieldset className="grupo-download-lista">
        <legend>Documentos Referenciados</legend>
        <p className="instrucao-em-titulo">A lista retornará uma linha para cada documento referenciado.</p>

        <Row>
          <Col md={ 6 }>
            <FormSection name="docReferenciadoNfe">
              <SelecaoTodos nomes={ ["docReferenciadoNfe"] } titulo="NF-es (modelo 55 ou 65)"/>

              <div className="grupo-checkbox">
                <Input name="chaveNfe" label="Chave" checkbox/>
              </div>
            </FormSection>

            <FormSection name="docReferenciadoNf">
              <SelecaoTodos nomes={ ["docReferenciadoNf"] } titulo="NFs (modelo 1 ou 1A)"/>

              <div className="grupo-checkbox">
                <Input name="ufEmitenteNf" label="UF do Emitente" checkbox/>
                <Input name="dataEmissaoNf" label="Data Emissão" checkbox/>
                <Input name="emitenteNf" label="CNPJ Emitente" checkbox/>
                <Input name="serieNf" label="Série" checkbox/>
                <Input name="numeroNf" label="Número" checkbox/>
              </div>
            </FormSection>
          </Col>
          <Col md={ 6 }>
            <FormSection name="docReferenciadoCte">
              <SelecaoTodos nomes={ ["docReferenciadoCte"] } titulo="CT-es"/>

              <div className="grupo-checkbox">
                <Input name="chaveCte" label="Chave" checkbox/>
              </div>
            </FormSection>

            <FormSection name="docReferenciadoCupom">
              <SelecaoTodos nomes={ ["docReferenciadoCupom"] } titulo="Cupons Fiscais"/>

              <div className="grupo-checkbox">
                <Input name="modeloCupom" label="Modelo" checkbox/>
                <Input name="numeroEcfCupom" label="Número ECF" checkbox/>
                <Input name="numeroContadorCupom" label="Número do Contador" checkbox/>
              </div>
            </FormSection>
          </Col>
        </Row>
      </fieldset>
  );
}