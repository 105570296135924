// @flow
'use strict';

import * as React from 'react';

import type { ItemProps } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';

export default function Intermediario({ item } : ItemProps) {
  return (
      <div className="tab-pane active">
        <h3>Intermediário</h3>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="intermediarioCpfCnpj" type="documento" width="25%">CPF / CNPJ</ColumnDetalhes>
          <ColumnDetalhes field="intermediarioIm" width="25%">Inscrição Municipal</ColumnDetalhes>
          <ColumnDetalhes field="intermediarioIssRetido" width="25%">ISS Retido</ColumnDetalhes>
          <ColumnDetalhes field="intermediarioEmail" width="25%">E-mail</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}