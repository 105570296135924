// @flow
'use strict';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { IProdutoServico } from '../../types';

import { abreDetalhesProdutoServico, modalOpenSelector } from '../../../../../reducers/nfe/detalhes_produto_servico';

import ColumnDetalhes from '../../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../../common/detalhes_documento/table_detalhes';
import ModalDetalhes from './modal_detalhes';

type ProdutoServicoProps = {
  produtoServico : IProdutoServico[],
};

export default function ProdutoServico({ produtoServico } : ProdutoServicoProps) {
  const modalOpen           = useSelector(modalOpenSelector),
        dispatch            = useDispatch(),
        handleDetalhesClick = React.useCallback((nfe) => dispatch(abreDetalhesProdutoServico(nfe)), [dispatch]);

  return (
      <div className="tab-pane active">
        <h3>Dados dos Produtos e Serviços</h3>
        <TableDetalhes data={ produtoServico } noDataClass="padding-10" noDataMsg="Nenhum item informado na NF-e">
          <ColumnDetalhes field="numero" width={ 50 }>Núm.</ColumnDetalhes>
          <ColumnDetalhes field="descricao" type="documento">Descrição</ColumnDetalhes>
          <ColumnDetalhes field="ncm" width={ 70 }>NCM</ColumnDetalhes>
          <ColumnDetalhes field="qtdComercial" width={ 70 }>Qtd.</ColumnDetalhes>
          <ColumnDetalhes field="cfop" width={ 70 }>CFOP</ColumnDetalhes>
          <ColumnDetalhes field="valorTotal" type="moeda" width={ 110 }>Valor</ColumnDetalhes>
          <ColumnDetalhes field="produtoServico" type="botaoDetalhes" buttonClick={ handleDetalhesClick } width={ 80 }/>
        </TableDetalhes>

        { modalOpen && <ModalDetalhes/> }
      </div>
  );
}
