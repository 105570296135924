'use strict';
// @flow

import * as React from 'react';
import { IndexRedirect, IndexRoute, Route } from 'react-router';

import BaseApp from 'components/common/base_app';
import ListagemArquivoSped from './arquivos/listagem';
import DetalhesArquivoSped from './arquivos/detalhes';
import ModalNovoArquivoSped from './arquivos/novo';
import ListagemDocumentosSped from './documentos/listagem';
import ListagemEscrituradosSped from './escriturados/listagem';

import './sped.less';

export default (
    <Route name="SPED" path="app/sped" component={ BaseApp }>
      <IndexRedirect to="arquivos"/>
      <Route name="Arquivos" title="Arquivos SPED" path="arquivos"
             subtitle="Lista de todos os arquivos EFD ICMS IPI constantes no Portal.">
        <Route name="Arquivos">
          <IndexRoute component={ ListagemArquivoSped }/>
          <Route name="Novo" modal path="new" component={ ModalNovoArquivoSped }/>
        </Route>
        <Route name="Detalhes do Arquivo"
               subtitle="Detalhes do arquivo EFD, contendo a lista dos documentos escriturados nele."
               path=":id" component={ DetalhesArquivoSped }/>
      </Route>
      <Route name="Documentos" title="Documentos SPED" path="documentos"
             subtitle="Lista dos documentos que constam em algum arquivo EFD ICMS IPI.">
        <IndexRoute component={ ListagemDocumentosSped }/>
      </Route>
      <Route name="Escriturados" title="Documentos Escriturados" path="escriturados"
             subtitle="Documentos custodiados no Portal com suas respectivas citações em arquivos EFD.">
        <IndexRoute component={ ListagemEscrituradosSped }/>
      </Route>
    </Route>
);