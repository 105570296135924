// @flow
'use strict';

import * as React from 'react';
import { Field } from 'redux-form/immutable';

import type { InputFieldProps } from './types';
import { InputControl } from './input_wrapper';

export default function InputField(props : InputFieldProps) {
  return <Field component={ InputControl } { ...props }/>;
}