// @flow
'use strict';

import * as React from 'react';

import type { ITransportador, ITransporte } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';

type TransporteProps = {
  transporte : ITransporte,
};

export default function Transporte({ transporte } : TransporteProps) {
  return (
      <div className="tab-pane active">
        <h3>Transporte</h3>
        <RowDetalhes data={ transporte }>
          <ColumnDetalhes field="modalidade">Modalidade do Frete</ColumnDetalhes>
        </RowDetalhes>

        { transporte && transporte.transportador &&
        <div>
          <h3>Dados do Transportador</h3>
          <RowDetalhes data={ transporte.transportador }>
            <ColumnDetalhes field="nome">Nome / Razão Social</ColumnDetalhes>
            <ColumnDetalhes field="cnpjCpf" type="documento">
              { tipoDocumento(transporte.transportador) }
            </ColumnDetalhes>
            <ColumnDetalhes field="inscricaoEstadual">Inscrição Estadual</ColumnDetalhes>
          </RowDetalhes>
          <RowDetalhes data={ transporte.transportador }>
            <ColumnDetalhes field="endereco">Endereço</ColumnDetalhes>
            <ColumnDetalhes field="municipio">Município</ColumnDetalhes>
            <ColumnDetalhes field="uf">UF</ColumnDetalhes>
          </RowDetalhes>
        </div>
        }
      </div>
  );
}

function tipoDocumento(transportador : ITransportador) {
  if (!transportador || !transportador.documento || transportador.documento.length === 14)
    return 'CNPJ';

  if (transportador.documento.length === 11)
    return 'CPF';

  return 'CNPJ';
}