// @flow
'use strict';

import * as React from 'react';
import { Table } from 'react-bootstrap';

type TableDetalhesProps = {
  data : any,
  children? : any,
  className? : string,
}

const CLASSNAME_DEFAULT = 'table-condensed table-striped inner';

export default function TableDetalhesComLinhas({ data, children, className = CLASSNAME_DEFAULT } : TableDetalhesProps) {
  const linhas : Array<React.Element<any>> = (function() {
    if (!children)
      return [];
    if (!Array.isArray(children))
      return [children];

    return children;
  })();

  const colunasPrimeiraLinha : Array<React.Element<any>> = (function() {
    if (linhas && linhas.length > 0) {
      const primeiraLinhaChildren = linhas[0].props.children;

      if (!primeiraLinhaChildren)
        return [];
      if (!Array.isArray(primeiraLinhaChildren))
        return [primeiraLinhaChildren];

      return primeiraLinhaChildren;
    }

    return [];
  })();

  return (
      <Table className={ className }>
        <colgroup>
          { colunasPrimeiraLinha.map((c, i) => <col key={ i } style={ { width: c.props.width || undefined } }/>) }
        </colgroup>
        <tbody>
        { linhas.map((l, i) => {
          if (Array.isArray(l.props.children)) {
            return (
                <React.Fragment key={ i }>
                  <tr>
                    { l.props.children.map((c : React.Element<any>, j) =>
                        <th key={ j } colSpan={ c.props.colSpan }>{ c.props.children }</th>,
                    ) }
                  </tr>
                  <tr>
                    { l.props.children.map((c : React.Element<any>, j) =>
                        React.cloneElement(c, { data, key: j, colSpan: c.props.colSpan }),
                    ) }
                  </tr>
                </React.Fragment>
            );
          }
          else {
            return (
                <React.Fragment key={ i }>
                  <tr>
                    <th colSpan={ l.props.children.props.colSpan }>{ l.props.children.props.children }</th>
                  </tr>
                  <tr>
                    { React.cloneElement(l.props.children, { data, colSpan: l.props.children.props.colSpan }) }
                  </tr>
                </React.Fragment>
            );
          }
        }) }
        </tbody>
      </Table>
  );
}
