// @flow
'use strict';

import { connect } from 'react-redux';

import { fechaAlertas } from '../../../../../../reducers/modelo_relatorio/salvar_relatorio';

import Alertas from '../../common/alertas';

export default connect<*, *, *, *, *, *>(null, { fechaAlertas })(Alertas);
