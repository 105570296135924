'use strict';
// @flow

import * as React from 'react';
import { IndexRoute, Route } from 'react-router';

import Lista from './lista';
import Detalhes from './detalhes';

export default (
    <Route name="Notícias" path="noticias">
      <IndexRoute component={ Lista }/>
      <Route name="Nova Notícia" path="new" component={ Detalhes }/>
      <Route name="Editando Notícia" path=":id" component={ Detalhes }/>
    </Route>
);
