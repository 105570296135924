// @flow
'use strict';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

import { modalOpenSelector, finalizaBuscaEstruturada } from 'reducers/nfe/filtro_estruturado';

import CamposDocumentos from './campos_documentos';
import CamposItens from './campos_itens';
import CamposCobranca from './campos_cobranca';
import CamposSituacoes from './campos_situacoes/campos_situacoes';
import Icone from '../../../common/icone';
import CamposManifestacoes from './campos_manifestacoes/campos_manifestacoes';
import CamposPortaria from './campos_portaria';

type ModalProps = {
  handleSubmit : () => void,
}

export default function ModalBuscaEstruturada({ handleSubmit } : ModalProps) {
  const open     = useSelector(modalOpenSelector),
        dispatch = useDispatch();

  const fechaModal = React.useCallback(() => dispatch(finalizaBuscaEstruturada()), [dispatch]);

  return (
      <Modal show={ open } onHide={ fechaModal } dialogClassName="modal-lg" id="busca-avancada">
        <Modal.Header closeButton>
          <Modal.Title>Busca Avançada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={ handleSubmit }>
            <section className="busca-estruturada">
              <Row>
                <Col md={ 7 }>
                  <CamposDocumentos/>
                </Col>
                <Col md={ 5 }>
                  <CamposItens/>
                </Col>
              </Row>
              <CamposCobranca/>
              <CamposSituacoes/>
              <CamposManifestacoes/>
              <CamposPortaria/>
            </section>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={ handleSubmit }><Icone nome="search" estilo="fas">Buscar</Icone></Button>
        </Modal.Footer>
      </Modal>
  );
}
