'use strict';

// @flow

import * as React from 'react';

import PainelCustodia from './painel_custodia';
import Situacao from './situacao';
import Portaria from './portaria';
import Manifestacao from './manifestacao';
import ManifestacaoCte from './manifestacao_cte';
import BotaoAtualizarPaineis from './botao_atualizar_paineis';

import type { IDocumento } from 'lib/api';
import type { IActions } from './tipos';

type PaineisProps = {
  url : string,             // URL do documento
  documento? : ?IDocumento, // objeto representando o documento
  env? : ?string,           // ambiente de execução do Rails - necessário para exibirmos opções de debug
  loading? : ?bool,         // informa se a tela está em estado de carregamento
  modeloId? : string,

  actions : IActions,       // ações
}

/**
 * Monta todos os painéis.
 */
export default function Paineis(props : PaineisProps) {
  const { env, documento, modeloId }                          = props,
        { situacao, manifestacao, portaria, manifestacaoCte } = documento || {};

  const paineis = [];

  paineis.push(<PainelCustodia { ...props } key="custodia"/>);

  if (situacao)
    paineis.push(<Situacao { ...props } key="situacao"/>);

  if (manifestacao)
    paineis.push(<Manifestacao { ...props } key="manifestacao"/>);

  if (manifestacaoCte)
    paineis.push(<ManifestacaoCte { ...props } key="manifestacaoCte"/>);

  if (portaria)
    paineis.push(<Portaria { ...props } key="portaria"/>);

  if (modeloId !== 59 && (env === 'test' || env === 'development'))
    paineis.push(<BotaoAtualizarPaineis { ...props } key="atualizar"/>);

  return <div>{ paineis }</div>;
}
