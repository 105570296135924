'use strict';
// @flow

import _ from 'lodash';
import React from 'react';

import { prepareContent } from 'lib/viewUtils';

type DefinitionType = {
  label : string,
  value : ?any,
  hideEmpty? : bool
};

type DefinitionListProps = {
  mode? : 'dl' | 'table',
  defaultContent? : any,
  children? : DefinitionType[] | DefinitionType,
};

export default function DefinitionList({ defaultContent, children, mode } : DefinitionListProps) {
  const items = _.chain(children)
      .castArray()
      .map(({ content, ...i }) => ( { ...i, content: content || prepareContent({ defaultContent, ...i }) } ))
      .reject(({ content, hideEmpty }) => !content && hideEmpty)
      .flatMap(mode === 'dl' ? dlFactory : trFactory)
      .value();

  if (mode === 'dl')
    return <dl className="dl-horizontal">{ items }</dl>;

  return (
      <table className="valign-top">
        <colgroup>
          <col width="50%"/>
          <col width="50%"/>
        </colgroup>
        <tbody>{ items }</tbody>
      </table>
  );
}
DefinitionList.defaultProps = { defaultContent: '–', mode: 'dl', children: [] };

function dlFactory({ label, content }, i) {
  return [
    <dt key={`dt-${i}`}>{ label }</dt>,
    <dd key={`dd-${i}`}>{ content }</dd>
  ];
}

function trFactory({ label, content }, i) {
  return [
    <tr key={`tr-${i}`}>
      <th scope="row">{ label }</th>
      <td>{ content }</td>
    </tr>
  ];
}