// @flow
'use strict';

import * as React from 'react';

import type { IPisNt } from '../../../../../types_imposto';

import RowDetalhes from '../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../common/detalhes_documento/column_detalhes';

type PisNtProps = {
  item : IPisNt,
};

export default function PisNt({ item } : PisNtProps) {
  return (
      <RowDetalhes data={ item }>
        <ColumnDetalhes field="cst">CST</ColumnDetalhes>
      </RowDetalhes>
  );
}