// @flow
'use strict';

import * as React from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';

type EditorProps = {
  input : {
    value : string,
    onChange(value : string) : void
  }
}

export default function Editor({ input: { onChange }, input } : EditorProps) {
  const [currentValue, setCurrentValue] = React.useState(input.value === ''
      ? RichTextEditor.createEmptyValue()
      : RichTextEditor.createValueFromString(input.value, 'html'),
  );

  const handleChange = React.useCallback((value : EditorValue) => {
    setCurrentValue(value);
    onChange(value.toString('html'));
  }, [onChange]);

  return <RichTextEditor value={ currentValue } onChange={ handleChange }/>;
}
