// @flow
'use strict';

import * as React from 'react';

import type { IProdutoServico } from '../../types';

import ColumnDetalhes from '../../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../../common/detalhes_documento/table_detalhes';
import ModalDetalhes from './modal_detalhes';

type ProdutoServicoProps = {
  produtoServico : IProdutoServico[],
  modalOpen : bool,
  handleDetalhesClick : () => void,
};

export default function ProdutoServico({ produtoServico, modalOpen, handleDetalhesClick } : ProdutoServicoProps) {
  return (
      <div className="tab-pane active">
        <h3>Dados dos Produtos e Serviços</h3>
        <TableDetalhes data={ produtoServico } noDataMsg="Nenhum item informado na NFC-e">
          <ColumnDetalhes field="item">Item</ColumnDetalhes>
          <ColumnDetalhes field="descricao" type="documento">Descrição</ColumnDetalhes>
          <ColumnDetalhes field="ncm">NCM</ColumnDetalhes>
          <ColumnDetalhes field="un">UN</ColumnDetalhes>
          <ColumnDetalhes field="quantidade">Quantidade</ColumnDetalhes>
          <ColumnDetalhes field="valorUnitario" type="moeda">Valor Unitário</ColumnDetalhes>
          <ColumnDetalhes field="valorTotal" type="moeda">Valor Total do Item</ColumnDetalhes>
          <ColumnDetalhes field="produtoServico" type="botaoDetalhes" buttonClick={ handleDetalhesClick }/>
        </TableDetalhes>

        { modalOpen && <ModalDetalhes/> }
      </div>
  );
}