// @flow
'use strict';

import type { ResumoFiltro } from './types';

export function filtroPorCodigo(filtros : ResumoFiltro[], codigo : string) {
  if (!filtros)
    return null;

  return filtros.find(f => f.codigo === codigo);
}

export function obterTipoNota(pathname : string) {
  if (!pathname || !pathname.includes('/'))
    return pathname;

  const pathParams = pathname.split('/');

  if (pathParams.length >= 3)
    return pathParams[2];

  return pathname;
}
