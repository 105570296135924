// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { openSelector, fechaModal, totalDocumentosSelector, exportaLoteNfse } from '../../../../../reducers/nfse/modal_exportacao_lote';

import ModalExportacaoLote from './modal_exportacao_lote';

const mapStateToProps = createStructuredSelector({
  open: openSelector, totalDocumentos: totalDocumentosSelector,
});

export default connect<*, *, *, *, *, *>(mapStateToProps, { fechaModal, exportaLoteNfse })(ModalExportacaoLote);