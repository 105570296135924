// @flow

import * as _ from 'lodash';
import { Map, Record } from 'immutable';
import { createSelector } from 'reselect';

import RailsErrors from 'rails_errors';
import type { Action, State } from 'lib/types';

// //////////////////
// CONSTANTES

export const NEW_ALERT = 'alerts/NEW_ALERT',
             CLEAR     = 'alerts/CLEAR',
             CLOSE     = 'alerts/CLOSE';

const DEFAULT_STATE = Map();

export type AlertType = 'info' | 'danger' | 'warn';

const Alert = Record({ type: 'info', message: '', seq: 0 });

// ////////////
// REDUCER

export default function reducer(state : State = DEFAULT_STATE, action : Action = {}) {
  switch (action.type) {
    case NEW_ALERT:
      return state.set(action.alert.seq, action.alert);
    case CLEAR:
      return state.clear();
    case CLOSE:
      return state.remove(action.seq);

    default:
      return state;
  }
}

// ////////////////////////
// SELECTORS

export const rootSelector = (state : State) => state.get('alerts');
export const todosAlertasSelector = createSelector<*, *, *, *>(rootSelector, root => root.valueSeq());

// ////////////////////////
// ATUALIZAÇÕES DA STORE

export function close(seq : number) {
  return { type: CLOSE, seq };
}

let nextSeq = 0;

export function alert(type : AlertType, message : string | {}, only : bool = false) {
  if (typeof message !== 'string') {
    if (Array.isArray(message))
      return alert(type, message[0]);

    if (typeof message === 'object') {
      const re = new RailsErrors(message);
      return alert(type, re.firstMessage);
    }
  }

  if (only)
    return { type: NEW_ALERT, alert: new Alert({ type, message, seq: nextSeq }) };

  return { type: NEW_ALERT, alert: new Alert({ type, message, seq: nextSeq++ }) };
}

type ErrorPayload = {
  status : number,
  body : string | { errors : any[] | {} },
  response? : any,
  responseText? : string
};

export function ajaxError(baseMessage : string, { status, body, ...e } : ErrorPayload) {
  let msg = baseMessage;

  if (!body && e.response)
    body = e.response.body;

  if (!body && e.responseText)
    body = JSON.parse(String(e.responseText));

  if (status === 422 && body && body.errors) {
    if (typeof body === 'string')
      msg += `: ${ body }`;
    else if (_.isArray(body.errors))
      msg += `: ${ body.errors[0] }`;
    else if (_.isObject(body.errors)) {
      const re = new RailsErrors(body.errors);
      msg += `: ${ re.firstMessage }`;
    }
  }

  return alert('danger', msg);
}

export function clear() {
  return { type: CLEAR };
}