'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';
import { push } from 'react-router-redux';

import type { Action, Dispatch, GetState, State } from 'lib/types';
import * as Paths from '../../paths';
import * as alerts from '../alerts';
import request from '../../lib/request';
import { currentRouteSelector } from '../routing';
import { carregaContas } from './contas';

type date = any;

type filtrosType = {
  tipo : ?string, cnpj_base : ?string, conta_id : ?string, valido_de : ?date, valido_ate : ?date,
  revogado_de : ?date, revogado_ate : ?date, situacao : ?string
};

const ALTERA_ORDENACAO   = 'admin/certificados_digitais/ALTERA_ORDENACAO',
      FETCH_LIST         = 'admin/certificados_digitais/FETCH_LIST',
      FETCH_LIST_OK      = 'admin/certificados_digitais/FETCH_LIST_OK',
      FETCH_RESOURCE     = 'admin/certificados_digitais/FETCH_RESOURCE',
      FETCH_RESOURCE_OK  = 'admin/certificados_digitais/FETCH_RESOURCE_OK',
      MUDA_FILTRO        = 'admin/certificados_digitais/MUDA_FILTRO',
      LIMPA_FILTRO       = 'admin/certificados_digitais/LIMPA_FILTRO',
      INICIALIZA_FILTROS = 'admin/certificados_digitais/INICIALIZA_FILTROS';

const FiltrosRecord = new Immutable.Record({
  tipo: '', cnpj_base: '', conta_id: '', validade_final_de: '', validade_final_ate: '',
  revogado_de: '', revogado_ate: '', situacao: '', conta_ativa: ''
});

const INITIAL_STATE = Immutable.fromJS({
  certificadoAtivo: {},
  paginaAtual: {},
  ordenacao: '',
  filtro: new FiltrosRecord(),
});

export default function reducer(state : Immutable.Map = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case ALTERA_ORDENACAO:
      return state.set('ordenacao', action.param);
    case FETCH_LIST_OK:
      return state.set('paginaAtual', action.body);
    case FETCH_RESOURCE:
      return state.set('certificadoAtivo', null);
    case FETCH_RESOURCE_OK:
      return state.set('certificadoAtivo', action.body);
    case INICIALIZA_FILTROS:
      return state.set('filtro', action.valores);
    case MUDA_FILTRO:
      return state.setIn(['filtro', action.nome], action.valor);
    case LIMPA_FILTRO:
      return state.set('filtro', new FiltrosRecord());

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['admin', 'certificados_digitais'], Immutable.Map());
export const currentPage = createSelector<*, *, *, *>(rootSelector, (root) => root.get('paginaAtual'));
export const currentResource = createSelector<*, *, *, *>(rootSelector, (root) => root.get('certificadoAtivo'));
export const ordenacaoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('ordenacao'));
export const filtroSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('filtro'));

// actions

export function alteraOrdenacao(param : string) {
  return { type: ALTERA_ORDENACAO, param };
}

export function mudaFiltro(nome : string, valor : any) {
  return { type: MUDA_FILTRO, nome, valor };
}

export function limpaFiltro() {
  return { type: LIMPA_FILTRO };
}

export function inicializaFiltros(query : filtrosType) {
  return { type: INICIALIZA_FILTROS, valores: new FiltrosRecord(query) };
}


export function fetchResource() {
  return { type: FETCH_RESOURCE };
}

export function fetchList() {
  return { type: FETCH_LIST };
}

// thunk actions

export function trocaPagina(page : number) {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const query = currentRouteSelector(getState()).get('query').toJS();
    await dispatch(push(Paths.admin.certificados_digitais.colecao({ ...query, page })));
    await dispatch(fetchList());
    await dispatch(carregaContas());
  };
}

export function ordenaPorValidadeFinal() {
  return async function(dispatch : Dispatch<*>) {
    return dispatch(await ordenaLista('validade_final'));
  };
}

export function ordenaPorRevogadoEm() {
  return async function(dispatch : Dispatch<*>) {
    return dispatch(await ordenaLista('revogado_em'));
  };
}

export function ordenaLista(paramOrdem : string) {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const query = currentRouteSelector(getState()).get('query').toJS();

    const ordenacao = ordenacaoSelector(getState());

    let ord = '';
    if (ordenacao && ordenacao.startsWith(paramOrdem))
      ord = ordenacao.endsWith('_decresc') ? `${ paramOrdem }_cresc` : `${ paramOrdem }_decresc`;
    else
      ord = `${ paramOrdem }_cresc`;

    await dispatch(alteraOrdenacao(ord));

    await dispatch(push(
        Paths.admin.certificados_digitais.colecao({ ...query, ordenacao: ord, page: undefined })));
    await dispatch(fetchList());
  };
}

export function aplicaFiltro() {
  return function(dispatch : Dispatch<*>, getState : GetState) {
    const params = filtroSelector(getState()).toJS();

    return dispatch(push(Paths.admin.certificados_digitais.colecao({ ...params, page: undefined })));
  };
}

export function navigateToResource(r : any, action? : string) {
  let url = r.url || `/app/admin/certificados_digitais/${ r.id || (r._id && r._id['$oid']) || r._id || r }`;
  if (action)
    url += `/${ action }`;
  return push(url);
}

export function excluiCertificadoDetalhes(id : string) {
  return async function(dispatch : Dispatch<*>) {
    await request.delete(`/app/admin/certificados_digitais/${ id }`)
        .accept('json')
        .query({ id })
        .then(async(r) => {
          const { alertas, certificado_excluido } = r.body;

          if (alertas.length > 0 && !certificado_excluido)
            return window.alert(alertas.join('/n'));

          await dispatch(push('/app/admin/certificados_digitais'));
          dispatch(alerts.alert('info', 'Certificado digital excluído.'));
        })
        .catch(e => dispatch(alerts.ajaxError('Erro ao excluir certificado digital', e)));
  };
}