// @flow
'use strict';

import { type Saga } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { change, formValueSelector } from 'redux-form/immutable';
import { actionTypes } from 'redux-form';

import { INICIALIZA_MODAL as INICIALIZA_MODAL_NFE } from '../reducers/lista_documentos/nfe';
import { INICIALIZA_MODAL as INICIALIZA_MODAL_NFSE } from '../reducers/lista_documentos/nfse';
import { INICIALIZA_MODAL as INICIALIZA_MODAL_NFCE } from '../reducers/lista_documentos/nfce';
import { INICIALIZA_MODAL as INICIALIZA_MODAL_CTE } from '../reducers/lista_documentos/cte';

export default function* listaDocumento() : Saga<*> {
  yield takeLatest(INICIALIZA_MODAL_NFE, sagaReiniciarCampo);
  yield takeLatest(INICIALIZA_MODAL_NFSE, sagaReiniciarCampo);
  yield takeLatest(INICIALIZA_MODAL_NFCE, sagaReiniciarCampo);
  yield takeLatest(INICIALIZA_MODAL_CTE, sagaReiniciarCampo);
}

export function* sagaReiniciarCampo() : Saga<*> {
  // atribui falso ao campo quando ele é desabilitado do formulário.
  yield takeEvery(
      ac => ac.type === actionTypes.UNREGISTER_FIELD && ac.meta.form && ac.payload.name,
      function* (ac : { meta : { form : string }, payload : any }) {
        yield call(reiniciaCampo, ac.meta.form, ac.payload.name);
      },
  );
}

export function* reiniciaCampo(form : string, name : string) : Saga<*> {
  const campo = yield select(formValueSelector(form), name);

  if (campo || campo === 0)
    yield put(change(form, name, false, false, false));
}