// @flow
'use strict';

import * as _ from 'lodash';
import React from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import type { IRoute } from 'lib/types';
import { jsSelector, useEffectOnce } from 'lib/viewUtils';

import { carregaDetalhes, itemSelector } from '../../../reducers/nfse/detalhes';

import DadosBasicos from './dados_basicos';
import AbasInformacoes from './abas_informacoes';
import Voltar from './voltar';
import Icone from '../../common/icone';
import BotoesDetalhes from '../../common/detalhes_documento/botoes_detalhes';
import { Breadcrumbs, Title } from '../../common/router_components';

type DetalhesProps = {
  routes : IRoute[],
  routeParams : { id : string };
}

const itemJsSelector = jsSelector(itemSelector, {});

export default function Detalhes({ routes, routeParams } : DetalhesProps) {
  const item = useSelector(itemJsSelector);

  const dispatch         = useDispatch(),
        carregarDetalhes = React.useCallback((id) => dispatch(carregaDetalhes(id)), [dispatch]);

  useEffectOnce(() => {
    // Caso os dados do item não estejam carregados, carrega detalhes do item
    if (_.isEmpty(item) && routeParams)
      carregarDetalhes(routeParams.id);
  });

  return (
      <>
        <Title routes={ routes }/>
        <Breadcrumbs routes={ routes }/>

        <Row className="botao-voltar-top">
          <Voltar classe="pull-right"/>
        </Row>

        <section className="visualizacao-documento detalhes-documento">
          <DadosBasicos item={ item }/>
          <AbasInformacoes item={ item }/>
        </section>

        <BotoesDetalhes id={ item.id } tipo="nfse" textoBotaoDownloadPdf="Gerar PDF" BotaoVoltar={ Voltar }>
          <a className="btn btn-default" href={ item.linkImpressao } target="_blank" rel="noopener noreferrer">
            <Icone nome="link" estilo="fas"/>{ ' ' }
            Ver na Prefeitura
          </a>
        </BotoesDetalhes>
      </>
  );
}
