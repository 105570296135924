'use strict';
// @flow

import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import type { ILegacyPaginatedData } from 'lib/types';
import { jsSelector } from 'lib/viewUtils';
import * as lista from 'reducers/sped/arquivos/lista';

import type { ISpedArquivo } from '../types';
import { arquivoNav } from '../arquivo_path';

import PaginationFooter from 'components/common/pagination_footer';
import Contador from 'components/common/contador';
import Filtros from '../filtros';
import Tabela from './tabela';

function trocaPagina(page) {
  arquivoNav.listagem({ page });
}

type ListagemProps = {
  arquivos? : ILegacyPaginatedData<ISpedArquivo>,
  carregaLista : (params : {}) => void,
  children? : any,
  ignoraCarregaListaInicial? : bool,
  lastQuery? : any,
  location : { query : any },
}

/**
 * Este componente possui uma prop "children", que é onde o react-router injeta a modal de criação de novo arquivo.
 *
 * Isso é necessário para que a modal abra sem perder informação de navegação da listagem.
 */
export function Listagem({ arquivos = {}, carregaLista, children, ...props } : ListagemProps) {
  const { page = 1, total = 0, items = 'loading', pageSize } = arquivos;

  const handlePagination = React.useCallback((page : number) => {
    trocaPagina(page);
    carregaLista({ ...(props.location.query || {}), page });
  }, [carregaLista, props.location]);

  return (
      <div>
        <Filtros { ...props }/>

        <Contador total={ total } prefix="Arquivos encontrados:"/>
        <Tabela dados={ items }/>
        <PaginationFooter label="Arquivos" page={ page } total={ total } pageSize={ pageSize }
                          onPageChange={ handlePagination }/>
        { children }
      </div>
  );
}

const mapStateToProps    = createStructuredSelector({
        arquivos: jsSelector(lista.currentPage),
      }),
      mapDispatchToProps = {
        carregaLista: lista.carregaLista,
      };

export default connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(Listagem);