// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfe from '../form_exportacao_nfe/campos_nfe/selecao_todos_nfe';
import { Input } from '../../../../../common/input';

type CamposManifestacaoProps = {
  component : React.ComponentType<*>,
}

export default function CamposManifestacaoDestinatario({ component } : CamposManifestacaoProps) {
  const SelecaoTodos = component || SelecaoTodosNfe;

  return (
      <FormSection name="manifestacaoDestinatario">
        <SelecaoTodos nomes={ ["manifestacaoDestinatario"] } titulo="Manifestação do Destinatário"/>

        <div className="grupo-checkbox">
          <Input name="manifestacao" label="Manifestação" checkbox/>
          <Input name="manifestacaoRealizadaPor" label="Usuário" checkbox/>
          <Input name="manifestacaoJustificativa" label="Justificativa" checkbox/>
          <Input name="manifestacaoRealizadaEm" label="Data" checkbox/>
        </div>
      </FormSection>
  );
}