'use strict';
// @flow

import * as _ from 'lodash';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import moment from 'moment';
import { autobind } from 'core-decorators';
import { FormControl } from 'react-bootstrap';
import { default as WrappedDatePicker } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const FORMATS = ['DD/MM/YYYY', 'YYYY-MM-DD', moment.ISO_8601];

type DatePickerProps = {
  onChange : (value : PickerValue) => any,
  value : ?PickerValue,
  placeholder? : string,
  width? : string | number,
  name? : string,
  id? : string
};

type PickerValue = ?(string | moment | Date);

/**
 * Componente que encapsula uma seleção de datas.
 *
 * Ele existe como um "façade" para outros componentes, para que seja fácil de trocar a implementação.
 */
export default class DatePicker extends React.PureComponent<DatePickerProps, void> {
  props : DatePickerProps;

  @autobind
  handlePickerApply(date : PickerValue) {
    this.props.onChange(date);
  }

  @autobind
  handleTextChanged(e : SyntheticInputEvent<*>) {
    this.props.onChange(e.target.value);
  }

  // formattedValue() : string {
  //   const value = this.parsedValue();
  //
  //   return (value && value.format(FORMATS[0])) || '';
  // }

  parsedValue() : ?moment {
    const { value } = this.props;

    if (value instanceof Date)
      return moment(value);
    if (value instanceof moment)
      return value;

    if (typeof value === 'string') {
      const m = moment(value, FORMATS, true);
      if (m.isValid())
        return m;
    }

    return undefined;
  }

  render() {
    const { placeholder, width, ...props } = this.props;
    delete props.value;

    return (
        <WrappedDatePicker
            { ...props }
            customInput={ <FormControlWrapper width={ width }/> }
            selected={ this.parsedValue() } placeholderText={ placeholder }
            onChange={ this.handlePickerApply } onChangeRaw={ this.handleTextChanged }
        />
    );
  }
}

type FormControlWrapperProps = { width? : string | number, style? : any };

class FormControlWrapper extends React.PureComponent<FormControlWrapperProps, *> {
  props : FormControlWrapperProps;

  /**
   * O react-datepicker chama o "focus" do input quando uma data é selecionada. Porém, o componente
   * FormControl não possui método "focus". Definimos, então, um aqui.
   */
  focus() {
    // HACK: código desabilitado em ambiente de testes, por causar mais problemas do que resolve
    if (_.get(document, 'body.dataset.env') !== 'test') {
      const node : any = ReactDOM.findDOMNode(this);
      if (node && typeof node.focus === 'function')
        node.focus();
    }
  }

  render() {
    const { width, ...props } = this.props;

    return <FormControl style={ { width: width || '7.5em' } } { ...props } />;
  }
}