// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfce from './selecao_todos_nfce';
import CamposDocumento from '../../common/campos_documento';
import { Input } from '../../../../../../common/input';

export default function CamposBasicos() {
  return (
      <FormSection name="dadosBasicos">
        <SelecaoTodosNfce nomes={ ["dadosBasicos"] } titulo="Dados Básicos"/>
        <div className="grupo-checkbox">
          <CamposDocumento/>

          <Input name="naturezaOperacao" label="Natureza da Operação" checkbox/>
          <Input name="tipoOperacaoIdEDescr" label="Operação (entrada ou saída)" checkbox/>
          <Input name="destinoOperacao" label="Destino da Operação" checkbox/>
          <Input name="tipoEmissao" label="Tipo de Emissão" checkbox/>
          <Input name="finalidade" label="Finalidade de Emissão" checkbox/>
          <Input name="consumidorFinal" label="Consumidor Final" checkbox/>
          <Input name="indicadorPresenca" label="Presença do comprador" checkbox/>
          <Input name="situacaoSefaz" label="Situação na SEFAZ" checkbox/>
          <Input name="autorizadoEm" label="Data de Autorização" checkbox/>
          <Input name="informacoesAdicionaisFisco" label="Inform. Adicionais Fisco" checkbox/>
          <Input name="informacoesComplementares" label="Inform. Complementares" checkbox/>
        </div>
      </FormSection>
  );
}