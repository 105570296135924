// @flow

import React from 'react';

import CampoTexto from '../../../../common/campo_texto';
import { Input } from '../../../../common/input';

type SelecaoContaProps = {
  opcoesContas : Array<{ value : string, label : string }>,
}

export default function SelecaoConta({ opcoesContas } : SelecaoContaProps) {
  return (
      <Input name="conta_id" label="Conta" placeholder="Conta" component={ CampoTexto } options={ opcoesContas }
             seletorComPlaceholder style={ { width: '100em' } }/>
  );
}
