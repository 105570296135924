// @flow
'use strict';

import * as React from 'react';
import { Col, ControlLabel, Row } from 'react-bootstrap';

import { Input } from '../input';

type CampoSituacaoSefazProps = {
  campos : { label : string, name : string }[],
}

export default function CampoSituacaoSefaz({ campos } : CampoSituacaoSefazProps) {
  return (
      <Row className="campo-formulario campo-input">
        <ControlLabel className="col-sm-4 titulo-checkbox">Situação na SEFAZ</ControlLabel>
        <Col sm={ 8 } className="opcao-checkbox margin-bottom-1">
          { campos && campos.length > 0 &&
              campos.map((c, k) => <Input key={ k } name={ c.name } label={ c.label } checkbox/>)
          }
        </Col>
      </Row>
  );
}
