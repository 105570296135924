// @flow
'use strict';

import * as React from 'react';
import { Button } from 'react-bootstrap';

type AlertasProps = {
  alertas : string[],
  cor : 'success' | 'danger' | 'warning',
  fechaAlertas : () => void,
}

export default function Alertas({ alertas, cor, fechaAlertas } : AlertasProps) {
  if (alertas.length === 0)
    return null;

  return (
      <div className={ `alert alert-${ cor }` }>
        <Button className="close" onClick={ fechaAlertas }>
          <span aria-hidden="true">&times;</span>
        </Button>
        { alertas.map((a, i) => <p key={ i }>{ a }</p>) }
      </div>
  );
}
