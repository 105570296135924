// @flow
'use strict';

import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import type { IDestinatario, IEmitente, ITransportador, ItemProps } from '../types';

import Nfe from './nfe';
import Emitente from './emitente';
import Destinatario from './destinatario';
import ProdutoServico from './produto_servico';
import Totais from './totais';
import Transporte from './transporte';
import Cobranca from './cobranca';
import Pagamento from './pagamento/pagamento';
import InformacoesAdicionais from './informacoes_adicionais';

export default function AbasInformacoes({ item } : ItemProps) {
  if (item.possuiXml) {
    return (
        <Tabs id="tab-detalhes-nfe" defaultActiveKey="nfe">
          <Tab eventKey="nfe" title="NF-e">
            <Nfe item={ item }/>
          </Tab>
          <Tab eventKey="emitente" title="Emit.">
            <Emitente emitente={ item.emitente }/>
          </Tab>
          <Tab eventKey="destinatario" title="Dest.">
            <Destinatario destinatario={ item.destinatario } pessoasAutorizadas={ item.pessoasAutorizadas }/>
          </Tab>
          <Tab eventKey="produto-servico" title="Produtos/Serviços">
            <ProdutoServico produtoServico={ item.produtoServico }/>
          </Tab>
          <Tab eventKey="totais" title="Totais">
            <Totais totais={ item.totais }/>
          </Tab>
          <Tab eventKey="transporte" title="Transp.">
            <Transporte transporte={ item.transporte }/>
          </Tab>
          <Tab eventKey="cobranca" title="Cobr.">
            <Cobranca cobranca={ item.cobranca }/>
          </Tab>
          <Tab eventKey="pagamento" title="Pgto.">
            <Pagamento pagamentos={ item.pagamentos } intermediadorTransacao={ item.intermediadorTransacao }/>
          </Tab>
          <Tab eventKey="informacoes-adicionais" title="Inf. Adicionais">
            <InformacoesAdicionais informacoesAdicionais={ item.informacoesAdicionais }
                                   informacoesPedidoEmissaoNff={ item.informacoesPedidoEmissaoNff }/>
          </Tab>
        </Tabs>
    );
  }

  return (
      <Tabs id="tab-detalhes-nfe" defaultActiveKey="nfe">
        <Tab eventKey="nfe" title="NF-e">
          <Nfe item={ item }/>
        </Tab>
      </Tabs>
  );
}

export function tipoDocumento(ator : IDestinatario | IEmitente | ITransportador) : string {
  if (!ator || !ator.cnpj)
    return 'CNPJ';

  if (ator.cnpj.length === 11)
    return 'CPF';

  if (ator.cnpj.length === 14)
    return 'CNPJ';

  return 'ID Estrangeiro';
}
