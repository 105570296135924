// @flow
'use strict';

import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import type { IDestinatario, IEmitente, IExpedidor, IRecebedor, IRemetente, ITomador, ItemProps } from '../types';

import Cte from './cte';
import Emitente from './emitente';
import Tomador from './tomador';
import Remetente from './remetente';
import Destinatario from './destinatario';
import Expedidor from './expedidor';
import Recebedor from './recebedor';
import Totais from './totais';
import Carga from './carga';
import Modal from './modal';
import InformacoesAdicionais from './informacoes_adicionais';

export default function AbasInformacoes({ item } : ItemProps) {
  if (item.possuiXml) {
    return (
      <Tabs id="tab-detalhes-cte" defaultActiveKey="cte">
        <Tab eventKey="cte" title="CT-e">
          <Cte item={ item }/>
        </Tab>
        <Tab eventKey="emitente" title="Emit.">
          <Emitente emitente={ item.emitente }/>
        </Tab>
        <Tab eventKey="tomador" title="Tom.">
          <Tomador tomador={ item.tomador }/>
        </Tab>
        <Tab eventKey="remetente" title="Rem.">
          <Remetente remetente={ item.remetente }/>
        </Tab>
        <Tab eventKey="destinatario" title="Dest.">
          <Destinatario destinatario={ item.destinatario }/>
        </Tab>
        <Tab eventKey="expedidor" title="Exped.">
          <Expedidor expedidor={ item.expedidor }/>
        </Tab>
        <Tab eventKey="recebedor" title="Receb.">
          <Recebedor recebedor={ item.recebedor }/>
        </Tab>
        <Tab eventKey="totais" title="Totais">
          <Totais totais={ item.totais }/>
        </Tab>
        <Tab eventKey="carga" title="Carga">
          <Carga carga={ item.carga }/>
        </Tab>
        <Tab eventKey="modal" title="Modal">
          <Modal modal={ item.modal }/>
        </Tab>
        <Tab eventKey="informacoes-adicionais" title="Inf. Ad.">
          <InformacoesAdicionais informacoesAdicionais={ item.informacoesAdicionais }/>
        </Tab>
      </Tabs>
    );
  }

  return (
    <Tabs id="tab-detalhes-cte" defaultActiveKey="cte">
      <Tab eventKey="cte" title="CT-e">
        <Cte item={ item }/>
      </Tab>
    </Tabs>
  );
}

export function tipoDocumento(ator : IEmitente | ITomador | IRemetente | IDestinatario | IExpedidor | IRecebedor | Object) : string {
  if (!ator || !ator.cnpj)
    return 'CNPJ';

  if (ator.cnpj.length === 11)
    return 'CPF';

  if (ator.cnpj.length === 14)
    return 'CNPJ';

  return 'ID Estrangeiro';
}
