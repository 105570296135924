// @flow
'use strict';

import { combineReducers } from 'redux-immutable';

import nfe from './nfe';
import cte from './cte';
import nfce from './nfce';
import nfse from './nfse';

export default combineReducers({
  nfe, cte, nfce, nfse,
});