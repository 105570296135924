// @flow
'use strict';

import * as _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import type { IItem } from './types';
import type { IRoute } from 'lib/types';
import { carregaDetalhes, itemSelector } from '../../../reducers/cte/detalhes';
import { modalOpenSelector } from '../../../reducers/cte/historico';
import { useEffectOnce, jsSelector } from 'lib/viewUtils';

import DadosBasicos from './dados_basicos';
import AbasInformacoes from './abas_informacoes';
import Voltar from './voltar';
import PainelLateral from '../../paineis';
import ModalHistorico from './modal_historico';
import BotaoHistorico from './botao_historico';
import AvisoNaoCustodiado from '../../common/detalhes_documento/aviso_nao_custodiado';
import BotoesDetalhes from '../../common/detalhes_documento/botoes_detalhes';
import { Breadcrumbs, Title } from '../../common/router_components';

type DetalhesProps = {
  routes : IRoute[],
  routeParams : { id : string };
}

const itemJsSelector = jsSelector(itemSelector, {});

export default function Detalhes({ routes, routeParams } : DetalhesProps) {
  const item : IItem  = useSelector(itemJsSelector),
        historicoOpen = useSelector(modalOpenSelector);

  const dispatch         = useDispatch(),
        carregarDetalhes = React.useCallback((id : string) => dispatch(carregaDetalhes(id)), [dispatch]);

  useEffectOnce(() => {
    // Caso os dados do item não estejam carregados, carrega detalhes do item
    if (_.isEmpty(item) && routeParams)
      carregarDetalhes(routeParams.id);
  });

  return (
      <>
        <Title routes={ routes }/>
        <Breadcrumbs routes={ routes }/>

        {/* Precisa desta comparação com false porque o campo inicia com o valor undefined, e exibe o aviso indevidamente */
          item.possuiXml === false && <AvisoNaoCustodiado/> }

        <Row>
          <Col md={ 9 } className="r"><Voltar/></Col>
          <Col md={ 3 }><BotaoHistorico id={ item.id }/></Col>
        </Row>
        <Row>
          <div className="col-md-9" style={ { marginTop: '-.8em' } }>
            <section className="visualizacao-documento detalhes-documento">
              <DadosBasicos item={ item }/>
              <AbasInformacoes item={ item }/>
            </section>

            <BotoesDetalhes id={ item.id } tipo="cte" possuiXml={ item.possuiXml } textoBotaoDownloadPdf="Download DACTE" BotaoVoltar={ Voltar }/>
          </div>

          <Col md={ 3 }>
            <PainelLateral ignoreInitialize url={ item.url } env={ item.env } reconsulta={ item.possuiXml }/>
          </Col>
        </Row>

        { historicoOpen && <ModalHistorico/> }
      </>
  );
}
