// @flow
'use strict';

import { type Saga } from 'redux-saga';
import { cancelled, put, takeLatest, throttle } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';

import request from 'lib/request';
import { ATUALIZA_CAIXAS, ATUALIZA_LISTA, BASE_PATH, LISTA_CARREGADA, LISTA_CARREGANDO } from 'reducers/emails';
import { ajaxError } from 'reducers/alerts';

export default function* emails() : Saga<*> {
  yield takeLatest(ac => ac.type === LOCATION_CHANGE && ac.payload.pathname === BASE_PATH, carregaListagem);

  yield throttle(5000, LISTA_CARREGANDO, carregaCaixas);
}

function* carregaListagem({ payload: { pathname, search } }) {
  yield put({ type: LISTA_CARREGANDO });

  const req = request.get(pathname + search).accept('json');

  try {
    const res = yield req;
    yield put({ type: ATUALIZA_LISTA, listagem: res.body });
  }
  catch (e) {
    yield put(ajaxError('Ocorreu um erro ao carregar a lista de e-mails', e));
  }
  finally {
    yield put({ type: LISTA_CARREGADA });
    if (yield cancelled())
      req.cancel();
  }
}

function* carregaCaixas() {
  const req = request.get(`${ BASE_PATH }/caixas`).accept('json');

  try {
    const res = yield req;
    yield put({ type: ATUALIZA_CAIXAS, payload: res.body });
  }
  finally {
    if (yield cancelled())
      req.cancel();
  }
}
