// @flow

import React from 'react';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import Icone from '../icone';

type VoltarProps = {
  path : string,
  classe? : string,
}

export default function Voltar({ path, classe } : VoltarProps) {
  const props = classe ? { className: classe } : {};

  return (
      <LinkContainer to={ path }>
        <Button { ...props } bsStyle="link">
          <Icone nome="chevron-left" estilo="fas">
            Voltar à Listagem
          </Icone>
        </Button>
      </LinkContainer>
  );
}
