// @flow
'use strict';

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import type { IProdutoServico } from '../../../types';

import DadosBasicos from './dados_basicos';
import Imposto from './imposto';

type ModalProps = {
  open : bool,
  fechaModal : ()  => void,
  item : IProdutoServico,
}

export default function ModalDetalhes({ open, fechaModal, item } : ModalProps) {
  const titulo = `Detalhes do Item ${ item.item }: ${ item.descricao }`;

  return (
      <Modal show={ open } onHide={ fechaModal } dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>{ titulo }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="visualizacao-documento">
            <DadosBasicos item={ item }/>
            <Imposto item={ item.imposto }/>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={ fechaModal }>Fechar</Button>
        </Modal.Footer>
      </Modal>
  );
}