// @flow
'use strict';

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Row } from 'react-bootstrap';

import { useEffectOnce } from '../../../../../lib/viewUtils';
import { carregaMunicipios, municipiosParaBusca } from '../../../../../reducers/municipios';

import { Input } from '../../../../common/input';

export default function CampoMunicipio() {
  const dispatch = useDispatch();

  useEffectOnce(() => {
    dispatch(carregaMunicipios());
  });

  return (
    <Row className="campo-formulario">
      <Input sm={ 9 } name="municipio" label="Município" optionsSelector={ municipiosParaBusca }
             placeholder="Selecionar município..." seletorComPlaceholder/>
    </Row>
  );
}
