// @flow
'use strict';

import * as React from 'react';
import { Button } from 'react-bootstrap';

import Icone from '../../common/icone';

type BotoesDetalhesProps = {
  id : string,
  tipo : string,
  possuiXml? : bool,
  textoBotaoDownloadPdf : string,
  BotaoVoltar : React.ComponentType<*>,
  children? : React.Node,
}

export default function BotoesDetalhes({ id, tipo, possuiXml, textoBotaoDownloadPdf, BotaoVoltar, children } : BotoesDetalhesProps) {
  const obtemXml = () => location.href = `/app/${ tipo }/${ id }/download_xml`,
        obtemPdf = () => location.href = `/app/${ tipo }/${ id }/download_pdf`;

  return (
    <section className="visualizacao-documento">
      { children }
      { ' ' }
      <Button type="button" bsStyle="default" onClick={ obtemPdf } disabled={ !possuiXml && tipo !== 'nfse' }>
        <Icone nome="print" estilo="fas"/>{ ' ' }
        { textoBotaoDownloadPdf }
      </Button>
      { ' ' }
      <Button type="button" bsStyle="default" onClick={ obtemXml } disabled={ !possuiXml && tipo !== 'nfse' }>
        <Icone nome="cloud-download" estilo="fas"/>{ ' ' }
        Download do XML
      </Button>

      <BotaoVoltar classe="pull-right"/>
    </section>
  );
}
