// @flow
'use strict';

import React from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { useEffectOnce } from 'lib/viewUtils';
import {
  alertasSelector, carregaListaRelatorios, fechaAlertas,
} from '../../../../../reducers/modelo_relatorio/modal_lista_relatorios';
import {
  openRelatorioSelector, fechaRelatorio,
} from '../../../../../reducers/modelo_relatorio/modal_escolha_tipo_relatorio';

import FormTipoDocumento from '../../modal_download_lista/form_tipo_documento';
import Alertas from './alertas';
import LinhasTabela from './linhas_tabela';

type ListaRelatoriosProps = {
  tipo : string,
}

export default function ModalListaRelatorios({ tipo } : ListaRelatoriosProps) {
  const alertas       = useSelector(alertasSelector),
        openRelatorio = useSelector(openRelatorioSelector);

  const dispatch = useDispatch();

  useEffectOnce(() => {
    dispatch(carregaListaRelatorios(tipo));
    dispatch(fechaAlertas());
  });

  if (openRelatorio) {
    return (
        <Modal show onHide={ dispatch(fechaRelatorio) } dialogClassName="custom-modal">
          <FormTipoDocumento tipo={ tipo }/>
        </Modal>
    );
  }

  return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Recuperar relatório</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { alertas && <Alertas alertas={ alertas } cor="success"/> }

          <p>Relatórios salvos por você e por outras pessoas da sua conta.</p>
          <Table condensed striped>
            <colgroup>
              <col/>
              <col/>
              <col/>
              <col width="5%"/>
            </colgroup>
            <thead>
              <tr>
                <th>Relatório</th>
                <th>Data de criação</th>
                <th colSpan={ 3 }>Criado por</th>
              </tr>
            </thead>
            <tbody>
              <LinhasTabela/>
            </tbody>
          </Table>
        </Modal.Body>
      </>
  );
}
