// @flow
'use strict';

import React from 'react';
import { Button, Modal } from 'react-bootstrap';

type AlertaProps = {
  carregado : bool,
  fechaModal : () => void,
}

const MSG_CARREGANDO      = 'Aguarde, calculando total de registros.',
      MSG_LIMITE_EXCEDIDO = 'A quantidade de arquivos excede a capacidade de exportação para lista. Realize mais filtros, para buscar apenas os arquivos mais importantes, e tente novamente.';

export default function Alerta({ carregado, fechaModal } : AlertaProps) {
  return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>Download de Documentos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> { carregado ? MSG_LIMITE_EXCEDIDO : MSG_CARREGANDO }</p>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={ fechaModal }>Cancelar</Button>
          <Button type="button" bsStyle="primary" onClick={ fechaModal } disabled={ !carregado }>OK</Button>
        </Modal.Footer>
      </div>
  );
}