'use strict';
// @flow

import * as React from 'react';

import Timeago from 'components/common/timeago';

export default function UltimaConsulta({ data } : { data : ?string }) {
  if (!data)
    return <span>Nenhuma consulta foi realizada até o momento.</span>;

  return (
      <span>
        Última consulta à SEFAZ:<br/>
        <Timeago time={ data } updateInterval={2000}/>
      </span>
  );
}