// @flow
'use strict';

import * as React from 'react';

import type { IIcms45 } from '../../../types_imposto';

import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';

type Icms45Props = {
  icms : IIcms45,
};

export default function Icms45({ icms } : Icms45Props) {
  return (
      <>
        <h4>ICMS - 45 = Isento, não tributado ou diferido</h4>
        <RowDetalhes data={ icms }>
          <ColumnDetalhes field="cst">Classificação Tributária do Serviço</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
