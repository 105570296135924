'use strict';
// @flow

import * as Immutable from 'immutable';
import Cookies from 'universal-cookie';
import { createSelector } from 'reselect';
import { push } from 'react-router-redux';

import type { Action, State, Dispatch, GetState } from 'lib/types';
import * as Paths from '../../paths';
import request from 'lib/request';
import { ajaxError } from '../alerts';
import { currentRouteSelector } from '../routing';

export const LISTA_CARREGANDO        = 'nfse/lista/LISTA_CARREGANDO',
             LISTA_CARREGADA         = 'nfse/lista/LISTA_CARREGADA',
             ALTERA_LAST_PATH        = 'nfse/lista/ALTERA_LAST_PATH',
             ALTERA_QUERY_EXPORTACAO = 'nfse/lista/ALTERA_QUERY_EXPORTACAO';

const cookie = new Cookies();

const INITIAL_STATE = Immutable.fromJS({
  paginaAtual: {},
  carregando: false,
  queryExportacao: {},
  lastPath: cookie.get('lastPathNfse') || '/app/nfse/lista/todas',
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case LISTA_CARREGANDO:
      return state.set('carregando', true);
    case LISTA_CARREGADA:
      return state.set('carregando', false).set('paginaAtual', action.body);
    case ALTERA_LAST_PATH:
      return state.set('lastPath', action.path);
    case ALTERA_QUERY_EXPORTACAO:
      return state.set('queryExportacao', action.queryExportacao);

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['nfse', 'lista'], Immutable.Map());
export const currentPage = createSelector<*, *, *, *>(rootSelector, (root) => root.get('paginaAtual'));
export const totalSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.getIn(['paginaAtual', 'total'], 0));
export const lastPathSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('lastPath', '/app/nfse/lista/todas'));
export const queryExportacaoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('queryExportacao'));

// actions

export function listaCarregando() {
  return { type: LISTA_CARREGANDO };
}

export function listaCarregada(lista : any) {
  return { type: LISTA_CARREGADA, body: Immutable.fromJS(lista) };
}

// thunk actions

export function trocaPagina(page : number) {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const query = currentRouteSelector(getState()).get('query').toJS();
    query.page = page;
    dispatch(carregaLista(query));
  };
}

export function carregaLista(query : any = {}) {
  return async function(dispatch : Dispatch<any>) {
    const path = Paths.nfses.lista(query);
    dispatch(push(path));

    const queryExportacao = query && query.q ? { q: query.q } : {};

    await dispatch({ type: ALTERA_QUERY_EXPORTACAO, queryExportacao });

    await dispatch({ type: ALTERA_LAST_PATH, path });
    cookie.set('lastPathNfse', path);

    await dispatch(listaCarregando());

    await request.get(`/app/nfse/lista/todas`)
        .accept('json')
        .query(query)
        .then(r => dispatch(listaCarregada(r.body)))
        .catch(e => {
          dispatch(listaCarregada({}));
          dispatch(ajaxError('Erro ao carregar lista de NFS-e', e));
        });
  };
}