// @flow
'use strict';

import * as React from 'react';

import type { IDadosComplementares } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';

type DadosComplementaresProps = {
  dadosComplementares : IDadosComplementares,
};

export default function DadosComplementares({ dadosComplementares } : DadosComplementaresProps) {
  if (!dadosComplementares)
    return null;

  return (
      <>
        <h3>Dados Complementares do CT-e</h3>
        <RowDetalhes data={ dadosComplementares } className="no-margin-bottom">
          <ColumnDetalhes field="caracteristicaTransporte" width="50%">Característica Transporte</ColumnDetalhes>
          <ColumnDetalhes field="caracteristicaServico" width="50%">Característica Serviço</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ dadosComplementares }>
          <ColumnDetalhes field="funcionarioEmissorCte">Funcionário Emissor do CT-e</ColumnDetalhes>
        </RowDetalhes>

        { dadosComplementares.infPrevisaoEntrega &&
            <>
              <h4>Informações referente a previsão de entrega</h4>
              { dadosComplementares.infPrevisaoEntrega.semData &&
                  <RowDetalhes data={ dadosComplementares.infPrevisaoEntrega.semData } className="no-margin-bottom">
                    <ColumnDetalhes field="tipoEntrega">Tipo Entrega</ColumnDetalhes>
                  </RowDetalhes>
              }
              { dadosComplementares.infPrevisaoEntrega.comData &&
                  <RowDetalhes data={ dadosComplementares.infPrevisaoEntrega.comData } className="no-margin-bottom">
                    <ColumnDetalhes field="tipoEntrega" width="50%">Tipo Entrega</ColumnDetalhes>
                    <ColumnDetalhes field="dataProgramada" width="50%" type="data">Data Programada</ColumnDetalhes>
                  </RowDetalhes>
              }
              { dadosComplementares.infPrevisaoEntrega.noPeriodo &&
                  <RowDetalhes data={ dadosComplementares.infPrevisaoEntrega.noPeriodo } className="no-margin-bottom">
                    <ColumnDetalhes field="tipoEntrega" width="33%">Tipo Entrega</ColumnDetalhes>
                    <ColumnDetalhes field="dataInicial" width="33%" type="data">Data Inicial</ColumnDetalhes>
                    <ColumnDetalhes field="dataFinal" width="33%" type="data">Data Final</ColumnDetalhes>
                  </RowDetalhes>
              }
              { dadosComplementares.infPrevisaoEntrega.semHora &&
                  <RowDetalhes data={ dadosComplementares.infPrevisaoEntrega.semHora } className="no-margin-bottom">
                    <ColumnDetalhes field="tipoHora">Tipo Hora</ColumnDetalhes>
                  </RowDetalhes>
              }
              { dadosComplementares.infPrevisaoEntrega.comHora &&
                  <RowDetalhes data={ dadosComplementares.infPrevisaoEntrega.comHora } className="no-margin-bottom">
                    <ColumnDetalhes field="tipoHora" width="50%">Tipo Hora</ColumnDetalhes>
                    <ColumnDetalhes field="horaProgramada" width="50%">Hora Programada</ColumnDetalhes>
                  </RowDetalhes>
              }
              { dadosComplementares.infPrevisaoEntrega.noInter &&
                  <RowDetalhes data={ dadosComplementares.infPrevisaoEntrega.noInter } className="no-margin-bottom">
                    <ColumnDetalhes field="tipoHora" width="33%">Tipo Hora</ColumnDetalhes>
                    <ColumnDetalhes field="horaInicial" width="33%">Hora Inicial</ColumnDetalhes>
                    <ColumnDetalhes field="horaFinal" width="33%">Hora Final</ColumnDetalhes>
                  </RowDetalhes>
              }
            </>
        }
      </>
  );
}
