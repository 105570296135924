// @flow
'use strict';

import * as React from 'react';

import type { IPisAliq } from '../../../../../types_imposto';

import RowDetalhes from '../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../common/detalhes_documento/column_detalhes';

type TributoAliquotaProps = {
  item : IPisAliq,
};

export default function TributoAliquota({ item } : TributoAliquotaProps) {
  return (
      <RowDetalhes data={ item }>
        <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
        <ColumnDetalhes field="valorBc" type="moeda" width="25%">Valor Base de Cálculo</ColumnDetalhes>
        <ColumnDetalhes field="aliquota" type="percentual" width="25%">Alíquota</ColumnDetalhes>
        <ColumnDetalhes field="valorPis" type="moeda" width="25%">Valor do PIS</ColumnDetalhes>
      </RowDetalhes>
  );
}
