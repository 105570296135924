// @flow
'use strict';

import * as Immutable from 'immutable';
import $ from 'jquery';
import { createSelector } from 'reselect';

import type { Action, Dispatch, GetState, State } from 'lib/types';
import store from './../../../stores/redux_store';
import request from '../../../lib/request';
import { carregaLista } from './lista';

const ABRE_MODAL           = 'arquivo/upload/ABRE_MODAL',
      FECHA_MODAL          = 'arquivo/upload/FECHA_MODAL',
      ALTERA_S3_PARAMS     = 'arquivo/upload/ALTERA_S3_PARAMS',
      ALTERA_STAGE         = 'arquivo/upload/ALTERA_STAGE',
      ALTERA_PROGRESSO     = 'arquivo/upload/ALTERA_PROGRESSO',
      ALTERA_DADOS_ARQUIVO = 'arquivo/upload/ALTERA_DADOS_ARQUIVO';

type Stage = 'ready' | 'build_error' | 'building' | 'sending' | 'finished' | 'upload_error' | 'cancelled' | null;

const DEFAULT_STATE = Immutable.fromJS({
  open: false,
  stage: null,
  s3params: null,
  xhr: null,
  nomeArquivo: '',
  descricao: '',
  progresso: { bytesLoaded: null, bytesTotal: null },
});

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action = {}) {
  switch (action.type) {
    case ABRE_MODAL:
      return state.set('open', true);
    case FECHA_MODAL:
      return state.set('open', false);
    case ALTERA_S3_PARAMS:
      return state.set('s3params', action.body);
    case ALTERA_STAGE:
      return state.set('stage', action.body);
    case ALTERA_PROGRESSO:
      return state.set('progresso', action.body);
    case ALTERA_DADOS_ARQUIVO:
      return state.set('nomeArquivo', action.nomeArquivo)
          .set('descricao', action.descricao);
    default:
      return state;
  }
}

// actions

function abrirModal() {
  return { type: ABRE_MODAL };
}

function fecharModal() {
  return { type: FECHA_MODAL };
}

export function alteraS3params(params : any) {
  return { type: ALTERA_S3_PARAMS, body: Immutable.fromJS(params) };
}

export function alteraStage(stage : Stage | null) {
  return { type: ALTERA_STAGE, body: stage };
}

export function alteraProgresso(params : any) {
  return { type: ALTERA_PROGRESSO, body: Immutable.fromJS(params) };
}

export function alteraDadosArquivo(nomeArquivo : string, descricao : string) {
  return { type: ALTERA_DADOS_ARQUIVO, nomeArquivo, descricao };
}

// selectors
export const rootSelector = (state : State) => state.getIn(['arquivo', 'upload', 'modal'], Immutable.Map());
export const openModalSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('open'));
export const stageSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('stage'));
export const s3paramsSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('s3params'));
export const progressoSelector = createSelector<*, *, *, *>(rootSelector,
    (root) => {
      const nomeArquivo = root.get('nomeArquivo', null),
            descricao   = root.get('descricao', null),
            progresso   = root.get('progresso', { bytesLoaded: 0, bytesTotal: 0 }).toJS();
      return { nomeArquivo, descricao, ...progresso };
    });

// thunk actions

export function abreModal() {
  return async function(dispatch : Dispatch<*>) {
    await dispatch(alteraStage('building'));
    await dispatch(abrirModal());

    dispatch(preparaUpload());
  };
}

export function fechaModal() {
  return async function(dispatch : Dispatch<*>) {
    await dispatch(fecharModal());

    dispatch(alteraStage(null));
    dispatch(alteraS3params(null));
  };
}

export function atualizaStage(stage : Stage | null) {
  return async function(dispatch : Dispatch<*>) {
    dispatch(alteraStage(stage));
  };
}

export function preparaUpload() {
  return async function(dispatch : Dispatch<any>) {
    await request.post('/app/ferramentas/cargas_upload/monta_parametros_s3')
        .accept('json').type('json')
        .then(r => {
          dispatch(alteraS3params(r.body));
          dispatch(alteraStage('ready'));
        })
        .catch(() => dispatch(alteraStage('build_error')));
  };
}

let xhr = null;

export function realizaUpload(file : any, description : string) {
  return async function(dispatch : Dispatch<any>, getState : GetState) {
    const s3params = s3paramsSelector(getState()).toJS();

    if (!s3params)
      throw new Error('dados do S3 não estão carregados');

    const fd = new FormData();
    fd.append('key', s3params.key || '');
    fd.append('AWSAccessKeyId', s3params.aws_key || '');
    fd.append('acl', s3params.acl || '');
    fd.append('success_action_status', '200');
    fd.append('policy', s3params.policy || '');
    fd.append('signature', s3params.signature || '');
    fd.append('file', file);

    xhr = $.ajax({
      xhr: buildXhrWithProgress,
      method: 'POST', url: s3params.action_url,
      dataType: 'xml', processData: false, contentType: false,
      data: fd,
    })
        .done(() => {
          dispatch(atualizaUpload(s3params.path, file.name, description));
          unlockNavigation();
        })
        .fail(() => unlockNavigation());
  };
}

export function cancelaUpload() {
  const cancelado = xhr && xhr.readyState !== 4 && xhr.abort();

  return async function(dispatch : Dispatch<any>) {
    if (cancelado && cancelado.status === 0)
      dispatch(alteraStage('cancelled'));
  };
}

/**
 * Constrói um objeto XMLHttpRequest específico, que consiga reportar o progresso de upload para o nosso componente,
 * através da atualização do state.
 *
 * @returns {XMLHttpRequest}
 */
function buildXhrWithProgress() {
  const xhr = new window.XMLHttpRequest();

  xhr.upload.addEventListener('progress', (evt) => {
    if (evt.lengthComputable)
      store.dispatch(alteraProgresso({ bytesLoaded: evt.loaded, bytesTotal: evt.total }));
  }, false);

  return xhr;
}

// Atualiza o registro de CargaUpload no servidor.
function atualizaUpload(path, nomeArquivo, descricao) {
  return async function(dispatch : Dispatch<any>) {
    await request.post(`/app/ferramentas/cargas_upload/${ path }`)
        .type('json').accept('json')
        .set({ 'X-Http-Method-Override': 'patch' })
        .send({ upload: { conteudo: nomeArquivo, descricao } })
        .then(() => {
          dispatch(alteraStage('finished'));
          dispatch(carregaLista(1));
        })
        .catch(() => dispatch(alteraStage('upload_error')));
  };
}

/**
 * Destrava a navegação do usuário.
 *
 * @see lockNavigation
 */
function unlockNavigation() {
  window.onbeforeunload = null;
}