// @flow
'use strict';

import * as React from 'react';
import { Col, ControlLabel, Row } from 'react-bootstrap';

import dateFormat from '../../../../lib/dateFormat';

import CampoTexto from '../../../common/campo_texto';
import { Input } from '../../../common/input';

type CampoIntervaloDataProps = {
  rotulo : string,
  nome : string,
  columnClassName? : string,
}

export default function CampoIntervaloData({ rotulo, nome, columnClassName = '' } : CampoIntervaloDataProps) {
  return (
      <Row>
        <Col sm={ 3 } className="titulo-emissao text-right">
          <ControlLabel>{ rotulo }</ControlLabel>
        </Col>
        <Col sm={ 9 } className={ columnClassName }>
            <Col sm={ 6 } className="campo-intervalo-de">
              <Input normalize={ dateFormat } name={ nome } label={ `${ rotulo } de` } placeholder="de"
                     component={ CampoTexto }/>
            </Col>
            <Col sm={ 6 } className="campo-intervalo-ate">
              <Input normalize={ dateFormat } name={ `${ nome }_ate` } label={ `${ rotulo } até` } placeholder="até"
                     component={ CampoTexto }/>
            </Col>
        </Col>
      </Row>
  );
}