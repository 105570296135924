// @flow
'use strict';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { aplicaFiltro, carregaOpcoesSelect } from '../../../../reducers/dashboard/filtro';

import Filtros from './filtros';

export default compose(
    connect<*, *, *, *, *, *>(null, { aplicaFiltro, carregaOpcoesSelect }),
    withRouter,
)(Filtros);