// @flow
'use strict';

import Url from 'url';

import { prepareQuery } from 'lib/viewUtils';

class Resource {
  baseUrl : string;

  constructor(baseUrl : string) {
    this.baseUrl = baseUrl;
  }

  colecao(params : {}) {
    return Url.format({ pathname: this.baseUrl, query: prepareQuery(params) });
  }

  item(id : string, params : {} = {}) {
    return Url.format({ pathname: `${ this.baseUrl }/${ id }`, query: prepareQuery(params) });
  }
}

export const admin = {
  empresas: new Resource('/app/admin/empresas'),
  certificados_digitais: new Resource('/app/admin/certificados_digitais'),
};

export const sped = {
  documentos: new Resource('/app/sped/documentos'),
  escriturados: new Resource('/app/sped/escriturados'),
};

const pathWithQuery = (baseUrl : string) => (params : {}) => Url.format({
  pathname: baseUrl, query: prepareQuery(params),
});

export const pathWithQueryAndFilter = (baseUrl : string) =>
    (params : {}, filtro : { sd : string, s : string, page? : string, f : string }) => {
      let url = baseUrl;

      if (!filtro)
        return Url.format({ pathname: url, query: prepareQuery(params) });

      if (filtro.f)
        url = `${ url }/f/${ filtro.f }`;

      if (filtro.sd && filtro.s)
        url = `${ url }/o/${ filtro.s }/${ filtro.sd }`;

      if (filtro.page)
        url = `${ url }/${ filtro.page }`;

      return Url.format({
        pathname: url, query: prepareQuery(params),
      });
    };

export const nfses = {
  lista: pathWithQuery('/app/nfse/lista/todas'),
};

export const nfes = {
  lista: { recebidos: pathWithQuery('/app/nfe/lista/recebidos') },
};

export const nfesFilter = {
  lista: {
    recebidos: pathWithQueryAndFilter('/app/nfe/lista/recebidos'),
    emitidos: pathWithQueryAndFilter('/app/nfe/lista/emitidos'),
    todos: pathWithQueryAndFilter('/app/nfe/lista/todos'),
  },
};

export const ctes = {
  lista: { tomados: pathWithQuery('/app/cte/lista/tomados') },
};

export const ctesFilter = {
  lista: {
    tomados: pathWithQueryAndFilter('/app/cte/lista/tomados'),
    emitidos: pathWithQueryAndFilter('/app/cte/lista/emitidos'),
    todos: pathWithQueryAndFilter('/app/cte/lista/todos'),
  },
};

export const nfces = {
  lista: {
    recebidos: pathWithQueryAndFilter('/app/nfce/lista/recebidos'),
    emitidos: pathWithQueryAndFilter('/app/nfce/lista/emitidos'),
    todos: pathWithQueryAndFilter('/app/nfce/lista/todos'),
  },
};

export const cfesFilter = {
  lista: {
    recebidos: pathWithQueryAndFilter('/app/cfe/lista/recebidos'),
    emitidos: pathWithQueryAndFilter('/app/cfe/lista/emitidos'),
    todos: pathWithQueryAndFilter('/app/cfe/lista/todos'),
  },
};

export const noticias = {
  lista: pathWithQuery('/app/ferramentas/noticias'),
};

export const ferramentas = {
  prestadores: new Resource('/app/ferramentas/prestadores'),
};
