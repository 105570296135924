'use strict';
// @flow

import React from 'react';
import { ProgressBar } from 'react-bootstrap';

import { formatHelper } from 'lib/viewUtils';

type UploadProgressProps = {
  progress? : ProgressEvent,
};

/**
 * Barra de progresso para uploads.
 */
export default function UploadProgress({ progress } : UploadProgressProps) {
  if (progress && progress.loaded > 0 && progress.total > 0) {
    const pct = progress.loaded / progress.total * 100;

    return <ProgressBar now={ progress.loaded } max={ progress.total }
                        label={ `${ formatHelper.numberWithDelimiter(pct, 0) }%` }/>;
  }

  // Exibe barra de progresso animada quando o progresso é zero ou indefinido.
  // Isso mantém uma apresentação aceitável em navegadores que não atualizam o progresso corretamente, como o Edge.
  return <ProgressBar active now={ 100 } label="Upload em andamento…"/>;
}
