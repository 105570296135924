'use strict';
// @flow

import React from 'react';
import Bugsnag from 'lib/bugsnag';

import type { IRoute } from '../../lib/types';
import { Erro } from './router_components';

type BaseAppProps = { routes : IRoute[], children? : any };
type BaseAppState = { error? : Error, info? : any };

/**
 * Componente que representa uma aplicação básica.
 */
export default class AppWithoutTitle extends React.PureComponent<BaseAppProps, BaseAppState> {
  state : BaseAppState = {};

  componentDidCatch(error : Error, info : any) {
    Bugsnag.notify(error);
    this.setState({ error, info });
  }

  render() {
    const { children } = this.props,
          { error }    = this.state;

    return (
        <div>
          { error ? <Erro error={ error }/> : children }
        </div>
    );
  }
}
