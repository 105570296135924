// @flow

import { combineReducers } from 'redux-immutable';

import paineis from './paineis';
import filtro from './filtro';
import conta from './conta';
import empresa from './empresa';
import modal_noticia from './modal_noticia';

export default combineReducers({ paineis, filtro, conta, empresa, modal_noticia });