// @flow
'use strict';

import * as React from 'react';

import type { IDutoviario } from '../../types';

import RowDetalhes from '../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../common/detalhes_documento/column_detalhes';

type DutoviarioProps = {
  dutoviario : IDutoviario,
};

export default function Dutoviario({ dutoviario } : DutoviarioProps) {
  return (
      <>
        <h3>Dutoviário</h3>
        <RowDetalhes data={ dutoviario }>
          <ColumnDetalhes field="valorTarifa" type="moeda">Valor Tarifa</ColumnDetalhes>
          <ColumnDetalhes field="inicioPrestServico" type="data">Início Prest. Serviço</ColumnDetalhes>
          <ColumnDetalhes field="fimPrestServico" type="data">Fim Prest. Serviço</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
