'use strict';
// @flow

import * as Immutable from 'immutable';
import { push } from 'react-router-redux';
import { createSelector } from 'reselect';

import type { Action, State, Dispatch } from 'lib/types';
import request from 'lib/request';
import { ajaxError } from '../alerts';

export const DETALHES_CARREGADO  = 'nfse/detalhes/DETALHES_CARREGADO';

const INITIAL_STATE = Immutable.fromJS({
  item: {},
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case DETALHES_CARREGADO:
      return state.set('item', action.body);
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['nfse', 'detalhes'], Immutable.Map());
export const itemSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('item'));

// actions

export function detalhesCarregado(item : any) {
  return { type: DETALHES_CARREGADO, body: Immutable.fromJS(item) };
}

// thunk actions

export function carregaDetalhes(id : string) {
  return async function(dispatch : Dispatch<any>) {
    await request.get(`/app/nfse/${id}`)
        .accept('json')
        .then(r => {
          dispatch(push(`/app/nfse/${ id }`));

          dispatch(detalhesCarregado(r.body));
        })
        .catch(e => {
          dispatch(ajaxError('Erro ao carregar detalhes da NFS-e', e));

          if (window.location.pathname.split('/')[3] !== 'lista')
            dispatch(push('/app/dashboard'));
        });
  };
}
