'use strict';
// @flow

import * as Immutable from 'immutable';
import Cookies from 'universal-cookie';
import { createSelector } from 'reselect';
import { push } from 'react-router-redux';

import type { Action, Dispatch, GetState, State } from 'lib/types';
import type { IFiltro } from './filtro_livre';
import * as Paths from '../../paths';
import request from 'lib/request';
import { opcaoResumoSelector, ordenacaoSelector, tipoSelector } from './filtro_livre';
import { ajaxError } from '../alerts';
import { currentRouteSelector } from '../routing';

export const LISTA_CARREGANDO        = 'nfce/lista/LISTA_CARREGANDO',
             LISTA_CARREGADA         = 'nfce/lista/LISTA_CARREGADA',
             ALTERA_LAST_PATH        = 'nfce/lista/ALTERA_LAST_PATH',
             ALTERA_QUERY_EXPORTACAO = 'nfce/lista/ALTERA_QUERY_EXPORTACAO';

const cookie = new Cookies();

const INITIAL_STATE = Immutable.fromJS({
  paginaAtual: {},
  carregando: false,
  queryExportacao: {},
  lastPath: cookie.get('lastPathNfce') || '/app/nfce/lista/todos',
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case LISTA_CARREGANDO:
      return state.set('carregando', true);
    case LISTA_CARREGADA:
      return state.set('carregando', false).set('paginaAtual', action.body);
    case ALTERA_LAST_PATH:
      return state.set('lastPath', action.path);
    case ALTERA_QUERY_EXPORTACAO:
      return state.set('queryExportacao', action.queryExportacao);

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['nfce', 'lista'], Immutable.Map());
export const currentPage = createSelector<*, *, *, *>(rootSelector, (root) => root.get('paginaAtual'));
export const carregandoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('carregando'));
export const totalSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.getIn(['paginaAtual', 'total'], 0));
export const suporteSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.getIn(['paginaAtual', 'suporte'], false));
export const lastPathSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('lastPath', '/app/nfce/lista/todos'));
export const queryExportacaoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('queryExportacao'));

// actions

export function listaCarregando() {
  return { type: LISTA_CARREGANDO };
}

export function listaCarregada(lista : any) {
  return { type: LISTA_CARREGADA, body: Immutable.fromJS(lista) };
}

// thunk actions

export function trocaPagina(page : number) {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const route            = currentRouteSelector(getState()),
          ordenacaoAtual   = ordenacaoSelector(getState()),
          opcaoResumoAtual = opcaoResumoSelector(getState()),
          query            = route.get('query').toJS();

    const filtro = {
      d: '', page: String(page), s: ordenacaoAtual.get('s'), sd: ordenacaoAtual.get('sd'), f: opcaoResumoAtual,
    };
    dispatch(carregaLista(query, filtro));
  };
}

export function carregaLista(query : any = {}, filtro : IFiltro) {
  return async function(dispatch : Dispatch<any>, getState : GetState) {
    if (!carregandoSelector(getState())) {
      const tipo        = tipoSelector(getState()),
            url         = Paths.nfces.lista[tipo](query, filtro),
            opcaoResumo = opcaoResumoSelector(getState());

      dispatch(push(url));

      const queryExportacao = query && query.q ? { d: tipo, q: query.q, f: opcaoResumo } : { d: tipo, f: opcaoResumo };

      await dispatch({ type: ALTERA_QUERY_EXPORTACAO, queryExportacao });

      await dispatch({ type: ALTERA_LAST_PATH, path: url });
      cookie.set('lastPathNfce', url);

      await dispatch(listaCarregando());

      await request.get(url)
          .accept('json')
          .query(query)
          .then(r => dispatch(listaCarregada(r.body)))
          .catch(e => {
            dispatch(listaCarregada({}));
            dispatch(ajaxError('Erro ao carregar lista de NFC-e', e));
          });
    }
  };
}