'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, State, Dispatch } from 'lib/types';
import api from '../../lib/api';

const ATUALIZA_CONTAS = 'admin/contas/ATUALIZA_CONTAS';

const INITIAL_STATE = Immutable.fromJS({
  itens: [],
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case ATUALIZA_CONTAS:
      return state.set('itens', action.contas);

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['admin', 'contas'], Immutable.Map());
export const contasSelector = createSelector<*, *, *, *>(rootSelector, contas => contas.get('itens'));

export const contasParaFiltro = createSelector<*, *, *, *>(contasSelector, (contas) => opcoesFiltroContas(contas));

function opcoesFiltroContas(contas : Immutable.List<*, *>) {
  return contas
      .sortBy(c => c.get('nome'))
      .map(c => {
        const id   = c.get('id'),
              nome = c.get('nome');
        return { value: id, label: nome };
      })
      .toArray();
}

// actions

function atualizaContas(contas) {
  return { type: ATUALIZA_CONTAS, contas: Immutable.fromJS(contas) };
}

// thunk actions

export function carregaContas(contaAtiva : any = null) {
  return async function(dispatch : Dispatch<*>) {
    const r = await api.admin.contas.index({ comCNPJS: 1, contaAtiva });
    await dispatch(atualizaContas(r.items));
  };
}
