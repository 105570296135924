// @flow
'use strict';

import * as React from 'react';

import type { ITotais } from '../types';

import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';
import TableDetalhesComLinhas from '../../../common/detalhes_documento/table_detalhes_com_linhas';

type TotaisProps = {
  totais : ITotais,
};

export default function Totais({ totais } : TotaisProps) {
  const { icms } = totais || {};

  return (
      <div className="tab-pane active">
        <h3>Totais ICMS</h3>
        <TableDetalhesComLinhas data={ icms }>
          <tr>
            <ColumnDetalhes field="bcIcms" type="moeda" width="50%">Valor Total Base de Cálculo ICMS</ColumnDetalhes>
            <ColumnDetalhes field="valor" type="moeda" width="50%">Valor Total ICMS</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="totalProd" type="moeda" width="50%">Valor Total dos Produtos e Serviços</ColumnDetalhes>
            <ColumnDetalhes field="frete" type="moeda" width="50%">Valor Total do Frete</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="desconto" type="moeda">Valor Total do Desconto</ColumnDetalhes>
            <ColumnDetalhes field="pis" type="moeda">Valor Total do PIS</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="cofins" type="moeda">Valor Total da COFINS</ColumnDetalhes>
            <ColumnDetalhes field="valorCfe" type="moeda">Valor Total da CF-e</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="valorAproxTrib" type="moeda" colSpan={ 2 }>Valor Aproximado Total de Tributos</ColumnDetalhes>
          </tr>
        </TableDetalhesComLinhas>
      </div>
  );
}
