// @flow
'use strict';

import * as React from 'react';
import { Button } from 'react-bootstrap';

import QuantidadeSemLink from './quantidade_sem_link';

type QuantidadeLinkButtonProps = {
  styleCelula? : Object,
  styleBotao? : Object,
  className? : string,
  path : ?string,
  quantidade : number,
}

export default function QuantidadeLinkButton({ styleCelula, styleBotao, className, path, quantidade } : QuantidadeLinkButtonProps) {
  if (!path) {
    return <QuantidadeSemLink styleCelula={ styleCelula }
                              styleBotao={ styleBotao }
                              className={ className }
                              quantidade={ quantidade }/>;
  }

  return (
      <td style={ styleCelula }>
        <a href={ path }>
          <Button className={ className } bsStyle="link" style={ styleBotao }>
            { quantidade }
          </Button>
        </a>
      </td>
  );
}
