// @flow

'use strict';

import * as React from 'react';

import AlertWithIcon from '../common/alert_with_icon';

export default function NextToLogo({ location } : { location : { pathname : string } }) {
  switch (location.pathname) {
    case '/app/sped/arquivos':
      return (
          <div className="mensagem-topo-layout">
            <AlertWithIcon bsStyle="info" icon="info-circle">
              <p>Faça o upload de seus arquivos EFD ICMS IPI, para verificar a situação dos seus documentos.</p>
              <p>O Consyste irá cruzar os dados do arquivo com as informações constantes no Portal.</p>
            </AlertWithIcon>
          </div>
      );
    case '/app/sped/documentos':
      return (
          <div className="mensagem-topo-layout">
            <AlertWithIcon bsStyle="info" icon="info-circle">
              <p>Utilize os filtros para realizar a busca por documentos em toda a base de dados do SPED</p>
              <p>no Portal Consyste.</p>
            </AlertWithIcon>
          </div>
      );
    case '/app/ferramentas/prestadores':
      return (
          <div className="mensagem-topo-layout">
            <AlertWithIcon bsStyle="info" icon="info-circle">
              <p>Cadastre os emitentes de notas fiscais de serviço do município Rio de Janeiro/RJ. Para toda empresa</p>
              <p>registrada nessa cidade, o Portal buscará as NFSes emitidas pelos fornecedores cadastrados.</p>
            </AlertWithIcon>
          </div>
      );
    default:
      return null;
  }
}
