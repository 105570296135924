'use strict';
// @flow

import { connect } from 'react-redux';

import * as detalhes from '../../../../../reducers/sped/arquivos/detalhes';

import ListaDocumentos from './lista_documentos';

const mapDispatchToProps = { carregaDetalhes: detalhes.carregaDetalhes };

export default connect<*, *, *, *, *, *>(null, mapDispatchToProps)(ListaDocumentos);