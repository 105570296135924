// @flow

import * as Immutable from 'immutable';

import type { State, Action, Dispatch } from '../../lib/types';
import request from '../../lib/request';
import { ajaxError } from '../alerts';

export const ALTERA_GRAFICO_NFE           = 'dahsboard/elemento/ALTERA_GRAFICO_NFE',
             ALTERA_GRAFICO_CTE           = 'dahsboard/elemento/ALTERA_GRAFICO_CTE ',
             ALTERA_QUADRO_RECEBIDOS_HOJE = 'dahsboard/elemento/ALTERA_QUADRO_RECEBIDOS_HOJE',
             ALTERA_ULTIMAS_NOTICIAS      = 'dahsboard/elemento/ALTERA_ULTIMAS_NOTICIAS',
             ALTERA_NFES_CANCELADAS       = 'dahsboard/elemento/ALTERA_NFES_CANCELADAS';

const DEFAULT_STATE = Immutable.fromJS({
  graficoNfe: null,
  graficoCte: null,
  quadroRecebidosHoje: null,
  ultimasNoticias: null,
  nfesCanceladas: null,
});

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action) {
  switch (action.type) {
    case ALTERA_GRAFICO_NFE:
      return state.set('graficoNfe', action.graficoNfe);
    case ALTERA_GRAFICO_CTE:
      return state.set('graficoCte', action.graficoCte);
    case ALTERA_QUADRO_RECEBIDOS_HOJE:
      return state.set('quadroRecebidosHoje', action.quadroRecebidosHoje);
    case ALTERA_ULTIMAS_NOTICIAS:
      return state.set('ultimasNoticias', action.ultimasNoticias);
    case ALTERA_NFES_CANCELADAS:
      return state.set('nfesCanceladas', action.nfesCanceladas);
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['dashboard', 'paineis'], DEFAULT_STATE);

// thunk actions

export function atualizaPaineisEGraficos(params : any) {
  return async function(dispatch : Dispatch<*>) {
    await Promise.all([
      carregaGraficoNfe(params),
      carregaGraficoCte(params),
      carregaUltimasNoticia(),
      carregaNfesCanceladas(params),
      carregaQuadroRecebidosHoje(params),
    ].map(x => dispatch(x)));
  };
}

function carregaGraficoNfe(params : any) {
  return async function(dispatch : Dispatch<*>) {
    return await request.get(`/app/dashboard/grafico_nfe`)
        .accept('json')
        .query(params)
        .then((r) => dispatch({
          type: ALTERA_GRAFICO_NFE, graficoNfe: Immutable.fromJS(r.body.graficoNfe),
        }))
        .catch(e => dispatch(ajaxError('Erro ao carregar gráfico de NF-e de dashboard', e)));
  };
}

function carregaGraficoCte(params : any) {
  return async function(dispatch : Dispatch<*>) {
    return await request.get(`/app/dashboard/grafico_cte`)
        .accept('json')
        .query(params)
        .then((r) => dispatch({
          type: ALTERA_GRAFICO_CTE, graficoCte: Immutable.fromJS(r.body.graficoCte),
        }))
        .catch(e => dispatch(ajaxError('Erro ao carregar gráfico de CT-e de dashboard', e)));
  };
}

function carregaUltimasNoticia() {
  return async function(dispatch : Dispatch<*>) {
    return await request.get(`/app/dashboard/ultimas_noticias`)
        .accept('json')
        .then((r) => dispatch({
          type: ALTERA_ULTIMAS_NOTICIAS, ultimasNoticias: Immutable.fromJS(r.body.ultimasNoticias),
        }))
        .catch(e => dispatch(ajaxError('Erro ao carregar últimas notícias de dashboard', e)));
  };
}

function carregaQuadroRecebidosHoje(params : any) {
  return async function(dispatch : Dispatch<*>) {
    return await request.get(`/app/dashboard/quadro_recebidos_hoje`)
        .accept('json')
        .query(params)
        .then((r) => dispatch({
          type: ALTERA_QUADRO_RECEBIDOS_HOJE, quadroRecebidosHoje: Immutable.fromJS(r.body.quadroRecebidosHoje),
        }))
        .catch(e => dispatch(ajaxError('Erro ao carregar quadro recebidos hoje de dashboard', e)));
  };
}

function carregaNfesCanceladas(params : any) {
  return async function(dispatch : Dispatch<*>) {
    return await request.get(`/app/dashboard/nfes_canceladas`)
        .accept('json')
        .query(params)
        .then((r) => dispatch({
          type: ALTERA_NFES_CANCELADAS, nfesCanceladas: Immutable.fromJS(r.body.nfesCanceladas),
        }))
        .catch(e => dispatch(ajaxError('Erro ao carregar quadro NF-es canceladas de dashboard', e)));
  };
}
