// @flow

export const CAMPOS_SITUACAO_CUSTODIA_CTE = [
  { label: 'Custodiado', name: 'ok' },
  { label: 'Notificado', name: 'notified' },
  { label: 'Apenas Resumo', name: 'abstract_only' },
  { label: 'Download Expirado', name: 'expired' },
  { label: 'Cancelada ou Denegada', name: 'cancelled' },
  { label: 'Não Encontrado', name: 'not_found' },
  { label: 'Aguardando Consulta', name: 'waiting' },
  { label: 'Erro de Sintaxe', name: 'syntax_error' },
  { label: 'Schema Inválido', name: 'invalid_schema' },
  { label: 'Ambiente Incorreto', name: 'wrong_environment' },
  { label: 'Assinatura Digital Inválida', name: 'invalid_dsig' },
  { label: 'Assinatura Digital Difere', name: 'wrong_dsig' },
];

export const CAMPOS_SITUACAO_SEFAZ_CTE = [
  { label: 'Autorizado', name: 'autorizado' },
  { label: 'Cancelado', name: 'cancelado' },
  { label: 'Denegado', name: 'denegado' },
  { label: 'Não Consta', name: 'nao_consta' },
];
