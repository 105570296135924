// @flow

import React from 'react';

import { formatHelper as fmt } from 'lib/viewUtils';

type PageEntriesProps = { label : any, firstOfPage : number, lastOfPage : number, total : number };

export default function PageEntries({ label, firstOfPage, lastOfPage, total } : PageEntriesProps) {
  return (
      <section className="page-entries">
        Exibindo {label},{' '}
        <b>{ fmt.numberWithDelimiter(firstOfPage) } - { fmt.numberWithDelimiter(lastOfPage) }</b>{' '}
        de <b>{ fmt.numberWithDelimiter(total) }</b>.
      </section>
  );
}