// @flow
'use strict';

import * as React from 'react';
import { FormGroup, HelpBlock } from 'react-bootstrap';

import type { InputWrapperProps } from './types';
import CheckboxReact from './checkbox';

type CheckboxInputWrapperProps = { ...InputWrapperProps, label : string };

export default function InputCheckbox({ hint, ...props } : CheckboxInputWrapperProps) {
  const hasError = props.meta.touched && props.meta.invalid,
        errorMsg = hasError && props.meta.error;

  return (
      <FormGroup controlId={ props.input.name }
                 validationState={ hasError ? 'error' : null }>
        <InputControl { ...props }/>
        <HelpBlock>{ errorMsg || hint }</HelpBlock>
      </FormGroup>
  );
}

function InputControl(props : CheckboxInputWrapperProps) {
  const handleSelectBlur = React.useCallback(() => props.input.onBlur(props.value), [props.input, props.value]);

  const checkboxProps = {
    ...props,
    input: {
      ...props.input,
      inputProps: { id: props.input.name }, // importante para o clique no label dar foco no input
      onBlur: handleSelectBlur, // importante para compatibilidade com o blur do redux-form
    }
  };
  return <CheckboxReact { ...checkboxProps }/>;
}