// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';
import { type Dispatch } from 'redux';
import { autofill } from 'redux-form/immutable';

import type { Action, State } from '../../lib/types';
import request from '../../lib/request';
import { ajaxError } from '../alerts';

type Empresa = {
  id : string,
  fantasia : string,
  cnpj : string,
  contaId : string,
}

const OPCOES_CARREGANDO      = 'dahsboard/empresa/OPCOES_CARREGANDO',
      DADOS_SELECT_RECEBIDOS = 'dahsboard/empresa/DADOS_SELECT_RECEBIDOS';

const INITIAL_STATE = Immutable.fromJS({
  carregando: false,
  items: [],
});

export default function reducer(state : Immutable.Map<*, *> = INITIAL_STATE, action : Action = { type: '' }) {
  switch (action.type) {
    case OPCOES_CARREGANDO:
      return state.set('carregando', true).set('items', Immutable.fromJS([]));
    case DADOS_SELECT_RECEBIDOS:
      return state.set('carregando', false).set('items', action.dados);
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['dashboard', 'empresa'], Immutable.Map());
export const carregandoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('carregando', false));
export const todosSelector = createSelector<*, *, *, *>(rootSelector, root => root.get('items'));

// action creators

export function dadosSelectRecebidos(dados : Empresa[]) {
  return { type: DADOS_SELECT_RECEBIDOS, dados: Immutable.fromJS(dados) };
}

// thunk actions

export function atualizaOpcoesEmpresas(contaId : string) {
  return async function(dispatch : Dispatch<any>) {
    dispatch(autofill('dashboardFiltro', 'empresa_id', null));

    if (!contaId)
      return dispatch(dadosSelectRecebidos([]));

    await dispatch({ type: OPCOES_CARREGANDO });

    await request.get(`/app/dashboard/empresas`)
        .accept('json')
        .query({ conta: contaId })
        .then(r => dispatch(dadosSelectRecebidos(r.body.empresas)))
        .catch(e => dispatch(ajaxError('Erro ao atualizar opções de empresa', e)));
  };
}