// @flow

import { connect } from 'react-redux';

import { mudaFiltro } from '../../../../reducers/sped/documentos/filtro';

import CampoFiltro from './campo_filtro';

export default connect<*, *, *, *, *, *>(null, (dispatch, { name }) => ({
  onChange(arg : any) {
    let val = arg;
    if (arg && arg.target && typeof arg.target === 'object')
      val = arg.target.value;

    dispatch(mudaFiltro(name, val));
  },
}))(CampoFiltro);