// @flow
'use strict';

import PisAliq from './pis_aliq';
import PisQtde from './pis_qtde';
import PisNt from './pis_nt';
import PisOutr from './pis_outr';

export default {
  PisAliq, PisQtde, PisNt, PisOutr,
};