// @flow
'use strict';

import * as React from 'react';

import type { IIcms60 } from '../../../../../../types_imposto';

import RowDetalhes from '../../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../../common/detalhes_documento/column_detalhes';

type Icms60Props = {
  item : IIcms60,
};

export default function Icms60({ item } : Icms60Props) {
  return (
      <>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="origem" width="25%">Origem da Mercadoria</ColumnDetalhes>
          <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
          <ColumnDetalhes field="valorBcIcmsSt" type="moeda" width="25%">BC ICMS ST Retido</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaConsumidorFinal" type="percentual" width="25%">Alíquota Consumidor Final</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="valorIcmsRetido" type="moeda" width="25%">ICMS ST Retido</ColumnDetalhes>
          <ColumnDetalhes field="valorBcFcpRetidoAnteriormente" type="moeda" width="25%">BC FCP Retido Ant. por ST</ColumnDetalhes>
          <ColumnDetalhes field="percentualBcFcpRetidoAnteriormente" type="percentual" width="25%">Percentual FCP retido ant. por ST</ColumnDetalhes>
          <ColumnDetalhes field="valorFcpRetido" type="moeda" width="25%">Valor FCP retido por ST</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="percentualReducaoBcEfetiva" type="percentual" width="25%">Redução BC Efetiva</ColumnDetalhes>
          <ColumnDetalhes field="valorBcEfetiva" type="moeda" width="25%">BC Efetiva</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaIcmsEfetiva" type="percentual" width="25%">Alíquota ICMS Efetiva</ColumnDetalhes>
          <ColumnDetalhes field="valorIcmsEfetivo" type="moeda" width="25%">ICMS Efetivo</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
