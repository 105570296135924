// @flow
'use strict';

import * as React from 'react';
import { Col, ControlLabel, Row } from 'react-bootstrap';

import { InputForm } from '../../../common/input';

export default function CampoSituacao() {
  return (
      <Row className="campo-formulario">
        <ControlLabel className="col-sm-3">Situação</ControlLabel>
        <Col sm={ 9 }>
          <Col xs={ 3 } className="opcao-checkbox-nfse">
            <InputForm name="status_sefaz_normal" label="Normal" checkbox/>
          </Col>
          <Col xs={ 3 } className="opcao-checkbox-nfse">
            <InputForm name="status_sefaz_cancelada" label="Cancelada" checkbox/>
          </Col>
        </Col>
      </Row>
  );
}