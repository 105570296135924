'use strict';
// @flow

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fechaModal, openModalSelector } from '../../../reducers/modal';

import ModalDownload from './modal_download';

export default connect<*, *, *, *, *, *>(createStructuredSelector({ open: openModalSelector }), { fechaModal })(ModalDownload);
