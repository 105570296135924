// @flow
'use strict';

import * as React from 'react';

import CampoIntervalo from '../../../common/campo_intervalo';
import CampoIntervaloData from '../../../common/campos_busca_estruturada/campo_intervalo_data';
import { InputForm } from '../../../common/input';

export default function CamposDadosConhecimento() {
  return (
      <>
        <legend>Dados do Conhecimento</legend>
        <CampoIntervaloData columnClassName="no-padding-left" rotulo="Emissão" nome="emitido_em" placeholderDe="após"/>
        <CampoIntervaloData columnClassName="no-padding-left" rotulo="Recepção" nome="recebido_em" placeholderDe="após"/>
        <CampoIntervalo columnClassName="no-padding-left" rotulo="Série" nome="serie" placeholderInicio="maior que" placeholderFim="menor que"/>
        <CampoIntervalo columnClassName="no-padding-left" rotulo="Número" nome="numero" placeholderInicio="maior que" placeholderFim="menor que"/>
        <CampoIntervalo columnClassName="no-padding-left" rotulo="Valor" nome="valor" placeholderInicio="acima de" tamanho={ 5 }/>
        <InputForm className="campo-input" inputClassName="no-padding-left" sm={ 9 } name="cfop" label="CFOP"/>
      </>
  );
}
