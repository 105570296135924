// @flow
'use strict';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { inicializaBuscaLivre } from '../../../reducers/nfse/filtro_livre';
import { carregaLista } from '../../../reducers/nfse/lista';

import Filtros from './filtros';

export default compose(
    connect<*, *, *, *, *, *>(null, { inicializaBuscaLivre, carregaLista }),
    withRouter,
)(Filtros);