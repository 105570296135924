'use strict';
// @flow

import * as Immutable from 'immutable';
import * as _ from 'lodash';
import url from 'url';
import { createSelector } from 'reselect';

import type { Action, Dispatch, GetState, State } from 'lib/types';
import request from '../../../lib/request';
import { ajaxError, alert } from '../../alerts';
import { formataQuery } from '../../../lib/formata_query';

export const LISTA_CARREGANDO = 'sped/escriturados/lista/LISTA_CARREGANDO',
             LISTA_CARREGADA  = 'sped/escriturados/lista/LISTA_CARREGADA';

const INITIAL_STATE = Immutable.fromJS({
  paginaAtual: { page: 1, items: null },
  carregando: false,
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case LISTA_CARREGANDO:
      return state.set('carregando', true).set('paginaAtual', action.body);
    case LISTA_CARREGADA:
      return state.set('carregando', false).set('paginaAtual', action.body);

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['sped', 'escriturados', 'lista'], Immutable.Map());
export const currentPage = createSelector<*, *, *, *>(rootSelector, (root) => root.get('paginaAtual'));
export const carregandoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('carregando'));

// actions

export function listaCarregando() {
  return { type: LISTA_CARREGANDO, body: Immutable.fromJS({ items: 'loading' }) };
}

export function listaCarregada(lista : any) {
  return { type: LISTA_CARREGADA, body: Immutable.fromJS(lista) };
}

// thunk actions

export function carregaLista(params : any = {}) {
  return async function(dispatch : Dispatch<any>, getState : GetState) {
    if (!carregandoSelector(getState())) {
      await dispatch(listaCarregando());

      await request.get('/app/sped/escriturados')
          .accept('json')
          .query(formataQuery(params))
          .then(r => dispatch(listaCarregada(r.body)))
          .catch(e => {
            dispatch(listaCarregada({ items: 'erro' }));
            dispatch(ajaxError('Erro ao carregar lista de escriturados', e));
          });
    }
  };
}

export function downloadArquivoXls(params : any) {
  return async function(dispatch : Dispatch<*>) {
    const queryDownload = _.omit(formataQuery(params), 'page');

    const path = url.format({
      pathname: '/app/sped/escriturados/download_docs_escr_xls', query: queryDownload,
    });

    await request.post(path)
        .accept('json')
        .query(queryDownload)
        .then(() => dispatch(alert('info', 'Seu download foi adicionado à fila de processamento. Você pode acompanhar o andamento através da opção “Downloads”, no menu lateral.')))
        .catch(e => dispatch(ajaxError('Erro ao enviar solicitação de download', e)));
  };
}