// @flow
'use strict';

import * as React from 'react';

import type { IIcms51 } from '../../../../../../types_imposto';

import RowDetalhes from '../../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../../common/detalhes_documento/column_detalhes';

type Icms51Props = {
  item : IIcms51,
};

export default function Icms51({ item } : Icms51Props) {
  return (
      <>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="origem" width="25%">Origem da Mercadoria</ColumnDetalhes>
          <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
          <ColumnDetalhes field="determinacaoBcIcms" width="25%">Determinação da BC ICMS</ColumnDetalhes>
          <ColumnDetalhes field="percentualReducaoBc" type="percentual" width="25%">Redução BC</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="valorBcIcms" type="moeda" width="25%">Base de Cálculo ICMS</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaIcms" type="percentual" width="25%">Alíquota</ColumnDetalhes>
          <ColumnDetalhes field="valorIcmsOperacao" type="moeda" width="25%">ICMS Operação</ColumnDetalhes>
          <ColumnDetalhes field="percentualDiferimento" type="percentual" width="25%">Diferimento</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="valorCmsDiferido" type="moeda" width="25%">ICMS Diferido</ColumnDetalhes>
          <ColumnDetalhes field="valorIcms" type="percentual" width="25%">Valor ICMS</ColumnDetalhes>
          <ColumnDetalhes field="valorBcFcp" type="moeda" width="25%">Base de Cálculo FCP</ColumnDetalhes>
          <ColumnDetalhes field="percentualFcp" type="percentual" width="25%">Percentual FCP</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="valorFcp" type="moeda">Valor FCP</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
