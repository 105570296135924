// @flow
'use strict';

import * as React from 'react';
import { Col, ControlLabel, Row } from 'react-bootstrap';
import { FormSection } from 'redux-form/immutable';

import { Input } from '../../../../common/input';
import CampoIntervaloData from '../../../../common/campos_busca_estruturada/campo_intervalo_data';

export default function CampoManifestacaoConsyste() {
  return (
      <>
        <Row className="campo-formulario campo-input">
          <ControlLabel className="col-sm-4 titulo-checkbox">No Consyst-e</ControlLabel>
          <Col sm={ 8 } className="opcao-checkbox">
            <FormSection name="manifestacao_cd">
              <Input name="nao_informado" label="Não Informada" checkbox/>
              <Input name="ciencia" label="Ciência da operação" checkbox/>
              <Input name="confirmada" label="Confirmada" checkbox/>
              <Input name="operacao_nao_realizada" label="Operação não realizada" checkbox/>
              <Input name="desconhecida" label="Desconhecida" checkbox/>
            </FormSection>
          </Col>
        </Row>
        <br/>
        <CampoIntervaloData rotulo="Data Manifesto" nome="manifestacao_realizada_em" tamanhoLabel={ 4 }/>
      </>
  );
}
