'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, State, Dispatch, GetState } from 'lib/types';
import * as alerts from '../alerts';
import request from '../../lib/request';
import { queryExportacaoSelector } from './lista';

export const ALTERA_TIPO             = 'nfce/modal_exportacao_lote/ALTERA_TIPO',
             ALTERA_TOTAL_DOCUMENTOS = 'nfce/modal_exportacao_lote/ALTERA_TOTAL_DOCUMENTOS',
             ABRE_MODAL              = 'nfce/modal_exportacao_lote/ABRE_MODAL',
             FECHA_MODAL             = 'nfce/modal_exportacao_lote/FECHA_MODAL';

const INITIAL_STATE = Immutable.fromJS({
  tipo: null,
  open: false,
  totalDocumentos: 0,
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case ALTERA_TIPO:
      return state.set('tipo', action.tipo);
    case ALTERA_TOTAL_DOCUMENTOS:
      return state.set('totalDocumentos', action.totalDocumentos);
    case ABRE_MODAL:
      return state.set('open', true);
    case FECHA_MODAL:
      return state.set('open', false);

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['nfce', 'modalExportacaoLote'], Immutable.Map());
export const openSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('open', false));
export const tipoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('tipo'));
export const totalDocumentosSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('totalDocumentos'));

// actions

function abrirModal() {
  return { type: ABRE_MODAL };
}

function fecharModal() {
  return { type: FECHA_MODAL };
}

// thunk actions

export function abreModal(tipo : string) {
  return async function(dispatch : Dispatch<*>) {
    await dispatch({ type: ALTERA_TIPO, tipo });
    await dispatch(alteraTotalDocumentos());
    dispatch(abrirModal());
  };
}

function alteraTotalDocumentos() {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    await request.get('/app/nfce/total')
        .accept('json')
        .query(queryExportacaoSelector(getState()))
        .then(r => dispatch({ type: ALTERA_TOTAL_DOCUMENTOS, totalDocumentos: r.body.totalDocumentos }))
        .catch(e => {
          dispatch(alerts.ajaxError('Erro ao verificar o total de documentos para download NFC-e', e));
        });
  };
}

export function fechaModal() {
  return function(dispatch : Dispatch<*>) {
    dispatch({ type: ALTERA_TIPO, tipo: null });
    dispatch({ type: ALTERA_TOTAL_DOCUMENTOS, totalDocumentos: 0 });
    dispatch(fecharModal());
  };
}

export function exportaLoteNfce() {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const path = tipoSelector(getState()) === 'xml' ? '/app/nfce/download_xml_lote' : '/app/nfce/download_pdf_lote';

    await dispatch(fechaModal());

    await request.post(path)
        .accept('json')
        .query(queryExportacaoSelector(getState()))
        .then(() => {
          dispatch(alerts.alert('info', 'Sua solicitação está sendo processada. Acompanhe através da tela de downloads.'));
        })
        .catch(e => dispatch(alerts.ajaxError('Erro ao enviar solicitação de download', e)));
  };
}