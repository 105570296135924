// @flow
'use strict';

import * as _ from 'lodash';
import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, ReduxFormContext } from 'redux-form/immutable';

/**
 * HOC que pode ser utilizado para obter informações do contexto do redux-form e encaminhar via props.
 *
 * Exemplo de uso para obter a prop "form" do contexto do redux-form:
 *
 * <code>
 * const MeuComponenteComPropForm = withFormProps('form')(MeuComponenteAEncapsular);
 * </code>
 *
 * @param formPropNames o nome das props desejadas.
 */
export function withFormProps(...formPropNames : Array<string>) {
  return function(Wrapped : React.ComponentType<*>) {
    const FormPropsContextWrapper = function FormPropsContextWrapper(props : {}) {
      const context = React.useContext(ReduxFormContext);
      const formProps = _.pick(context, ...formPropNames);
      return <Wrapped { ...formProps } { ...props } />;
    };

    if (Wrapped.displayName)
      FormPropsContextWrapper.displayName = `FormPropsContextWrapper(${ Wrapped.displayName })`;

    return FormPropsContextWrapper;
  };
}

/**
 * HOC que pode ser utilizado para receber via props o valor de alguns campos do redux-form.
 *
 * Útil para componentes que precisam mudar a forma como são apresentados de acordo com o valor de um campo
 * preenchido pelo usuário.
 *
 * @param useSectionPrefix se falso, ignora o FormSection atual e retorna valores a partir do "raiz" do formulário
 * @param fields a lista de campos a obter.
 */
export function withFormValues(useSectionPrefix : bool = true, ...fields : Array<string>) {
  function mapStateToProps<P:{ form : string, sectionPrefix? : string }>(state : Map<*, *>, { form, sectionPrefix } : P) : P {
    let finalFieldNames;
    if (!useSectionPrefix || !sectionPrefix)
      finalFieldNames = fields;
    else
      finalFieldNames = fields.map(k => `${String(sectionPrefix)}.${k}`);

    // inclui um campo inexistente na consulta, para forçar o selector a retornar um objeto
    finalFieldNames = ['_', ...finalFieldNames];

    let formValues = formValueSelector(form)(state, ...finalFieldNames);

    // remove o prefixo do retorno
    if (useSectionPrefix && sectionPrefix)
      formValues = _.get(formValues, sectionPrefix, {});

    return formValues;
  }

  // $FlowFixMe
  return compose(
      withFormProps('form', 'sectionPrefix'),
      connect(mapStateToProps),
  );
}
