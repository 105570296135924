'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, Dispatch, GetState, State } from 'lib/types';
import request from '../../../lib/request';
import { ajaxError } from '../../alerts';

export const LISTA_CARREGANDO = 'arquivo/upload/lista/LISTA_CARREGANDO',
             LISTA_CARREGADA  = 'arquivo/upload/lista/LISTA_CARREGADA';

const INITIAL_STATE = Immutable.fromJS({
  paginaAtual: { page: 1 },
  carregando: false,
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case LISTA_CARREGANDO:
      return state.set('carregando', true).set('paginaAtual', action.body);
    case LISTA_CARREGADA:
      return state.set('carregando', false).set('paginaAtual', action.body);

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['arquivo', 'upload', 'lista'], Immutable.Map());
export const currentPage = createSelector<*, *, *, *>(rootSelector, (root) => root.get('paginaAtual'));
export const carregandoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('carregando'));
export const pageSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.getIn(['paginaAtual', 'page']));

// actions

export function listaCarregando() {
  return { type: LISTA_CARREGANDO, body: Immutable.fromJS({ items: 'loading' }) };
}

export function listaCarregada(lista : any) {
  return { type: LISTA_CARREGADA, body: Immutable.fromJS(lista) };
}

// thunk actions

export function carregaLista(page : ?number) {
  return async function(dispatch : Dispatch<any>, getState : GetState) {
    if (!carregandoSelector(getState())) {
      await dispatch(listaCarregando());

      await request.get('/app/ferramentas/cargas_upload')
          .accept('json')
          .query({ page: !page ? 1 : page })
          .then(r => dispatch(listaCarregada(r.body)))
          .catch(e => {
            dispatch(listaCarregada({ items: 'erro' }));
            dispatch(ajaxError('Erro ao carregar lista de Uploads', e));
          });
    }
  };
}