// @flow
'use strict';

import * as React from 'react';
import moment from 'moment/moment';
import momentLocalizer from 'react-widgets-moment';
import DatePicker from 'react-widgets/lib/DatePicker';
import { Field } from 'redux-form/immutable';

moment.locale('pt-br');
momentLocalizer();

const FORMATOS_DATA = ['D/M/YY', 'D/M/YYYY', 'D/MM/YY', 'D/MM/YYYY', 'DD/M/YY', 'DD/M/YYYY', 'DD/MM/YY', 'DD/MM/YYYY'];

type DatePickerFormProps = {
  nome : string,
  placeholder : string,
}

export default function DatePickerForm({ nome, placeholder } : DatePickerFormProps) {
  return (
      <Field
          name={ nome }
          id={ nome }
          placeholder={ placeholder }
          component={ renderDatePicker }
      />
  );
}

type renderDatePickerProps = {
  input : { onChange : any, value : any },
  placeholder : string,
  id : string,
}

function renderDatePicker({ input: { onChange, value }, id, placeholder } : renderDatePickerProps) {
  return (
      <DatePicker
          onChange={ onChange }
          value={ !value ? null : new Date(value) }
          placeholder={ placeholder }
          id={ id }
          parse={ FORMATOS_DATA }
          messages={{ dateButton: 'Selecione a data' }}
      />
  );
}
