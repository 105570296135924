'use strict';
// @flow

import * as React from 'react';

import Icone from '../icone';
import classnames from 'classnames';

type UpdateNagProps = {
  icone : string | React.Element<*>,
  tipo? : string,
  children? : any,
}

export default function UpdateNag({ tipo, icone, children } : UpdateNagProps) {
  if (typeof icone === 'string')
    icone = <Icone nome={icone} estilo="fas" />;

  const cls = ['update-split'];
  if (tipo)
    cls.push(`update-${tipo}`);

  return (
      <div className="update-nag">
        <div className={ classnames(cls) }>{ icone }</div>
        <div className="update-text">{ children }</div>
      </div>
  );
}