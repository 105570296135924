// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfe from '../../../form_exportacao_nfe/campos_nfe/selecao_todos_nfe';
import { Input } from '../../../../../../../common/input';

export default function CamposIpi() {
  return (
      <FormSection name="dadosIpi">
        <SelecaoTodosNfe nomes={ ["dadosIpi"] } titulo="Dados do IPI"/>

        <div className="grupo-checkbox">
          <Input name="cnpjProdutor" label="CNPJ do Produtor" checkbox/>
          <Input name="codigoSeloControle" label="Código Selo de Controle" checkbox/>
          <Input name="qtdSeloControle" label="Quantidade Selo de Controle" checkbox/>
          <Input name="codigoEnquadramento" label="Código Enquadramento Legal" checkbox/>
          <Input name="cstIpi" label="Situação Tributária" checkbox/>
          <Input name="valorBcIpi" label="Base Cálculo IPI" checkbox/>
          <Input name="qtdUnidadePadrao" label="Quantidade Un. Padrão" checkbox/>
          <Input name="valorUnidadeTributavelIpi" label="Valor Un. Tributável" checkbox/>
          <Input name="aliquotaIpi" label="Alíquota do IPI" checkbox/>
          <Input name="valorIpi" label="Valor do IPI" checkbox/>
        </div>
      </FormSection>
  );
}