/**
 * @file Este arquivo foi feito com base em uma recomendação do react-boostrap,
 * fonte: https://github.com/react-bootstrap/pagination#alternatives
 *
 * Para que funcione corretamente, algumas validações do eslint são desligadas.
 */

/* eslint-disable react/prop-types */

'use strict';
// @flow

import * as React from 'react';
import * as _ from 'lodash';
import { Pagination } from 'react-bootstrap';
import { createUltimatePagination, ITEM_TYPES } from 'react-ultimate-pagination';

import Icone from '../icone';

const Wrapper = (props) => <Pagination { ..._.omit(props, 'hasLastButton') }/>;

const Item = ({ name, children, isActive, onClick, isDisabled }) => {
  const handleClick = React.useCallback((e) => {
    e.preventDefault();
    onClick(e);
  }, [onClick]);

  return (
      <Pagination.Item href={ `#${ name }` } active={ isActive } onClick={ handleClick }
                       disabled={ isDisabled }>{ children }</Pagination.Item>
  );
};

const Page = ({ value, ...props }) => <Item name={ `p${ value }` } { ...props }>{ value }</Item>;

const Ellipsis = () => <Item name="ellipsis" isDisabled>{ '\u2026' }</Item>;

const FirstPage = ({ value, isActive, onClick }) => value === 1 && isActive ? null :
    <Item name="first" onClick={ onClick }><Icone nome="fast-backward" estilo="fas"/></Item>;

const PreviousPage = ({ value, isActive, onClick }) => value === 1 && isActive ? null :
    <Item name="previous" onClick={ onClick }><Icone nome="step-backward" estilo="fas"/></Item>;

const NextPage = ({ isActive, onClick }) => !isActive &&
    <Item name="next" onClick={ onClick }><Icone nome="step-forward" estilo="fas"/></Item>;

const LastPage = () => null;

export default createUltimatePagination({
  WrapperComponent: Wrapper,
  itemTypeToComponent: {
    [ITEM_TYPES.PAGE]: Page,
    [ITEM_TYPES.ELLIPSIS]: Ellipsis,
    [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPage,
    [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPage,
    [ITEM_TYPES.NEXT_PAGE_LINK]: NextPage,
    [ITEM_TYPES.LAST_PAGE_LINK]: LastPage,
  },
});
