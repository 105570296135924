// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfe from '../../form_exportacao_nfe/campos_nfe/selecao_todos_nfe';
import { Input } from '../../../../../../common/input';

type CamposItemProps = {
  component : React.ComponentType<*>,
}

export default function CamposCest({ component } : CamposItemProps) {
  const SelecaoTodos = component || SelecaoTodosNfe;

  return (
      <FormSection name="dadosCest">
        <SelecaoTodos nomes={ ["dadosCest"] } titulo="Dados do CEST"/>

        <div className="grupo-checkbox">
          <Input name="cest" label="Código CEST" checkbox/>
          <Input name="escalaRelevante" label="Escala Relevante" checkbox/>
          <Input name="cnpjFabricante" label="CNPJ do Fabricante" checkbox/>
        </div>
      </FormSection>
  );
}