// @flow
'use strict';

import * as React from 'react';
import { FormGroup } from 'react-bootstrap';

import type { InputWrapperProps } from './input/types';
import { InputControl } from './input/input_wrapper';

export default function CampoTexto({ ...props } : InputWrapperProps) {
  return (
      <FormGroup controlId={ props.input.name } className={ props.className }>
        <InputControl { ...props }/>
      </FormGroup>
  );
}