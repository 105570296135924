// @flow
'use strict';

import * as React from 'react';
import { MenuItem } from 'react-bootstrap';

type BotaoExportacaoLoteProps = {
  downloadPdf : () => void,
  downloadXml : () => void,
}

export default function BotaoExportacaoLoteCte({ downloadPdf, downloadXml } : BotaoExportacaoLoteProps) {
  return (
      <>
        <MenuItem onClick={ downloadPdf }>DACTE</MenuItem>
        <MenuItem onClick={ downloadXml }>XML</MenuItem>
      </>
  );
}
