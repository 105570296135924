// @flow
'use strict';

import * as React from 'react';

import type { IProduto } from '../types';

import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../common/detalhes_documento/table_detalhes';

type ProdutoProps = {
  produto : IProduto[],
};

export default function Produto({ produto } : ProdutoProps) {
  return (
      <div className="tab-pane active">
        <h3>Produtos</h3>
        <TableDetalhes data={ produto } noDataClass="padding-10" noDataMsg="Nenhum item informado na CF-e">
          <ColumnDetalhes field="numero" width={ 50 }>Item</ColumnDetalhes>
          <ColumnDetalhes field="codigo" width={ 50 }>Código</ColumnDetalhes>
          <ColumnDetalhes field="descricao" type="documento">Descrição</ColumnDetalhes>
          <ColumnDetalhes field="ncm" width={ 70 }>NCM</ColumnDetalhes>
          <ColumnDetalhes field="un" width={ 40 }>UN</ColumnDetalhes>
          <ColumnDetalhes field="qtd" width={ 50 }>Quantidade</ColumnDetalhes>
          <ColumnDetalhes field="valorUnit" type="moeda" width={ 100 }>Valor Unitário</ColumnDetalhes>
          <ColumnDetalhes field="valorTotal" type="moeda" width={ 130 }>Valor Total do Item</ColumnDetalhes>
        </TableDetalhes>
      </div>
  );
}
