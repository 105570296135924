// @flow
'use strict';

import * as React from 'react';

import { useEffectOnce } from 'lib/viewUtils';

import Formulario from '../formulario';

type FiltrosProps = {
  router : { location : { query : {} } },

  inicializaBuscaLivre : (query : {}) => void,
  carregaLista : (query : {}) => void,
};

export default function Filtros({ router, inicializaBuscaLivre, carregaLista } : FiltrosProps) {
  useEffectOnce(() => {
    if (router && router.location) {
      inicializaBuscaLivre(router.location.query);
      carregaLista(router.location.query);
    }
  });

  return (
      <Formulario/>
  );
}