'use strict';
// @flow

import React from 'react';

import type { IReduxFormInputProps, ISelectOption } from '../types';

import RadioReact from './radio';

type RadioGroupReactProps = {
  input : IReduxFormInputProps,
  optionsRadio? : ISelectOption[],
}

export default function RadioGroupReact({ input, optionsRadio } : RadioGroupReactProps) {
  return (
      <div>
        <span/>
        { optionsRadio && optionsRadio.map((o, i) =>
            <RadioReact key={ i } name={ input.name } dado={ o }
                        onChange={ input.onChange } value={ input.value }/>) }
      </div>
  );
}