// @flow
'use strict';

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { State, Action, Dispatch } from '../../lib/types';
import * as alerts from '../alerts';
import request from '../../lib/request';
import { ajaxError } from '../alerts';

export const LISTA_CARREGANDO = 'modelo_relatorio/modal_lista_relatorios/CONTAS_CARREGANDO',
             LISTA_CARREGADAS = 'modelo_relatorio/modal_lista_relatorios/CONTAS_CARREGADAS',
             EXIBE_ALERTA     = 'modelo_relatorio/modal_lista_relatorios/EXIBE_ALERTA',
             FECHA_ALERTA     = 'modelo_relatorio/modal_lista_relatorios/FECHA_ALERTA';

const DEFAULT_STATE = Immutable.fromJS({ listaRelatorios: [], alertas: [], carregando: false });

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action) {
  switch (action.type) {
    case LISTA_CARREGANDO:
      return state.set('carregando', true);
    case LISTA_CARREGADAS:
      return state.set('carregando', false).set('listaRelatorios', Immutable.fromJS(action.body));
    case EXIBE_ALERTA:
      return state.set('alertas', action.alertas);
    case FECHA_ALERTA:
      return state.set('alertas', '');
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['modeloRelatorio', 'modalListaRelatorios'], Immutable.Map());
export const listaRelatoriosSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('listaRelatorios'));
export const alertasSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('alertas'));
export const carregandoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('carregando'));

// action creators

export function exibeAlertas(alertas : string[]) {
    return { type: EXIBE_ALERTA, alertas };
}

export function fechaAlertas() {
  return { type: FECHA_ALERTA };
}

export function carregaListaRelatorios(modeloDocumento : string) {
  return async function(dispatch : Dispatch<any>) {
    dispatch({ type: LISTA_CARREGANDO });

    request.get('/app/modelos_relatorio')
        .accept('json')
        .query({ modeloDocumento })
        .then(r => dispatch({ type: LISTA_CARREGADAS, body: r.body['modelosRelatorio'] }))
        .catch(e => {
          dispatch({ type: LISTA_CARREGADAS, body: [] });
          dispatch(ajaxError('Erro ao carregar lista de contas', e));
        });
  };
}

export function excluiModeloRelatorio(id : string, tipo : string) {
  return async function(dispatch : Dispatch<*>) {
    await request.delete(`/app/modelos_relatorio/${ id }`)
        .accept('json')
        .query({ id })
        .then(() => {
          dispatch(carregaListaRelatorios(tipo));
          dispatch(exibeAlertas(['Relatório excluído com sucesso.']));
        })
        .catch(e => dispatch(alerts.ajaxError('Erro ao excluir modelo de relatório', e)));
  };
}
