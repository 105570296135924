// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { jsSelector } from 'lib/viewUtils';
import { withProvider } from 'lib/reduxTools';

import { carregaDetalhes, carregandoSelector, itemSelector } from 'reducers/ferramentas/prestador/detalhes';
import { alert } from 'reducers/alerts';

import Detalhes from './detalhes';

const mapStateToProps = createStructuredSelector({ item: jsSelector(itemSelector), carregando: carregandoSelector });

export default compose(
    withProvider(),
    connect(mapStateToProps, { carregaDetalhes, alert }),
)(Detalhes);
