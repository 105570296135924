'use strict';
// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';

import * as filtro from '../../../../reducers/sped/documentos/filtro';

import Filtros from './filtros';

const mapStateToProps    = createStructuredSelector({
        filtroAtual: filtro.filtroAtualSelector,
      }),
      mapDispatchToProps = {
        inicializaFiltros: filtro.inicializaFiltros,
        aplicaFiltro: filtro.aplicaFiltro,
      };

export default compose(
    connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
    withRouter,
)(Filtros);