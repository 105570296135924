// @flow
'use strict';

import * as React from 'react';

import type { IPisAliq } from '../../../../../types_imposto';

import TributoAliquota from './tributo_aliquota';

type PisAliqProps = {
  item : IPisAliq,
};

export default function PisAliq({ item } : PisAliqProps) {
  return <TributoAliquota item={ item }/>;
}
