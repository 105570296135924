// @flow
'use strict';

import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import CampoManifestacaoConsyste from './campo_manifestacao_consyste';
import CampoManifestacaoSefaz from './campo_manifestacao_sefaz';

export default function CamposManifestacoes() {
  return (
      <>
        <fieldset>
          <legend>Manifestações</legend>
        </fieldset>
        <Row>
          <Col sm={ 6 }>
            <CampoManifestacaoConsyste/>
          </Col>
          <Col sm={ 6 }>
            <CampoManifestacaoSefaz/>
          </Col>
        </Row>
      </>
  );
}
