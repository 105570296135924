// @flow
'use strict';

import * as React from 'react';

import type { IPisOutr } from '../../../../../types_imposto';

import ColumnDetalhes from '../../../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../../../common/detalhes_documento/row_detalhes';
import TributoAliquota from './tributo_aliquota';
import TributoQuantidade from './tributo_quantidade';
import ValorPis from './valor_pis';

type PisOutrProps = {
  item : IPisOutr,
};

export default function PisOutr({ item } : PisOutrProps) {
  if (item.valorBc && item.quantidadeVendida) {
    return (
        <div>
          <RowDetalhes data={ item }>
            <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
            <ColumnDetalhes field="valorBc" type="moeda" width="25%">Valor da BC do PIS</ColumnDetalhes>
            <ColumnDetalhes field="aliquotaPisPercentual" type="percentual" width="25%">Alíquota do PIS</ColumnDetalhes>
            <ColumnDetalhes field="quantidadeVendida" width="25%">Quantidade Vendida</ColumnDetalhes>
          </RowDetalhes>
          <RowDetalhes data={ item }>
            <ColumnDetalhes field="aliquotaPisReais" type="moeda" width="50%">Alíquota do PIS</ColumnDetalhes>
            <ColumnDetalhes field="valorPis" type="moeda" width="50%">Valor do PIS</ColumnDetalhes>
          </RowDetalhes>
        </div>
    );
  }

  if (item.valorBc)
    return <TributoAliquota item={ item }/>;

  if (item.quantidadeVendida)
    return <TributoQuantidade item={ item }/>;

  return <ValorPis item={ item }/>;
}