// @flow
'use strict';

import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import type { Ponto } from './grafico';
import type { Noticia } from './tabela_noticias/tabela_noticias';
import type { Documentos } from './tabela_documentos';
import type { NfesCanceladas } from './tabela_nfes_canceladas';
import { COL_MD_4, CTE_LEGENDA, NFE_LEGENDA } from './constantes';

import Grafico from './grafico';
import TabelaDocumentos from './tabela_documentos';
import PainelDashboard from './painel_dashboard';
import TabelaNoticias from './tabela_noticias';
import TabelaNfesCanceladas from './tabela_nfes_canceladas';

const STYLE_POSICAO_QUADRO_NFE_CANCELADAS = { padding: 5, marginLeft: 10, marginRight: 10 };

export type Elemento = {
  graficoNfe : ?{ emitidas : Ponto[], recebidas : Ponto[] },
  graficoCte : ?{ emitidos : Ponto[], tomados : Ponto[] },
  quadroRecebidosHoje : ? Documentos,
  ultimasNoticias : Noticia[],
  nfesCanceladas : ? NfesCanceladas,
};

type PaineisProps = {
  elementos : Elemento,
}

export default function Paineis({ elementos } : PaineisProps) {
  const { graficoNfe, graficoCte, quadroRecebidosHoje, ultimasNoticias, nfesCanceladas } = elementos;

  const linhasGraficoNfe = !graficoNfe ? {} : graficoNfe,
        linhasGraficoCte = !graficoCte ? {} : graficoCte;

  return (
       <>
        <Row>
          <Col{ ...COL_MD_4 } className="coluna-dashboard">
            <PainelDashboard carregado={ !!graficoNfe }>
              <Grafico titulo="NF-e / NFC-e" legenda={ NFE_LEGENDA } linhaAzulDados={ linhasGraficoNfe.emitidas }
                       linhaLaranjaDados={ linhasGraficoNfe.recebidas }/>
            </PainelDashboard>
          </Col>
          <Col { ...COL_MD_4 } className="coluna-dashboard">
            <PainelDashboard carregado={ !!graficoCte }>
              <Grafico titulo="CT-e / CT-e OS" legenda={ CTE_LEGENDA } linhaAzulDados={ linhasGraficoCte.emitidos }
                       linhaLaranjaDados={ linhasGraficoCte.tomados }/>
            </PainelDashboard>
          </Col>
        </Row>
        <Row>
          <Col lg={ 4 } className="coluna-dashboard tabela-dashboard">
            <PainelDashboard carregado={ !!quadroRecebidosHoje } titulo="Recebidos Hoje" classNamePainel="altura-quadro-inferior">
              <TabelaDocumentos documentos={ quadroRecebidosHoje }/>
            </PainelDashboard>
          </Col>
          <Col lg={ 4 } className="coluna-dashboard tabela-dashboard-fique-por-dentro">
            <PainelDashboard carregado={ !!ultimasNoticias } titulo="Fique por Dentro" classNamePainel="altura-quadro-inferior">
              <TabelaNoticias ultimasNoticias={ ultimasNoticias }/>
            </PainelDashboard>
          </Col>
          <Col lg={ 4 } className="coluna-dashboard tabela-dashboard-canceladas">
            <PainelDashboard carregado={ !!nfesCanceladas } titulo="NFes Canceladas"
                             classNamePainel="altura-quadro-inferior"
                             classNameTitulo="titulo-nfes-canceladas-dashboard"
                             stylePanelBody={ STYLE_POSICAO_QUADRO_NFE_CANCELADAS }>
              <TabelaNfesCanceladas nfes={ nfesCanceladas }/>
            </PainelDashboard>
          </Col>
        </Row>
      </>
  );
}
