// @flow
'use strict';

import * as React from 'react';

import type { IIcms101 } from '../../../../../../types_imposto';

import RowDetalhes from '../../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../../common/detalhes_documento/column_detalhes';

type Icms101Props = {
  item : IIcms101,
};

export default function Icms101({ item } : Icms101Props) {
  return (
      <RowDetalhes data={ item }>
        <ColumnDetalhes field="origem" width="25%">Origem da mercadoria</ColumnDetalhes>
        <ColumnDetalhes field="csosn" width="25%">CSOSN</ColumnDetalhes>
        <ColumnDetalhes field="aliquotaCalcCreditoSn" type="percentual" width="25%">Alíquota de cálculo do crédito
          SN</ColumnDetalhes>
        <ColumnDetalhes field="creditoIcms" type="moeda" width="25%">Crédito ICMS a ser aproveitado</ColumnDetalhes>
      </RowDetalhes>
  );
}
