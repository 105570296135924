'use strict';
// @flow

import type { IEmpresa } from '../types';
import { json } from '../fetch';
import { Resource } from '../resource';

export class AdminEmpresasResource extends Resource<IEmpresa> {
  async listaUsuariosInclusao(id : string, query : {}) {
    return await this.memberAction(id, 'adiciona_usuarios', { query });
  }

  async adicionaUsuarios(id : string, payload : {}) {
    return await this.memberAction(id, 'adiciona_usuarios', {}, json('PATCH', payload));
  }

  async removeUsuario(empresaId : string, id : string) {
    return await this.memberAction(empresaId, `remove_usuario/${id}`, {}, { method: 'DELETE' });
  }
}
