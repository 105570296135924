// @flow

import * as Immutable from 'immutable';
import * as _ from 'lodash';
import { autofill } from 'redux-form/immutable';

import type { State, Action, Dispatch } from '../../lib/types';
import { inicializaCampos } from './functions';
import {
  CAMPOS_CARGA, CAMPOS_DESTINATARIO, CAMPOS_EMITENTE, CAMPOS_TRANSACOES_CONSYSTE, CAMPOS_DESACORDO_SERVICO,
  CAMPOS_DADOS_TOTAIS, CAMPOS_DADOS_ICMS_CTE, CAMPOS_DADOS_ICMS_UF_FIM, CAMPOS_DADOS_TRIBUTOS_FEDERAIS,
} from './constantes';

export const INICIALIZA_MODAL       = 'lista_documentos/cte/INICIALIZA_MODAL',
             INICIALIZA_VALORES_CTE = 'lista_documentos/cte/INICIALIZA_VALORES_CTE',
             REINICIA_VALORES_CTE   = 'lista_documentos/cte/REINICIA_VALORES_CTE';

const DEFAULT_STATE_JS = {
  query: null, formato: 'txt', tipoDocumento: 'cte', delimiter: ';', linhaPor: 1, salvarRelatorio: false,
  visibilidade: 'publica',
  dadosBasicos: {
    versao: false, modeloDescricao: false, chave: false, numero: false, serie: false, ambienteDescr: false,
    emitidoEm: false, tipoCte: false, cfop: false, naturezaOperacao: false, tipoOperacaoIdEDescr: false,
    municipioEnvio: false, ufEnvio: false, modalDescr: false, tipoServico: false, municipioInicio: false,
    ufInicio: false, municipioFim: false, ufFim: false, caracTransporte: false, caracServico: false,
    tipoTomador: false, papelTomador: false, situacaoSefaz: false, autorizadoEm: false, numeroProtocolo: false,
    valor: false, cobrDataVencimento: false, informacoesAdicionaisFisco: false,
  },
  ...CAMPOS_TRANSACOES_CONSYSTE, ...CAMPOS_DESACORDO_SERVICO, ...CAMPOS_EMITENTE, ...CAMPOS_DESTINATARIO,
  ...CAMPOS_DADOS_TOTAIS, ...CAMPOS_DADOS_ICMS_CTE, ...CAMPOS_DADOS_ICMS_UF_FIM, ...CAMPOS_DADOS_TRIBUTOS_FEDERAIS,
  ...CAMPOS_CARGA,
  toma: {
    tomaCnpj: false, tomaNome: false, tomaIe: false, tomaEndUf: false, tomaCpf: false, tomaFantasia: false,
    tomaEmail: false,
  },
  rem: {
    remCnpj: false, remCpf: false, remNome: false, remIe: false, remEndUf: false, remFantasia: false, remEmail: false,
  },
  receb: {
    recebCnpj: false, recebCpf: false, recebNome: false, recebIe: false, recebEndUf: false, recebFantasia: false,
    recebEmail: false,
  },
  exped: {
    expedCnpj: false, expedCpf: false, expedNome: false, expedIe: false, expedEndUf: false, expedFantasia: false,
    expedEmail: false,
  },
};

const DEFAULT_STATE = Immutable.fromJS(DEFAULT_STATE_JS);

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action) {
  switch (action.type) {
    case INICIALIZA_MODAL:
      return state.set('query', action.query);
    case INICIALIZA_VALORES_CTE:
      return inicializaCampos(state, action.campos);
    case REINICIA_VALORES_CTE:
      return DEFAULT_STATE.set('query', state.get('query'));
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['listaDocumentos', 'cte'], DEFAULT_STATE);

// thunk actions

export function atualizaSelecaoTodos(campos : string[], valor : bool) {
  return async function(dispatch : Dispatch<*>) {
    campos.forEach((campo : string) => {
      const grupoCheckbox = _.cloneDeep(DEFAULT_STATE_JS[campo]);

      Object.keys(grupoCheckbox).forEach(k => grupoCheckbox[k] = valor);

      return dispatch(autofill('listaDocumentosCte', campo, Immutable.fromJS(grupoCheckbox)));
    });
  };
}
