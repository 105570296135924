// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfe from '../../../form_exportacao_nfe/campos_nfe/selecao_todos_nfe';
import { Input } from '../../../../../../../common/input';

export default function CamposIcmsInterestadual() {
  return (
      <FormSection name="dadosIcmsInterestadual">
        <SelecaoTodosNfe nomes={ ["dadosIcmsInterestadual"] } titulo="Dados do ICMS Interestadual"/>

        <div className="grupo-checkbox">
          <Input name="valorBcIcmsUfDestino" label="Base Cálculo (BC) ICMS UF Destino" checkbox/>
          <Input name="valorBcFcpUfDestino" label="Base Cálculo (BC) FCP UF Destino" checkbox/>
          <Input name="percentualIcmsFcpUfDestino" label="% ICMS Relativo ao FCP UF Destino" checkbox/>
          <Input name="aliquotaInternaUfDestino" label="Alíquota Interna UF Destino" checkbox/>
          <Input name="aliquotaInterestadualUfsEnvolvidas" label="Alíquota Interestadual UFs Envolvidas" checkbox/>
          <Input name="percentualPartilhaIcmsInterestadual" label="% Provisório Partilha ICMS Interestadual" checkbox/>
          <Input name="valorIcmsFcpUfDestino" label="ICMS Relativo ao FCP UF Destino" checkbox/>
          <Input name="valorIcmsInterestadualUfDestino" label="ICMS Interestadual UF Destino" checkbox/>
          <Input name="valorIcmsInterestadualUfRemetente" label="ICMS Interestadual UF Remetente" checkbox/>
        </div>
      </FormSection>
  );
}