// @flow
'use strict';

import { connect } from 'react-redux';

import { downloadLote } from '../../../../reducers/cte/lista';

import BotaoExportacaoLoteCte from './botao_exportacao_lote_cte';

const mapDispatchToProps = {
  downloadPdf: () => downloadLote('pdf'),
  downloadXml: () => downloadLote('xml'),
}

export default connect<*, *, *, *, *, *>(null, mapDispatchToProps)(BotaoExportacaoLoteCte);
