// @flow
'use strict';

import * as React from 'react';
import numeral from 'numeral';
import {
  VictoryChart, VictoryVoronoiContainer, VictoryGroup, VictoryTooltip, VictoryLine, VictoryScatter,
  VictoryLabel, VictoryLegend, VictoryAxis,
} from 'victory';

import { BLUE_COLOR, FONT_STYLE, ORANGE_COLOR, LEGENDA_STYLE, PADDING, SIMBOLO_STYLE, TITULO_STYLE, AXIS_STYLE, LARGULA_LINHA_GRAFICO } from './constantes';
import { toNumberBrDecimal2 } from '../../../lib/toNumberBrDecimal';

export type Ponto = { x : string, y : number | string };

type GraficoType = {
  titulo : string,
  legenda : { name : string, symbol : { fill : string } }[],
  linhaAzulDados : Ponto[],
  linhaLaranjaDados : Ponto[],
}

const formataValorEixoY = (t) => numeral(t).format('0.[00]a');

export default function Grafico({ titulo, legenda, linhaAzulDados, linhaLaranjaDados } : GraficoType) {
  if (!linhaAzulDados || !linhaLaranjaDados)
    return <span/>;

  const dominio = obtemDominio(linhaAzulDados, linhaLaranjaDados);

  const config = dominio && dominio.y ? { domain: dominio, domainPadding: { y: 4, x: 10 } } : {};

  return (
      <VictoryChart height={ 513 } width={ 800 } containerComponent={ <VictoryVoronoiContainer/> } padding={ PADDING }>
        <VictoryAxis dependentAxis standalone={ false } tickFormat={ formataValorEixoY } style={ AXIS_STYLE }/>
        <VictoryAxis standalone={ false } style={ AXIS_STYLE }/>
        <VictoryLabel x={ 570 } y={ 41 } text={ titulo } style={ TITULO_STYLE }/>
        <VictoryLabel x={ 93 } y={ 40 } text="R$" style={ SIMBOLO_STYLE }/>
        <VictoryLegend x={ 242 } y={ 480 } orientation="horizontal" gutter={ 20 } data={ legenda }
                       style={ LEGENDA_STYLE }/>

        <VictoryGroup color={ BLUE_COLOR } { ...config } labels={ groupLabel }
                      data={ formataPontos(linhaAzulDados) }
                      labelComponent={ <VictoryTooltip dy={ 85 } style={ { ...FONT_STYLE, fill: BLUE_COLOR } }/> }>
          <VictoryLine style={ LARGULA_LINHA_GRAFICO }/>
          <VictoryScatter size={ scatterSize }/>
        </VictoryGroup>

        <VictoryGroup color={ ORANGE_COLOR } { ...config } labels={ groupLabel }
                      data={ formataPontos(linhaLaranjaDados) }
                      labelComponent={ <VictoryTooltip dy={ 10 } style={ { ...FONT_STYLE, fill: ORANGE_COLOR } }/> }>
          <VictoryLine style={ LARGULA_LINHA_GRAFICO }/>
          <VictoryScatter size={ scatterSize }/>
        </VictoryGroup>
      </VictoryChart>
  );
}

function groupLabel({ datum }) {
  return `${ datum.y }`;
}

function scatterSize({ active }) {
  return active ? 5 : 4;
}

function formataPontos(pontos : Ponto[]) {
  return pontos.map(p => ({ x: p.x, y: Number(p.y), label: toNumberBrDecimal2(p.y) }));
}

export function obtemDominio(linhaAzulDados : Ponto[], linhaLaranjaDados : Ponto[]) {
  if (!linhaAzulDados || !linhaLaranjaDados)
    return {};

  const maiorValorY = obtemMaiorValorY(linhaAzulDados, linhaLaranjaDados);

  return { y: [0, maiorValorY + 20] };
}

function obtemMaiorValorY(linhaAzulDados : Ponto[], linhaLaranjaDados : Ponto[]) {
  let maiorValorY = 20;

  linhaAzulDados.forEach(p => p && p.y && Number(p.y) > maiorValorY ? maiorValorY = Number(p.y) : null);
  linhaLaranjaDados.forEach(p => p && p.y && Number(p.y) > maiorValorY ? maiorValorY = Number(p.y) : null);

  return maiorValorY;
}