// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, ControlLabel, FormControl, FormGroup, Navbar } from 'react-bootstrap';
import ReactSelect from 'react-select';

import DatePicker from 'components/common/date_picker';

import {
  CUSTODIADO, ESCOLHA_BOOLEANA, MODELO_ESCRITURADO, SITUACAO_SEFAZ, SITUACOES_ARQUIVO, SITUACOES_DESACORDO,
  SITUACOES_MANIFESTACAO, TIPO_EMISSAO, TIPO_OPERACOES,
} from 'i18n/constants';

import * as filtro from '../../../../reducers/sped/escriturados/filtro';
import Icone from 'components/common/icone';

type date = any;

type BuscaBasicaProps = {
  filtros : {
    chave : ?string, serie : ?string, numero : ?string, emitente : ?string, cnpj : ?string,
    tipo_operacao_id : ?(number | string), emitido_em_de : ?date, emitido_em_ate : ?date,
    modelo : ?(number | string), custodiado : ? bool, custodiado_em_de : ?date, tipo_emissao : ?string,
    custodiado_em_ate : ?date, situacao_sefaz : ?(number | string),
    situacao_arquivo : ?string, escriturado : ? bool, cod_manifestacao_cd : ?string,
    tomador : ?string, ctes_tomados : ? bool, situacao_desacordo_servico : ?string,
  },
  mudaFiltro : (string, any) => void,
  limpaFiltro : () => void,
  aplicaFiltro : () => void,
};

export function BuscaBasica({ filtros, mudaFiltro, limpaFiltro, aplicaFiltro } : BuscaBasicaProps) {
  const {
          chave, serie, numero, cnpj, emitente, tipo_operacao_id,
          emitido_em_de, emitido_em_ate, modelo, custodiado,
          custodiado_em_de, custodiado_em_ate, situacao_sefaz, tipo_emissao,
          situacao_arquivo, escriturado, cod_manifestacao_cd, tomador, ctes_tomados,
          situacao_desacordo_servico,
        } = filtros || {};

  const handleSetFor    = React.useCallback((k) => v => mudaFiltro(k, v), [mudaFiltro]),
        handleChangeFor = React.useCallback((k) => e => mudaFiltro(k, e.target.value), [mudaFiltro]);

  const reiniciaFiltroEEscriturados = React.useCallback(() => {
    limpaFiltro();
    aplicaFiltro();
  }, [limpaFiltro, aplicaFiltro]);

  return (
      <Navbar fluid className="sped-busca-basica">
        <Navbar.Header><Navbar.Brand>Filtros</Navbar.Brand></Navbar.Header>

        <Navbar.Form className="filtros">
          <FormGroup controlId="chave" style={ { width: '17em' } }>
            <ControlLabel srOnly>Chave</ControlLabel>
            <FormControl name="chave" placeholder="Chave" type="text" style={ { width: '100%' } } value={ chave }
                         onChange={ handleChangeFor('chave') }/>
          </FormGroup>
          <FormGroup controlId="serie">
            <FormControl name="serie" placeholder="Série" type="text" value={ serie }
                         onChange={ handleChangeFor('serie') }
                         style={ { width: '6em' } }/>
          </FormGroup>
          <FormGroup controlId="numero">
            <FormControl name="numero" placeholder="Número" type="text" value={ numero }
                         onChange={ handleChangeFor('numero') }
                         style={ { width: '7em' } }/>
          </FormGroup>
          <FormGroup controlId="tipoEmissao">
            <ControlLabel srOnly>Tipo de Emissão</ControlLabel>
            <ReactSelect options={ TIPO_EMISSAO } placeholder="Tipo de Emissão" value={ tipo_emissao }
                         style={ { width: '12em' } } simpleValue
                         onChange={ handleSetFor('tipo_emissao') }/>
          </FormGroup>
          <FormGroup controlId="emitente">
            <FormControl name="emitente" placeholder="Emitente" type="text"
                         value={ emitente } onChange={ handleChangeFor('emitente') }
                         style={ { width: '17em' } }/>
          </FormGroup>
          <FormGroup controlId="cnpj">
            <FormControl name="cnpjs" placeholder="CNPJ Interessado" type="text" value={ cnpj }
                         onChange={ handleChangeFor('cnpj') }
                         style={ { width: '17em' } }/>
          </FormGroup>
          <FormGroup>
            <ControlLabel srOnly>Emissão de</ControlLabel>
            <DatePicker value={ emitido_em_de } placeholder="Emissão de"
                        onChange={ handleSetFor('emitido_em_de') }/>
          </FormGroup>
          <FormGroup>
            <ControlLabel srOnly>Emissão até</ControlLabel>
            <DatePicker value={ emitido_em_ate } placeholder="Emissão até"
                        onChange={ handleSetFor('emitido_em_ate') }/>
          </FormGroup>
          <FormGroup controlId="tipoOperacaoId">
            <ControlLabel srOnly>Tipo Operação</ControlLabel>
            <ReactSelect options={ TIPO_OPERACOES } placeholder="Tipo Operação" value={ tipo_operacao_id }
                         style={ { width: '12em' } } simpleValue
                         onChange={ handleSetFor('tipo_operacao_id') }/>
          </FormGroup>
          <FormGroup controlId="custodiado">
            <ControlLabel srOnly>Custodiado</ControlLabel>
            <ReactSelect options={ CUSTODIADO } placeholder="Custodiado" value={ custodiado }
                         style={ { width: '10em' } } simpleValue
                         onChange={ handleSetFor('custodiado') }/>
          </FormGroup>
          <FormGroup>
            <ControlLabel srOnly>Custodiado de</ControlLabel>
            <DatePicker value={ custodiado_em_de } placeholder="Custodiado de"
                        width="10em"
                        onChange={ handleSetFor('custodiado_em_de') }/>
          </FormGroup>
          <FormGroup>
            <ControlLabel srOnly>Custodiado até</ControlLabel>
            <DatePicker value={ custodiado_em_ate } placeholder="Custodiado até"
                        width="10em"
                        onChange={ handleSetFor('custodiado_em_ate') }/>
          </FormGroup>
          <FormGroup controlId="situacaoSefaz">
            <ControlLabel srOnly>Situação SEFAZ</ControlLabel>
            <ReactSelect options={ SITUACAO_SEFAZ } placeholder="Situação SEFAZ" value={ situacao_sefaz }
                         style={ { width: '15em' } } simpleValue
                         onChange={ handleSetFor('situacao_sefaz') }/>
          </FormGroup>
          <FormGroup controlId="situacaoArquivo">
            <ControlLabel srOnly>Situação Arquivo</ControlLabel>
            <ReactSelect options={ SITUACOES_ARQUIVO } placeholder="Situação Arquivo" value={ situacao_arquivo }
                         style={ { width: '15em' } } simpleValue
                         onChange={ handleSetFor('situacao_arquivo') }/>
          </FormGroup>
          <FormGroup controlId="escriturado">
            <ControlLabel srOnly>Escriturado</ControlLabel>
            <ReactSelect options={ ESCOLHA_BOOLEANA } placeholder="Escriturado" value={ escriturado }
                         style={ { width: '10em' } } simpleValue
                         onChange={ handleSetFor('escriturado') }/>
          </FormGroup>
          <FormGroup controlId="modelo">
            <ControlLabel srOnly>Modelo</ControlLabel>
            <ReactSelect options={ MODELO_ESCRITURADO } placeholder="Modelo" value={ modelo }
                         style={ { width: '10em' } } simpleValue
                         onChange={ handleSetFor('modelo') }/>
          </FormGroup>

          { modelo === '55' &&
          <FormGroup controlId="manifestacaoCd">
            <ControlLabel srOnly>Manifesto do destinatário</ControlLabel>
            <ReactSelect options={ SITUACOES_MANIFESTACAO } placeholder="Manifesto do destinatário"
                         value={ cod_manifestacao_cd }
                         style={ { width: '18em' } } simpleValue
                         onChange={ handleSetFor('cod_manifestacao_cd') }/>
          </FormGroup>
          }

          { ['57', '67'].includes(modelo) &&
          <div>
            <FormGroup controlId="tomador">
              <FormControl name="tomador" placeholder="Tomador" type="text"
                           value={ tomador } onChange={ handleChangeFor('tomador') }
                           style={ { width: '17em' } }/>
            </FormGroup>
            <FormGroup controlId="ctes_tomados">
              <ControlLabel srOnly>Apenas CT-es Tomados</ControlLabel>
              <ReactSelect options={ ESCOLHA_BOOLEANA } placeholder="Apenas CT-es Tomados" value={ ctes_tomados }
                           style={ { width: '15em' } } simpleValue
                           onChange={ handleSetFor('ctes_tomados') }/>
            </FormGroup>
            <FormGroup controlId="situacao_desacordo_servicos">
              <ControlLabel srOnly>Desacordo</ControlLabel>
              <ReactSelect options={ SITUACOES_DESACORDO } placeholder="Desacordo"
                           value={ situacao_desacordo_servico }
                           style={ { width: '15em' } } simpleValue
                           onChange={ handleSetFor('situacao_desacordo_servico') }/>
            </FormGroup>
          </div>
          }
        </Navbar.Form>

        <Navbar.Form className="botoes">
          <div className="pull-right">
            <Button type="reset" onClick={ reiniciaFiltroEEscriturados }>
              <Icone nome="eraser" estilo="fas">Limpar</Icone>
            </Button> { ' ' }
            <Button type="submit" bsStyle="primary">
              <Icone nome="filter" estilo="far">Filtrar</Icone>
            </Button>
          </div>
          <div className="clearfix"/>
        </Navbar.Form>
      </Navbar>
  );
}

export default connect<*, *, *, *, *, *>(createStructuredSelector({ filtros: filtro.filtrosEscrituradosSelector }), filtro)(BuscaBasica);