// @flow
'use strict';

import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";

import PaginationFooter from 'components/common/pagination_footer';
import Contador from 'components/common/contador';

import * as lista from '../../../../reducers/sped/documentos/lista';
import * as filtro from '../../../../reducers/sped/documentos/filtro';
import { jsSelector } from '../../../../lib/viewUtils';

import Filtros from '../filtros';
import Tabela from './tabela';
import Funcoes from '../funcoes';

type ListagemDocumentosSpedProps = {
  documentos : ?any,
  trocaPagina : () => void,
}

export function ListagemDocumentosSped({ documentos, trocaPagina } : ListagemDocumentosSpedProps) {
  const { page, total, items, pageSize, limiteDownload } = documentos && _.isObject(documentos) ? documentos : {};

  return (
      <div>
        <Filtros/>
        <Contador total={ total } prefix="Documentos encontrados:"/>
        <Tabela dados={ items }/>
        <div className="pull-left">
          <Funcoes total={ total } limiteDownload={ limiteDownload }/>
        </div>
        <PaginationFooter label="Arquivos" page={ page } total={ total } pageSize={ pageSize }
                          showPageEntries={ false }
                          onPageChange={ trocaPagina }>
        </PaginationFooter>
      </div>
  );
}

const mapStateToProps    = createStructuredSelector({
        documentos: jsSelector(lista.currentPage),
      }),
      mapDispatchToProps = {
        trocaPagina: filtro.trocaPagina,
      };

export default connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(ListagemDocumentosSped);