// @flow
'use strict';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { jsSelector } from '../../../lib/viewUtils';

import { inicializaFiltro, ordenaLista, tipoSelector, ordenacaoSelector } from '../../../reducers/nfce/filtro_livre';
import { openSelector } from '../../../reducers/nfce/modal_exportacao_lote';
import { trocaPagina, queryExportacaoSelector, currentPage } from '../../../reducers/nfce/lista';
import { carregaDetalhes } from '../../../reducers/nfce/detalhes';
import { serieNumero } from '../../sped/table_components';
import { formatoValor, documentoComTooltip, documentoNome } from '../../common/lista_documentos/functions';

import PaginationFooter from '../../common/pagination_footer_notas';
import DataTable, { Column } from '../../common/data_table';
import OrdenacaoPorCampo from './ordenacao_por_campo';
import BotaoExportacaoLote from '../../common/botoes_exportacao_lote/botao_exportacao_lote';
import ExportacaoLista from '../../documento/exportacao_lista';
import ModalExportacaoLote from './exportacao_lote/modal_exportacao_lote';

type ListaProps = {
  router : {
    location : { pathname : string, query : { q? : string } },
    params : { d : ?string, s : ?string, sd : ?string, page : ?string, f : ?string },
  },
}

const ordenacaoJsSelector = jsSelector(ordenacaoSelector, { s: '', sd: '' }),
      dadosJsSelector     = jsSelector(currentPage, {});

export default function Lista({ router } : ListaProps) {
  const dispatch = useDispatch();

  const tipo            = useSelector(tipoSelector),
        ordenacao       = useSelector(ordenacaoJsSelector),
        queryExportacao = useSelector(queryExportacaoSelector),
        open            = useSelector(openSelector),
        dados           = useSelector(dadosJsSelector);

  React.useEffect(() => {
    if (router.params.d !== tipo || router.params.s !== ordenacao.s || router.params.sd !== ordenacao.sd)
      dispatch(inicializaFiltro(router.location.query, router.params));
  }, [dispatch, router.params, tipo, ordenacao, router.location.query]);

  const ordenaPorEmitidoEm    = React.useCallback(() => dispatch(ordenaLista('emitido_em')), [dispatch]),
        ordenaPorSerieNumero  = React.useCallback(() => dispatch(ordenaLista('serie,numero')), [dispatch]),
        ordenaPorValorTotal   = React.useCallback(() => dispatch(ordenaLista('valor')), [dispatch]),
        ordenaPorEmitente     = React.useCallback(() => dispatch(ordenaLista('emit_nome,emit_cnpj')), [dispatch]),
        ordenaPorDestinatario = React.useCallback(() => dispatch(ordenaLista('dest_nome,dest_cnpj')), [dispatch]),
        trocarPagina          = React.useCallback((n : number) => dispatch(trocaPagina(n)), [dispatch]),
        handleRowClick        = React.useCallback((nfce) => nfce && dispatch(carregaDetalhes(nfce.id)), [dispatch]);

  const { items = null, page, total, pageSize } = dados || {};

  return (
      <>
        <DataTable className="table-documento nfce link-button table-striped table-bordered table-hover notas"
                   noRecordsFoundMessage="Nenhum documento encontrado com os critérios especificados."
                   data={ items } onRowClick={ handleRowClick } striped hover>
          <Column expr="emitidoEm" align="center" width={ 10 }>
            <OrdenacaoPorCampo campo="emitidoEm" rotulo="Emissão" ordenacao={ ordenacao }
                               onClick={ ordenaPorEmitidoEm }/>
          </Column>
          <Column expr="serie,numero" align="center" formatter={ serieNumero }>
            <OrdenacaoPorCampo campo="serieNumero" rotulo="Série / Nº" ordenacao={ ordenacao }
                               onClick={ ordenaPorSerieNumero }/>
          </Column>
          <Column expr="valorTotal" align="center" formatter={ formatoValor }>
            <OrdenacaoPorCampo campo="valorTotal" rotulo="Valor Total" ordenacao={ ordenacao }
                               onClick={ ordenaPorValorTotal }/>
          </Column>
          <Column expr="emitCnpj,emitNome" formatter={ documentoNome }>
            <OrdenacaoPorCampo campo="emitente" rotulo="Emitente" ordenacao={ ordenacao }
                               onClick={ ordenaPorEmitente }/>
          </Column>
          <Column expr="destCnpj,destNome" align="center" formatter={ documentoComTooltip }>
            <OrdenacaoPorCampo campo="destinatario" rotulo="Destinatário" ordenacao={ ordenacao }
                               onClick={ ordenaPorDestinatario }/>
          </Column>
        </DataTable>

        <PaginationFooter page={ page } total={ total } pageSize={ pageSize } onPageChange={ trocarPagina }
                          hasLastButton={ false }>
          <BotaoExportacaoLote tipo="nfce"/>
          <ExportacaoLista tipo="nfce" query={ queryExportacao }/>
        </PaginationFooter>

        { open && <ModalExportacaoLote/> }
      </>
  );
}
