// @flow
'use strict';

import * as _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';

import * as manifestacao from 'reducers/nfe/manifestacao';
import request from 'lib/request';
import { formToQuery } from 'lib/viewUtils';
import { alert } from 'reducers/alerts';

import ModalManifestaNfe from './modal_manifesta_nfe';

export default compose(
    connect<*, *, *, *, *, *>(createStructuredSelector({
          initialValues: manifestacao.rootSelector,
        })),
    reduxForm({
      form: 'manifestacaoNfe', enableReinitialize: true,
      onSubmit(form, dispatch) {
        request.patch(form.get('url'))
            .accept('json')
            .query(formToQuery(_.omit(form.toJS(), 'url')))
            .then(() => {
                dispatch(manifestacao.fechaModal());
                dispatch(alert('info', 'Estamos enviando a manifestação do documento. Quando obtivermos retorno da SEFAZ, a situação será atualizada.'));
            })
            .catch(e => {
              dispatch(manifestacao.exibeAlertas(e.response.body.alertas.join('\n\n')));
              dispatch(manifestacao.setJustificativa(e.response.body.justificativa));
            });
      },
    }),
)(ModalManifestaNfe);
