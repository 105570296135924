// @flow
'use strict';

import * as React from 'react';
import { Button } from 'react-bootstrap';

type QuantidadeSemLinkProps = {
  styleCelula? : Object,
  styleBotao? : Object,
  className? : string,
  quantidade : number,
}

export default function QuantidadeSemLink({ styleCelula, styleBotao, className, quantidade } : QuantidadeSemLinkProps) {
  return (
      <td style={ styleCelula }>
        <Button className={ className } bsStyle="light" style={ { ...styleBotao, backgroundColor: 'transparent' } }>
          { quantidade }
        </Button>
      </td>
  );
}
