// @flow
'use strict';

import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormSection } from 'redux-form/immutable';

import CampoIntervalo from '../../../common/campo_intervalo';
import CampoIntervaloData from '../../../common/campos_busca_estruturada/campo_intervalo_data';
import { InputForm } from '../../../common/input';

export default function CamposCobranca() {
  return (
      <>
        <fieldset>
          <legend>Cobrança</legend>
        </fieldset>
        <Row>
          <Col sm={ 6 }>
            <p>Fatura</p>
            <InputForm className="campo-input" sm={ 9 } name="fatura_numero" label="Número"/>
          </Col>
          <Col sm={ 6 }>
            <p>Parcelas</p>
            <FormSection name="parcelas">
              <CampoIntervaloData rotulo="Vencimento" nome="vencimento" campoPai="parcelas"/>
              <CampoIntervalo rotulo="Valor" nome="valor"/>
            </FormSection>
          </Col>
        </Row>
      </>
  );
}
