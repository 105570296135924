'use strict';
// @flow

import * as React from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

let id = 0;
const inc = process.env.NODE_ENV === 'test' ? 0 : 1;

type ComTooltipProps = { hint : ?any, children : React.Node, posicao? : string };

/**
 * Componente para apresentar um conteúdo e sua tooltip.
 */
export default function ComTooltip({ hint, children, posicao = 'right' } : ComTooltipProps) {
  if (!hint)
    return children;

  const tip = <Tooltip id={ `desc-tooltip-${ id += inc }` }>{ hint }</Tooltip>;

  return <OverlayTrigger overlay={ tip } placement={ posicao }>{ children }</OverlayTrigger>;
}
