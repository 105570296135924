// @flow

import React from 'react';

export default function AvisoNaoCustodiado() {
  return (
      <div className="panel panel-warning">
        <div className="panel-heading">
          <h3 className="panel-title">Documento não custodiado</h3>
        </div>
        <div className="panel-body">
          <p>O XML do documento em questão ainda não está em custódia do Consyst-e.</p>
        </div>
      </div>
  );
}
