'use strict';
// @flow

import _ from 'lodash';
import React from 'react';

import { Panel } from 'react-bootstrap';

import DefinitionList from 'components/common/definition_list';

import { formatHelper } from 'lib/viewUtils';
import { LOADING } from 'i18n/constants';

import type { ISpedArquivoCompleto } from '../types';

const EM_ANDAMENTO = 'Em andamento';

const EMPTY = {
  data_inicial: null, data_final: null,
  leiaute_versao: LOADING, perfil_arquivo: LOADING, inserido_em: LOADING, inserido_por: LOADING,
  carregado_em: EM_ANDAMENTO,
};

export default function DadosArquivo({ arquivo } : { arquivo? : ISpedArquivoCompleto }) {
  if (!arquivo || !_.isObject(arquivo))
    arquivo = EMPTY;

  return (
      <Panel className="form-horizontal">
        <Panel.Heading>Arquivo</Panel.Heading>
        <Panel.Body>
          <DefinitionList>
            { { label: 'Período', value: <Periodo de={ arquivo.data_inicial } ate={ arquivo.data_final }/> } }
            { { label: 'Versão do Leiaute', value: arquivo.leiaute_versao } }
            { { label: 'Perfil do Arquivo', value: arquivo.perfil_arquivo } }
            { { label: 'Inserido em', value: arquivo.inserido_em } }
            { { label: 'Inserido por', value: arquivo.inserido_por } }
            { { label: 'Carregado em', value: mensagemCarregadoEm(arquivo.carregado_em) } }
          </DefinitionList>
        </Panel.Body>
      </Panel>
  );
}

function mensagemCarregadoEm(carregadoEm : ? string) {
  if (!carregadoEm)
    return EM_ANDAMENTO;

  return carregadoEm;
}

function Periodo({ de, ate } : { de : ?string, ate : ?string }) {
  if (!de && !ate)
    return <span>{ LOADING }</span>;

  const deStr  = formatHelper.formatDate(de),
        ateStr = formatHelper.formatDate(ate);

  return <span>de { deStr } até { ateStr }</span>;
}
