'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';
import { push } from 'react-router-redux';

import api from 'lib/api';
import type { Action, Dispatch, GetState, State } from 'lib/types';

const FETCH_LIST        = 'admin/empresas/FETCH_LIST',
      FETCH_LIST_OK     = 'admin/empresas/FETCH_LIST_OK',
      FETCH_RESOURCE    = 'admin/empresas/FETCH_RESOURCE',
      FETCH_RESOURCE_OK = 'admin/empresas/FETCH_RESOURCE_OK',
      SAVE              = 'admin/empresas/SAVE',
      SAVE_OK           = 'admin/empresas/SAVE_OK',

      PAGINATE          = 'admin/empresas/PAGINATE',
      QUICK_SEARCH      = 'admin/empresas/QUICK_SEARCH';

const ABRE_MODAL_USUARIOS           = 'admin/empresas/ABRE_MODAL_USUARIOS',
      FECHA_MODAL_USUARIOS          = 'admin/empresas/FECHA_MODAL_USUARIOS',
      LISTA_USUARIOS                = 'admin/empresas/LISTA_USUARIOS',
      LISTA_USUARIOS_OK             = 'admin/empresas/LISTA_USUARIOS_OK',
      FILTRA_USUARIOS_PARA_INCLUSAO = 'admin/empresas/FILTRA_USUARIOS_PARA_INCLUSAO',
      SELECIONA_USUARIO             = 'admin/empresas/SELECIONA_USUARIO',
      MUDA_USUARIOS_SELECIONADOS    = 'admin/empresas/MUDA_USUARIOS_SELECIONADOS',
      SALVA_SELECAO_USUARIOS        = 'admin/empresas/SALVA_SELECAO_USUARIOS',
      SALVA_SELECAO_USUARIOS_OK     = 'admin/empresas/SALVA_SELECAO_USUARIOS_OK';

const INITIAL_STATE = Immutable.fromJS({
  empresaAtiva: null,
  paginaAtual: {},
  modalUsuarios: {
    aberta: false,
    filtro: '',
    usuarios: { items: null },
    selecionados: [],
  }
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case FETCH_LIST_OK:
      return state.set('paginaAtual', action.body);

    case FETCH_RESOURCE:
      return state.set('empresaAtiva', null);

    case FETCH_RESOURCE_OK:
      return state.set('empresaAtiva', action.body);

    case SAVE_OK:
      return state;

    case ABRE_MODAL_USUARIOS:
      return state.setIn(['modalUsuarios', 'aberta'], true);

    case FECHA_MODAL_USUARIOS:
      return state.set('modalUsuarios', INITIAL_STATE.get('modalUsuarios'));

    case LISTA_USUARIOS_OK:
      return state.setIn(['modalUsuarios', 'usuarios'], Immutable.fromJS(action.dados));

    case FILTRA_USUARIOS_PARA_INCLUSAO:
      return state.setIn(['modalUsuarios', 'filtro'], action.filtro);

    case SELECIONA_USUARIO:
      return state.updateIn(['modalUsuarios', 'selecionados'], sel => sel.push(Immutable.fromJS(action.selecao)));

    case MUDA_USUARIOS_SELECIONADOS:
      return state.setIn(['modalUsuarios', 'selecionados'], Immutable.fromJS(action.selecionados));

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['admin', 'empresas'], Immutable.Map());
export const currentResource = createSelector<*, *, *, *>(rootSelector, (root) => root.get('empresaAtiva'));
export const currentPage = createSelector<*, *, *, *>(rootSelector, (root) => root.get('paginaAtual'));
export const modalUsuarios = createSelector<*, *, *, *>(rootSelector, (root) => root.get('modalUsuarios'));

// navegação e interação

export function navigateToIndex() {
  return push('/app/admin/empresas');
}

export function navigateToResource(r : any, action? : string) {
  let url = r.url || `/app/admin/empresas/${r.id || (r._id && r._id['$oid']) || r._id || r}`;
  if (action)
    url += `/${action}`;
  return push(url);
}

export function abreModalUsuarios() {
  return { type: ABRE_MODAL_USUARIOS };
}

export function fechaModalUsuarios() {
  return { type: FECHA_MODAL_USUARIOS };
}

// outras ações

export function filtraUsuariosParaInclusao(filtro : {}) {
  return async function(dispatch : Dispatch<*>) {
    await dispatch({ type: FILTRA_USUARIOS_PARA_INCLUSAO, filtro });
    await dispatch(listaUsuariosParaInclusao());
  };
}

export function listaUsuariosParaInclusao(page? : number) {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    dispatch({ type: LISTA_USUARIOS });

    const state     = getState(),
          empresaId = currentResource(state).get('id'),
          filtro    = modalUsuarios(state).get('filtro');

    const r = await api.admin.empresas.listaUsuariosInclusao(empresaId, { q: filtro, page });

    dispatch({ type: LISTA_USUARIOS_OK, dados: r });
  };
}

export function selecionaUsuario(usuario : { name : string }, papel : { label : string }) {
  return { type: SELECIONA_USUARIO, selecao: { usuario, papel, label: `${usuario.name} (${papel.label})` } };
}

export function mudaSelecaoUsuarios(selecionados : any[]) {
  return { type: MUDA_USUARIOS_SELECIONADOS, selecionados };
}

export function salvaSelecaoUsuarios() {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    dispatch({ type: SALVA_SELECAO_USUARIOS });

    const state             = getState(),
          empresaId         = currentResource(state).get('id'),
          selecionados : [] = modalUsuarios(state).get('selecionados').toJS();

    const papeis   = selecionados.map(s => ({ [s.usuario.id]: s.papel.value })).reduce((p, c) => Object.assign(p, c), {}),
          user_ids = selecionados.map(s => s.usuario.id);

    const r = await api.admin.empresas.adicionaUsuarios(empresaId, { user_ids, papeis });

    await dispatch({ type: SALVA_SELECAO_USUARIOS_OK, retorno: r });
    await dispatch(fetchResource());
    await dispatch(fechaModalUsuarios());
  };
}

export function removeUsuario(id : string) {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const empresaId = currentResource(getState()).get('id');

    await api.admin.empresas.removeUsuario(empresaId, id);

    await dispatch(fetchResource());
  };
}

// ações CRUD - são tratadas por sagas/crud.js

export function save(payload : any) {
  return { type: SAVE, payload };
}

export function newResource() {
  return { type: FETCH_RESOURCE };
}

export function fetchResource() {
  return { type: FETCH_RESOURCE };
}

export function fetchList() {
  return { type: FETCH_LIST };
}

export function paginate(page : number) {
  return { type: PAGINATE, page, then: FETCH_LIST };
}

export function quickSearch(q : string) {
  return { type: QUICK_SEARCH, q, then: FETCH_LIST };
}
