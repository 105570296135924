'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, Dispatch, State } from 'lib/types';
import * as lista from './lista';
import request from 'lib/request';
import { ajaxError, alert } from '../../alerts';
import { arquivoNav, arquivoPath } from '../../../components/sped/arquivos/arquivo_path';

export const DETALHES_CARREGANDO = 'sped/arquivos/detalhes/DETALHES_CARREGANDO',
             DETALHES_CARREGADO  = 'sped/arquivos/detalhes/DETALHES_CARREGADO';

const INITIAL_STATE = Immutable.fromJS({
  carregando: false,
  item: {},
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case DETALHES_CARREGANDO:
      return state.set('carregando', true).set('item', Immutable.fromJS({ documentos: { items: 'loading' } }));
    case DETALHES_CARREGADO:
      return state.set('carregando', false).set('item', action.body);

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['sped', 'arquivos', 'detalhes'], Immutable.Map());
export const itemSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('item'));
export const carregandoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('carregando', false));

// actions

export function detalhesCarregando() {
  return { type: DETALHES_CARREGANDO };
}

export function detalhesCarregado(item : any) {
  return { type: DETALHES_CARREGADO, body: Immutable.fromJS(item) };
}

// thunk actions

export function carregaDetalhes(id : string, params : any) {
  return async function(dispatch : Dispatch<any>) {
    await dispatch(detalhesCarregando());

    await request.get(arquivoPath.entidade(id, params))
        .accept('json')
        .then(r => dispatch(detalhesCarregado(r.body)))
        .catch(e => {
          dispatch(detalhesCarregado({ items: 'erro', erro: e }));
          dispatch(ajaxError('Erro ao abrir detalhes de arquivo sped', e));
        });
  };
}

export function excluirArquivo(id : string) {
  return async function(dispatch : Dispatch<*>) {
    await request.delete(arquivoPath.entidade(id))
        .accept('json')
        .then(() => {
          dispatch(lista.carregaLista({}));
          arquivoNav.listagem();
        })
        .catch(e => dispatch(ajaxError('Erro ao excluir arquivo', e)));
  };
}

export function atualizaArquivo(id : string) {
  return async function(dispatch : Dispatch<*>) {
    await request.post(arquivoPath.atualiza(id))
        .accept('json')
        .then(() => {
          dispatch(carregaDetalhes(id, {}));
          alert('info', 'Arquivo atualizado');
        })
        .catch(e => dispatch(ajaxError('Erro ao atualizar arquivo', e)));
  };
}

export function downloadArquivoXls(id : string, params : any) {
  return async function(dispatch : Dispatch<*>) {
    await request.post(arquivoPath.download_xls(id, params))
        .accept('json')
        .then(() => dispatch(alert('info', 'Seu download foi adicionado à fila de processamento. Você pode acompanhar o andamento através da opção “Downloads”, no menu lateral.')))
        .catch(e => dispatch(ajaxError('Erro ao enviar solicitação de download', e)));
  };
}