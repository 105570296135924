// @flow
'use strict';

import * as React from 'react';
import { Table } from 'react-bootstrap';

import * as Paths from '../../../paths';

import QuantidadeLinkButton from './common/quantidade_link_button';

const STYLE_ESPACAMENTO_INFERIOR_TABELA = { marginBottom: '10px' },
      STYLE_ESPACAMENTO_SUPERIOR_BOTAO  = { paddingTop: '3.8em', height: '12.6em' },
      STYLE_TAMANHO_FONTE_BOTAO         = { fontSize: '3em' },
      STYLE_TRANSPARENTE_TABELA         = { backgroundColor: 'rgba(255,255,255,0.0)', fontSize: 12, textAlign: 'right' },
      STYLE_SUBTITULO_PAINEL            = {
        paddingRight: '15px', paddingTop: 0, paddingBottom: '10px', borderTop: 'unset', color: '#b8b8b8',
        fontFamily: 'Open Sans',
      };

export type NfesCanceladas = {
  quantidade : number,
  query : ?string,
}

type TabelaNfesCanceladasProps = {
  nfes : ?NfesCanceladas
}

export default function TabelaNfesCanceladas({ nfes } : TabelaNfesCanceladasProps) {
  if (!nfes)
    return null;

  return (
      <Table condensed style={ STYLE_ESPACAMENTO_INFERIOR_TABELA }>
        <colgroup>
          <col width="365px"/>
        </colgroup>
        <tbody>
        <tr style={ STYLE_TRANSPARENTE_TABELA }>
          <td style={ STYLE_SUBTITULO_PAINEL }>últimas 24 horas</td>
        </tr>
        <tr className="botao-link-nfes-canceladas">
          <QuantidadeLinkButton styleCelula={ STYLE_ESPACAMENTO_SUPERIOR_BOTAO }
                                styleBotao={ STYLE_TAMANHO_FONTE_BOTAO }
                                quantidade={ nfes.quantidade }
                                path={ nfes.query ? Paths.nfes.lista.recebidos({ q: nfes.query }) : null }/>
        </tr>
        </tbody>
      </Table>
  );
}
