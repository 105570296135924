// @flow
'use strict';

import * as _ from 'lodash';
import * as React from 'react';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import InputMask from 'react-input-mask';

import type { InputWrapperProps } from './types';
import SelectControl from './select_control';
import VirtualizedSelectControl from './virtualized_select_control';
import RadioGroupReact from './radio_group';

const PROPS_TO_COPY = ['autoFocus', 'placeholder'];

export default function InputWrapper({ label, hint, ...props } : InputWrapperProps) {
  const sm = props.sm || 8;

  const hasError = props.meta.touched && props.meta.invalid,
        errorMsg = hasError && props.meta.error;

  return (
      <FormGroup controlId={ props.input.name }
                 validationState={ hasError ? 'error' : null }
                 style={ props.formGroupStyle }>
        { label ?
            <>
              <Col sm={ 12 - sm } componentClass={ ControlLabel }>
                { props.required ? '* ' : '' }
                { label }
              </Col>
              <Col sm={ sm } className={ props.inputClassName || '' }>
                <InputControl { ...props }/>
                <HelpBlock>{ errorMsg || hint }</HelpBlock>
              </Col>
            </> :
            <>
              <InputControl { ...props }/>
              <HelpBlock>{ errorMsg || hint }</HelpBlock>
            </>
        }
      </FormGroup>
  );
}

export function InputControl(props : InputWrapperProps) {
  const handleSelectBlur = React.useCallback(() => props.input.onBlur(props.value), [props.input, props.value]);

  if (props.options || props.optionsSelector || props.optionsRadio) {
    const config = {};

    if (props.seletorComPlaceholder)
      config.placeholder = props.placeholder;

    if (props.seletorTransparente)
      config.className = 'filtro-transparente';

    if (props.seletorSemEdicao)
      config.disabled = true;

    const selectProps = {
      ...props,
      input: {
        ...props.input,
        ...config,
        noResultsText: props.isLoading ? 'Carregando...' : ' Nenhum resultado',
        inputProps: { id: props.input.name }, // importante para o clique no label dar foco no input
        onBlur: handleSelectBlur, // importante para compatibilidade com o blur do redux-form
      },
    };

    if (props.optionsRadio)
      return <RadioGroupReact { ...selectProps }/>;

    if (props.options)
      return <SelectControl { ...selectProps }/>;

    if (props.optionsSelector)
      return <VirtualizedSelectControl { ...selectProps }/>;
  }

  const { somenteLeitura = false } = props;

  let configInput = {};

  if (props.livre)
    configInput = { componentClass: 'textarea', style: props.style || { height: 200 } };

  if (props.mascara)
    configInput = { ...configInput, mask: props.mascara, alwaysShowMask: true, componentClass: InputMask };

  return <FormControl { ...props.input } { ...configInput } style={ props.formControlStyle }
                      readOnly={ somenteLeitura } { ..._.pick(props, PROPS_TO_COPY) } />;
}