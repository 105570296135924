import { library } from '@fortawesome/fontawesome-svg-core';

import * as fal from 'lib/initializers/icons/fontawesome-light';
import * as far from 'lib/initializers/icons/fontawesome-regular';
import * as fas from 'lib/initializers/icons/fontawesome-solid';
import * as fab from 'lib/initializers/icons/fontawesome-brand';
import * as fad from 'lib/initializers/icons/fontawesome-duotone';

// Referência da forma de uso: https://fontawesome.com/how-to-use/on-the-web/using-with/react

// registra os ícones exportados
library.add(...Object.values(fal), ...Object.values(far), ...Object.values(fas), ...Object.values(fab), ...Object.values(fad));
