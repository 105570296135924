// @flow
'use strict';

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row } from 'react-bootstrap';

import { useEffectOnce, jsSelector } from '../../../../../lib/viewUtils';
import { withFormValues } from '../../../../../lib/forms';
import {
  contasSelector, carregaContas, fechaAlertas, alertasSelector,
} from '../../../../../reducers/modelo_relatorio/salvar_relatorio';

import Alertas from './alertas';
import { Input } from '../../../../common/input';
import { SALVAR_RELATORIO, VISIBILIDADE } from '../../../../../i18n/constants';

type FormularioSalvarModeloProps = {
  salvarRelatorio? : bool,
}

export function FormularioSalvarRelatorio({ salvarRelatorio } : FormularioSalvarModeloProps) {
  const contasJsSelector = React.useMemo(() => jsSelector(contasSelector, []), []),
        contas           = useSelector(contasJsSelector),
        alertas          = useSelector(alertasSelector),
        dispatch         = useDispatch();

  useEffectOnce(() => {
    dispatch(carregaContas());
    dispatch(fechaAlertas());
  });

  return (
      <section className="download-lista">
        <Row><Input sm={ 4 } name="salvarRelatorio" label="Deseja salvar configuração como um novo relatório?"
                    options={ SALVAR_RELATORIO }/></Row>
        <fieldset>
          {
            salvarRelatorio &&
            <>
              <legend>Salvar Relatório</legend>
              { alertas && <Alertas alertas={ alertas } cor="danger"/> }
              <Row><Input sm={ 6 } name="nome" label="Informe um nome para seu relatório:" placeholder="Nome"
                          required/></Row>
              <Row><Input sm={ 6 } name="visibilidade" label="Informe a visibilidade:" options={ VISIBILIDADE }
                          required/></Row>
              <Row><Input sm={ 6 } name="contaId" label="Conta:" options={ contas }/></Row>
            </>
          }
        </fieldset>
      </section>
  );
}

export default withFormValues(true, 'salvarRelatorio')(FormularioSalvarRelatorio);
