// @flow

import * as Immutable from 'immutable';
import * as _ from 'lodash';
import { autofill } from 'redux-form/immutable';

import type { State, Action, Dispatch } from '../../lib/types';
import { inicializaCampos } from './functions';
import {
  CAMPOS_EMITENTE, CAMPOS_DESTINATARIO, CAMPOS_DADOS_ITENS, CAMPOS_COBRANCA,
  CAMPOS_DADOS_OPERACAO, CAMPOS_DADOS_CEST, CAMPOS_DOCUMENTOS_REFERENCIADOS,
  CAMPOS_TRANSACOES_CONSYSTE, CAMPOS_DADOS_TOTAIS, CAMPOS_DADOS_ICMS, CAMPOS_DADOS_IPI,
  CAMPOS_DADOS_ICMS_INTERESTADUAL, CAMPOS_DADOS_PIS, CAMPOS_DADOS_COFINS, CAMPOS_TRANSPORTE_NFE,
  CAMPOS_MANIFESTACAO_DESTINATARIO, CAMPOS_PAGAMENTO, CAMPOS_LOCAL_RETIRADA, CAMPOS_LOCAL_ENTREGA,
} from './constantes';

export const INICIALIZA_MODAL       = 'lista_documentos/nfe/INICIALIZA_MODAL',
             INICIALIZA_VALORES_NFE = 'lista_documentos/nfe/INICIALIZA_VALORES_NFE',
             REINICIA_VALORES_NFE   = 'lista_documentos/nfe/REINICIA_VALORES_NFE';

const DEFAULT_STATE_JS = {
  query: null, formato: 'txt', tipoDocumento: 'nfe', delimiter: ';', linhaPor: 1, salvarRelatorio: false, visibilidade: 'publica',
  dadosBasicos: {
    versao: false, modeloDescricao: false, chave: false, numero: false, serie: false, ambienteDescr: false,
    emitidoEm: false, naturezaOperacao: false, tipoOperacaoIdEDescr: false, finalidade: false,
    consumidorFinal: false, entradaSaida: false, situacaoSefaz: false, autorizadoEm: false, numeroProtocolo: false,
    valor: false, informacoesAdicionaisFisco: false, informacoesComplementares: false, destinoOperacao: false,
    tipoEmissao: false, indicadorPresenca: false,
  },
  ...CAMPOS_TRANSACOES_CONSYSTE, ...CAMPOS_EMITENTE, ...CAMPOS_DESTINATARIO, ...CAMPOS_DADOS_ITENS,
  ...CAMPOS_DADOS_OPERACAO, ...CAMPOS_DADOS_CEST, ...CAMPOS_DOCUMENTOS_REFERENCIADOS, ...CAMPOS_DADOS_TOTAIS,
  ...CAMPOS_DADOS_ICMS, ...CAMPOS_DADOS_ICMS_INTERESTADUAL, ...CAMPOS_DADOS_IPI, ...CAMPOS_DADOS_PIS,
  ...CAMPOS_DADOS_COFINS, ...CAMPOS_TRANSPORTE_NFE, ...CAMPOS_COBRANCA, ...CAMPOS_MANIFESTACAO_DESTINATARIO,
  ...CAMPOS_PAGAMENTO, ...CAMPOS_LOCAL_RETIRADA, ...CAMPOS_LOCAL_ENTREGA,
};

export const DEFAULT_STATE = Immutable.fromJS(DEFAULT_STATE_JS);

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action) {
  switch (action.type) {
    case INICIALIZA_MODAL:
      return state.set('query', action.query);
    case INICIALIZA_VALORES_NFE:
      return inicializaCampos(state, action.campos);
    case REINICIA_VALORES_NFE:
      return DEFAULT_STATE.set('query', state.get('query'));
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['listaDocumentos', 'nfe'], DEFAULT_STATE);

// thunk actions

export function atualizaSelecaoTodos(campos : string[], valor : bool) {
  return async function(dispatch : Dispatch<*>) {
    campos.forEach((campo : string) => {
      const grupoCheckbox = _.cloneDeep(DEFAULT_STATE_JS[campo]);

      Object.keys(grupoCheckbox).forEach(k => grupoCheckbox[k] = valor);

      return dispatch(autofill('listaDocumentosNfe', campo, Immutable.fromJS(grupoCheckbox)));
    });
  };
}