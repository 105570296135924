// @flow
'use strict';

import { type Saga } from 'redux-saga';
import { delay, put, takeEvery } from 'redux-saga/effects';

import { close, NEW_ALERT } from '../reducers/alerts';

/**
 * Saga que fecha os alertas 5s depois que eles forem exibidos.
 */
export default function* alerts() : Saga<*> {
  yield takeEvery(NEW_ALERT, closeAlertAfter(5000));
}

function closeAlertAfter(ms : number) {
  return function* (action : { alert : { seq : number } }) : Generator<*, *, *> {
    yield delay(ms);
    yield put(close(action.alert.seq));
  };
}