'use strict';
// @flow

import * as React from 'react';
import { Table } from 'react-bootstrap';

import type { IItem } from '../../../portaria/types';

type TabelaProps = {
  itensDecisaoPortaria : ?IItem[]
}

export default function TabelaItens(props : TabelaProps) {
  return (
      <Table condensed striped hover className="tabela-checkbox">
        <colgroup>
          <col width={ 1 }/>
          <col/>
        </colgroup>
        <thead>
        <tr>
          <th><span/></th>
          <th className="text-left"><span>Item</span></th>
        </tr>
        </thead>
        <tbody>
        { renderRows(props.itensDecisaoPortaria, 2) }
        </tbody>
      </Table>
  );
}

function renderRows(dados : ?IItem[], cols : number) {
  if (!Array.isArray(dados) || dados.length === 0) {
    return <tr className="nodata">
      <td colSpan={ cols }>Nenhum item encontrado.</td>
    </tr>;
  }
  else if (dados.length > 0)
    return <> { dados.map((d, index) => <TableRow key={ index } row={ d }/>) }</>;
  else
    throw new Error(`Dados inválidos: ${ JSON.stringify(dados) }`);
}

/**
 * Uma linha da tabela de documentos.
 */
export function TableRow({ row } : { row : IItem }) {
  return (
      <tr>
        <td>
          <input name="pendente" type="checkbox" checked={ row.pendente }/>
        </td>
        <td className="text-left">
          <strong>{ row.codigo }</strong>
          <span>{ descricao(row.descricao) } </span>
          <em className="pedido">{ pedido(row.pedido_nro, row.pedido_item) }</em>
        </td>
      </tr>
  );
}

function descricao(descr : ?string) {
  return descr ? ` ${ descr }` : '';
}

function pedido(numero : ?string, item : ?number) {
  if (!numero && !item)
    return '';

  const strNum  = numero ? `pedido ${ numero }` : '',
        strItem = item ? ` item ${ item }` : '';

  return ` (${ strNum }${ strItem })`;
}