'use strict';
// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as modal from '../../../../reducers/modal';
import { withProvider } from '../../../../lib/reduxTools';

import ManifestaCte from './manifesta_cte';

const mapStateToProps = createStructuredSelector({ open: modal.openModalSelector });

export default compose(
    withProvider(),
    connect(mapStateToProps, modal)
)(ManifestaCte);
