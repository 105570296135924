// @flow
'use strict';

import * as React from 'react';

import type { ICofinsNt } from '../../../../../types_imposto';

import RowDetalhes from '../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../common/detalhes_documento/column_detalhes';

type CofinsNtProps = {
  item : ICofinsNt,
};

export default function CofinsNt({ item } : CofinsNtProps) {
  return (
      <RowDetalhes data={ item }>
        <ColumnDetalhes field="cst">CST</ColumnDetalhes>
      </RowDetalhes>
  );
}