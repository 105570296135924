// @flow
'use strict';

import * as React from 'react';

import Icone from '../../common/icone';

type BotaoOrdenacaoProps = {
  campo : string,
  rotulo : string,
  ordenacao : { sd : string, s : string },
  onClick : () => void,
}

const CAMPOS_ORDENACAO = {
  emitidoEm: 'emitido_em', serieNumero: 'serie,numero', valorTotal: 'valor',
  emitente: 'emit_nome,emit_cnpj', tomador: 'toma_nome,toma_cnpj',
};

export default function OrdenacaoPorCampo({ campo, rotulo, ordenacao, onClick } : BotaoOrdenacaoProps) {
  return (
      <a className="a-btn" onClick={ onClick }>
        <RotuloComSinalOrdenacao campo={ campo } rotulo={ rotulo } ordenacao={ ordenacao }/>
      </a>
  );
}

function RotuloComSinalOrdenacao({ campo, rotulo, ordenacao } : { campo : string, rotulo : string, ordenacao : { sd : string, s : string } }) {
  if (!deveApresentarSinalOrdenacao(campo, ordenacao))
    return <span>{ rotulo }</span>;

  const sentidoSinal = !ordenacao.s || ordenacao.sd === 'desc' ? 'caret-up' : 'caret-down';

  return <span>{ rotulo }{ '  ' }<Icone nome={ sentidoSinal } estilo="fas"/></span>;
}

function deveApresentarSinalOrdenacao(campo : string, ordenacao : { sd : string, s : string }) {
  if (!ordenacao || !ordenacao.s)
    return campo === 'emitidoEm';

  return ordenacao.s === CAMPOS_ORDENACAO[campo];
}
