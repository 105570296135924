// @flow
'use strict';

import * as React from 'react';

import type { ICofinsOutr } from '../../../../../types_imposto';

import RowDetalhes from '../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../common/detalhes_documento/column_detalhes';
import TributoAliquota from './tributo_aliquota';
import TributoQuantidade from './tributo_quantidade';
import ValorCofins from './valor_cofins';

type CofinsOutrProps = {
  item : ICofinsOutr,
};

export default function CofinsOutr({ item } : CofinsOutrProps) {
  if (item.valorBc && item.quantidadeVendida) {
    return (
        <div>
          <RowDetalhes data={ item }>
            <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
            <ColumnDetalhes field="valorBc" type="moeda" width="25%">Valor da BC COFINS</ColumnDetalhes>
            <ColumnDetalhes field="aliquotaCofinsPercentual" type="percentual" width="25%">Alíquota COFINS</ColumnDetalhes>
            <ColumnDetalhes field="quantidadeVendida" width="25%">Quantidade Vendida</ColumnDetalhes>
          </RowDetalhes>
          <RowDetalhes data={ item }>
            <ColumnDetalhes field="aliquotaCofinsReais" type="moeda" width="50%">Alíquota COFINS</ColumnDetalhes>
            <ColumnDetalhes field="valorCofins" type="moeda" width="50%">Valor da COFINS</ColumnDetalhes>
          </RowDetalhes>
        </div>
    );
  }

  if (item.valorBc)
    return <TributoAliquota item={ item }/>;

  if (item.quantidadeVendida)
    return <TributoQuantidade item={ item }/>;

  return <ValorCofins item={ item }/>;
}