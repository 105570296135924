// @flow
'use strict';

import * as _ from 'lodash';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import type { IRoute } from 'lib/types';
import { jsSelector, useEffectOnce } from 'lib/viewUtils';

import { carregaDetalhes, itemSelector } from '../../../reducers/cfe/detalhes';
import { modalOpenSelector } from '../../../reducers/cfe/historico';

import DadosBasicos from './dados_basicos';
import AbasInformacoes from './abas_informacoes';
import PainelLateral from '../../paineis';
import Voltar from './voltar';
import ModalHistorico from './modal_historico';
import BotaoHistorico from './botao_historico';
import BotoesDetalhes from '../../common/detalhes_documento/botoes_detalhes';
import AvisoNaoCustodiado from '../../common/detalhes_documento/aviso_nao_custodiado';
import { Breadcrumbs, Title } from '../../common/router_components';

type DetalhesProps = {
  routes : IRoute[],
  routeParams : { id : string };
}

const itemJsSelector = jsSelector(itemSelector, {});

export default function Detalhes({ routeParams, routes } : DetalhesProps) {
  const historicoOpen = useSelector(modalOpenSelector),
        item          = useSelector(itemJsSelector);

  const dispatch         = useDispatch(),
        carregarDetalhes = React.useCallback((id) => dispatch(carregaDetalhes(id)), [dispatch]);

  useEffectOnce(() => {
    // Caso os dados do item não estejam carregados, carrega detalhes do item
    if (_.isEmpty(item) && routeParams)
      carregarDetalhes(routeParams.id);
  });

  return (
      <>
        <Title routes={ routes }/>
        <Breadcrumbs routes={ routes }/>

        {/* Precisa desta comparação com false porque o campo inicia com o valor undefined, e exibe o aviso indevidamente */
            item.possuiXml === false && <AvisoNaoCustodiado/> }

        <Row>
          <Col md={ 9 } className="r"><Voltar/></Col>
          <Col md={ 3 }><BotaoHistorico id={ item.id }/></Col>
        </Row>
        <Row>
          <Col md={ 9 }/>
          <Col md={ 3 } className="padding-bottom-zoom">
            <PainelLateral ignoreInitialize url={ item.url } env={ item.env } modeloId={ item.modeloId }/>
          </Col>
        </Row>
        <Row>
          <div className="col-md-9" style={ { marginTop: '-5.2em' } }>
            <section className="visualizacao-documento detalhes-documento">
              <DadosBasicos item={ item }/>
            </section>
          </div>
          <div className="col-md-12" style={ { marginTop: '-1.05em' } }>
            <section className="visualizacao-documento detalhes-documento" style={ { paddingTop: 0 } }>
              <AbasInformacoes item={ item }/>
            </section>

            <BotoesDetalhes id={ item.id } tipo="cfe" possuiXml={ item.possuiXml } textoBotaoDownloadPdf="Download do Demonstrativo CFe" BotaoVoltar={ Voltar }/>
          </div>
        </Row>

        { historicoOpen && <ModalHistorico/> }
      </>
  );
}
