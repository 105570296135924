// @flow
'use strict';

import * as React from 'react';

import type { IIcms10 } from '../../../../../../types_imposto';

import RowDetalhes from '../../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../../common/detalhes_documento/column_detalhes';

type Icms10Props = {
  item : IIcms10,
};

export default function Icms10({ item } : Icms10Props) {
  return (
      <>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="origem" width="25%">Origem da Mercadoria</ColumnDetalhes>
          <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
          <ColumnDetalhes field="determinacaoBcIcms" width="25%">Determinação da BC</ColumnDetalhes>
          <ColumnDetalhes field="valorBcIcms" type="moeda" width="25%">Base de Cálculo</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="aliquotaIcms" type="percentual" width="25%">Alíquota</ColumnDetalhes>
          <ColumnDetalhes field="valorIcms" type="moeda" width="25%">Valor do ICMS</ColumnDetalhes>
          <ColumnDetalhes field="bcFcp" type="moeda" width="25%">Base de Cálculo FCP</ColumnDetalhes>
          <ColumnDetalhes field="percentualFcp" type="percentual" width="25%">Percentual FCP</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="valorFcp" type="moeda" width="25%">Valor FCP</ColumnDetalhes>
          <ColumnDetalhes field="determinacaoBcIcmsSt" width="25%">Determinação da BC ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="margemValorAdicionadoIcmsSt" type="percentual" width="25%">Margem Valor Adicionado ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="reducaoBcIcmsSt" type="percentual" width="25%">Redução BC ICMS ST</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="valorBcIcmsSt" type="moeda" width="25%">Valor BC ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaBcIcmsSt" type="percentual" width="25%">Alíquota ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="valorIcmsSt" type="moeda" width="25%">Valor ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="bcFcpRetidoSt" type="moeda" width="25%">BC FCP retido por ST</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="percFcpRetidoSt" type="percentual" width="25%">FCP Retido por ST</ColumnDetalhes>
          <ColumnDetalhes field="valorFcpRetidoSt" type="moeda" width="75%">FCP Retido por ST </ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
