// @flow
'use strict';

import * as React from 'react';

import type { IImposto } from '../../types_imposto';

import Icms00 from './opcoes_impostos/icms00';
import Icms20 from './opcoes_impostos/icms20';
import Icms45 from './opcoes_impostos/icms45';
import Icms60 from './opcoes_impostos/icms60';
import Icms90 from './opcoes_impostos/icms90';
import IcmsOutraUf from './opcoes_impostos/icms_outra_uf';
import IcmsSn from './opcoes_impostos/icms_sn';
import IcmsPartilha from './opcoes_impostos/icms_partilha';

type ImpostoProps = {
  imposto : IImposto,
};

export default function Impostos({ imposto } : ImpostoProps) {
  if (!imposto)
    return null;

  return (
      <>
        <h4>Impostos</h4>
        { imposto.icms00 && <Icms00 icms={ imposto.icms00 }/> }
        { imposto.icms20 && <Icms20 icms={ imposto.icms20 }/> }
        { imposto.icms45 && <Icms45 icms={ imposto.icms45 }/> }
        { imposto.icms60 && <Icms60 icms={ imposto.icms60 }/> }
        { imposto.icms90 && <Icms90 icms={ imposto.icms90 }/> }
        { imposto.icmsOutraUF && <IcmsOutraUf icms={ imposto.icmsOutraUF }/> }
        { imposto.icmsSn && <IcmsSn icms={ imposto.icmsSn }/> }
        { imposto.icmsPartilha && <IcmsPartilha icms={ imposto.icmsPartilha }/> }
      </>
    );
}
