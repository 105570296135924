// @flow
'use strict';

import * as React from 'react';

import type { IProdutoServico } from '../../../types';

import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';

type DadosBasicosProps = {
  item : IProdutoServico,
};

export default function DadosBasicos({ item } : DadosBasicosProps) {
  return (
      <div id="table-dados-basicos">
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="codigo" width="25%">Código</ColumnDetalhes>
          <ColumnDetalhes field="gtinComercial" width="25%">GTIN Comercial</ColumnDetalhes>
          <ColumnDetalhes field="descricao" width="25%">Descrição</ColumnDetalhes>
          <ColumnDetalhes field="ncm" width="25%">NCM</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="cest" width="25%">CEST</ColumnDetalhes>
          <ColumnDetalhes field="escalaRelevante" width="25%">Escala Relevante</ColumnDetalhes>
          <ColumnDetalhes field="cnpjFabricante" type="documento" width="25%">CNPJ Fabr.</ColumnDetalhes>
          <ColumnDetalhes field="codBenFiscal" width="25%">Cód. Ben. Fiscal</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="exTipi" width="25%">EX_TIPI</ColumnDetalhes>
          <ColumnDetalhes field="cfop" width="25%">CFOP</ColumnDetalhes>
          <ColumnDetalhes field="undComercial" width="25%">Und. Comercial</ColumnDetalhes>
          <ColumnDetalhes field="qtdComercial" width="25%">Qtd. Comercial</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="valorUnitCom" type="moeda" width="25%">Valor Unit. Com.</ColumnDetalhes>
          <ColumnDetalhes field="valorTotal" type="moeda" width="25%">Valor Total</ColumnDetalhes>
          <ColumnDetalhes field="gtinTributavel" width="25%">GTIN Tributável</ColumnDetalhes>
          <ColumnDetalhes field="undTributavel" width="25%">Und. Tributável</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="qtdTributavel" width="25%">Qtd. Tributável</ColumnDetalhes>
          <ColumnDetalhes field="valorUnitTrib" type="moeda" width="25%">Valor Unit.Trib.</ColumnDetalhes>
          <ColumnDetalhes field="frete" type="moeda" width="25%">Frete</ColumnDetalhes>
          <ColumnDetalhes field="seguro" type="moeda" width="25%">Seguro</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="desconto" type="moeda" width="25%">Desconto</ColumnDetalhes>
          <ColumnDetalhes field="outrasDespesas" type="moeda" width="25%">Outras Despesas</ColumnDetalhes>
          <ColumnDetalhes field="compoeValorTotalNfe" width="50%">Item Compõe Valor Total da NF-e</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
