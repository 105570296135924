// @flow
'use strict';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Filtros from './filtros';

export default compose(connect<*, *, *, *, *, *>(), withRouter)(Filtros);
