'use strict';

// @flow

export function numberToString(n : ?number) {
  if (typeof n === 'number')
    return String(n);

  return n;
}

const SITUACOES_MANIFESTACAO_CD = {
  '0': 'confirmada', '1': 'ciencia', '2': 'desconhecimento', '4': 'operacao_nao_realizada',
  '210200': 'confirmada', '210210': 'ciencia', '210220': 'desconhecimento', '210240': 'operacao_nao_realizada',
};

/**
 * Função para converter valor de manifestacao_cd para código
 */
export function obtemCodManifestacaoCd(manif : ?number) {
  if ([4, 2, 1, 0, 210200, 210210, 210220, 210240].includes(manif))
    return SITUACOES_MANIFESTACAO_CD[String(manif)];

  if (!manif)
    return 'none';

  return `__unknown__`;
}

export function obtemOpcaoDesacordo(desacordo_servico : ?bool) {
  if (desacordo_servico)
    return 'true';

  return 'false';
}