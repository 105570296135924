// @flow
'use strict';

import * as React from 'react';

import type { IIcms40 } from '../../../../../../types_imposto';

import RowDetalhes from '../../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../../common/detalhes_documento/column_detalhes';

type Icms40Props = {
  item : IIcms40,
};

export default function Icms40({ item } : Icms40Props) {
  return (
      <div>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="origem" width="25%">Origem da Mercadoria</ColumnDetalhes>
          <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
          <ColumnDetalhes field="valorIcmsDesonerado" type="moeda" width="25%">ICMS Desonerado</ColumnDetalhes>
          <ColumnDetalhes field="motivoDesoneracaoIcms" width="25%">Motivo Desoneração</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
