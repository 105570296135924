'use strict';
// @flow

import * as _ from 'lodash';
import * as React from 'react';

import Icone from '../icone';
import NoDataRow from '../no_data_row';

import type { TableBodyProps } from './types';

export default function TableBody({ rowComponent, columns, data, onRowClick, noRecordsFoundMessage } : TableBodyProps<*>) {
  let body;

  const span = columns.reduce((s, c : React.Element<any>) => s + (c.props.colSpan || 1), 0);

  if (!data || data === 'loading')
    body = <NoDataRow span={span}><Icone nome="sync-alt" estilo="fas" tamanho={2} spin /></NoDataRow>;
  else if (Array.isArray(data) && data.length === 0)
    body = <NoDataRow span={span}>{ noRecordsFoundMessage || 'Nenhum registro encontrado.' }</NoDataRow>;
  else if (data === 'erro')
    body = <NoDataRow span={span}>Ocorreu um erro ao carregar a lista.</NoDataRow>;
  else if (!_.isArray(data))
    throw new Error(`Dados inválidos: ${JSON.stringify(data)}`);
  else {
    const RC = rowComponent;
    body = data.map((row, key) => <RC key={key} row={row} columns={columns} onClick={onRowClick}/>);
  }

  return <tbody>{body}</tbody>;
}
