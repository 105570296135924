// @flow
'use strict';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';

import * as filtroEstruturado from '../../../../reducers/nfce/filtro_estruturado';
import { suporteSelector } from '../../../../reducers/nfce/lista';

import ModalBuscaEstruturada from './modal_busca_estruturada';

const mapStateToProps = createStructuredSelector({
  initialValues: filtroEstruturado.rootSelector, open: filtroEstruturado.modalOpenSelector,
  suporte: suporteSelector,
});

export default compose(
    connect<*, *, *, *, *, *>(mapStateToProps, { fechaModal: filtroEstruturado.finalizaBuscaEstruturada }),
    reduxForm({
      form: 'nfceFiltroEstruturado', enableReinitialize: true,
      onSubmit(form, dispatch) {
        dispatch(filtroEstruturado.aplicaFiltro(form));
      },
    }),
)(ModalBuscaEstruturada);