// @flow
'use strict';

import * as React from 'react';
import _ from 'lodash';

import { toNumberBrDecimal2 } from '../../../lib/toNumberBrDecimal';
import { formatHelper as fmt } from '../../../lib/viewUtils';

import ComTooltip from '../com_tooltip';

export function formatoValor(v : ?string | ?number) {
  if (!v && typeof v !== 'number')
    return v;

  return (
      <div className="r">
        <div className="pull-left">R$</div>
        <span>{ toNumberBrDecimal2(v) }</span>
      </div>
  );
}

export function documentoNome(t : string[]) {
  if (t.length === 2) {
    const cnpj = fmt.formatId(t[0]);
    return _([cnpj, t[1]]).compact().join(' - ');
  }

  return _(t).compact().join(' - ');
}

export function documentoComTooltip(e : string[]) {
  if (e.length === 2) {
    return (
        <ComTooltip hint={ e[1] } posicao="top">
          <span className="tooltip-td-table-documento">{ fmt.formatId(e[0]) }</span>
        </ComTooltip>
    );
  }

  return _(e).compact().join(' - ');
}
