// @flow
'use strict';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { aplicaFiltro, inicializaFiltros } from 'reducers/ferramentas/prestador/lista';
import { carregaContas } from 'reducers/ferramentas/prestador/detalhes';

import Filtros from './filtros';

export default compose(
    connect<*, *, *, *, *, *>(null, { inicializaFiltros, aplicaFiltro, carregaContas }),
    withRouter,
)(Filtros);
