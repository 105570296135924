// @flow
'use strict';

import * as React from 'react';

import type { ICofinsQtde } from '../../../../../types_imposto';

import TributoQuantidade from './tributo_quantidade';

type CofinsQtdeProps = {
  item : ICofinsQtde,
};

export default function CofinsQtde({ item } : CofinsQtdeProps) {
  return <TributoQuantidade item={ item }/>;
}
