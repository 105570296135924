'use strict';
// @flow

import React from 'react';
import moment from 'moment';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

let lastId = 0;

type TimeagoProps = {
  time? : string,
  updateInterval? : number,
};

export default class Timeago extends React.Component<TimeagoProps, *> {
  props : TimeagoProps;

  tooltipId : string = `timeago-tooltip-${++lastId}`;
  timer : ?IntervalID;

  componentDidMount() {
    if (this.props.updateInterval)
      this.timer = setInterval(() => this.forceUpdate(), this.props.updateInterval);
  }

  componentWillUnmount() {
    if (this.timer)
      clearInterval(this.timer);
  }

  render() {
    const m   = moment(this.props.time),
          tip = <Tooltip id={this.tooltipId}> { m.format(' L, HH:mm:ss') } </Tooltip>;

    return (
        <OverlayTrigger placement="bottom" overlay={tip}>
          <time> { m.fromNow() } </time>
        </OverlayTrigger>
    );
  }
}
