// @flow
'use strict';

import * as React from 'react';

import type { ItemProps } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';
import Endereco from './endereco';

export default function Prestador({ item } : ItemProps) {
  return (
      <div className="tab-pane active">
        <h3>Prestador</h3>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="prestadorCpfCnpj" type="documento" width="25%">CPF / CNPJ</ColumnDetalhes>
          <ColumnDetalhes field="prestadorRazaoSocial" width="75%">Nome / Razão social</ColumnDetalhes>
        </RowDetalhes>

        <RowDetalhes data={ item }>
          <ColumnDetalhes field="prestadorIm" width="25%">Inscrição Municipal</ColumnDetalhes>
          <ColumnDetalhes field="prestadorEmail" width="40%">E-mail</ColumnDetalhes>
          <ColumnDetalhes field="simplesOpcao" width="35%">Opção Simples</ColumnDetalhes>
        </RowDetalhes>

        <Endereco item={ item.prestadorEndereco }/>
      </div>
  );
}