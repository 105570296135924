// @flow
'use strict';

import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import SelecaoTodosNfe from './selecao_todos_nfe';
import CamposNfeENfce from '../../common/campos_nfe_e_nfce';
import CamposBasicos from './campos_basicos';
import CamposTransacoesConsyste from '../../common/campos_transacoes_consyste';
import CamposPessoa from '../../common/campos_pessoa';
import CamposManifestacaoDestinatario from '../../common/campos_manifestacao_destinatario';
import CamposLocalRetirada from '../../common/campos_local_retirada';
import CamposLocalEntrega from '../../common/campos_local_entrega';
import CamposTotais from '../../common/campos_totais';
import CamposTransporte from '../../common/campos_transporte';

export default function CamposNfe() {
  return (
      <CamposNfeENfce component={ SelecaoTodosNfe }>
        <Row>
          <Col md={ 6 }>
            <CamposBasicos key="camposBasicos"/>
            <CamposTotais key="camposTotais" component={ SelecaoTodosNfe }/>
            <CamposPessoa nome="emit" titulo="Dados do Emitente" component={ SelecaoTodosNfe }/>
            <CamposPessoa nome="dest" titulo="Dados do Destinatário" component={ SelecaoTodosNfe }/>
          </Col>
          <Col md={ 6 }>
            <CamposTransacoesConsyste component={ SelecaoTodosNfe }/>
            <CamposLocalRetirada component={ SelecaoTodosNfe }/>
            <CamposLocalEntrega component={ SelecaoTodosNfe }/>
            <CamposTransporte tipoDocumento="nfe" component={ SelecaoTodosNfe }/>
            <CamposManifestacaoDestinatario component={ SelecaoTodosNfe }/>
          </Col>
        </Row>
      </CamposNfeENfce>
  );
}