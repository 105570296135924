// @flow
'use strict';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';

import { jsSelector } from 'lib/viewUtils';
import { alert } from 'reducers/alerts';
import {
  atualizaFornecedor, carregaContas, contasSelector, criaFornecedor, excluiFornecedor, itemSelector, navigateToIndex,
} from 'reducers/ferramentas/prestador/detalhes';

import Formulario from './formulario';

/**
 * Lista de campos do formulário que não devem ser enviados ao servidor.
 */
const FIELD_BLACKLIST = [
  'usuarioId',        // não deve ser enviada porque este campo não deve ser alterado
  'autor',            // não deve ser enviada porque este campo não deve ser alterado
  'createdAt',        // não devem ser enviados pois a atualização destes campos é automática
];

const mapStateToProps = createStructuredSelector({
  initialValues: itemSelector,
  contas: jsSelector(contasSelector, []),
});

export default compose(
    connect<*, *, *, *, *, *>(mapStateToProps, { navigateToIndex, excluiFornecedor, carregaContas }),
    reduxForm({
      form: 'fornecedor', enableReinitialize: true,
      async onSubmit(form) {
        const { id, ...fornecedor } = form.deleteAll(FIELD_BLACKLIST).toJS();
        if (id)
          return atualizaFornecedor(id, fornecedor);
        else
          return criaFornecedor(fornecedor);
      },
      onSubmitFail(syncErrors, dispatch, e) {
        let msg;
        if (syncErrors || (e instanceof SubmissionError))
          msg = 'Ocorreram erros de validação. Verifique os dados preenchidos e tente novamente';
        else {
          global.console.error(e);
          msg = 'Ocorreu um erro ao enviar o formulário. Se o problema persistir, contate o suporte.';
        }
        dispatch(alert('danger', msg));
      },
      onSubmitSuccess(r, dispatch) {
        dispatch(navigateToIndex());
        dispatch(alert('info', 'Fornecedor salvo.'));
      },
    }),
)(Formulario);
