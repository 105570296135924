// @flow
'use strict';

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, Dispatch, GetState, State } from 'lib/types';
import { carregaLista, listaCarregando } from './lista';
import { carregaFiltrosResumoLista } from './resumo_lista';
import { currentRouteSelector } from '../routing';

const BUSCA_LIVRE               = 'nfe/filtroLivre/BUSCA_LIVRE',
      ALTERA_ORDENACAO          = 'nfe/filtroLivre/ALTERA_ORDENACAO',
      ALTERA_TIPO_DOCUMENTO     = 'nfe/filtroLivre/ALTERA_TIPO_DOCUMENTO',
      FILTRO_RESUMO_LISTA       = 'nfe/filtroLivre/FILTRO_RESUMO_LISTA',
      LIMPA_FILTRO_RESUMO_LISTA = 'nfe/filtroLivre/LIMPA_FILTRO_RESUMO_LISTA';

const INITIAL_STATE = Immutable.fromJS({ q: '', d: '', f: '', ordenacao: { s: '', sd: '' } });

type IQuery = { q? : string };
export type IFiltro = { d : ?string, s : ?string, sd : ?string, page : ?string, f : ?string };

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case BUSCA_LIVRE:
      return state.set('q', action.busca);
    case ALTERA_ORDENACAO:
      return state.set('ordenacao', action.ordenacao);
    case ALTERA_TIPO_DOCUMENTO:
      return state.set('d', action.tipo);
    case FILTRO_RESUMO_LISTA:
      return state.set('f', action.opcao);
    case LIMPA_FILTRO_RESUMO_LISTA:
      return state.set('f', '');
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['nfe', 'filtroLivre'], Immutable.Map());
export const ordenacaoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('ordenacao'));
export const tipoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('d'));
export const querySelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('q'));
export const opcaoResumoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('f'));

// actions

export function inicializaBuscaLivre(query : { q? : string }) {
  if (query && query.q)
    return { type: BUSCA_LIVRE, busca: query.q };
  else
    return { type: BUSCA_LIVRE, busca: '' };
}

export function limpaFiltroResumoLista() {
  return { type: LIMPA_FILTRO_RESUMO_LISTA };
}

export function filtraResumoLista(opcao : ?string) {
  return { type: FILTRO_RESUMO_LISTA, opcao };
}

// thunk actions

export function alteraTipoDocumento(tipo : string) {
  return async function(dispatch : Dispatch<*>) {
    dispatch({ type: ALTERA_TIPO_DOCUMENTO, tipo });
    dispatch(limpaFiltroResumoLista());
  }
}

export function atualizaListaEFiltro(query : IQuery, filtro : IFiltro) {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const query_busca = { q: query.q };

    await dispatch(inicializaBuscaLivre(query_busca));
    await dispatch(carregaLista(query_busca, filtro));

    if (!filtro)
      return null;

    const ordenacaoAtual = ordenacaoSelector(getState());

    if (filtro.sd !== ordenacaoAtual.get('sd') || filtro.s !== ordenacaoAtual.get('s'))
      await dispatch({ type: ALTERA_ORDENACAO, ordenacao: Immutable.fromJS({ sd: filtro.sd, s: filtro.s }) });

    const opcaoResumoAtual = opcaoResumoSelector(getState());
    if (!filtro.f)
      await dispatch(limpaFiltroResumoLista());

    if (filtro.f && filtro.f !== opcaoResumoAtual)
      await dispatch(filtraResumoLista(filtro.f));
  };
}

export function limpaFiltro() {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    await dispatch(atualizaListaEFiltro({ q: '' }, {
      d: '', page: undefined, sd: '', s: '', f: opcaoResumoSelector(getState()),
    }));
  };
}

export function aplicaFiltro(form : any) {
  if (form.toJS)
    form = form.toJS();

  const { ordenacao, ...query } = form;
  ordenacao.sd = '';
  ordenacao.s = '';

  return async function(dispatch : Dispatch<*>) {
    await dispatch(atualizaListaEFiltro(query, ordenacao));
  };
}

export function ordenaLista(paramOrdem : string) {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const query       = currentRouteSelector(getState()).get('query').toJS(),
          ordenacao   = ordenacaoSelector(getState()),
          opcaoResumo = opcaoResumoSelector(getState()),
          ord         = { s: '', sd: '' };

    ord.s = paramOrdem;
    if (ordenacao && ordenacao.get('s') === paramOrdem)
      ord.sd = ordenacao.get('sd') === 'desc' ? 'asc' : 'desc';
    else
      ord.sd = 'asc';

    const filtro = { ...ord, page: undefined, d: '', f: opcaoResumo };
    await dispatch(atualizaListaEFiltro(query, filtro));
  };
}

export function inicializaFiltro(query : IQuery, filtro : IFiltro) {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    await dispatch(listaCarregando());

    if (filtro && filtro.d && tipoSelector(getState()) !== filtro.d) {
      await dispatch(alteraTipoDocumento(filtro.d));
      await dispatch(carregaFiltrosResumoLista(filtro.d));
    }

    await dispatch(atualizaListaEFiltro(query, filtro));
  };
}

export function filtrarResumoLista(opcao : string) {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const ordenacao = ordenacaoSelector(getState()).toJS();

    const filtro = { ...ordenacao, d: '', page: undefined, f: opcao };
    await dispatch(atualizaListaEFiltro({}, filtro));
  };
}
