// @flow
'use strict';

import * as React from 'react';
import { Button } from 'react-bootstrap';

type BotaoOpcaoXmlProps = {
  abreModalXml : () => void;
}

export default function BotaoOpcaoXml({ abreModalXml } : BotaoOpcaoXmlProps) {
  return (
      <Button className="btn-exportacao-lote dropdown-item" bsSize="large" bsStyle="link" block
              onClick={ abreModalXml }>XML</Button>
  );
}
