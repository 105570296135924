'use strict';
// @flow

import * as _ from 'lodash';
import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, State, Dispatch, GetState } from 'lib/types';
import { carregaDetalhes } from './detalhes';

export const INICIALIZA_FILTRO = 'arquivo/upload/filtro/INICIALIZA_FILTRO',
             LIMPA_FILTRO      = 'arquivo/upload/filtro/LIMPA_FILTRO';

const FiltroRecord = new Immutable.Record({
  id: '', nome: '', chave: '', digestValue: '',
});

export default function reducer(state : any = new FiltroRecord(), action : Action = {}) {
  switch (action.type) {
    case INICIALIZA_FILTRO:
      return state = new FiltroRecord(action.body);
    case LIMPA_FILTRO:
      return state = new FiltroRecord({ id: action.id });
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['arquivo', 'upload', 'filtro'], Immutable.Map());
export const idSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('id'));

// actions

export function inicializaFiltro(id : string, filtro : Object) {
  return { type: INICIALIZA_FILTRO, body: { ...filtro, id } };
}

// thunk actions

export function limpaFiltro() {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const id = idSelector(getState());

    dispatch({ type: LIMPA_FILTRO, id });
    dispatch(aplicaFiltro({}));
  };
}

export function aplicaFiltro(form : any) {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const id = idSelector(getState());

    if (form.toJS)
      form = _.omit(form.toJS(), ['id']);

    await dispatch(carregaDetalhes(id, form));
  };
}
