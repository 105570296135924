'use strict';
// @flow

/**
 * Este arquivo implementa o componente "update-nag".
 *
 * Inspiração: http://bootsnipp.com/snippets/featured/status-bar
 */

import UpdateNag from './update_nag';
import './update_nag.less';

export default UpdateNag;