// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfe from '../form_exportacao_nfe/campos_nfe/selecao_todos_nfe';
import { Input } from '../../../../../common/input';

type CamposTotaisProps = {
  component : React.ComponentType<*>,
}

export default function CamposTotais({ component } : CamposTotaisProps) {
  const SelecaoTodos = component || SelecaoTodosNfe,
        tipoNfe      = SelecaoTodos === SelecaoTodosNfe;

  return (
      <FormSection name="dadosTotais">
        <SelecaoTodos nomes={ ['dadosTotais'] } titulo="Dados dos Totais"/>

        <div className="grupo-checkbox">
          <Input name="totalBcIcms" label="Total Base Calculo do ICMS" checkbox/>
          <Input name="totalIcms" label="Total do ICMS" checkbox/>
          <Input name="totalIcmsDeson" label="Total do ICMS Desonerado" checkbox/>
          { tipoNfe &&
              <>
                <Input name="totalIcmsFcpUfDest" label="Total do ICMS relativo ao FCP da UF Destino" checkbox/>
                <Input name="totalIcmsInterUfDest" label="Total do ICMS Interestadual para a UF Destino" checkbox/>
                <Input name="totalIcmsInterUfRemet" label="Total do ICMS Interestadual para a UF Remetente" checkbox/>
              </>
          }
          <Input name="totalFcp" label="Total do FCP" checkbox/>
          <Input name="totalBcIcmsSt" label="Total Base Calculo do ICMS ST" checkbox/>
          <Input name="totalIcmsSt" label="Total do ICMS ST" checkbox/>
          <Input name="totalFcpRetidoSt" label="Total do FCP retido por ST" checkbox/>
          <Input name="totalFcpRetidoAntSt" label="Total do FCP retido anteriormente por ST" checkbox/>
          <Input name="totalProd" label="Total dos Produtos e Serviços" checkbox/>
          <Input name="totalFrete" label="Total do Frete" checkbox/>
          <Input name="totalSeguro" label="Total do Seguro" checkbox/>
          <Input name="totalDesconto" label="Total do Desconto" checkbox/>
          { tipoNfe &&
              <>
                <Input name="totalImpostoImportacao" label="Total do Imposto Importação (II)" checkbox/>
                <Input name="totalIpi" label="Total do IPI" checkbox/>
                <Input name="totalIpiDevolvido" label="Total do IPI Devolvido" checkbox/>
              </>
          }
          <Input name="totalPis" label="Total do PIS" checkbox/>
          <Input name="totalCofins" label="Total da COFINS" checkbox/>
          <Input name="totalOutras" label="Total Outras Despesas" checkbox/>
          <Input name="totalValor" label={tipoNfe ? 'Total da NF-e' : 'Total da NFC-e'} checkbox/>
          <Input name="totalTributos" label="Total Aprox. Tributos" checkbox/>
        </div>
      </FormSection>
  );
}
