// @flow
'use strict';

import * as React from 'react';

import type { ICofins } from '../../../../types_imposto';

import OpcoesCofins from './opcoes_cofins';

type CofinsProps = {
  item : ICofins,
};

export default function Cofins({ item } : CofinsProps) {
  const opcoesCofins = [];

  Object.keys(item).forEach((i : string) => {
    const Opcao = OpcoesCofins[i.replace('cofins', 'Cofins')];
    if (Opcao)
      opcoesCofins.push(<Opcao key={ i } item={ item[i] }/>);
  });

  return (
      <div id="table-cofins">
        <h4>COFINS - Contribuição para o Financiamento da Seguridade Social</h4>
        { opcoesCofins }
      </div>
  );
}
