// @flow
'use strict';

import { fork } from 'redux-saga/effects';

import alerts from './alerts';
import crud from './crud';
import escriturados from './escriturados';
import listaDocumento from './lista_documento';
import manifestacaoCte from './manifestacao_cte';
import dashboard from './dashboard';
import noticia from './noticia';
import fornecedor from './fornecedor';
import emails from './emails';

export default function* rootSaga() : Generator<*, *, *> {
  const sagas = [alerts, crud, escriturados, listaDocumento, manifestacaoCte, dashboard, noticia, fornecedor, emails];

  for (const saga of sagas)
    yield fork(saga);
}
