'use strict';
// @flow

import * as Immutable from 'immutable';
import { push } from 'react-router-redux';
import { createSelector } from 'reselect';

import type { Action, State, Dispatch } from 'lib/types';
import request from 'lib/request';
import { uploadPath } from '../../../components/uploads/upload_path';
import { ajaxError } from '../../alerts';

export const DETALHES_CARREGANDO = 'arquivo/upload/detalhes/DETALHES_CARREGANDO',
             DETALHES_CARREGADO  = 'arquivo/upload/detalhes/DETALHES_CARREGADO';

const INITIAL_STATE = Immutable.fromJS({
  carregando: false,
  item: {},
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case DETALHES_CARREGANDO:
      return state.set('carregando', true).set('item', Immutable.fromJS({}));
    case DETALHES_CARREGADO:
      return state.set('carregando', false).set('item', action.body);

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['arquivo', 'upload', 'detalhes'], Immutable.Map());
export const itemSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('item'));
export const carregandoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('carregando', false));

// actions

export function detalhesCarregando() {
  return { type: DETALHES_CARREGANDO };
}

export function detalhesCarregado(item : any) {
  return { type: DETALHES_CARREGADO, body: Immutable.fromJS(item) };
}

// thunk actions

export function carregaDetalhes(id : string, params : any) {
  return async function(dispatch : Dispatch<any>) {
    dispatch(detalhesCarregando());

    dispatch(push(uploadPath.entidade(id, params)));

    request.get(uploadPath.entidade(id, params))
        .accept('json')
        .then(r => dispatch(detalhesCarregado(r.body)))
        .catch(e => {
          dispatch(ajaxError('Erro ao carregar detalhes do Upload', e));
        });
  };
}