// @flow
'use strict';

import * as React from 'react';
import { Table } from 'react-bootstrap';

type TableDetalhesProps = {
  data : any[],
  children? : any,
  className? : string,
  noDataMsg? : string,
  noDataClass? : string,
  showFields? : bool,
}

const CLASSNAME_DEFAULT = 'table-condensed table-striped inner',
      MSG_DEFAULT       = 'Nenhum item na lista';

export default function TableDetalhes({ data, children, className = CLASSNAME_DEFAULT, noDataMsg = MSG_DEFAULT, noDataClass = '', showFields = false } : TableDetalhesProps) {
  const colunas : Array<React.Element<any>> = (function() {
    if (!children)
      return [];
    if (!Array.isArray(children))
      return [children];

    return children;
  })();

  if (!data || data.length === 0) {
    return (
        <Table className={ className }>
          <colgroup>
            { colunas.map((c, i) => <col key={ i } style={ { width: c.props.width || undefined } }/>) }
          </colgroup>
          <tbody>
          { showFields && <tr>{ colunas.map((c, i) => <th key={ i } className={ c.props.classNameTitle || '' }>{ c.props.children }</th>) }</tr> }
          <tr className={noDataClass ? `nodata ${noDataClass}` : "nodata"}>
            <td colSpan={ colunas.length }>{ noDataMsg }</td>
          </tr>
          </tbody>
        </Table>
    );
  }

  return (
      <Table className={ className }>
        <colgroup>
          { colunas.map((c, i) => <col key={ i } style={ { width: c.props.width || undefined } }/>) }
        </colgroup>
        <tbody>
        <tr>
          { colunas.map((c, i) => <th key={ i } className={ c.props.classNameTitle || '' }>{ c.props.children }</th>) }
        </tr>
        { data.map((e, i) =>
            <tr key={ i }>
              { colunas.map((col : React.Element<any>, i) => React.cloneElement(col, { data: e, key: i })) }
            </tr>)
        }
        </tbody>
      </Table>
  );
}