// @flow
'use strict';

import * as _ from 'lodash';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import type { IItem } from './types';
import type { IRoute } from 'lib/types';
import { useEffectOnce } from 'lib/viewUtils';

import DadosBasicos from './dados_basicos';
import AbasInformacoes from './abas_informacoes';
import Voltar from './voltar';
import PainelLateral from '../../paineis';
import ModalHistorico from './modal_historico';
import BotaoHistorico from './botao_historico';
import BotoesDetalhes from '../../common/detalhes_documento/botoes_detalhes';
import { Breadcrumbs, Title } from '../../common/router_components';

type DetalhesProps = {
  routes : IRoute[],
  routeParams : { id : string };
  carregaDetalhes : (id : string) => void;
  item : IItem,
  carregando : bool,
  historicoOpen : bool,
}

export default function Detalhes({ carregaDetalhes, carregando, routeParams, historicoOpen, routes, item } : DetalhesProps) {
  useEffectOnce(() => {
    // Caso os dados do item não estejam carregados, carrega detalhes do item
    if (!carregando && _.isEmpty(item) && routeParams)
      carregaDetalhes(routeParams.id);
  });

  return (
      <div>
        <Title routes={ routes }/>
        <Breadcrumbs routes={ routes }/>

        <Row>
          <Col md={ 9 }>
            <Row className="botao-voltar-top">
              <Voltar classe="pull-right"/>
            </Row>

            <section className="visualizacao-documento detalhes-documento">
              <DadosBasicos item={ item }/>
              <AbasInformacoes item={ item }/>
            </section>

            <BotoesDetalhes id={ item.id } tipo="nfce" possuiXml={ item.possuiXml } textoBotaoDownloadPdf="Download DANFE NFC-e" BotaoVoltar={ Voltar }/>
          </Col>

          <Col md={ 3 }>
            <BotaoHistorico id={ item.id }/>
            <PainelLateral ignoreInitialize url={ item.url } env={ item.env }/>
          </Col>
        </Row>

        { historicoOpen && <ModalHistorico/> }
      </div>
  );
}
