// @flow
'use strict';

import * as React from 'react';

import type { IMultimodal } from '../../types';

import RowDetalhes from '../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../common/detalhes_documento/column_detalhes';

type MultimodalProps = {
  multimodal : IMultimodal,
};

export default function Aereo({ multimodal } : MultimodalProps) {
  return (
      <>
        <h3>Multimodal</h3>
        <RowDetalhes data={ multimodal }>
          <ColumnDetalhes field="numCertifOperador">Núm. Certif. Operador</ColumnDetalhes>
          <ColumnDetalhes field="indicadorNegociavel">Indicador Negociável</ColumnDetalhes>
        </RowDetalhes>

        <h4>Informações do Seguro</h4>
        <RowDetalhes data={ multimodal && multimodal.seguradora }>
          <ColumnDetalhes field="nome">Nome Seguradora</ColumnDetalhes>
          <ColumnDetalhes field="cnpj" type="documento">CNPJ Seguradora</ColumnDetalhes>
          <ColumnDetalhes field="numApolice">Núm. Apólice</ColumnDetalhes>
          <ColumnDetalhes field="numAverbacao">Núm. Averbação</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
