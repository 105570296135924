// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import { Input } from '../../../../../common/input';

type CamposLocalRetiradaProps = {
  component : React.ComponentType<*>,
}

export default function CamposLocalRetirada({ component } : CamposLocalRetiradaProps) {
  const SelecaoTodos = component;

  return (
      <FormSection name="localRetirada">
        <SelecaoTodos nomes={ ["localRetirada"] } titulo="Local de Retirada"/>

        <div className="grupo-checkbox">
          <Input name="localRetiradaCnpj" label="CNPJ" checkbox/>
          <Input name="localRetiradaCpf" label="CPF" checkbox/>
          <Input name="localRetiradaRazaoSocial" label="Razão Social" checkbox/>
          <Input name="localRetiradaLogr" label="Logradouro" checkbox/>
          <Input name="localRetiradaNum" label="Número" checkbox/>
          <Input name="localRetiradaCompl" label="Complemento" checkbox/>
          <Input name="localRetiradaBairro" label="Bairro" checkbox/>
          <Input name="localRetiradaMun" label="Município" checkbox/>
          <Input name="localRetiradaUf" label="UF" checkbox/>
          <Input name="localRetiradaCep" label="CEP" checkbox/>
          <Input name="localRetiradaPais" label="País" checkbox/>
          <Input name="localRetiradaFone" label="Telefone" checkbox/>
          <Input name="localRetiradaEmail" label="E-mail" checkbox/>
          <Input name="localRetiradaIe" label="Inscrição Estadual" checkbox/>
        </div>
      </FormSection>
  );
}
