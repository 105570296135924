'use strict';
// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as detalhes from '../../../../../reducers/sped/arquivos/detalhes';

import Filtros from './filtros';

const mapDispatchToProps = { carregaDetalhes: detalhes.carregaDetalhes };

export default compose(
    connect<*, *, *, *, *, *>(null, mapDispatchToProps),
    withRouter,
)(Filtros);