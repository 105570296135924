/**
 * @file Este arquivo foi feito com base em uma recomendação do react-boostrap,
 * fonte: https://github.com/react-bootstrap/pagination#alternatives
 *
 * Para que funcione corretamente, algumas validações do eslint são desligadas.
 */

/* eslint-disable react/prop-types */

'use strict';
// @flow

import * as React from 'react';
import * as _ from 'lodash';
import { Pagination } from 'react-bootstrap';
import { createUltimatePagination, ITEM_TYPES } from 'react-ultimate-pagination';

const Wrapper = (props) => <Pagination { ..._.omit(props, 'hasLastButton') }/>;

const Item = ({ name, children, isActive, onClick }) => {
  const handleClick = React.useCallback((e) => {
    e.preventDefault();
    onClick(e);
  }, [onClick]);

  return (
      <Pagination.Item href={ `#${ name }` } active={ isActive } onClick={ handleClick }>{ children }</Pagination.Item>
  );
};

const Page = ({ value, ...props }) => (<Item name={ `p${ value }` } { ...props }>{ value }</Item>);

const Ellipsis = ({ onClick }) => <Item name="ellipsis" onClick={ onClick }>{ '\u2026' }</Item>;
const FirstPage = ({ onClick }) => <Item name="first" onClick={ onClick }>{ '\xAB' }</Item>;
const PreviousPage = ({ onClick }) => <Item name="previous" onClick={ onClick }>{ '\u2039' }</Item>;
const NextPage = ({ onClick }) => <Item name="next" onClick={ onClick }>{ '\u203A' }</Item>;
const LastPage = ({ onClick, hasLastButton }) =>
    <Item name="last" disabled={ !hasLastButton } onClick={ onClick }>{ '\xBB' }</Item>;

export default createUltimatePagination({
  WrapperComponent: Wrapper,
  itemTypeToComponent: {
    [ITEM_TYPES.PAGE]: Page,
    [ITEM_TYPES.ELLIPSIS]: Ellipsis,
    [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPage,
    [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPage,
    [ITEM_TYPES.NEXT_PAGE_LINK]: NextPage,
    [ITEM_TYPES.LAST_PAGE_LINK]: LastPage,
  },
});