'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, State } from 'lib/types';

export const ABRE_DETALHES_PRODUTO_SERVICO  = 'nfe/detalhes_produto_servico/ABRE_DETALHES_PRODUTO_SERVICO',
             FECHA_DETALHES_PRODUTO_SERVICO = 'nfe/detalhes_produto_servico/FECHA_DETALHES_PRODUTO_SERVICO';

const INITIAL_STATE = Immutable.fromJS({
  carregando: false,
  item: {},
  modalOpen: false,
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case ABRE_DETALHES_PRODUTO_SERVICO:
      return state.set('item', action.item).set('modalOpen', true);
    case FECHA_DETALHES_PRODUTO_SERVICO:
      return state = INITIAL_STATE;
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['nfe', 'detalhesProdutoServico'], Immutable.Map());
export const itemSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('item'));
export const modalOpenSelector = createSelector<*, *, *, *>(rootSelector, root => root.get('modalOpen', false));

// actions

export function abreDetalhesProdutoServico(item : any) {
  return { type: ABRE_DETALHES_PRODUTO_SERVICO, item: Immutable.fromJS(item) };
}

export function fechaDetalhesProdutoServico() {
  return { type: FECHA_DETALHES_PRODUTO_SERVICO };
}
