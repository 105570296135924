// @flow

'use strict';

import * as React from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch } from 'react-redux';

import * as cte from '../../reducers/cte/filtro_livre';
import * as nfe from '../../reducers/nfe/filtro_livre';
import * as cfe from '../../reducers/cfe/filtro_livre';

import FiltrosNfe from '../nfe/filtros';
import FiltrosCte from '../cte/filtros';
import FiltrosNfse from '../nfse/filtros';
import FiltrosNfce from '../nfce/filtros';
import FiltrosCfe from '../cfe/filtros';
import FiltrosDashboard from '../dashboard/paineis/filtros/index';
import LinkContainerForPathParams from './link_container_for_path_params';
import type { NavigationItem, NavigationTree } from './types';

const BY_PATHNAME = {
  '/app/admin': 'admin',
  '/app/sped': 'sped',
  '/app/nfe/lista': TopAreaNfe,
  '/app/cte/lista': TopAreaCte,
  '/app/nfse/lista/todas': TopAreaNfse,
  '/app/nfce/lista': TopAreaNfce,
  '/app/cfe/lista': TopAreaCfe,
  '/app/ferramentas': 'ferramentas',
  '/app/dashboard/paineis': TopAreaDashboard,
};

type TopAreaNavigationProps = {
  location : { pathname : string },
  topAreaMenu : NavigationTree,
};

export default function TopAreaNavigation({ topAreaMenu, location: { pathname } = window.location } : TopAreaNavigationProps) {
  const key = Object.keys(BY_PATHNAME).find(k => pathname.startsWith(k));

  if (!key)
    return null;

  const TopAreaComponent = BY_PATHNAME[key];

  if (!TopAreaComponent)
    return null;
  else if (typeof TopAreaComponent === 'string')
    return <GenericTopArea pathname={ pathname } items={ topAreaMenu[TopAreaComponent] }/>;
  else
    return <TopAreaComponent pathname={ pathname }/>;
}

function TopAreaDashboard() {
  return (
      <div className="busca-rapida">
        <FiltrosDashboard/>
      </div>
  );
}

function TopAreaNfe({ pathname } : { pathname : string }) {
  const dispatch  = useDispatch(),
        recebidos = () => dispatch(nfe.inicializaFiltro({}, { d: 'recebidos', f: '', page: '', s: '', sd: '' })),
        emitidos  = () => dispatch(nfe.inicializaFiltro({}, { d: 'emitidos', f: '', page: '', s: '', sd: '' })),
        todos     = () => dispatch(nfe.inicializaFiltro({}, { d: 'todos', f: '', page: '', s: '', sd: '' }));

  return (
      <div>
        <Nav bsStyle="pills">
          <LinkContainerForPathParams to="/app/nfe/lista/recebidos" pathname={ pathname } onClick={ recebidos }>
            <NavItem>Recebidas</NavItem>
          </LinkContainerForPathParams>
          <LinkContainerForPathParams to="/app/nfe/lista/emitidos" pathname={ pathname } onClick={ emitidos }>
            <NavItem>Emitidas</NavItem>
          </LinkContainerForPathParams>
          <LinkContainerForPathParams to="/app/nfe/lista/todos" pathname={ pathname } onClick={ todos }>
            <NavItem>Todas</NavItem>
          </LinkContainerForPathParams>
        </Nav>
        <div className="busca-rapida-listas">
          <FiltrosNfe/>
        </div>
      </div>
  );
}

function TopAreaNfse() {
  return (
      <div>
        <Nav bsStyle="pills">
          <LinkContainer to="/app/nfse/lista/todas">
            <NavItem>Todas</NavItem>
          </LinkContainer>
        </Nav>
        <div className="busca-rapida">
          <FiltrosNfse/>
        </div>
      </div>
  );
}

function TopAreaNfce({ pathname } : { pathname : string }) {
  return (
      <div>
        <Nav bsStyle="pills">
          <LinkContainerForPathParams to="/app/nfce/lista/recebidos" pathname={ pathname }>
            <NavItem>Recebidas</NavItem>
          </LinkContainerForPathParams>
          <LinkContainerForPathParams to="/app/nfce/lista/emitidos" pathname={ pathname }>
            <NavItem>Emitidas</NavItem>
          </LinkContainerForPathParams>
          <LinkContainerForPathParams to="/app/nfce/lista/todos" pathname={ pathname }>
            <NavItem>Todas</NavItem>
          </LinkContainerForPathParams>
        </Nav>
        <div className="busca-rapida-listas">
          <FiltrosNfce/>
        </div>
      </div>
  );
}

function TopAreaCte({ pathname } : { pathname : string }) {
  const dispatch = useDispatch(),
        tomados  = () => dispatch(cte.inicializaFiltro({}, { d: 'tomados', f: '', page: '', s: '', sd: '' })),
        emitidos = () => dispatch(cte.inicializaFiltro({}, { d: 'emitidos', f: '', page: '', s: '', sd: '' })),
        todos    = () => dispatch(cte.inicializaFiltro({}, { d: 'todos', f: '', page: '', s: '', sd: '' }));

  return (
      <div>
        <Nav bsStyle="pills">
          <LinkContainerForPathParams to="/app/cte/lista/tomados" pathname={ pathname } onClick={ tomados }>
            <NavItem>Tomados</NavItem>
          </LinkContainerForPathParams>
          <LinkContainerForPathParams to="/app/cte/lista/emitidos" pathname={ pathname } onClick={ emitidos }>
            <NavItem>Emitidos</NavItem>
          </LinkContainerForPathParams>
          <LinkContainerForPathParams to="/app/cte/lista/todos" pathname={ pathname } onClick={ todos }>
            <NavItem>Todos</NavItem>
          </LinkContainerForPathParams>
        </Nav>
        <div className="busca-rapida-listas">
          <FiltrosCte/>
        </div>
      </div>
  );
}

function TopAreaCfe({ pathname } : { pathname : string }) {
  const dispatch  = useDispatch(),
        recebidos = () => dispatch(cfe.inicializaFiltro({}, { d: 'recebidos', f: '', page: '', s: '', sd: '' })),
        emitidos  = () => dispatch(cfe.inicializaFiltro({}, { d: 'emitidos', f: '', page: '', s: '', sd: '' })),
        todos     = () => dispatch(cfe.inicializaFiltro({}, { d: 'todos', f: '', page: '', s: '', sd: '' }));

  return (
      <div>
        <Nav bsStyle="pills">
          <LinkContainerForPathParams to="/app/cfe/lista/recebidos" pathname={ pathname } onClick={ recebidos }>
            <NavItem>Recebidas</NavItem>
          </LinkContainerForPathParams>
          <LinkContainerForPathParams to="/app/cfe/lista/emitidos" pathname={ pathname } onClick={ emitidos }>
            <NavItem>Emitidas</NavItem>
          </LinkContainerForPathParams>
          <LinkContainerForPathParams to="/app/cfe/lista/todos" pathname={ pathname } onClick={ todos }>
            <NavItem>Todas</NavItem>
          </LinkContainerForPathParams>
        </Nav>
        <div className="busca-rapida-listas">
          <FiltrosCfe/>
        </div>
      </div>
  );
}

function GenericTopArea({ pathname, items } : { pathname : string, items : NavigationItem[] }) {
  if (!items || items.length === 0)
    return null;

  return (
      <Nav bsStyle="pills">
        { items.map(i => (
            <LinkContainerForPathParams key={ i.id } to={ i.url } pathname={ pathname }>
              <NavItem>{ i.text }</NavItem>
            </LinkContainerForPathParams>
        )) }
      </Nav>
  );
}
