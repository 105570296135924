// @flow
'use strict';

import { type Saga } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { autofill } from 'redux-form/immutable';
import { BLUR } from 'redux-form/lib/actionTypes';

import { ALTERA_URL as INICIA_MANIFESTACAO } from '../reducers/cte/manifestacao';

export default function* manifestacaoCte() : Saga<*> {
  yield takeLatest(INICIA_MANIFESTACAO, sagaManifestacaoCte);
}

export function* sagaManifestacaoCte() : Saga<*> {
  yield takeLatest(
      ac => ac.type === BLUR && ac.meta.form === 'manifestacaoCte' && ac.meta.field === 'observacoesTomador',
      function* (ac : { meta : { form : string }, payload : any }) {
        yield call(removeEspacoNoFimDoValor, ac.payload);
      },
  );
}

export function* removeEspacoNoFimDoValor(valor : ?string) : Saga<*> {
  if (typeof valor === 'string')
    yield put(autofill('manifestacaoCte', 'observacoesTomador', valor.trimRight()));
}