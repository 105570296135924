// @flow
'use strict';

import * as React from 'react';

import type { IInformacoesAdicionaisProduto } from '../../../types';

import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';

type InformacoesAdicionaisProdutoProps = {
  informacoesAdicionaisProduto : IInformacoesAdicionaisProduto,
};

export default function InformacoesAdicionaisProduto({ informacoesAdicionaisProduto } : InformacoesAdicionaisProdutoProps) {
  return (
      <div id="table-inf-add-produto">
        <h3>Informações Adicionais do Produto</h3>
        <RowDetalhes data={ informacoesAdicionaisProduto }>
          <ColumnDetalhes field="codigoFiscal" width="50%">Código Fiscal do Produto</ColumnDetalhes>
          <ColumnDetalhes field="codigoOperacaoNff" width="50%">Código da Operação NFF</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
