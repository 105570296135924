// @flow
'use strict';

import * as React from 'react';

import type { IIcmsPartilha } from '../../../types_imposto';

import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';

type IcmsPartilhaProps = {
  icms : IIcmsPartilha,
};

export default function IcmsPartilha({ icms } : IcmsPartilhaProps) {
  return (
      <>
        <h4>ICMS - ICMS de Partilha</h4>
        <RowDetalhes data={ icms } className="no-margin-bottom">
          <ColumnDetalhes field="valorBc" type="moeda" width="50%">Valor da Base de Cálculo</ColumnDetalhes>
          <ColumnDetalhes field="percIcmsFcp" type="percentual" width="50%">Percentual do ICMS FCP</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ icms } className="no-margin-bottom">
          <ColumnDetalhes field="aliquotaInternaUf" type="percentual" width="50%">Alíquota Interna da
            UF</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaInterestadual" type="percentual" width="50%">Alíquota Interestadual das UFs
            envolvidas</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ icms } className="no-margin-bottom">
          <ColumnDetalhes field="valorIcmsFcp" type="moeda" width="50%">Valor ICMS FCP da UF de Término da
            Prestação</ColumnDetalhes>
          <ColumnDetalhes field="valorIcmsPartilhaTermino" type="moeda" width="50%">Valor ICMS de Partilha para UF de
            Término da Prestação</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ icms }>
          <ColumnDetalhes field="valorIcmsPartilhaInicio" type="moeda" width="50%">Valor ICMS de Partilha para UF de
            Início da Prestação</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
