// @flow
'use strict';

import React from 'react';
import moment from 'moment';

import type { IPortaria } from '../../../../lib/api';

import CampoDetalhes from './campo_detalhes';

type InformacoesConsultaProps = {
  portaria : ?IPortaria
}

export default function InformacoesConsulta({ portaria } : InformacoesConsultaProps) {
  if (!portaria)
    return <p>Portaria não encontrada.</p>;

  const h             = portaria.historico || {},
        ultimaDecisao = portaria.ultimaDecisao || {},
        data          = h && h.createdAt ? moment(h.createdAt).format(' L, HH:mm:ss') : '';

  return (
      <div>
        <CampoDetalhes label="Decisão" texto={ ultimaDecisao.label }/>
        <CampoDetalhes label="Observação" texto={ ultimaDecisao.observacao }/>
        <CampoDetalhes label="Data" texto={ data }/>
        <CampoDetalhes label="Usuário" texto={ informacoesUsuario(h.usuario) }/>
      </div>
  );
}

function informacoesUsuario(usuario : ?{ name : string, email : string }) {
  if (!usuario || (!usuario.name && !usuario.email))
    return '';

  if (!usuario.name)
    return usuario.email;

  if (!usuario.email)
    return usuario.name;

  return `${usuario.name} (${usuario.email})`;
}