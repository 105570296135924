// @flow
'use strict';

import * as React from 'react';

import type { IAquaviario } from '../../types';

import DetalheContainer from './detalhamento_container/detalhamento_container';
import RowDetalhes from '../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../../common/detalhes_documento/table_detalhes';

type AquaviarioProps = {
  aquaviario : IAquaviario,
};

export default function Aquaviario({ aquaviario } : AquaviarioProps) {
  return (
      <>
        <h3>Aquaviário</h3>
        <RowDetalhes data={ aquaviario } className="no-margin-bottom">
          <ColumnDetalhes field="valorPrestacaoBcAfrmm" type="moeda" width="33%">Valor da Prestação BC do AFRMM</ColumnDetalhes>
          <ColumnDetalhes field="valorAfrmm" type="moeda" width="33%">Valor AFRMM</ColumnDetalhes>
          <ColumnDetalhes field="idNavio" width="33%">Identificação do Navio</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ aquaviario } className="no-margin-bottom">
          <ColumnDetalhes field="numViagem" width="33%">Núm. da Viagem</ColumnDetalhes>
          <ColumnDetalhes field="direcao" width="33%">Direção</ColumnDetalhes>
          <ColumnDetalhes field="irinNavio" width="33%">Irin do Navio</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ aquaviario }>
          <ColumnDetalhes field="tipoNavegacao">Tipo de Navegação</ColumnDetalhes>
        </RowDetalhes>

        <h4>Informações das Balsas</h4>
        <TableDetalhes data={ aquaviario.infBalsas } noDataClass="padding-10" noDataMsg="Nenhuma balsa informada.">
          <ColumnDetalhes field="idBalsa">Identificador da Balsa</ColumnDetalhes>
        </TableDetalhes>

        <DetalheContainer detalhesContainers={ aquaviario.detalhesContainers }/>
      </>
  );
}
