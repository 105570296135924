// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import * as listaNoticia from 'reducers/ferramentas/noticia/lista';
import { carregaDetalhes } from 'reducers/ferramentas/noticia/detalhes';
import { jsSelector } from 'lib/viewUtils';
import { withProvider } from 'lib/reduxTools';

import ListaNfce from './lista';

const mapStateToProps    = createStructuredSelector({
        dados: jsSelector(listaNoticia.currentPage),
      }),
      mapDispatchToProps = {
        trocaPagina: listaNoticia.trocaPagina,
        carregaLista: listaNoticia.carregaLista,
        handleRowClick: noticia => noticia && carregaDetalhes(noticia.id),
      };

export default compose(
    withProvider(),
    connect(mapStateToProps, mapDispatchToProps),
)(ListaNfce);
