'use strict';
// @flow

import _ from 'lodash';
import React from 'react';

import BaseComponent from 'lib/base_component';
import { autobind } from 'core-decorators';

import ReactSelect from 'react-select';
import { Button, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';

import DatePicker from 'components/common/date_picker';

import Icone from '../../../../common/icone';
import { arquivoNav } from '../../arquivo_path';

import {
  CUSTODIADO,
  SITUACOES_ARQUIVO,
  TIPO_OPERACOES,
  TIPO_EMISSAO,
  SITUACAO_SPED,
  SITUACAO_SEFAZ,
  MODELO_SPED,
  POSSUI_CHAVE
} from 'i18n/constants';

const EMPTY_STATE = {
  emissao_de: '', emissao_ate: '', operacao: undefined,
  custodiado: undefined, custodiado_upload: undefined, sefaz: undefined, sefaz_upload: undefined,
  numero: '', serie: '', emissao: undefined, sped: undefined, modelo: undefined,
  custodiado_de: '', custodiado_ate: '', situacao_arquivo: undefined, situacao_arquivo_upload: undefined,
  chave: '', possui_chave: undefined
};

type FiltrosProps = {
  location : { query : {} },
  routeParams : { id : string };
  carregaDetalhes : (id : string, params : any) => void,
}

type FiltrosState = {
  emissao_de : ?string, emissao_ate : ?string, operacao : ?string,
  custodiado? : ?bool, custodiado_upload? : ?bool, sefaz : ?string, sefaz_upload : ?string,
  custodiado_de : ?string, custodiado_ate : ?string, situacao_arquivo? : ?string, situacao_arquivo_upload? : ?string,
  numero : ?string, serie : ?string, emissao : ?string, sped : ?string, modelo : ?string,
  chave : ?string, possui_chave : ?string, page? : ?number | string,
};

export default class Filtros extends BaseComponent<FiltrosProps, FiltrosState> {
  props : FiltrosProps;
  state : FiltrosState = EMPTY_STATE;

  UNSAFE_componentWillMount() {
    const q = _.get(this.props, 'location.query') || {};

    const query = {
      emissao_de: q.emissao_de || '',
      emissao_ate: q.emissao_ate || '',
      operacao: q.operacao || null,
      serie: q.serie || '',
      numero: q.numero || '',
      emissao: q.emissao || null,
      sped: q.sped || null,
      sefaz: q.sefaz || null,
      sefaz_upload: q.sefaz_upload || null,
      custodiado_de: q.custodiado_de || '',
      custodiado_ate: q.custodiado_ate || '',
      custodiado: q.custodiado || null,
      custodiado_upload: q.custodiado_upload || null,
      situacao_arquivo: q.situacao_arquivo || null,
      situacao_arquivo_upload: q.situacao_arquivo_upload || null,
      modelo: q.modelo || null,
      chave: q.chave || '',
      possui_chave: q.possui_chave || null,
      page: q.page || null,
    };

    this.setState(query);
    this.props.carregaDetalhes(this.props.routeParams.id, query);
  }

  @autobind
  handleFormSubmit(e? : SyntheticEvent<*>) {
    e && e.preventDefault();
    if (this.props.routeParams && this.props.routeParams.id) {
      const query = { ...this.state, page: undefined };

      arquivoNav.detalhes(this.props.routeParams.id, query);
      this.props.carregaDetalhes(this.props.routeParams.id, query);
    }
  }

  @autobind
  handleFormReset() {
    this.setState(EMPTY_STATE, () => this.handleFormSubmit());
  }

  render() {
    const {
            operacao, emissao_de, emissao_ate, numero, serie, emissao, modelo, sped,
            sefaz_upload, custodiado_upload, sefaz, custodiado, chave, possui_chave,
            situacao_arquivo, situacao_arquivo_upload, custodiado_de, custodiado_ate
          } = this.state;

    return (
        <div>
          <form method="post" role="search" onSubmit={ this.ignoreEvent }>
            <nav className="navbar navbar-default">
              <div className="container-fluid">
                <div className="navbar-header"><a className="navbar-brand">Filtros</a></div>
                <div className="navbar-form navbar-left">
                  <FormGroup>
                    <ControlLabel srOnly>Emissão de</ControlLabel>
                    <DatePicker value={ emissao_de } placeholder="Emissão de"
                                onChange={ this.handleSelectFor('emissao_de') }/>
                  </FormGroup> { ' ' }
                  <FormGroup>
                    <ControlLabel srOnly>Emissão até</ControlLabel>
                    <DatePicker value={ emissao_ate } placeholder="Emissão até"
                                onChange={ this.handleSelectFor('emissao_ate') }/>
                  </FormGroup> { ' ' }
                  <FormGroup controlId="chave" style={ { width: '17em' } }>
                    <ControlLabel srOnly>Chave</ControlLabel>
                    <FormControl name="chave" placeholder="Chave" type="text" style={ { width: '100%' } }
                                 value={ chave }
                                 onChange={ this.handleChangeFor('chave') }/>
                  </FormGroup> { ' ' }
                  <FormGroup controlId="possuiChave">
                    <ControlLabel srOnly>Possui Chave</ControlLabel>
                    <ReactSelect options={ POSSUI_CHAVE } placeholder="Possui Chave" value={ possui_chave }
                                 style={ { width: '9em' } } simpleValue
                                 onChange={ this.handleSelectFor('possui_chave') }/>
                  </FormGroup> { ' ' }
                  <FormGroup controlId="numero">
                    <FormControl name="numero" placeholder="Número" type="text" value={ numero }
                                 onChange={ this.handleChangeFor('numero') }
                                 style={ { width: '6em' } }/>
                  </FormGroup> { ' ' }
                  <FormGroup controlId="serie">
                    <FormControl name="serie" placeholder="Série" type="text" value={ serie }
                                 onChange={ this.handleChangeFor('serie') }
                                 style={ { width: '5em' } }/>
                  </FormGroup> { ' ' }
                  <FormGroup controlId="tipoOperacao">
                    <ControlLabel srOnly>Tipo Operação</ControlLabel>
                    <ReactSelect options={ TIPO_OPERACOES } placeholder="Tipo Operação" value={ operacao }
                                 style={ { width: '9.4em' } } simpleValue
                                 onChange={ this.handleSelectFor('operacao') }/>
                  </FormGroup> { ' ' }
                  <FormGroup controlId="tipoEmissao">
                    <ControlLabel srOnly>Tipo Emissão</ControlLabel>
                    <ReactSelect options={ TIPO_EMISSAO } placeholder="Tipo Emissão" value={ emissao }
                                 style={ { width: '8.8em' } } simpleValue
                                 onChange={ this.handleSelectFor('emissao') }/>
                  </FormGroup> { ' ' }
                  <FormGroup controlId="modelo">
                    <ControlLabel srOnly>Modelo</ControlLabel>
                    <ReactSelect options={ MODELO_SPED } placeholder="Modelo" value={ modelo }
                                 style={ { width: '10em' } } simpleValue
                                 onChange={ this.handleSelectFor('modelo') }/>
                  </FormGroup> { ' ' }
                  <FormGroup controlId="situacaoSped">
                    <ControlLabel srOnly>Situação SPED</ControlLabel>
                    <ReactSelect options={ SITUACAO_SPED } placeholder="Situação no SPED" value={ sped }
                                 style={ { width: '15em' } } simpleValue
                                 onChange={ this.handleSelectFor('sped') }/>
                  </FormGroup> { ' ' }
                  <FormGroup controlId="situacaoSefazUpload">
                    <ControlLabel srOnly>Situação SEFAZ no Upload</ControlLabel>
                    <ReactSelect options={ SITUACAO_SEFAZ } placeholder="Situação SEFAZ no Upload" value={ sefaz_upload }
                                 style={ { width: '15em' } } simpleValue
                                 onChange={ this.handleSelectFor('sefaz_upload') }/>
                  </FormGroup> { ' ' }
                  <FormGroup controlId="situacaoSefaz">
                    <ControlLabel srOnly>Situação SEFAZ</ControlLabel>
                    <ReactSelect options={ SITUACAO_SEFAZ } placeholder="Situação SEFAZ Atual" value={ sefaz }
                                 style={ { width: '15em' } } simpleValue
                                 onChange={ this.handleSelectFor('sefaz') }/>
                  </FormGroup> { ' ' }
                  <FormGroup controlId="custodiadoUpload">
                    <ControlLabel srOnly>Custodiado no upload</ControlLabel>
                    <ReactSelect options={ CUSTODIADO } placeholder="Custodiado no upload"
                                 value={ custodiado_upload }
                                 style={ { width: '14em' } } simpleValue
                                 onChange={ this.handleSelectFor('custodiado_upload') }/>
                  </FormGroup> { ' ' }
                  <FormGroup controlId="custodiado">
                    <ControlLabel srOnly>Custodiado</ControlLabel>
                    <ReactSelect options={ CUSTODIADO } placeholder="Custodiado Atual" value={ custodiado }
                                 style={ { width: '12em' } } simpleValue
                                 onChange={ this.handleSelectFor('custodiado') }/>
                  </FormGroup> { ' ' }
                  <FormGroup>
                    <ControlLabel srOnly>Custodiado de</ControlLabel>
                    <DatePicker value={ custodiado_de } placeholder="Custodiado de"
                                width="10em"
                                onChange={ this.handleSelectFor('custodiado_de') }/>
                  </FormGroup> { ' ' }
                  <FormGroup>
                    <ControlLabel srOnly>Custodiado até</ControlLabel>
                    <DatePicker value={ custodiado_ate } placeholder="Custodiado até"
                                width="10em"
                                onChange={ this.handleSelectFor('custodiado_ate') }/>
                  </FormGroup> { ' ' }
                  <FormGroup controlId="situacaoArquivo">
                    <ControlLabel srOnly>Situação do Arquivo</ControlLabel>
                    <ReactSelect options={ SITUACOES_ARQUIVO } placeholder="Situação do Arquivo Atual"
                                 value={ situacao_arquivo }
                                 style={ { width: '15em' } } simpleValue
                                 onChange={ this.handleSelectFor('situacao_arquivo') }/>
                  </FormGroup> { ' ' }
                  <FormGroup controlId="situacaoArquivoUpload">
                    <ControlLabel srOnly>Situação do Arquivo no Upload</ControlLabel>
                    <ReactSelect options={ SITUACOES_ARQUIVO } placeholder="Situação do Arquivo no Upload"
                                 value={ situacao_arquivo_upload }
                                 style={ { width: '17em' } } simpleValue
                                 onChange={ this.handleSelectFor('situacao_arquivo_upload') }/>
                  </FormGroup> { ' ' }
                </div>
                <div className="navbar-form navbar-right">
                  <Button type="reset" onClick={ this.handleFormReset }>
                    <Icone nome="eraser" estilo="fas">Limpar</Icone>
                  </Button> { ' ' }
                  <Button type="submit" bsStyle="primary" onClick={ this.handleFormSubmit }>
                    <Icone nome="filter" estilo="far">Filtrar</Icone>
                  </Button>
                </div>
              </div>
            </nav>
          </form>
        </div>
    );
  }
}
