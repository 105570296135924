// @flow
'use strict';

import * as React from 'react';
import { Col, ControlLabel, Row } from 'react-bootstrap';
import { FormSection } from 'redux-form/immutable';

import CampoIntervaloData from '../../../common/campos_busca_estruturada/campo_intervalo_data';
import { Input } from '../../../common/input';

export default function CamposPortaria() {
  return (
      <>
        <fieldset>
          <legend>Portaria</legend>
        </fieldset>

        <Row>
          <Col sm={ 6 }>
            <Row className="campo-formulario campo-input">
              <ControlLabel className="col-sm-4 titulo-checkbox">Decisão na Portaria</ControlLabel>
              <Col sm={ 8 } className="opcao-checkbox">
                <FormSection name="decisao_portaria">
                  <Input name="nao_informado" label="Não Informada" checkbox/>
                  <Input name="receber" label="Receber" checkbox/>
                  <Input name="devolver" label="Devolver" checkbox/>
                  <Input name="receber_com_pendencia" label="Receber com Pendência" checkbox/>
                </FormSection>
              </Col>
            </Row>
          </Col>
          <Col sm={ 6 }>
            <CampoIntervaloData rotulo="Data da Decisão" nome="data_decisao_portaria" tamanhoLabel={ 4 }/>
          </Col>
        </Row>
      </>
  );
}
