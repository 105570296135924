// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as modal from '../../../reducers/modal';

import ModalInformacoes from './modal_informacoes';

const mapStateToProps = createStructuredSelector({
  open: modal.openModalSelector,
});

export default connect<*, *, *, *, *, *>(mapStateToProps, { fechaModal: modal.fechaModal })(ModalInformacoes);