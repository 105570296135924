// @flow
'use strict';

import * as React from 'react';

import type { IIpi } from '../../../../types_imposto';

import RowDetalhes from '../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../common/detalhes_documento/column_detalhes';

type IpiProps = {
  item : IIpi,
};

export default function Ipi({ item } : IpiProps) {
  return (
      <div id="table-ipi">
        <h4>IPI - Imposto sobre Produtos Industrializados</h4>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="cnpjProdutor" type="documento" width="25%">CNPJ Produtor</ColumnDetalhes>
          <ColumnDetalhes field="codSeloControleIpi" width="25%">Código Selo de Controle IPI</ColumnDetalhes>
          <ColumnDetalhes field="qtdSeloControle" width="25%">Qtd. Selo de Controle</ColumnDetalhes>
          <ColumnDetalhes field="codEnquadraLegalIpi" width="25%">Código Enquadramento Legal do IPI</ColumnDetalhes>
        </RowDetalhes>

        { item &&
        <>
          { item.valorBcIpi && !item.qtdTotalUnPadraoTrib &&
          <RowDetalhes data={ item }>
            <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
            <ColumnDetalhes field="valorBcIpi" type="moeda" width="25%">Valor da BC do IPI</ColumnDetalhes>
            <ColumnDetalhes field="aliquotaIpi" type="percentual" width="25%">Alíquota do IPI</ColumnDetalhes>
            <ColumnDetalhes field="valorIpi" type="moeda" width="25%">Valor do IPI</ColumnDetalhes>
          </RowDetalhes>
          }

          { item.qtdTotalUnPadraoTrib && !item.valorBcIpi &&
          <RowDetalhes data={ item }>
            <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
            <ColumnDetalhes field="qtdTotalUnPadraoTrib" width="25%">Qtd. total na un. padrão para
              tributação</ColumnDetalhes>
            <ColumnDetalhes field="valorUnTributavel" type="moeda" width="25%">Valor por Unidade
              Tributável</ColumnDetalhes>
            <ColumnDetalhes field="valorIpi" type="moeda" width="25%">Valor do IPI</ColumnDetalhes>
          </RowDetalhes>
          }

          { item.qtdTotalUnPadraoTrib && item.valorBcIpi &&
          <>
            <RowDetalhes data={ item } className="no-margin-bottom">
              <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
              <ColumnDetalhes field="valorBcIpi" type="moeda" width="25%">Valor da BC do IPI</ColumnDetalhes>
              <ColumnDetalhes field="aliquotaIpi" type="percentual" width="25%">Alíquota do IPI</ColumnDetalhes>
              <ColumnDetalhes field="qtdTotalUnPadraoTrib" width="25%">Qtd. total na un. padrão para
                tributação</ColumnDetalhes>
            </RowDetalhes>
            <RowDetalhes data={ item }>
              <ColumnDetalhes field="valorUnTributavel" type="moeda" width="25%">Valor por Unidade
                Tributável</ColumnDetalhes>
              <ColumnDetalhes field="valorIpi" type="moeda" width="75%">Valor do IPI</ColumnDetalhes>
            </RowDetalhes>
          </>
          }

          { !item.qtdTotalUnPadraoTrib && !item.valorBcIpi &&
          <RowDetalhes data={ item }>
            <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
            <ColumnDetalhes field="valorIpi" type="moeda" width="75%">Valor do IPI</ColumnDetalhes>
          </RowDetalhes>
          }
        </>
        }
      </div>
  );
}
