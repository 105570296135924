'use strict';
// @flow

import * as React from 'react';
import { IndexRoute, Route } from 'react-router';

import Lista from './lista';
import Detalhes from './detalhes';

export default (
    <Route name="Prestadores de Serviço" path="prestadores">
      <IndexRoute component={ Lista }/>
      <Route name="Novo Prestador" path="new" component={ Detalhes }/>
      <Route name="Editando Prestador" path=":id" component={ Detalhes }/>
    </Route>
);
