// @flow
'use strict';

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';
import { push } from 'react-router-redux';

import type { Action, Dispatch, GetState, State } from 'lib/types';
import * as Paths from '../../../paths';
import { buscaEstruturadaParaBuscaLivre } from 'lib/buscas';
import { currentRouteSelector } from '../../routing';
import { carregaLista } from '../documentos/lista';
import { formataQuery } from '../../../lib/formata_query';

const MUDA_FILTRO        = 'sped/documentos/filtro/MUDA_FILTRO',
      MUDA_LIVRE         = 'sped/documentos/filtro/MUDA_LIVRE',
      LIMPA_FILTRO       = 'sped/documentos/filtro/LIMPA_FILTRO',
      INICIALIZA_FILTROS = 'sped/documentos/filtro/INICIALIZA_FILTROS',
      BUSCA_AVANCADA     = 'sped/documentos/filtro/BUSCA_AVANCADA',
      BUSCA_BASICA       = 'sped/documentos/filtro/BUSCA_BASICA';

const FiltrosRecord = new Immutable.Record({
  data_emissao: '', data_emissao_de: '', data_emissao_ate: '',
  chave: '', possui_chave: null, empresa: '', participante: '', numero: '', serie: '',
  tipo_operacao: null, ind_emissao: null, cod_modelo: null,
  data_sped: '', data_sped_de: '', data_sped_ate: '',
  situacao_sped: null, situacao_sefaz_upload: null, situacao_sefaz: null,
  custodiado_upload: null, custodiado: null, custodiado_de: '', custodiado_ate: '',
  situacao_arquivo: null, situacao_arquivo_upload: null,
});

const INITIAL_STATE = Immutable.fromJS({
  filtro: {
    atual: 'estruturado',
    estruturado: new FiltrosRecord(),
    livre: '',
  },
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case INICIALIZA_FILTROS:
      return state.setIn(['filtro', 'estruturado'], action.valores);
    case MUDA_FILTRO:
      return state.setIn(['filtro', 'estruturado', action.nome], action.valor);
    case MUDA_LIVRE:
      return state.setIn(['filtro', 'livre'], action.valor);
    case LIMPA_FILTRO:
      return state.setIn(['filtro', 'estruturado'], new FiltrosRecord());
    case BUSCA_AVANCADA:
      return state
          .setIn(['filtro', 'atual'], 'livre')
          .setIn(['filtro', 'livre'], action.busca);
    case BUSCA_BASICA:
      return state
          .setIn(['filtro', 'atual'], 'estruturado');
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['sped', 'documentos', 'filtro'], Immutable.Map());

export const filtrosEstruturadosSelector = createSelector<*, *, *, *>(rootSelector, root => root.getIn(['filtro', 'estruturado'], new FiltrosRecord()));
export const filtroAtualSelector = createSelector<*, *, *, *>(rootSelector, root => root.getIn(['filtro', 'atual'], ''));
export const buscaAvancadaSelector = createSelector<*, *, *, *>(rootSelector, root => root.getIn(['filtro', 'livre'], ''));

export const filtroParamsSelector = createSelector<*, *, *, *, *, *, *>(filtroAtualSelector, buscaAvancadaSelector, filtrosEstruturadosSelector,
    (filtroAtual, q, estruturados) : {} =>
        ((filtroAtual === 'livre' ? { q } : estruturados.toJS())),
);

// action creators

export function trocaPagina(page : number) {
  return function(dispatch : Dispatch<*>, getState : GetState) {
    const query = currentRouteSelector(getState()).get('query').toJS();

    dispatch(push(Paths.sped.documentos.colecao({ ...query, page })));
    dispatch(carregaLista({ ...query, page }));
  };
}

export function mudaBuscaAvancada(busca : string) {
  return { type: BUSCA_AVANCADA, busca };
}

export function mudaFiltro(nome : string, valor : any) {
  return { type: MUDA_FILTRO, nome, valor };
}

export function mudaLivre(valor : any) {
  return { type: MUDA_LIVRE, valor };
}

export function limpaFiltro() {
  return { type: LIMPA_FILTRO };
}

export function limpaBuscaAvancada() {
  return mudaBuscaAvancada('');
}

export function inicializaFiltros(query : { q : ?string }) {
  return async function(dispatch : Dispatch<*>) {
    if (query && query.q)
      await dispatch({ type: BUSCA_AVANCADA, busca: query.q });
    else
      await dispatch({ type: INICIALIZA_FILTROS, valores: new FiltrosRecord(query) });

    dispatch(carregaLista(query));
  };
}

export function alternaParaBuscaBasica() {
  return { type: BUSCA_BASICA };
}

export function alternaParaBuscaAvancada() {
  return function(dispatch : Dispatch<*>, getState : GetState) {
    const params = filtrosEstruturadosSelector(getState());

    return dispatch(mudaBuscaAvancada(buscaEstruturadaParaBuscaLivre(formataQuery(params))));
  };
}

export function aplicaFiltro() {
  return function(dispatch : Dispatch<*>, getState : GetState) {
    const params = filtroParamsSelector(getState()),
          query  = { ...formataQuery(params), page: undefined };

    dispatch(push(Paths.sped.documentos.colecao(query)));
    dispatch(carregaLista(query));
  };
}