'use strict';
// @flow

import * as React from 'react';
import * as _ from 'lodash';

import Icone from 'components/common/icone';
import ComTooltip from 'components/common/com_tooltip';

import moment from 'moment';

import CODIGOS from 'i18n/codigos.pt.json';

const NULL_DESC : IItem = { texto: '', longa: '', curta: '–' };

type IItens = { hint : string, [k : string] : IItem };
type IItem = {
  texto : string,
  curta : string, longa? : string,
  icone? : string, cor? : string, hint_com_codigo? : bool,
};

type DescricaoProps = {
  tipo : string,
  codigo? : any,
  atualizado_em? : string,
  posicao? : string,
  conversorCodigo? : (cod : any) => string,
  completa? : bool,
  large? : bool,
};

/**
 * Apresenta a descrição de um código, com sua tooltip.
 *
 * As informações são buscadas do arquivo em i18n/codigos.*.json
 */
export default function Descricao({ tipo, codigo, atualizado_em, posicao, completa = false } : DescricaoProps) {
  const itens : IItens = CODIGOS[tipo],                       // lista de itens do tipo de código
        item : IItem   = itens[String(codigo)] || descricaoCurtaComCodigo(codigo);  // item a apresentar

  // monta o hint
  let hint = item.longa || '';

  // se o hint deve incluir código, adiciona no final, entre parênteses
  if (codigo && (itens.hint_com_codigo || itens.hint_com_codigo === undefined))
    hint += ` (${codigo})`;

  // se a descrição possui data de atualização, inclui no final do hint
  if (item.longa && atualizado_em) {
    const d = moment(atualizado_em, [moment.ISO_8601, 'YYYY-MM-DD', 'DD/MM/YYYY'], true);
    hint += ` - ${d.format('DD/MM/YYYY')}`;
  }

  const texto = completa && codigo && String(codigo) !== item.curta ? `${codigo}: ${item.curta}` : item.curta;

  return (
      <ComTooltip hint={ hint } posicao={ posicao }>
        <span>{ texto }</span>
      </ComTooltip>
  );
}

Descricao.defaultProps = { posicao: 'right' };

function descricaoCurtaComCodigo(codigo : any) {
  if (!codigo)
    return NULL_DESC;

  const strCodigo = String(codigo);
  return { texto: '', longa: '', curta: strCodigo };
}

/**
 * Apresenta a descrição de um código, com seu ícone e tooltip.
 *
 * As informações são buscadas do arquivo em i18n/codigos.*.json
 */
export function DescricaoIcone({ tipo, codigo, conversorCodigo = _.identity, large } : DescricaoProps) {
  const chaveI18n = conversorCodigo(codigo),
        itens     = CODIGOS[tipo],
        item      = itens[chaveI18n] || NULL_DESC,
        hint      = `${itens.hint ? `${itens.hint}:` : ''} ${item.texto}${obtemValor(chaveI18n, codigo)}`;

  return (
      <ComTooltip hint={ hint }>
        <Icone nome={ item.icone || 'circle' } estilo={ item.estilo || 'fas' } cor={ item.cor } large={ large } />
      </ComTooltip>
  );
}

function obtemValor(chaveI18n : string, codigo : any) {
  if (chaveI18n && chaveI18n === '__unknown__')
    return ` (${codigo})`;

  return '';
}

export function textoDescricao(tipo : string, codigo : ?string) {
  const itens : IItens = CODIGOS[tipo],                       // lista de itens do tipo de código
        item : IItem   = itens[String(codigo)] || descricaoCurtaComCodigo(codigo);  // item a apresentar

  return item.texto || '';
}

export { default as DescricaoChave } from './descricao_chave';