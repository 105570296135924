'use strict';
// @flow

import * as React from 'react';
import { IndexRedirect, Route } from 'react-router';

import AppWithoutTitle from '../common/app_without_title';
import Lista from './lista';
import Detalhes from './detalhes';

export default (
    <Route name="NFS-e" path="app/nfse" component={ AppWithoutTitle }>
      <IndexRedirect to="lista/todas"/>
      <Route name="Todas" path="lista/todas" component={ Lista }/>
      <Route name="Visualização de Documento" path=":id" component={ Detalhes }/>
    </Route>
);
