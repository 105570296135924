// @flow

'use strict';

import * as React from 'react';

import { Nav, NavItem } from 'react-bootstrap';

import Icone from 'components/common/icone';
import IosBadge from 'components/common/ios_badge';

type MenuItemType = { id : string, url : string, icon : string, text : string, useCounter? : bool };

export default function ModulesMenu({ items } : { items : Array<MenuItemType> }) {
  const ci = items.find(({ url }) => location.pathname.indexOf(url) !== -1);

  return (
      <div className="modules-menu">
        <Nav bsStyle="pills" stacked activeKey={ ci && ci.id }>
          { items.map(renderMenuItem) }
        </Nav>
      </div>
  )
}

function renderMenuItem({ id, url, icon, text, useCounter } : MenuItemType) {
  return (
      <NavItem key={ id } eventKey={ id } href={ url }>
        <Icone nome={ icon } estilo="fas" tamanho={3}>{ text }</Icone>
        { useCounter && <IosBadge counter={ id }/> }
      </NavItem>
  );
}
