// @flow
'use strict';

import * as React from 'react';
import { MenuItem } from 'react-bootstrap';

type BotaoExportacaoLoteProps = {
  abreModalPdf : () => void,
  abreModalXml : () => void,
}

export default function BotaoExportacaoLoteCfe({ abreModalPdf, abreModalXml } : BotaoExportacaoLoteProps) {
  return (
      <>
        <MenuItem onClick={ abreModalPdf }>Demonstrativo CFe</MenuItem>
        <MenuItem onClick={ abreModalXml }>XML</MenuItem>
      </>
  );
}
