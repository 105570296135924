// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfe from '../form_exportacao_nfe/campos_nfe/selecao_todos_nfe';
import SelecaoTodosCte from '../form_exportacao_cte/campos_cte/selecao_todos_cte';
import SelecaoTodosNfce from '../form_exportacao_nfce/campos_nfce/selecao_todos_nfce';
import { Input } from '../../../../../common/input';

type CamposPessoaProps = {
  nome : string,
  titulo : string,
  component : React.ComponentType<*>,
}

export default function CamposPessoa({ nome, titulo, component } : CamposPessoaProps) {
  const SelecaoTodos  = component || SelecaoTodosNfe,
        tipoNfeEmit   = (SelecaoTodos === SelecaoTodosNfe) && (nome === 'emit'),
        tipoNfeDest   = (SelecaoTodos === SelecaoTodosNfe) && (nome === 'dest'),
        tipoNfe       = tipoNfeDest || tipoNfeEmit,
        tipoNfceEmit  = (SelecaoTodos === SelecaoTodosNfce) && (nome === 'emit'),
        tipoNfceDest  = (SelecaoTodos === SelecaoTodosNfce) && (nome === 'dest'),
        tipoNfce      = tipoNfceDest || tipoNfceEmit,
        tipoCteEmit   = (SelecaoTodos === SelecaoTodosCte) && (nome === 'emit'),
        tipoCteDest   = (SelecaoTodos === SelecaoTodosCte) && (nome === 'dest'),
        tipoCteOutros = (SelecaoTodos === SelecaoTodosCte) && (nome !== 'dest') && (nome !== 'emit'),
        tipoCte       = (SelecaoTodos === SelecaoTodosCte);

  return (
      <FormSection name={ nome }>
        <SelecaoTodos nomes={ [nome] } titulo={ titulo }/>

        <div className="grupo-checkbox">
          <Input name={ `${ nome }Cnpj` } label="CNPJ / CPF" checkbox/>
          { tipoNfeDest && <Input name={ `${ nome }IdEstrangeiro` } label="ID Estrangeiro" checkbox/> }
          <Input name={ `${ nome }Nome` } label="Razão Social" checkbox/>
          { tipoNfeDest &&
              <>
                <Input name={ `${ nome }Logr` } label="Logradouro" checkbox/>
                <Input name={ `${ nome }Num` } label="Número" checkbox/>
                <Input name={ `${ nome }Compl` } label="Complemento" checkbox/>
              </> }
          { (tipoNfeEmit || tipoCteEmit || tipoNfceEmit || (tipoCte && !tipoCteDest)) &&
              <Input name={ `${ nome }Fantasia` } label="Nome Fantasia" checkbox/> }
          { (tipoNfe || tipoNfce) && <Input name={ `${ nome }Bairro` } label="Bairro" checkbox/> }
          { (tipoNfe || tipoNfce) && <Input name={ `${ nome }Municipio` } label="Município" checkbox/> }
          { tipoCteOutros && <Input name={ `${ nome }Ie` } label="Inscrição Estadual (IE)" checkbox/> }
          <Input name={ `${ nome }EndUf` } label="UF" checkbox/>
          { tipoNfeDest && <Input name={ `${ nome }Cep` } label="CEP" checkbox/> }
          { (tipoNfe || tipoNfce) && <Input name={ `${ nome }Pais` } label="País" checkbox/> }
          { tipoNfeDest && <Input name={ `${ nome }Fone` } label="Telefone" checkbox/> }
          { (tipoNfeDest || tipoNfceDest) &&
              <Input name={ `${ nome }IndIe` } label="Indicador IE Destinatário" checkbox/> }
          { !tipoCteOutros && <Input name={ `${ nome }Ie` } label="Inscrição Estadual (IE)" checkbox/> }
          { tipoNfeDest && <Input name={ `${ nome }Isuframa` } label="Inscrição SUFRAMA" checkbox/> }
          { tipoCteDest && <Input name={ `${ nome }Isuf` } label="Inscrição SUFRAMA" checkbox/> }
          { (tipoNfeEmit || tipoCteEmit) && <Input name={ `${ nome }Iest` } label="Inscrição Estadual ST" checkbox/> }
          { (tipoNfe || tipoNfceEmit) && <Input name={ `${ nome }Im` } label="Inscrição Municipal (IM)" checkbox/> }
          { (tipoNfeDest || tipoCteDest || (tipoCte && !tipoCteEmit)) &&
              <Input name={ `${ nome }Email` } label="E-mail" checkbox/> }
          { (tipoNfeEmit || tipoNfceEmit) && <Input name={ `${ nome }Cnae` } label="CNAE" checkbox/> }
          { (tipoNfeEmit || tipoNfceEmit) && <Input name={ `${ nome }Crt` } label="Regime Tributário" checkbox/> }
          { tipoNfceDest && <Input name={ `${ nome }Email` } label="E-mail" checkbox/> }
        </div>
      </FormSection>
  );
}