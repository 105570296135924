'use strict';
// @flow

import React from 'react';

interface IEventEmitter {
  listen(cb : Function) : () => void;
}

/**
 * Componente base, com funções utilitárias.
 */
export default class BaseComponent<Props, State> extends React.PureComponent<Props, State> {
  props : Props;
  state : State;

  subscriptions : Array<() => void>;
  pickHandlers : { [k : string] : Function };
  changeHandlers : { [k : string] : Function };
  selectHandlers : { [k : string] : Function };

  /**
   * Assina um objeto (passado no parâmetro listenable), utilizando o callback especificado. A assinatura é salva
   * em um array, e a desassinatura é realizada ao desmontar o componente.
   *
   * @param listenable
   * @param callback
   */
  listenTo(listenable : IEventEmitter, callback : Function) {
    this.subscriptions = this.subscriptions || [];
    this.subscriptions.push(listenable.listen(callback));
  }

  componentWillUnmount() {
    this.subscriptions && this.subscriptions.map(unsub => unsub());
  }

  /**
   * Para os parâmetros startKey (sk) e endKey (ek), cria uma função que pega o objeto recebido como primeiro parâmetro,
   * extrai as propriedades startDate (sd) e endDate (ed), e chama this.setState({ [sk]: sd, [ek]: ed }).
   * O principal uso é para passá-la em props "onDatePicked" de componentes como o <DateRangePicker/>.
   *
   * @param startKey o nome da chave no state onde armazenar o valor da data inicial.
   * @param endKey o nome da chave no state onde armazenar o valor da data final.
   */
  handleDatePickFor(startKey : string, endKey : string) {
    const hh = this.pickHandlers = this.pickHandlers || {},
          key = `${startKey}-${endKey}`;
    return hh[key] = hh[key] || ((e, p) => this.setState({ [startKey]: p.startDate, [endKey]: p.endDate }));
  }

  /**
   * Para um parâmetro stateKey (k), cria uma função que pega o evento recebido como primeiro parâmetro (e) e chama
   * this.setState({ [k]: e.target.value }). O principal uso é para passá-la em props "onChange" de componentes como
   * o <FormControl/> do React Bootstrap.
   *
   * @param stateKey o nome da chave no state onde armazenar o valor.
   */
  handleChangeFor(stateKey : string) {
    const hh = this.changeHandlers = this.changeHandlers || {};
    return hh[stateKey] = hh[stateKey] || (e => this.setState({ [stateKey]: e.target.value }));
  }

  /**
   * Para um parâmetro stateKey (k), cria uma função que pega o valor de seu primeiro parâmetro (v) e chama
   * this.setState({ [k]: v }). O principal uso é para passá-la em props "onSelect" de componentes
   * como o <ReactSelect/>.
   *
   * @param stateKey o nome da chave no state onde armazenar o valor.
   */
  handleSelectFor(stateKey : string) {
    const hh = this.selectHandlers = this.selectHandlers || {};
    return hh[stateKey] = hh[stateKey] || (v => this.setState({ [stateKey]: v }));
  }

  /**
   * Para um parâmetro stateKey (k), cria uma função que pega o evento recebido como primeiro parâmetro (e) e chama
   * this.setState({ [k]: e.target.checked }). O principal uso é para passá-la em props "onChange" de componentes como
   * o <Checkbox/> do React Bootstrap.
   *
   * @param stateKey o nome da chave no state onde armazenar o valor.
   */
  handleCheckboxFor(stateKey : string) {
    const hh = this.selectHandlers = this.selectHandlers || {};
    return hh[stateKey] = hh[stateKey] || (e => this.setState({ [stateKey]: e.target.checked }));
  }

  ignoreEvent(e : SyntheticEvent<*>) {
    e && e.preventDefault();
  }
}