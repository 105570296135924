// @flow
'use strict';

import * as React from 'react';

import type { ItemProps } from './types';

import RowDetalhes from '../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../common/detalhes_documento/column_detalhes';

export default function DadosBasicos({ item } : ItemProps) {
  return (
      <RowDetalhes className="table-condensed table-striped table-main" data={ item }>
        <ColumnDetalhes field="chave">Chave de Acesso</ColumnDetalhes>
        <ColumnDetalhes field="numero">Número NFC-e</ColumnDetalhes>
        <ColumnDetalhes field="versao">Versão</ColumnDetalhes>
      </RowDetalhes>
  );
}