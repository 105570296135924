// @flow
'use strict';

import * as React from 'react';

import type { IInformacoesAdicionais } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';

type InformacoesAdicionaisProps = {
  informacoesAdicionais : IInformacoesAdicionais,
};

export default function InformacoesAdicionais({ informacoesAdicionais } : InformacoesAdicionaisProps) {
  return (
      <div className="tab-pane active">
        <h3>Informações Adicionais</h3>
        <h4>Informações Complementares de Interesse do Contribuinte</h4>
        <RowDetalhes data={ informacoesAdicionais } headerRow={ false }>
          <ColumnDetalhes field="infInteresseContribuinte" textoPreformatado />
        </RowDetalhes>
      </div>
  );
}
