'use strict';
// @flow

import * as React from 'react';
import { IndexRedirect, Route } from 'react-router';

import Paineis from './paineis';

export default (
    <Route name="Dashboard" path="app/dashboard">
      <IndexRedirect to="paineis"/>
      <Route name="Paineis" path="paineis" component={ Paineis }/>
    </Route>
);
