// @flow
'use strict';

import * as React from 'react';

import type { IIcms90 } from '../../../../../../types_imposto';

import RowDetalhes from '../../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../../common/detalhes_documento/column_detalhes';

type Icms90Props = {
  item : IIcms90,
};

export default function Icms90({ item } : Icms90Props) {
  return (
      <div>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="origem" width="25%">Origem da Mercadoria</ColumnDetalhes>
          <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
          <ColumnDetalhes field="determinacaoBcIcms" width="25%">Determinação BC ICMS</ColumnDetalhes>
          <ColumnDetalhes field="valorBcIcms" type="moeda" width="25%">Base de Cálculo ICMS</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="percentualReducaoBc" type="percentual" width="25%">
            Redução Base de Cálculo
          </ColumnDetalhes>
          <ColumnDetalhes field="aliquotaIcms" type="percentual" width="25%">Alíquota ICMS</ColumnDetalhes>
          <ColumnDetalhes field="valorIcms" type="moeda" width="25%">Valor ICMS</ColumnDetalhes>
          <ColumnDetalhes field="valorBcFcp" type="moeda" width="25%">Valor Base de Cálculo FCP</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="percentualFcp" type="percentual" width="25%">Percentual FCP</ColumnDetalhes>
          <ColumnDetalhes field="valorFcp" type="moeda" width="25%">Valor FCP</ColumnDetalhes>
          <ColumnDetalhes field="valorIcmsDesonerado" type="moeda" width="25%">ICMS Desonerado</ColumnDetalhes>
          <ColumnDetalhes field="percentualReducaoBc" width="25%">Motivo Desoneração</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
