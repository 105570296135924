'use strict';
// @flow

import * as React from 'react';
import ReactSelect from 'react-select';

import type { IReduxFormInputProps, ISelectOption } from './types';

type SelectControlProps = {
  input : IReduxFormInputProps,
  options? : ISelectOption[],
}

export default function SelectControl({ options, input } : SelectControlProps) {
  return <ReactSelect { ...input } simpleValue options={ options }/>;
}
