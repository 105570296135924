'use strict';
// @flow

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';

import { openAlertaSelector, abreAlerta, fechaAlerta } from '../../../reducers/alerta_certificado_expirado';
import { useEffectOnce } from '../../../lib/viewUtils';

type AlertaCertificadoExpiradoProps = {
  possuiCertificadoExpirado : bool,
};

export default function AlertaCertificadoExpirado({ possuiCertificadoExpirado } : AlertaCertificadoExpiradoProps) {
  const dispatch = useDispatch();

  useEffectOnce(() => {
    if (possuiCertificadoExpirado)
      dispatch(abreAlerta());
  });

  const open = useSelector(openAlertaSelector);

  const fecharAlerta = React.useCallback(() => dispatch(fechaAlerta()), [dispatch]);

  return (
      <div className={ `alert alert-warning ${ open || 'hide' }` }>
        <Button className="close" onClick={ fecharAlerta }>
          <span aria-hidden="true">&times;</span>
        </Button>
        Sua conta está com certificado digital expirado. <a
          href="/app/admin/certificados_digitais?situacao=expirado&conta_ativa=true">Clique aqui</a> e verifique o
        certificado que necessita ser substituído.
      </div>
  );
}
