// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';
import { type Dispatch } from 'redux';

import type { Action, State } from '../../lib/types';
import type { Noticia } from '../../components/dashboard/paineis/tabela_noticias/tabela_noticias';

const ABRE_MODAL  = 'dashboard/modal_noticia/ABRE_MODAL',
      FECHA_MODAL = 'dashboard/modal_noticia/FECHA_MODAL',
      ALTERA_ITEM = 'dashboard/modal_noticia/ALTERA_ITEM';

const INITIAL_STATE = Immutable.fromJS({
  item: {},
  open: false,
});

export default function reducer(state : Immutable.Map<*, *> = INITIAL_STATE, action : Action = { type: '' }) {
  switch (action.type) {
    case ABRE_MODAL:
      return state.set('open', true);
    case FECHA_MODAL:
      return state.set('open', false);
    case ALTERA_ITEM:
      return state.set('item', action.item);
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['dashboard', 'modal_noticia'], Immutable.Map());
export const openModalSelector = createSelector<*, *, *, *>(rootSelector, root => root.get('open', false));
export const itemSelector = createSelector<*, *, *, *>(rootSelector, root => root.get('item'));

// action creators

export function itemSelecionado(item : ?Noticia) {
  return { type: ALTERA_ITEM, item: Immutable.fromJS(item) };
}

// thunk actions

export function abreNoticiaNoModal(item : ?Noticia) {
  return async function(dispatch : Dispatch<any>) {
    await dispatch(itemSelecionado(null));

    await dispatch(itemSelecionado(item));
    await dispatch({ type: ABRE_MODAL });
  };
}

export function fechaNoticiaNoModal() {
  return async function(dispatch : Dispatch<any>) {
    await dispatch({ type: FECHA_MODAL });
    await dispatch(itemSelecionado(null));
  };
}