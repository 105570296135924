// @flow
'use strict';

import * as React from 'react';

import type { IIcmsNormalSt } from '../../../../../types_imposto';

import Icms00 from './opcoes_icms_normal_st/icms00';
import Icms10 from './opcoes_icms_normal_st/icms10';
import Icms20 from './opcoes_icms_normal_st/icms20';
import Icms30 from './opcoes_icms_normal_st/icms30';
import Icms40 from './opcoes_icms_normal_st/icms40';
import Icms51 from './opcoes_icms_normal_st/icms51';
import Icms60 from './opcoes_icms_normal_st/icms60';
import Icms70 from './opcoes_icms_normal_st/icms70';
import Icms90 from './opcoes_icms_normal_st/icms90';

type IcmsNormalStProps = {
  item : IIcmsNormalSt,
};

export default function IcmsNormalSt({ item } : IcmsNormalStProps) {
  return (
      <div id="table-icms-normal-st">
        <h4>ICMS Normal e ST</h4>
        { item.icms00 && item.icms00.cst.substr(0, 2) === '00' && <Icms00 item={ item.icms00 }/> }
        { item.icms10 && item.icms10.cst.substr(0, 2) === '10' && <Icms10 item={ item.icms10 }/> }
        { item.icms20 && item.icms20.cst.substr(0, 2) === '20' && <Icms20 item={ item.icms20 }/> }
        { item.icms30 && item.icms30.cst.substr(0, 2) === '30' && <Icms30 item={ item.icms30 }/> }
        { item.icms40 && ['40', '41', '50'].includes(item.icms40.cst.substr(0, 2)) && <Icms40 item={ item.icms40 }/> }
        { item.icms51 && item.icms51.cst.substr(0, 2) === '51' && <Icms51 item={ item.icms51 }/> }
        { item.icms60 && item.icms60.cst.substr(0, 2) === '60' && <Icms60 item={ item.icms60 }/> }
        { item.icms70 && item.icms70.cst.substr(0, 2) === '70' && <Icms70 item={ item.icms70 }/> }
        { item.icms90 && item.icms90.cst.substr(0, 2) === '90' && <Icms90 item={ item.icms90 }/> }
      </div>
  );
}
