'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, State } from 'lib/types';

export const ABRE_DETALHES_CARTAO  = 'nfe/detalhes_cartao/ABRE_DETALHES_CARTAO',
             FECHA_DETALHES_CARTAO = 'nfe/detalhes_cartao/FECHA_DETALHES_CARTAO';

const INITIAL_STATE = Immutable.fromJS({
  carregando: false,
  cartao: {},
  modalOpen: false,
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case ABRE_DETALHES_CARTAO:
      return state.set('cartao', action.cartao).set('modalOpen', true);
    case FECHA_DETALHES_CARTAO:
      return state = INITIAL_STATE;
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['nfe', 'detalhesCartao'], Immutable.Map());
export const cartaoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('cartao'));
export const modalOpenSelector = createSelector<*, *, *, *>(rootSelector, root => root.get('modalOpen', false));

// actions

export function abreDetalhesCartao(cartao : any) {
  return { type: ABRE_DETALHES_CARTAO, cartao: Immutable.fromJS(cartao) };
}

export function fechaDetalhesCartao() {
  return { type: FECHA_DETALHES_CARTAO };
}
