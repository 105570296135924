// @flow
'use strict';

import * as React from 'react';

import type { ICobranca } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../common/detalhes_documento/table_detalhes';

type CobrancaProps = {
  cobranca : ICobranca,
};

export default function Cobranca({ cobranca } : CobrancaProps) {
  const { fatura, parcelas } = cobranca || {};

  return (
      <div className="tab-pane active">
        <h3>Cobrança</h3>

        <h4>Fatura</h4>
        <RowDetalhes data={ fatura } className="no-margin-bottom">
          <ColumnDetalhes field="numero" width="33%">Número</ColumnDetalhes>
          <ColumnDetalhes field="valorOriginal" type="moeda" width="33%">Valor Original</ColumnDetalhes>
          <ColumnDetalhes field="valorDesconto" type="moeda" width="33%">Valor Desconto</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ fatura }>
          <ColumnDetalhes field="valorLiquido" type="moeda" width="33%">Valor Líquido</ColumnDetalhes>
        </RowDetalhes>

        <h4>Parcelas</h4>
        <TableDetalhes data={ parcelas } noDataClass="padding-10" noDataMsg="Nenhuma parcela informada na NF-e.">
          <ColumnDetalhes field="numero" width="33%">Número</ColumnDetalhes>
          <ColumnDetalhes field="vencimento" type="data" width="33%">Vencimento</ColumnDetalhes>
          <ColumnDetalhes field="valor" type="moeda" width="33%">Valor</ColumnDetalhes>
        </TableDetalhes>
      </div>
  );
}
