'use strict';
// @flow

import _ from 'lodash';
import * as React from 'react';
import classnames from 'classnames';

import type { CellProps } from './types';

export default function Cell<T>({ row, expr, formatter, align, padding, hasTitle, className, colSpan, ...props } : CellProps<T>) {
  const [val, title] = React.useMemo(() => {
    let title : string;
    let val : any = (expr || '').split(',').map(e => typeof row === 'object' ? _.result(row, e) : row);
    if (val.length === 1)
      val = val[0];

    if (formatter && typeof formatter === 'function')
      val = formatter(val);

    if (hasTitle && val.length === 2)
      [val, title] = val;

    return [val, title];
  }, [expr, row, formatter, hasTitle]);

  const tdProps = _(props).omit('headerHint', 'width', 'colSpan', 'widthCells').omitBy(v => v === undefined || v === null).value();

  let cell;

  if (colSpan && colSpan > 1) {
    cell = val.map((v, i) => <td key={ i } { ...tdProps }
                                 className={ classnames(className, estilosCelula(align, padding)) || undefined }
                                 title={ title }>{ v }</td>);
  }
  else {
    cell = <td { ...tdProps } className={ classnames(className, estilosCelula(align, padding)) || undefined }
               title={ title }>{ val }</td>;
  }

  return cell;
}

export function estilosCelula(align : ?'center' | 'left' | 'right', padding : ?'left' | 'right') {
  if (align && padding)
    return `text-${ align }` + ` padd-${ padding }`;

  if (align)
    return `text-${ align }`;

  if (padding)
    return `padd-${ padding }`;

  return null;
}