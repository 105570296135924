// @flow
'use strict';

import * as React from 'react';

import type { ITotais } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';

type TotaisProps = {
  totais : ITotais,
};

export default function Totais({ totais } : TotaisProps) {
  return (
      <div className="tab-pane active">
        <h3>Totais ICMS</h3>
        <RowDetalhes data={ totais }>
          <ColumnDetalhes field="valorTotalBcIcms" type="moeda" width="25%">
            Valor Total Base de Cálculo ICMS
          </ColumnDetalhes>
          <ColumnDetalhes field="valorTotalIcms" type="moeda" width="25%">Valor Total ICMS</ColumnDetalhes>
          <ColumnDetalhes field="valorTotalProdutoServico" type="moeda" width="25%">
            Valor Total dos Produtos e Serviços
          </ColumnDetalhes>
          <ColumnDetalhes field="valorTotalFrete" type="moeda" width="25%">Valor Total do Frete</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ totais }>
          <ColumnDetalhes field="valorTotalDesconto" type="moeda" width="25%">Valor Total do Desconto</ColumnDetalhes>
          <ColumnDetalhes field="valorTotalPis" type="moeda" width="25%">Valor Total do PIS</ColumnDetalhes>
          <ColumnDetalhes field="valorTotalCofins" type="moeda" width="25%">Valor Total da COFINS</ColumnDetalhes>
          <ColumnDetalhes field="valorTotalNfce" type="moeda" width="25%">Valor Total da NFC-e</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ totais }>
          <ColumnDetalhes field="valorAproximadoTotalTributos" type="moeda">
            Valor Aproximado Total de Tributos
          </ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}