// @flow
'use strict';

import * as React from 'react';

import type { IIcms30 } from '../../../../../../types_imposto';

import RowDetalhes from '../../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../../common/detalhes_documento/column_detalhes';

type Icms30Props = {
  item : IIcms30,
};

export default function Icms30({ item } : Icms30Props) {
  return (
      <>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="origem" width="25%">Origem da Mercadoria</ColumnDetalhes>
          <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
          <ColumnDetalhes field="determinacaoBcIcmsSt" width="25%">Determinação da BC ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="margemValorAdicionadoIcmsSt" type="percentual" width="25%">Margem Valor Adicionado ICMS ST</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="percentualReducaoBcIcmsSt" type="percentual" width="25%">Redução BC ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="valorBcIcmsSt" type="moeda" width="25%">BC ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaIcmsSt" type="percentual" width="25%">Alíquota</ColumnDetalhes>
          <ColumnDetalhes field="valorIcmsSt" type="moeda" width="25%">Valor ICMS ST</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="valorBcFcpRetidoSt" type="moeda" width="25%">BC FCP Retido por ST</ColumnDetalhes>
          <ColumnDetalhes field="percentualFcpRetidoSt" type="percentual" width="25%">FCP Retido por ST</ColumnDetalhes>
          <ColumnDetalhes field="valorFcpRetidoSt" type="moeda" width="25%">FCP Retido por ST</ColumnDetalhes>
          <ColumnDetalhes field="valorIcmsDesonerado" type="moeda" width="25%">ICMS Desonerado</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="motivoDesoneracaoIcmsSt">Motivo Desoneração</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
