// @flow
'use strict';

import * as React from 'react';
import { Col, ControlLabel, Row } from 'react-bootstrap';

import DatePickerForm from '../input/date_picker_form';

type CampoIntervaloDataProps = {
  rotulo : string,
  nome : string,
  tamanhoLabel? : number,
  placeholderDe? : string,
  placeholderAte? : string,
  columnClassName? : string,
}

export default function CampoIntervaloData({
                                             rotulo, nome, tamanhoLabel = 3, placeholderDe = 'de',
                                             placeholderAte = 'até', columnClassName = '',
                                           } : CampoIntervaloDataProps) {
  return (
      <Row className="campo-formulario campo-input">
        <ControlLabel className={ `col-sm-${ tamanhoLabel }` }>{ rotulo }</ControlLabel>
        <Col sm={ 12 - tamanhoLabel } className={ columnClassName }>
          <Col xs={ 6 } className="campo-intervalo-de intervalo-data">
            <DatePickerForm nome={ nome } placeholder={ placeholderDe }/>
          </Col>
          <Col xs={ 6 } className="campo-intervalo-ate intervalo-data">
            <DatePickerForm nome={ `${ nome }_ate` } placeholder={ placeholderAte }/>
          </Col>
        </Col>
      </Row>
  );
}
