// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosCte from './selecao_todos_cte';
import { Input } from '../../../../../../common/input';

export default function CamposIcmsUfFim() {
  return (
      <FormSection name="dadosIcmsUfFim">
        <SelecaoTodosCte nomes={ ['dadosIcmsUfFim'] } titulo="Dados do ICMS UF Fim"/>

        <div className="grupo-checkbox">
          <Input name="bcUfFim" label="Base de Cálculo na UF Fim" checkbox/>
          <Input name="percIcmsFcpUfFim" label="% ICMS FCP na UF Fim" checkbox/>
          <Input name="aliquotaInternaUfFim" label="Alíquota Interna da UF Fim" checkbox/>
          <Input name="aliquotaInterestUfEnvolvidas" label="Alíquota Interestadual UFs Envolvidas" checkbox/>
          <Input name="valorIcmsFcpUfFim" label="Valor ICMS FCP na UF Fim" checkbox/>
          <Input name="valorIcmsUfFim" label="Valor ICMS para UF Fim" checkbox/>
          <Input name="valorIcmsUfInicio" label="Valor ICMS para UF Início" checkbox/>
        </div>
      </FormSection>
  );
}
