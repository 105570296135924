// @flow
'use strict';

import * as React from 'react';

import type { IValores } from '../../types';

import ColumnDetalhes from '../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../common/detalhes_documento/row_detalhes';

type ValoresProps = {
  valores : IValores,
};

export default function Valores({ valores } : ValoresProps) {
  return (
      <>
        <h4>Valores</h4>
        <RowDetalhes data={ valores }>
          <ColumnDetalhes field="valorTotal" type="moeda" width="50%">Valor Prestação Serviço</ColumnDetalhes>
          <ColumnDetalhes field="valorReceber" type="moeda" width="50%">Valor a Receber</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
