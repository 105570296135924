// @flow
'use strict';

import * as React from 'react';

import type { IComponente } from '../../types';

import ColumnDetalhes from '../../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../../common/detalhes_documento/table_detalhes';

type ComponenteProps = {
  componentes : IComponente[],
};

export default function Componentes({ componentes } : ComponenteProps) {
  return (
      <>
        <h4>Componentes do Valor da Prestação de Serviço</h4>
        <TableDetalhes data={ componentes } noDataClass="padding-10">
          <ColumnDetalhes field="nome" width="50%">Nome</ColumnDetalhes>
          <ColumnDetalhes field="valor" type="moeda" width="50%">Valor</ColumnDetalhes>
        </TableDetalhes>
      </>
  );
}
