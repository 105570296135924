'use strict';
// @flow

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as modal from '../../../../reducers/modal';
import * as lista from '../../../../reducers/sped/escriturados/lista';
import * as filtro from '../../../../reducers/sped/escriturados/filtro';

import Funcoes from './funcoes';

const mapStateToProps    = createStructuredSelector({
        open: modal.openModalSelector,
        query: filtro.filtroParamsSelector,
      }),
      mapDispatchToProps = {
        downloadArquivoXls: lista.downloadArquivoXls,
        abreModal: modal.abreModal,
        fechaModal: modal.fechaModal,
      };

export default connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(Funcoes);