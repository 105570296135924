'use strict';
// @flow

import * as React from 'react';
import { Button, FormControl, FormGroup, Modal, Navbar } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Icone from 'components/common/icone';

type DescricaoChaveProps = {
  chave : ?string
}

export default class DescricaoChave extends React.PureComponent<DescricaoChaveProps, *> {
  state = { open: false };

  abreModal = () => this.setState({ open: true });
  fechaModal = () => this.setState({ open: false });

  render() {
    return (
        <div>
          <Button onClick={ this.abreModal } type="button" bsSize="xsmall" className="btn-block" bsStyle="link">
            <Icone nome="key-skeleton" estilo="fas" cor="text-muted" />
          </Button>

          { this.state.open &&
          <Modal show={ this.state.open } onHide={ this.fechaModal } dialogClassName="custom-modal">
            <Modal.Header closeButton>
              <Modal.Title>Chave de Acesso do Documento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Navbar.Form>
                <FormGroup controlId="chave">
                  <FormControl readOnly name="chave" type="text" value={ this.props.chave }
                               style={ { width: '28em' } }/>
                  <CopyToClipboard text={ this.props.chave || '' }>
                    <Button bsStyle="default" onClick={ this.fechaModal }>Copiar Chave</Button>
                  </CopyToClipboard>
                </FormGroup>
              </Navbar.Form>
            </Modal.Body>
            <Modal.Footer>
              <Button bsStyle="default" onClick={ this.fechaModal }>Fechar</Button>
            </Modal.Footer>
          </Modal>
          }
        </div>
    );
  }
}