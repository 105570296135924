// @flow
'use strict';

import * as React from 'react';

import type { IIssqn } from '../../../../types_imposto';

import RowDetalhes from '../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../common/detalhes_documento/column_detalhes';

type IssqnProps = {
  item : IIssqn,
};

export default function Issqn({ item } : IssqnProps) {
  return (
      <div id="table-issqn">
        <h4>ISSQN</h4>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="valorBcIssqn" type="moeda" width="25%">Base de Cálculo</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaIssqn" type="percentual" width="25%">Alíquota do ISSQN</ColumnDetalhes>
          <ColumnDetalhes field="valorIssqn" type="moeda" width="25%">Valor do ISSQN</ColumnDetalhes>
          <ColumnDetalhes field="municipioOcorrencia" width="25%">Município de Ocorrência</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="table-fixed">
          <ColumnDetalhes field="itemServico" className="overflow-ellipsis" width="25%" titleCelula>
            Item da Lista de Serviços
          </ColumnDetalhes>
          <ColumnDetalhes field="valorDeducoes" type="moeda" width="25%">Valor das Deduções</ColumnDetalhes>
          <ColumnDetalhes field="valorOutrasRetencoes" type="moeda" width="25%">Valor Outras Retenções</ColumnDetalhes>
          <ColumnDetalhes field="valorDescontoIncondicionado" type="moeda" width="25%">
            Valor Desconto Incondicionado
          </ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="valorDescontoCondicionado" type="moeda" width="25%">
            Valor Desconto Condicionado
          </ColumnDetalhes>
          <ColumnDetalhes field="valorRetencaoIss" type="moeda" width="25%">Valor Retenção ISS</ColumnDetalhes>
          <ColumnDetalhes field="indicadorExigibilidadeIss" width="25%">
            Indicador da Exigibilidade do ISS</ColumnDetalhes>
          <ColumnDetalhes field="codigoServicoPrestado" width="25%">Código Serviço Prestado</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="municipioIncidencia" width="50%">Município de Incidência</ColumnDetalhes>
          <ColumnDetalhes field="pais" width="50%">País </ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="numeroProcesso" width="50%">Processo de Suspensão da Exigibilidade</ColumnDetalhes>
          <ColumnDetalhes field="indicadorIncentivoFiscal" width="50%">Indicador Incentivo Fiscal</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}