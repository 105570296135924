// @flow
'use strict';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FormSection } from 'redux-form/immutable';

import { modalOpenSelector, finalizaBuscaEstruturada } from '../../../../reducers/cte/filtro_estruturado';

import Icone from '../../../common/icone';
import CampoSituacaoSefaz from '../../../common/campos_busca_estruturada/campo_situacao_sefaz';
import CampoCustodiado from './campo_custodiado';
import CampoSituacaoArquivo from '../../../common/campos_busca_estruturada/campo_situacao_arquivo';
import CampoLido from '../../../common/campos_busca_estruturada/campo_lido';
import CampoSituacaoCustodia from '../../../common/campos_busca_estruturada/campo_situacao_custodia';
import CampoSituacaoDesacordo from './campo_situacao_desacordo';
import CamposAtores from './campos_atores';
import CamposDadosConhecimento from './campos_dados_conhecimento';
import { CAMPOS_SITUACAO_CUSTODIA_CTE, CAMPOS_SITUACAO_SEFAZ_CTE } from '../../constantes';

type ModalProps = {
  handleSubmit : () => void,
  suporte : bool,
}

export default function ModalBuscaEstruturada({ handleSubmit, suporte } : ModalProps) {
  const open     = useSelector(modalOpenSelector),
        dispatch = useDispatch();

  const fechaModal = React.useCallback(() => dispatch(finalizaBuscaEstruturada()), [dispatch]);

  return (
      <Modal show={ open } onHide={ fechaModal } dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Busca Avançada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={ handleSubmit }>
            <section className="busca-estruturada">
              <Row>
                <Col md={ 7 }>
                  <CamposAtores/>
                </Col>
                <Col md={ 5 }>
                  <CamposDadosConhecimento/>
                </Col>
              </Row>

              <fieldset>
                <legend>Situações</legend>
              </fieldset>
              <Row>
                <Col md={ 7 }>
                  <FormSection name="situacao_sefaz">
                    <CampoSituacaoSefaz campos={ CAMPOS_SITUACAO_SEFAZ_CTE }/>
                  </FormSection>

                  <FormSection name="custodiado">
                    <CampoCustodiado/>
                  </FormSection>

                  <FormSection name="situacao_arquivo">
                    <CampoSituacaoArquivo/>
                  </FormSection>

                  <FormSection name="lido">
                    <CampoLido/>
                  </FormSection>
                </Col>
                { suporte &&
                    <Col md={ 5 }>
                      <FormSection name="situacao_custodia">
                        <CampoSituacaoCustodia campos={ CAMPOS_SITUACAO_CUSTODIA_CTE }/>
                      </FormSection>
                    </Col>
                }
              </Row>

              <fieldset>
                <legend>Manifestação de Desacordo do Serviço</legend>
                <Row>
                  <Col md={ 7 } className="text-left">
                    <FormSection name="situacao_desacordo_servico">
                      <CampoSituacaoDesacordo/>
                    </FormSection>
                  </Col>
                </Row>
              </fieldset>
            </section>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={ handleSubmit }><Icone nome="search" estilo="fas">Buscar</Icone></Button>
        </Modal.Footer>
      </Modal>
  );
}
