// @flow
'use strict';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';

import * as modal from '../../../reducers/modal';
import * as filtroLivre from '../../../reducers/nfse/filtro_livre';
import * as filtroEstruturado from '../../../reducers/nfse/filtro_estruturado';
import { totalSelector } from '../../../reducers/nfse/lista';

import Formulario from './formulario';

const mapStateToProps = createStructuredSelector({
  initialValues: filtroLivre.rootSelector, total: totalSelector, open: filtroEstruturado.modalOpenSelector,
  openInformacoes: modal.openModalSelector,
});

export default compose(
    connect<*, *, *, *, *, *>(mapStateToProps, {
      limpaFiltro: filtroLivre.limpaFiltro, inicializaBuscaLivre: filtroLivre.inicializaBuscaLivre,
      inicializaBuscaEstruturada: filtroEstruturado.inicializaBuscaEstruturada, abreModalInformacoes: modal.abreModal,
    }),
    reduxForm({
      form: 'nfseFiltroLivre', enableReinitialize: true,
      onSubmit(form, dispatch) {
        dispatch(filtroLivre.aplicaFiltro(form));
      },
    }),
)(Formulario);