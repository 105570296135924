// @flow
'use strict';

export default function dateFormat(value : string) {
  if (!value)
    return value;

  const numeros = value.replace(/[^\d]/g, '');
  if (numeros.length <= 2)
    return numeros;

  if (numeros.length <= 4)
    return `${ numeros.slice(0, 2) }/${ numeros.slice(2) }`;

  return `${ numeros.slice(0, 2) }/${ numeros.slice(2, 4) }/${ numeros.slice(4, 8) }`;
}