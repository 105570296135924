'use strict';
// @flow

import React from 'react';
import { Modal } from 'react-bootstrap';

import { handleModalClose } from './functions';

import Form from './form';
import ListagemArquivoSped from '../listagem';

type ModalNovoArquivoSpedProps = {
  lastQuery? : any,
};

export default function ModalNovoArquivoSped({ lastQuery } : ModalNovoArquivoSpedProps) {
  return (
      <div>
        <ListagemArquivoSped ignoraCarregaListaInicial lastQuery={ lastQuery }/>
        <Modal show onHide={ handleModalClose }>
          <Form/>
        </Modal>
      </div>
  );
}
