// @flow
'use strict';

import * as React from 'react';

import type { IIcmsNormalSt } from '../../../../../types_imposto';

import OpcoesIcmsNormalSt from './opcoes_icms_normal_st';

type IcmsNormalStProps = {
  item : IIcmsNormalSt,
};

export default function IcmsNormalSt({ item } : IcmsNormalStProps) {
  const opcoesIcmsNormalSt = [];

  Object.keys(item).forEach((i : string) => {
    const Opcao = OpcoesIcmsNormalSt[i.replace('icms', 'Icms')];
    if (Opcao)
      opcoesIcmsNormalSt.push(<Opcao key={ i } item={ item[i] }/>);
  });

  return (
      <div id="table-icms-normal-st">
        <h4>ICMS Normal e ST</h4>
        { opcoesIcmsNormalSt }
      </div>
  );
}
