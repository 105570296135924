// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";

import { openModalSelector } from '../../../../reducers/dashboard/modal_noticia';

import TabelaNoticias from './tabela_noticias';

const mapStateToProps = createStructuredSelector({
  open: openModalSelector,
});

export default connect<*, *, *, *, *, *>(mapStateToProps)(TabelaNoticias);