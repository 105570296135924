'use strict';

import url from 'url';
import { browserHistory } from 'react-router'

import { prepareQuery } from 'lib/viewUtils';

/**
 * Métodos para construir os caminhos dos arquivos do módulo SPED.
 */
export const arquivoPath = {
  base: `/app/sped/arquivos`,
  colecao: (params) => url.format({ pathname: arquivoPath.base, query: prepareQuery(params) }),
  novo: () => `${arquivoPath.base}/new`,
  entidade: (id, params) => {
    if (!id)
      throw new Error('ID é obrigatório');
    return url.format({ pathname: `${arquivoPath.base}/${id}`, query: prepareQuery(params) });
  },
  atualiza: (id) => {
    if (!id)
      throw new Error('ID é obrigatório');
    return url.format({ pathname: `${arquivoPath.base}/${id}/atualiza` });
  },
  download: (id, query) => {
    if (!id)
      throw new Error('ID é obrigatório');
    return url.format({ pathname: `${arquivoPath.base}/${id}/download`, query });
  },
  download_xls: (id, query) => {
    if (!id)
      throw new Error('ID é obrigatório');
    return url.format({ pathname: `${arquivoPath.base}/${id}/download_xls`, query });
  },
};

/**
 * Métodos para navegar entre os arquivos do módulo SPED.
 */
class ArquivoNav {
  listagemParams = {};
  detalhesParams = {};

  listagem = (params) => {
    const p = Object.assign(this.listagemParams, params);

    browserHistory.push(arquivoPath.colecao(p));
  };

  novo = () => {
    browserHistory.push(arquivoPath.entidade('new'));
  };

  detalhes = (id, params = {}) => {
    const p = this.detalhesParams[id] = Object.assign(this.detalhesParams[id] || {}, params);

    browserHistory.push(arquivoPath.entidade(id, p));
  };
}

export const arquivoNav = new ArquivoNav();
