// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fechaModal, openModalSelector } from 'reducers/modal_exportacao_lista';
import { ajaxError } from 'reducers/alerts';

import ModalDownloadLista from './modal_download_lista';

export default connect<*, *, *, *, *, *>(
    createStructuredSelector({ open: openModalSelector }),
    { fechaModal, ajaxError },
)(ModalDownloadLista);
