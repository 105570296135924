// @flow
'use strict';

import * as React from 'react';

import type { IRemetente } from '../types';
import { tipoDocumento } from './abas_informacoes';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';

type RemetenteProps = {
  remetente : IRemetente,
};

export default function Remetente({ remetente } : RemetenteProps) {
  return (
      <div className="tab-pane active">
        <h3>Remetente</h3>
        <RowDetalhes data={ remetente } className="no-margin-bottom">
          <ColumnDetalhes field="cnpj" type="documento" width="50%">{ tipoDocumento(remetente) }</ColumnDetalhes>
          <ColumnDetalhes field="ie" width="50%">Inscrição Estadual</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ remetente } className="no-margin-bottom">
          <ColumnDetalhes field="nomeRazaoSocial" width="50%">Nome / Razão Social</ColumnDetalhes>
          <ColumnDetalhes field="nomeFantasia" width="50%">Nome Fantasia</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ remetente } className="no-margin-bottom">
          <ColumnDetalhes field="telefone" width="50%">Telefone</ColumnDetalhes>
          <ColumnDetalhes field="endereco" width="50%">Endereço</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ remetente } className="no-margin-bottom">
          <ColumnDetalhes field="bairro" width="50%">Bairro</ColumnDetalhes>
          <ColumnDetalhes field="municipio" width="50%">Município</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ remetente } className="no-margin-bottom">
          <ColumnDetalhes field="cep" type="cep" width="50%">CEP</ColumnDetalhes>
          <ColumnDetalhes field="uf" width="50%">UF</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ remetente }>
          <ColumnDetalhes field="pais" width="50%">País</ColumnDetalhes>
          <ColumnDetalhes field="email" width="50%">E-mail</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
