// @flow
'use strict';

import * as React from 'react';

import type { ITotais } from '../../types';

import Valores from './valores';
import Componentes from './componentes';
import Impostos from './impostos';
import ColumnDetalhes from '../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../common/detalhes_documento/row_detalhes';

type TotaisProps = {
  totais : ITotais,
};

export default function Totais({ totais } : TotaisProps) {
  const { valores, componentes, impostos } = totais || { valores: null, componentes: null, impostos: null };

  return (
      <div id="table-totais">
        <h3>Totais</h3>
        <Valores valores={ valores }/>
        <Componentes componentes={ componentes }/>
        <Impostos imposto={ impostos }/>

        <RowDetalhes data={ totais }>
          <ColumnDetalhes field="valorTotal" type="moeda">Valor Total Tributos</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
