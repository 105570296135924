// @flow
'use strict';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';

import * as filtro from '../../../../reducers/dashboard/filtro';

import Formulario from './formulario';

const mapStateToProps = createStructuredSelector({ initialValues: filtro.rootSelector });

export default compose(
    connect<*, *, *, *, *, *>(mapStateToProps),
    reduxForm({
      form: 'dashboardFiltro', enableReinitialize: true,
      onSubmit(form, dispatch) {
        dispatch(filtro.aplicaFiltro(form));
      },
    }),
)(Formulario);