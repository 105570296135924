// @flow

import React from 'react';

import { withFormValues } from '../../../../../lib/forms';

import CampoTexto from '../../../../common/campo_texto';
import { Input } from '../../../../common/input';

type SelecaoEmpresaProps = {
  opcoesEmpresas : Array<{ value : string, label : string }>,
  conta_id : ? string,
  carregando : bool,
}

function SelecaoEmpresa({ opcoesEmpresas, conta_id, carregando } : SelecaoEmpresaProps) {
  return (
      <Input name="empresa_id" label="Empresa" placeholder="Empresa" component={ CampoTexto }
             options={ opcoesEmpresas } seletorSemEdicao={ !conta_id } isLoading={ carregando }
             seletorComPlaceholder style={ { width: '100em' } }/>
  );
}

export default withFormValues(true, 'conta_id')(SelecaoEmpresa);
