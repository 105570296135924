'use strict';
// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';

import type { State } from '../../../lib/types';
import * as modal from '../../../reducers/modal_exportacao_lista';
import { withProvider } from '../../../lib/reduxTools';

import ExportacaoLista from './exportacao_lista';

function mapStateToProps(state : State) {
  return { open: modal.openModalSelector(state) };
}

export default compose(
    withProvider(),
    connect(mapStateToProps, modal)
)(ExportacaoLista);
