// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, ControlLabel, FormControl, FormGroup, Navbar } from 'react-bootstrap';

import {
  alternaParaBuscaBasica, aplicaFiltro, buscaAvancadaSelector, limpaBuscaAvancada, mudaLivre,
} from '../../../../reducers/sped/documentos/filtro';

type BuscaAvancadaProps = {
  busca : string,

  alteraBuscaLivre(e : SyntheticInputEvent<*>) : void,
  alternaParaBuscaBasicaComBusca() : void,
  limpaBusca() : void,
};

export function BuscaAvancada({ busca, alteraBuscaLivre, alternaParaBuscaBasicaComBusca, limpaBusca } : BuscaAvancadaProps) {
  return (
      <Navbar fluid className="sped-busca-avancada">
        <Navbar.Header><Navbar.Brand>Busca Avançada</Navbar.Brand></Navbar.Header>

        <Navbar.Form>
          <FormGroup controlId="q">
            <ControlLabel srOnly>Busca Avançada</ControlLabel>
            <FormControl name="q" placeholder="Busca Avançada" type="search"
                         value={ busca } onChange={ alteraBuscaLivre }/>
          </FormGroup>
        </Navbar.Form>

        <Navbar.Form className="botoes">
          <div className="pull-left">
            <Button type="button" onClick={ alternaParaBuscaBasicaComBusca }>Busca Estruturada</Button>
          </div>
          <div className="pull-right">
            <Button type="reset" onClick={ limpaBusca }>Limpar</Button> { ' ' }
            <Button type="submit" bsStyle="primary">Filtrar</Button>
          </div>
          <div className="clearfix"/>
        </Navbar.Form>
      </Navbar>
  );
}

const mapStateToProps = createStructuredSelector({ busca: buscaAvancadaSelector });

const mapDispatchToProps = (dispatch) => ({
  limpaBusca() {
    dispatch(limpaBuscaAvancada());
    dispatch(aplicaFiltro());
  },
  alternaParaBuscaBasicaComBusca() {
    dispatch(alternaParaBuscaBasica());
    dispatch(aplicaFiltro());
  },
  alteraBuscaLivre(e : SyntheticInputEvent<*>) { dispatch(mudaLivre(e.target.value)); },
});

export default connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(BuscaAvancada);