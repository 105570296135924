// @flow
'use strict';

import React from 'react';

import FormExportacaoNfse from './form_exportacao/form_exportacao_nfse';
import FormExportacaoCte from './form_exportacao/form_exportacao_cte';
import FormExportacaoNfe from './form_exportacao/form_exportacao_nfe';
import FormExportacaoNfce from './form_exportacao/form_exportacao_nfce';

type FormTipoDocumentoProps = {
  tipo : string,
}

export default function FormTipoDocumento({ tipo } : FormTipoDocumentoProps) {
  switch (tipo) {
    case 'nfe':
      return <FormExportacaoNfe/>;
    case 'cte':
      return <FormExportacaoCte/>;
    case 'nfce':
      return <FormExportacaoNfce/>;
    case 'nfse':
      return <FormExportacaoNfse/>;
    default:
      return null;
  }
}
