// @flow
'use strict';

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import request from 'lib/request';
import type { Action, Dispatch, GetState, State } from '../lib/types';

const SET_URL       = 'documento/SET_URL',
      SET_DOCUMENTO = 'documento/SET_DOCUMENTO',
      SET_LOADING   = 'documento/SET_LOADING';

const DEFAULT_STATE = Immutable.fromJS({
  loading: false,
  documento: undefined,
});

/* ===================== */
/* REDUCER FUNCTION      */
/* ===================== */

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action = {}) {
  switch (action.type) {
    case SET_URL:
      return state.set('url', action.url);
    case SET_DOCUMENTO:
      return state.set('documento', Immutable.fromJS(action.documento)).set('loading', false);
    case SET_LOADING:
      return state.set('loading', action.loading);
    default:
      return state;
  }
}

/* ========= */
/* SELECTORS */
/* ========= */

export const rootSelector = (state : State) => state.get('documento');

export const urlSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('url'));

export const statusSefazSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.getIn(['documento', 'situacao', 'sefaz', 'status']));

/* ===================== */
/* BASIC ACTION CREATORS */
/* ===================== */

export function setUrl(url : string) {
  return { type: SET_URL, url };
}

export function setDocumento(documento : {}) {
  return { type: SET_DOCUMENTO, documento };
}

export function setLoading(loading : bool) {
  return { type: SET_LOADING, loading };
}

/* ===================== */
/* THUNK ACTION CREATORS */
/* ===================== */

export function initialize(url : string) {
  return function(dispatch : Dispatch<*>) {
    dispatch(setUrl(url));
    dispatch(requestData());
  };
}

const DEFAULT_REFRESH_DELAY = 30000;
let requestTimeout;

export function scheduleRequest(delay? : number) {
  return function(dispatch : Dispatch<*>) {
    const fn = () => dispatch(requestData());
    requestTimeout = setTimeout(fn, delay || DEFAULT_REFRESH_DELAY);
  };
}

export function requestData() {
  return function(dispatch : Dispatch<*>, getState : GetState) {
    if (requestTimeout)
      clearTimeout(requestTimeout);

    dispatch(setLoading(true));

    const url = urlSelector(getState());

    request.get(`${url}/situacoes`)
        .accept('json')
        .then(r => {
          dispatch(setDocumento(r.body));

          const delayed = statusSefazSelector(getState()) === 'delayed';

          if (delayed)
            dispatch(scheduleRequest(5000)); // se a situação é 'delayed', busca em 5s
          else
            dispatch(scheduleRequest()); // caso contrário, busca no tempo padrão (30s)
        });
  };
}
