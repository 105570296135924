// @flow
'use strict';

import * as React from 'react';

import FormExportacao from '../form_exportacao';
import CamposNfce from './campos_nfce';

type FormProps = {
  handleSubmit : () => void,
}

export default function FormExportacaoNfce(props : FormProps) {
  return (
      <FormExportacao { ...props } tipo="nfce">
        <CamposNfce/>
      </FormExportacao>
  );
}