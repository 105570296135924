'use strict';
// @flow

import * as React from 'react';
import { Provider } from 'react-redux';
import { applyRouterMiddleware, Route, Router } from 'react-router';
import { useScroll } from 'react-router-scroll';

import store, { history } from '../stores/redux_store';

import adminRoutes from './admin/routes';
import spedRoutes from './sped/routes';
import nfeRoutes from './nfe/routes';
import cteRoutes from './cte/routes';
import nfseRoutes from './nfse/routes';
import nfceRoutes from './nfce/routes';
import cfeRoutes from './cfe/routes';
import dashboardRoutes from './dashboard/routes';
import ferramentasRoutes from './ferramentas/routes';

import BaseLayout from './layout/base_layout';
import type { AlertType } from 'reducers/alerts';
import { alert } from 'reducers/alerts';

const rootRoutes = (
    <Route name="Início" path="/" component={ BaseLayout }>
      { adminRoutes }
      { spedRoutes }
      { nfeRoutes }
      { cteRoutes }
      { nfseRoutes }
      { nfceRoutes }
      { cfeRoutes }
      { dashboardRoutes }
      { ferramentasRoutes }
    </Route>
);

type AppProps = {
  alerts? : [{ type : AlertType, message : string }],
};

export default function App({ alerts, ...appProps } : AppProps) {
  setTimeout(() => {
    if (alerts && alerts.length > 0)
      alerts.forEach(({ type, message }) => store.dispatch(alert(type, message)));
  }, 500);

  const injectAppProps = React.useCallback(
      (Component, props) => <Component { ...appProps } { ...props } />,
      [appProps],
  );

  return (
      <Provider store={ store }>
        <Router history={ history } render={ applyRouterMiddleware(useScroll()) } routes={ rootRoutes }
                createElement={ injectAppProps }/>
      </Provider>
  );
}
