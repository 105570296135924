// @flow
'use strict';

import * as React from 'react';

import type { IAereo } from '../../types';

import RowDetalhes from '../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../../common/detalhes_documento/table_detalhes';

type AereoProps = {
  aereo : IAereo,
};

export default function Aereo({ aereo } : AereoProps) {
  return (
      <>
        <h3>Aéreo</h3>
        <RowDetalhes data={ aereo }>
          <ColumnDetalhes field="numMinuta">Núm. Minuta</ColumnDetalhes>
          <ColumnDetalhes field="numOpConhecAereo">Núm. Op. Conhec. Aéreo</ColumnDetalhes>
          <ColumnDetalhes field="previsaoEntrega" type="data">Previsão Entrega</ColumnDetalhes>
        </RowDetalhes>

        <h4>Natureza da Carga</h4>
        <RowDetalhes data={ aereo }>
          <ColumnDetalhes field="dimensao">Dimensão</ColumnDetalhes>
          <ColumnDetalhes field="infManuseio">Inf. de Manuseio</ColumnDetalhes>
        </RowDetalhes>

        <h4>Informações de Tarifa</h4>
        <RowDetalhes data={ aereo }>
          <ColumnDetalhes field="classe">Classe</ColumnDetalhes>
          <ColumnDetalhes field="codTarifa">Cód. Tarifa</ColumnDetalhes>
          <ColumnDetalhes field="valorTarifa" type="moeda">Valor Tarifa</ColumnDetalhes>
        </RowDetalhes>

        <h4>Transporte de Produtos Perigosos</h4>
        <TableDetalhes data={ aereo.transpProdPerigosos } noDataClass="padding-10">
          <ColumnDetalhes field="numOnuUn">Núm. ONU/UN</ColumnDetalhes>
          <ColumnDetalhes field="qtdVolumeArtigosPerigosos">Qtde. Total Vol. c/ Artigos Perigosos</ColumnDetalhes>
          <ColumnDetalhes field="qtdArtigosPerigosos">Qtde. Total Artigos Perigosos</ColumnDetalhes>
          <ColumnDetalhes field="unMedida">Un. Medida</ColumnDetalhes>
        </TableDetalhes>
      </>
  );
}
