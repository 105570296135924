// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { lastPathSelector } from '../../../../reducers/nfce/lista';

import Voltar from '../../../common/detalhes_documento/voltar';

const mapStateToProps = createStructuredSelector({ path: lastPathSelector });

export default connect<*, *, *, *, *, *>(mapStateToProps, {})(Voltar);