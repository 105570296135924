// @flow
'use strict';

import * as React from 'react';
import { MenuItem } from 'react-bootstrap';

import BotaoOpcaoXml from './botao_opcao_xml';
import BotaoOpcaoPdf from './botao_opcao_pdf';

export default function BotaoExportacaoLoteNfce() {
  return (
      <>
        <MenuItem><BotaoOpcaoPdf/></MenuItem>
        <MenuItem><BotaoOpcaoXml/></MenuItem>
      </>
  );
}
