// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosNfse from './selecao_todos_nfse';
import { Input } from '../../../../../../common/input';

export default function CamposTransacoesConsyste() {
  return (
      <FormSection name="transacoesConsyste">
        <SelecaoTodosNfse nomes={ ["transacoesConsyste"] } titulo="Transações no Consyste"/>

        <div className="grupo-checkbox">
          <Input name="createdAt" label="Data do Recebimento" checkbox/>
          <Input name="statusSefazDesc" label="Situação na SEFAZ" checkbox/>
        </div>
      </FormSection>
  );
}