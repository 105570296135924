'use strict';
// @flow

import * as React from 'react';
import { IndexRedirect, Redirect, Route } from 'react-router';

import AppWithoutTitle from '../common/app_without_title';
import Lista from './lista';
import Detalhes from './detalhes';

export default (
    <Route name="CT-es" path="app/cte" component={ AppWithoutTitle }>
      <IndexRedirect to="lista/tomados"/>
      <Route name="Todos" path="lista/:d(/f/:f)(/o/:s/:sd)(/:page)" component={ Lista }/>
      <Route name="Visualização de Documento" path=":id" component={ Detalhes }/>
      <Route name="Visualização de Documento" path=":id/situacoes" component={ Detalhes }/>
      <Redirect from="lista/:d(/o/:s/:sd)(/:page)" to="lista/:d(/f/:f)(/o/:s/:sd)(/:page)"/>
    </Route>
);
