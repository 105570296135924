'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, State, Dispatch, GetState } from 'lib/types';
import request from '../../lib/request';

const ATUALIZA_DESCRICAO = 'admin/politica_senha/ATUALIZA_DESCRICAO',
      ATUALIZA_STATUS    = 'admin/politica_senha/ATUALIZA_STATUS',
      ATUALIZA_ERRO      = 'admin/politica_senha/ATUALIZA_ERRO';

const INITIAL_STATE = Immutable.fromJS({
  descricao: {}, status: 'ready', erro: null,
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case ATUALIZA_DESCRICAO:
      return state.set('descricao', action.body);
    case ATUALIZA_STATUS:
      return state.set('status', action.body);
    case ATUALIZA_ERRO:
      return state.set('erro', action.body);

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['admin', 'politica_senha'], Immutable.Map());
export const descricaoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('descricao'));
export const statusSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('status'));
export const erroSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('erro'));
export const contaIdSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.getIn(['descricao', 'contaId']));

// actions

export function atualizaDescricao(descricao : any) {
  return { type: ATUALIZA_DESCRICAO, body: Immutable.fromJS(descricao) };
}

export function atualizaStatus(status : string) {
  return { type: ATUALIZA_STATUS, body: status };
}

// thunk actions


export function atualizaPoliticaSenha(data : any) {
  return async function(dispatch : Dispatch<any>, getState : GetState) {
    await dispatch(atualizaStatus('sending'));
    await dispatch({ type: ATUALIZA_ERRO, body: null });

    await request.post(`/app/admin/contas/${ contaIdSelector(getState()) }/definir_politica`)
        .accept('json')
        .set({ 'X-Http-Method-Override': 'patch' })
        .send({ politica_senha: data })
        .then(r => {
          dispatch(atualizaDescricao(r.body));
          dispatch(atualizaStatus('done'));
          dispatch({ type: ATUALIZA_ERRO, body: null });
        })
        .catch(e => {
          dispatch(atualizaStatus('ready'));
          dispatch({ type: ATUALIZA_ERRO, body: e });
        });
  };
}