// @flow
'use strict';

import * as _ from 'lodash';
import React from 'react';
import { autobind } from 'core-decorators';
import { Button } from 'react-bootstrap';

import type { ISpedArquivoCompleto } from '../../types';
import { arquivoNav, arquivoPath } from '../../arquivo_path';

import Icone from 'components/common/icone';
import ModalDownload from '../../../modal_download';

type OpcoesProps = {
  open : bool,
  arquivo? : ISpedArquivoCompleto,
  abreModal : () => void,
  fechaModal : () => void,
  excluirArquivo : (id : string) => void,
  atualizaArquivo : (id : string) => void,
  downloadArquivoXls : (id : string, params : any) => void,
  id : string,
  params : any,
}

const LIMITE_DOC_DOWNLOAD_PROD = 50000;

export default class Opcoes extends React.PureComponent<OpcoesProps, *> {
  props : OpcoesProps;

  @autobind
  handleExcluiArquivo(e : SyntheticEvent<*>) {
    e.preventDefault();
    if (window.confirm('Tem certeza que deseja excluir o arquivo SPED?\nEsta operação é irreversível.')) {
      if (this.props.arquivo && this.props.arquivo.id && this.props.arquivo.carregado_em)
        this.props.excluirArquivo(this.props.arquivo.id);
      else
        window.alert('Não é possível excluir o arquivo, porque ainda não está carregado. Aguarde até que o arquivo esteja totalmente carregado e tente novamente.');
    }
  }

  @autobind
  handleAtualizaArquivo(e : SyntheticEvent<*>) {
    e.preventDefault();
    if (window.confirm('Tem certeza que deseja atualizar as situações dos documentos do arquivo SPED?\nEsta operação é irreversível.'))
      this.props.atualizaArquivo(this.props.id);
  }

  @autobind
  handleDownloadArquivoXls(e : SyntheticEvent<*>) {
    e.preventDefault();
    this.props.downloadArquivoXls(this.props.id, this.props.params);
    this.props.fechaModal();
  }

  @autobind
  handleDownloadArquivo(e : SyntheticEvent<*>) {
    e.preventDefault();
    if (window.confirm('Deseja fazer o download do arquivo SPED?'))
      location.href = arquivoPath.download(this.props.id, { format: 'txt' });
  }

  @autobind
  handleVoltar(e : SyntheticEvent<*>) {
    e.preventDefault();
    arquivoNav.listagem();
  }

  render() {
    const numDocumentos     = _.get(this.props.arquivo, 'documentos.total', 0),
          limiteDocDownload = _.get(this.props.arquivo, 'limiteDocDownload', LIMITE_DOC_DOWNLOAD_PROD);

    return (
        <div>
          <div className="form-actions well well-sm">
            <Button onClick={ this.handleVoltar }><Icone nome="chevron-left" estilo="fas">Voltar</Icone></Button>
            <div className="pull-right">
              <Button bsStyle="primary" onClick={ this.props.abreModal }>
                <Icone nome="file-alt" estilo="fal">Exportar Dados (xls)</Icone>
              </Button> { ' ' }
              <Button bsStyle="primary" onClick={ this.handleDownloadArquivo }>
                <Icone nome="cloud-download" estilo="fas">Download</Icone>
              </Button> { ' ' }
              <Button bsStyle="danger" onClick={ this.handleExcluiArquivo }>
                <Icone nome="trash-alt" estilo="fal">Excluir</Icone>
              </Button>
            </div>
          </div>

          { this.props.open && <ModalDownload limiteDownload={ limiteDocDownload } totalDocumentos={ numDocumentos }
                                              onConfirma={ this.handleDownloadArquivoXls }/> }
        </div>
    );
  }
}

// CL-405: Botão atualizar situações desabilitado
/*
<Button bsStyle="primary" onClick={ this.handleAtualizaArquivo }>
  <Icone tipo="refresh">Atualizar situações</Icone>
</Button> { ' ' }
*/