'use strict';
// @flow

import React from 'react';
import { Alert } from 'react-bootstrap';

import type { RenderedAlertProps } from './types';

export function RenderedAlert({ alert, close } : RenderedAlertProps) {
  const onDismiss = React.useCallback(() => close(alert.seq), [close, alert.seq]);

  return <Alert bsStyle={ alert.type || 'info' } onDismiss={ onDismiss }>{ alert.message }</Alert>;
}