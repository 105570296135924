'use strict';
// @flow

import React from 'react';

import { Radio } from 'react-bootstrap';
import type { ISelectOption } from '../types';

type RadioReactProps = {
  name : string,
  dado : ISelectOption,
  onChange : () => void,
  value : string,
}

// Verificar opção default - checked = true
export default function RadioReact({ name, dado, onChange, value } : RadioReactProps) {
  return (
      <Radio name={ name } value={ dado.value } inline onChange={ onChange }
             checked={ dado.value === value }>
        { dado.label }
      </Radio>
  );
}