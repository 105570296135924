// @flow
'use strict';

import * as React from 'react';

import type { IIcms900 } from '../../../../../../types_imposto';

import RowDetalhes from '../../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../../common/detalhes_documento/column_detalhes';

type Icms900Props = {
  item : IIcms900,
};

export default function Icms900({ item } : Icms900Props) {
  return (
      <>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="origem" width="25%">Origem da mercadoria</ColumnDetalhes>
          <ColumnDetalhes field="csosn" width="25%">CSOSN</ColumnDetalhes>
          <ColumnDetalhes field="determinacaoBcIcms" width="25%">Determinação da BC do ICMS</ColumnDetalhes>
          <ColumnDetalhes field="valorBcIcms" type="moeda" width="25%">Base de Cálculo ICMS</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="percentualReducaoBc" type="percentual" width="25%">Redução Base de Cálculo</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaIcms" type="percentual" width="25%">Alíquota do ICMS</ColumnDetalhes>
          <ColumnDetalhes field="valorIcms" type="moeda" width="25%">Valor do ICMS</ColumnDetalhes>
          <ColumnDetalhes field="determinacaoBcIcmsSt" width="25%">Determinação da BC ICMS ST</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="margemValorAdicionadoIcmsSt" type="percentual" width="25%">Margem valor Adicionado ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="reducaoBcIcmsSt" type="percentual" width="25%">Redução Base de Cálculo ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="bcIcmsSt" type="moeda" width="25%">Base de Cálculo ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaIcmsSt" type="percentual" width="25%">Alíquota do ICMS ST</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="valorIcmsSt" type="moeda" width="25%">Valor ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="bcFcpRetido" type="moeda" width="25%">BC FCP Retido por ST</ColumnDetalhes>
          <ColumnDetalhes field="percentualFcpRetido" type="percentual" width="25%">FCP Retido por ST</ColumnDetalhes>
          <ColumnDetalhes field="fcpRetido" type="moeda" width="25%">FCP Retido por ST</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="aliquotaCalculoCredito" type="percentual" width="25%">Alíquota cálculo crédito SN</ColumnDetalhes>
          <ColumnDetalhes field="creditoIcms" type="moeda" width="25%">Crédito ICMS</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
