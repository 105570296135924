// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { jsSelector } from '../../../lib/viewUtils';
import { rootSelector } from '../../../reducers/dashboard/paineis';

import Paineis from './paineis';

const mapStateToProps = createStructuredSelector({
  elementos: jsSelector(rootSelector),
});

export default connect<*, *, *, *, *, *>(mapStateToProps)(Paineis);