// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import { Input } from '../../../../../common/input';

type CamposLocalEntregaProps = {
  component : React.ComponentType<*>,
}

export default function CamposLocalEntrega({ component } : CamposLocalEntregaProps) {
  const SelecaoTodos = component;

  return (
      <FormSection name="localEntrega">
        <SelecaoTodos nomes={ ["localEntrega"] } titulo="Local de Entrega"/>

        <div className="grupo-checkbox">
          <Input name="localEntregaCnpj" label="CNPJ" checkbox/>
          <Input name="localEntregaCpf" label="CPF" checkbox/>
          <Input name="localEntregaRazaoSocial" label="Razão Social" checkbox/>
          <Input name="localEntregaLogr" label="Logradouro" checkbox/>
          <Input name="localEntregaNum" label="Número" checkbox/>
          <Input name="localEntregaCompl" label="Complemento" checkbox/>
          <Input name="localEntregaBairro" label="Bairro" checkbox/>
          <Input name="localEntregaMun" label="Município" checkbox/>
          <Input name="localEntregaUf" label="UF" checkbox/>
          <Input name="localEntregaCep" label="CEP" checkbox/>
          <Input name="localEntregaPais" label="País" checkbox/>
          <Input name="localEntregaFone" label="Telefone" checkbox/>
          <Input name="localEntregaEmail" label="E-mail" checkbox/>
          <Input name="localEntregaIe" label="Inscrição Estadual" checkbox/>
        </div>
      </FormSection>
  );
}
