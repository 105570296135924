// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { abreDetalhesProdutoServico, modalOpenSelector } from '../../../../../reducers/nfce/detalhes_produto_servico';

import ProdutoServico from './produto_servico';

const mapStateToProps    = createStructuredSelector({
        modalOpen: modalOpenSelector,
      }),
      mapDispatchToProps = {
        handleDetalhesClick: nfce => nfce && abreDetalhesProdutoServico(nfce),
      };

export default connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(ProdutoServico);