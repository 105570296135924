// @flow
'use strict';

import * as React from 'react';

import type { IIntermediadorTransacao, IPagamento } from '../../types';

import Pagamentos from './pagamentos';
import IntermediadorTransacao from './intermediador_transacao';

type PagamentoProps = {
  pagamentos : IPagamento[],
  intermediadorTransacao : IIntermediadorTransacao,
};

export default function Pagamento({ pagamentos, intermediadorTransacao } : PagamentoProps) {
  return (
      <div className="tab-pane active">
        <Pagamentos pagamentos={ pagamentos }/>

        { intermediadorTransacao && <IntermediadorTransacao intermediadorTransacao={ intermediadorTransacao }/> }
      </div>
  );
}
