'use strict';
// @flow

import React from 'react';

import type { ISpedEfd } from '../types';
import { formatHelper } from 'lib/viewUtils';
import {
  custodiado, custodiadoOrig, emissao, modelo, moeda, operacao, participante, serieNumeroSped, situacaoArquivo,
  situacaoSefaz, situacaoSped,
} from '../../table_components';

import DataTable from '../../../common/data_table';
import Column from '../../../common/data_table/column';

type DadosTabela = 'loading' | 'erro' | any[];

type TabelaProps = {
  dados : ?DadosTabela
}

export default function Tabela({ dados } : TabelaProps) {
  return (
      <DataTable data={ dados } striped hover>
        <Column expr="sped_efd" width={ 8 } formatter={ LinkSped }
                headerHint="Data inicial do SPED">SPED</Column>
        <Column expr="data_emissao" width={ 8 } formatter={ formatHelper.formatDateSped }>Emissão</Column>
        <Column expr="cod_modelo" width={ 50 } formatter={ modelo }
                headerHint="Modelo do documento">Mod.</Column>
        <Column expr="serie,numero" width={ 60 } formatter={ serieNumeroSped }>Série Número</Column>
        <Column expr="valor" width={ 90 } formatter={ moeda }>Valor</Column>
        <Column expr="participante" formatter={ participante }
                headerHint="Emitente ou destinatário terceiro">Participante</Column>
        <Column expr="ind_emissao" width={ 1 } align="center" padding="right" formatter={ emissao }/>
        <Column expr="tipo_operacao" width={ 1 } align="center" padding="right" formatter={ operacao }/>
        <Column expr="situacao_sped" width={ 60 } formatter={ situacaoSped }
                headerHint="Situação do documento no SPED">SPED</Column>
        <Column expr="custodiado" width={ 1 } align="right" padding="right" formatter={ custodiado }
                headerHint="Doc. está custodiado?" className="column-sped-c">C.</Column>
        <Column expr="custodiado_upload" width={ 1 } align="center" formatter={ custodiadoOrig }
                headerHint="Doc. estava custodiado no upload do SPED?" className="column-sped-small">C.O.</Column>
        <Column expr="custodiado_em" width={ 10 } formatter={ formatHelper.formatDate }
                headerHint="Data de início da custódia no Consyste">Custodiado em</Column>
        <Column expr="situacao_arquivo,situacao_arquivo_upload" width={ 92 } formatter={ situacaoArquivo }
                headerHint="Situação do arquivo: atual / no upload">Situação Arquivo</Column>
        <Column expr="situacao_sefaz,situacao_sefaz_upload" width={ 85 } formatter={ situacaoSefaz }
                headerHint="Situação do doc na SEFAZ: atual / no upload">Situação SEFAZ</Column>
      </DataTable>
  );
}

export function LinkSped({ url, data_inicial } : ISpedEfd) {
  const val = formatHelper.formatDateSped(data_inicial);
  return url ? <a href={ url }>{ val }</a> : <>{ val }</>;
}