// @flow
'use strict';

import { connect } from 'react-redux';

import { abreModal } from '../../../../../../reducers/nfce/modal_exportacao_lote';

import BotaoOpcaoXml from '../../../../../common/botoes_exportacao_lote/botao_opcao_xml';

const mapDispatchToProps = {
  abreModalXml: () => abreModal('xml'),
};

export default connect<*, *, *, *, *, *>(null, mapDispatchToProps)(BotaoOpcaoXml);
