// @flow
'use strict';

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { State, Action, Dispatch } from '../../lib/types';
import request from '../../lib/request';
import { ajaxError } from '../alerts';

export const CONTAS_CARREGANDO = 'modelo_relatorio/salvar_relatorio/CONTAS_CARREGANDO',
             CONTAS_CARREGADAS = 'modelo_relatorio/salvar_relatorio/CONTAS_CARREGADAS',
             EXIBE_ALERTA      = 'modelo_relatorio/salvar_relatorio/EXIBE_ALERTA',
             FECHA_ALERTA      = 'modelo_relatorio/salvar_relatorio/FECHA_ALERTA';

const DEFAULT_STATE = Immutable.fromJS({
  contas: [],
  alertas: '',
});

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action) {
  switch (action.type) {
    case CONTAS_CARREGANDO:
      return state.set('contas', null);
    case CONTAS_CARREGADAS:
      return state.set('contas', Immutable.fromJS(action.body));
    case EXIBE_ALERTA:
      return state.set('alertas', action.alertas);
    case FECHA_ALERTA:
      return state.set('alertas', '');
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['modeloRelatorio', 'salvarRelatorio'], Immutable.Map());
export const contasSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('contas'));
export const alertasSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('alertas'));

// action creators

export function exibeAlertas(alertas : string) {
  return { type: EXIBE_ALERTA, alertas };
}

export function fechaAlertas() {
  return { type: FECHA_ALERTA };
}

export function carregaContas() {
  return async function(dispatch : Dispatch<any>) {
    dispatch({ type: CONTAS_CARREGANDO });

    await request.get('/app/modelos_relatorio/lista_contas')
        .accept('json')
        .then(r => dispatch({ type: CONTAS_CARREGADAS, body: r.body.listaContas }))
        .catch(e => {
          dispatch({ type: CONTAS_CARREGADAS, body: [] });
          dispatch(ajaxError('Erro ao carregar lista de contas', e));
        });
  };
}
