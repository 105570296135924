// @flow
'use strict';

import * as React from 'react';

import type { IIcms900 } from '../../../../../../types_imposto';

import ColumnDetalhes from '../../../../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../../../../common/detalhes_documento/row_detalhes';

type IssqnProps = {
  item : IIcms900,
};

export default function Icms900({ item } : IssqnProps) {
  return (
      <div>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="origem" width="25%">Origem da Mercadoria</ColumnDetalhes>
          <ColumnDetalhes field="csosn" width="25%">CSOSN</ColumnDetalhes>
          <ColumnDetalhes field="determinacaoBcIcms" width="25%">Determinação da BC do ICMS</ColumnDetalhes>
          <ColumnDetalhes field="valorBcIcms" type="moeda" width="25%">Base de Cálculo ICMS</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="percentualReducaoBc" type="percentual" width="25%">
            Redução Base de Cálculo
          </ColumnDetalhes>
          <ColumnDetalhes field="aliquotaIcms" type="percentual" width="25%">Alíquota do ICMS</ColumnDetalhes>
          <ColumnDetalhes field="valorIcms" type="moeda" width="50%">Valor do ICMS</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
