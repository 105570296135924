'use strict';
// @flow

import * as React from 'react';

import DataTable, { Column } from 'components/common/data_table';
import ComTooltip from 'components/common/com_tooltip';
import { formatHelper } from 'lib/viewUtils';
import type { ILegacyData } from 'lib/types';

import type { ISpedArquivo } from '../types';
import { arquivoNav } from '../arquivo_path';

const handleRowClick = d => d && arquivoNav.detalhes(d.id);

export default function Tabela({ dados } : { dados : ILegacyData<ISpedArquivo> }) {
  return (
      <DataTable data={ dados } striped hover onRowClick={ handleRowClick }>
        <Column expr="data_inicial" width={ 100 } formatter={ formatHelper.formatDate }>Data</Column>
        <Column expr="empresa_cnpj" width={ 150 } formatter={ formatHelper.formatId }>CNPJ</Column>
        <Column expr="empresa_nome">Empresa</Column>
        <Column expr="empresa_uf" width={ 50 }>UF</Column>
        <Column expr="created_at" width={ 100 } cellComponent={ DataUpload }>Upload</Column>
      </DataTable>
  );
}

function DataUpload({ row } : { row? : ISpedArquivo }) {
  if (!row)
    return <td/>;

  return (
      <td>
        <ComTooltip hint={ formatHelper.formatDateTime(row.created_at) }>
          <time dateTime={ row.created_at }>
            { formatHelper.formatDate(row.created_at) }
          </time>
        </ComTooltip>
      </td>
  );
}