// @flow
'use strict';

import * as React from 'react';

import type { IInformacoesEmbalagemProduto } from '../../../types';

import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';

type InformacoesEmbalagemProdutoProps = {
  informacoesEmbalagemProduto : IInformacoesEmbalagemProduto,
};

export default function InformacoesEmbalagemProduto({ informacoesEmbalagemProduto } : InformacoesEmbalagemProdutoProps) {
  return (
      <div id="table-inf-embalagem-produto">
        <h3>Informações da Embalagem do Produto</h3>
        <RowDetalhes data={ informacoesEmbalagemProduto }>
          <ColumnDetalhes field="embalagem" width="33%">Embalagem do Produto</ColumnDetalhes>
          <ColumnDetalhes field="volume" width="33%">Volume do Produto na Embalagem</ColumnDetalhes>
          <ColumnDetalhes field="unidadeMedida" width="33%">Unidade de Medida da Embalagem</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
