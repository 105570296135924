// @flow
'use strict';

import * as React from 'react';

export default function DocFiscaisVazio() {
  return (
      <>
        <h4>Notas Fiscais</h4>
        <h4>Notas Fiscais Eletrônicas</h4>
        <h4>Demais Documentos</h4>
      </>
  );
}
