// @flow
'use strict';

import { connect } from 'react-redux';

import { abreModal } from '../../../../../reducers/nfse/modal_exportacao_lote';

import BotaoExportacaoLoteNfse from './botao_exportacao_lote';

const mapDispatchToProps = {
  abreModalPdf: () => abreModal('pdf'),
  abreModalXml: () => abreModal('xml'),
}

export default connect<*, *, *, *, *, *>(null, mapDispatchToProps)(BotaoExportacaoLoteNfse);
