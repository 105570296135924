// @flow
'use strict';

import * as React from 'react';

import type { IIcms00 } from '../../../types_imposto';

import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../common/detalhes_documento/row_detalhes';

type Icms00Props = {
  icms : IIcms00,
};

export default function Icms00({ icms } : Icms00Props) {
  return (
      <>
        <h4>ICMS - 00 = Tributação Normal</h4>
        <RowDetalhes data={ icms } className="no-margin-bottom">
          <ColumnDetalhes field="cst" width="50%">Classificação Tributária do Serviço</ColumnDetalhes>
          <ColumnDetalhes field="valorBc" type="moeda" width="50%">Valor da Base de Cálculo do ICMS</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ icms }>
          <ColumnDetalhes field="aliquota" type="percentual" width="50%">Alíquota do ICMS</ColumnDetalhes>
          <ColumnDetalhes field="valor" type="moeda" width="50%">Valor do ICMS</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
