// @flow
'use strict';

import * as React from 'react';
import { ControlLabel, Row } from 'react-bootstrap';

import CampoIntervalo from '../../../common/campo_intervalo';
import CampoIntervaloData from '../../../common/campos_busca_estruturada/campo_intervalo_data';
import CampoTexto from '../../../common/campo_texto';
import { InputForm, Input } from '../../../common/input';

export default function CamposDocumentos() {
  return (
      <>
        <legend>Documentos</legend>
        <Row className="campo-formulario">
          <ControlLabel className="col-sm-3">Chave</ControlLabel>
          <Input className="col-sm-9 no-padding-left" style={ { height: '34px' } } name="chave" label="Chave"
                 placeholder="Insira uma chave por linha" component={ CampoTexto } livre/>
        </Row>
        <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="emitente" label="Emitente"
                   placeholder="nome ou CNPJ"/>
        <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="destinatario"
                   label="Destinatário" placeholder="nome ou CNPJ"/>
        <CampoIntervaloData columnClassName="no-padding-left" rotulo="Emissão" nome="emitido_em"/>
        <CampoIntervaloData columnClassName="no-padding-left" rotulo="Recepção" nome="recebido_em"/>
        <CampoIntervalo columnClassName="no-padding-left" rotulo="Série" nome="serie"/>
        <CampoIntervalo columnClassName="no-padding-left" rotulo="Número" nome="numero"/>
        <CampoIntervalo columnClassName="no-padding-left" rotulo="Valor" nome="valor"/>
      </>
  );
}
