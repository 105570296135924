// @flow
'use strict';

import * as React from 'react';
import { Button } from 'react-bootstrap';

type BotaoOpcaoPdfProps = {
  abreModalPdf : () => void,
}

export default function BotaoOpcaoPdf({ abreModalPdf } : BotaoOpcaoPdfProps) {
  return (
      <Button className="btn-exportacao-lote dropdown-item" bsSize="large" bsStyle="link" block
              onClick={ abreModalPdf }>DANFE</Button>
)
  ;
}
