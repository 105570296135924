// @flow
'use strict';

import * as React from 'react';
import { Col, ControlLabel, Row } from 'react-bootstrap';

import { Input } from '../input';

export default function CampoSituacaoArquivo() {
  return (
      <Row className="campo-formulario campo-input">
        <ControlLabel className="col-sm-4 titulo-checkbox">Situação do Arquivo</ControlLabel>
        <Col sm={ 8 } className="opcao-checkbox margin-bottom-1">
          <Input name="ok" label="Válido" checkbox/>
          <Input name="not_found" label="Não Encontrado" checkbox/>
          <Input name="syntax_error" label="Erro de Sintaxe" checkbox/>
          <Input name="invalid_schema" label="Schema Inválido" checkbox/>
          <Input name="wrong_environment" label="Ambiente Incorreto" checkbox/>
          <Input name="invalid_dsig" label="Assinatura Digital Inválida" checkbox/>
        </Col>
      </Row>
  );
}
