'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';
import { push } from 'react-router-redux';

import type { Action, Dispatch, GetState, State } from 'lib/types';
import * as Paths from '../../../paths';
import request from 'lib/request';
import { ajaxError } from 'reducers/alerts';

export const LISTA_CARREGANDO = 'noticia/lista/LISTA_CARREGANDO',
             LISTA_CARREGADA  = 'noticia/lista/LISTA_CARREGADA';

const INITIAL_STATE = Immutable.fromJS({
  paginaAtual: {},
  carregando: false,
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case LISTA_CARREGANDO:
      return state.set('carregando', true);
    case LISTA_CARREGADA:
      return state.set('carregando', false).set('paginaAtual', action.body);

    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['ferramentas', 'noticia', 'lista'], Immutable.Map());
export const currentPage = createSelector<*, *, *, *>(rootSelector, (root) => root.get('paginaAtual'));
export const carregandoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('carregando'));

// actions

export function listaCarregando() {
  return { type: LISTA_CARREGANDO };
}

export function listaCarregada(lista : any) {
  return { type: LISTA_CARREGADA, body: Immutable.fromJS(lista) };
}

// thunk actions

export function trocaPagina(page : number) {
  return async function(dispatch : Dispatch<*>) {
    dispatch(carregaLista({ page: String(page) }));
  };
}

export function carregaLista(query : any = {}) {
  return async function(dispatch : Dispatch<any>, getState : GetState) {
    if (!carregandoSelector(getState())) {
      const url = Paths.noticias.lista(query);

      dispatch(push(url));

      await dispatch(listaCarregando());

      await request.get(url)
          .accept('json')
          .query(query)
          .then(r => dispatch(listaCarregada(r.body)))
          .catch(e => {
            dispatch(listaCarregada({}));
            dispatch(ajaxError('Erro ao carregar lista de notícia', e));
          });
    }
  };
}
