// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/immutable';

import CampoIntervalo from '../../../common/campo_intervalo';
import { InputForm } from '../../../common/input';

export default function CamposItens() {
  return (
      <>
        <legend>Itens</legend>
        <FormSection name="itens">
          <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="descricao" label="Descrição"/>
          <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="codigo" label="Código"/>
          <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="ncm" label="NCM"/>
          <CampoIntervalo columnClassName="no-padding-left" rotulo="Valor Unit." nome="valor_unitario"/>
          <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="ean" label="EAN"/>
        </FormSection>
      </>
  );
}
