'use strict';
// @flow

import * as React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type IconeProps = {
  nome : string,
  estilo : 'fas' | 'fab' | 'far' | 'fal' | 'fad',
  tamanho? : number,
  cor? : string,
  children? : React$Node,
  spin? : bool,
  large? : bool,
  small? : bool,
  flip? : 'horizontal' | 'vertical' | 'both',
  titulo? : string,
  classe? : string,
};

export default function Icone({ nome, estilo, tamanho, cor, children, spin, large, small, flip, titulo, classe = '', ...props } : IconeProps) {
  const cls = classe || classNames(cor, { 'fa-spin': spin, 'fa-lg': large, 'fa-s': small });

  const icon = <FontAwesomeIcon icon={[estilo, nome]} size={ tamanho && `${tamanho}x` } flip={ flip } className={ cls } title={ titulo } />;

  if (Object.keys(props).length > 0 || children)
    return <span {...props}>{ icon }{' '}{ children }</span>;
  else
    return icon;
}