// @flow
'use strict';

import * as React from 'react';
import { Field } from 'redux-form/immutable';

import type { InputProps } from './types';
import InputWrapper from './input_wrapper';
import InputCheckbox from './input_checkbox';

export default function Input({ component, checkbox, ...props } : InputProps) {
  const Comp = component || (checkbox ? InputCheckbox : InputWrapper);

  return <Field { ...props } component={ Comp } validate={ validate }/>;
}

function validate(val, allVals, props) {
  if (props.required && !val && val !== 0)
    return 'é obrigatório';

  return undefined;
}
