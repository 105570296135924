// @flow

import { combineReducers } from 'redux-immutable';

import lista from './lista';
import detalhes from './detalhes';
import modal from './modal';
import filtro from './filtro';

export default combineReducers({ lista, detalhes, modal, filtro });
