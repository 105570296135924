'use strict';
// @flow
/* eslint-disable react/no-string-refs */

import $ from 'jquery';
import * as React from 'react';

import { formatHelper as fmt, useEffectOnce } from 'lib/viewUtils';
import api from 'lib/api';

import 'jquery-ui/ui/effect';
import 'jquery-ui/ui/effects/effect-fade';
import 'jquery-ui/ui/effects/effect-shake';
import 'jquery-ui/ui/effects/effect-slide';

import './ios-badge.less';

type IosBadgeProps = { counter : string }

export default function IosBadge({ counter } : IosBadgeProps) {
  const [timer, setTimer] = React.useState<TimeoutID | null>(null);
  const [currentCount, setCurrentCount] = React.useState<number | 'error' | null>(null);

  const elRef = React.useRef(null);

  const shake = React.useCallback(() => {
    $(elRef.current).effect('shake', { distance: 3 });
  }, []);

  useEffectOnce(() => {
    function updateCounters() {
      api.counters(counter)
          .then(data => {
            const val  = data[counter],
                  last = currentCount;

            if (last !== val) {
              if (last !== undefined)
                setTimeout(shake, 500);
              setCurrentCount(val);
            }
            setTimer(setTimeout(updateCounters, 30000));
          })
          .catch(() => {
            setCurrentCount('error');
            setTimer(setTimeout(updateCounters, 60000));
          });
    }

    updateCounters();

    return () => {
      if (timer)
        clearTimeout(timer);
    };
  });

  if (!currentCount)
    return <span/>;

  return (
      <span className="ios-badge" ref={ elRef }>
        { currentCount === 'error' ? '•' : fmt.numberWithDelimiter(currentCount, 0) }
      </span>
  );
}
