'use strict';
// @flow

import React from 'react';
import { MenuItem, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { openModalSelector, fechaModal, abreModal } from '../../../../reducers/modal';
import { filtroParamsSelector } from '../../../../reducers/sped/documentos/filtro';
import { opcaoDownloadSelector, downloadArquivo, setOpcaoDownload } from '../../../../reducers/sped/documentos/lista';

import ModalDownload from '../../modal_download';
import Icone from 'components/common/icone';

type FuncoesProps = {
  limiteDownload : number,
  total? : ?number,
}

export default function Funcoes({ limiteDownload, total } : FuncoesProps) {
  const open          = useSelector(openModalSelector),
        query         = useSelector(filtroParamsSelector),
        opcaoDownload = useSelector(opcaoDownloadSelector);

  const dispatch = useDispatch();

  const abrirModalDados = React.useCallback(() => {
    dispatch(setOpcaoDownload('dados'));
    dispatch(abreModal());
  }, [dispatch]);

  const abrirModalChaves = React.useCallback(() => {
    dispatch(setOpcaoDownload('chaves'));
    dispatch(abreModal());
  }, [dispatch]);

  const handleDownload = React.useCallback(() => {
    dispatch(downloadArquivo(query, opcaoDownload));
    dispatch(fechaModal());
  }, [dispatch, query, opcaoDownload]);

  return (
      <div>
        <div className="navbar-form navbar-left" style={ { marginLeft: -14, marginTop: 0 } }>
          <Dropdown id="botao-exportacao-documento-sped" dropup>
            <Dropdown.Toggle bsStyle="primary" id="split-button-dropup">
              <Icone nome="file-alt" estilo="fal">Exportar</Icone>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <MenuItem eventKey="1" onClick={ abrirModalChaves }>Chaves (txt)</MenuItem>
              <MenuItem eventKey="2" onClick={ abrirModalDados }>Dados (xls)</MenuItem>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        { open && <ModalDownload limiteDownload={ limiteDownload } totalDocumentos={ total }
                                 onConfirma={ handleDownload }/> }
      </div>
  );
}
