'use strict';
// @flow

import React from 'react';
import { autobind } from 'core-decorators';
import { Button, Modal } from 'react-bootstrap';

import type { Upload } from '../types';
import { arquivoPath } from '../../arquivo_path';
import { handleModalClose } from '../functions';

import ModalBody from '../modal_body';

type FormProps = {
  upload : Upload,
  iniciaUpload : () => void,
  uploadArquivos : (files : any) => void,
}

export default class Form extends React.PureComponent<FormProps, *> {
  /** Função chamada quando o usuário solicita o envio do upload */
  @autobind
  handleFormSubmit(e : SyntheticEvent<*>) {
    e.preventDefault();
    this.props.iniciaUpload();

    const form : any = e.target,
          input      = form['novoArquivo'],
          files      = input.files;

    this.props.uploadArquivos(files);
  }

  render() {
    return (
        <form action={ arquivoPath.base } method="post" onSubmit={ this.handleFormSubmit }>
          <Modal.Header>
            <Modal.Title>Enviar Novo Arquivo Sped</Modal.Title>
          </Modal.Header>
          <ModalBody { ...this.props.upload }/>
          <Modal.Footer>
            <Button onClick={ handleModalClose }>Fechar</Button>
            <Button bsStyle="primary" type="submit"
                    disabled={ this.props.upload.uploading || this.props.upload.progress === 'done' }>Enviar</Button>
          </Modal.Footer>
        </form>
    );
  }
}