// @flow
'use strict';

import * as React from 'react';
import classnames from 'classnames';
import { Row } from 'react-bootstrap';

import type { InputProps } from './types';

import { Input } from './index';

type InputFormProps = {
  ...InputProps,
  className? : string,
}

export default function InputForm(props : InputFormProps) {
  return (
      <Row className={ classnames('campo-formulario', props.className) }>
        <Input { ...props }/>
      </Row>
  );
}