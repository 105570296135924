// @flow
'use strict';

import * as React from 'react';

import type { IIcms } from '../../../../types_imposto';

import IcmsNormalSt from './opcoes_icms/icms_normal_st';
import IcmsSimplesNacional from './opcoes_icms/icms_simples_nacional';

type IcmsProps = {
  item : IIcms,
};

export default function Icms({ item } : IcmsProps) {
  return (
      <div id="table-icms">
        { item.icmsNormalSt && <IcmsNormalSt item={ item.icmsNormalSt }/> }
        { item.icmsSimplesNacional && <IcmsSimplesNacional item={ item.icmsSimplesNacional }/> }
      </div>
  );
}
