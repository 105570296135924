// @flow
'use strict';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { abreModal } from 'reducers/nfe/modal_manifestacao_lote';
import { querySelector, tipoSelector } from 'reducers/nfe/filtro_livre';

import Icone from 'components/common/icone';

export default function BotaoOpcaoDesconhecida() {
  const dispatch              = useDispatch(),
        tipo                  = useSelector(tipoSelector),
        query                 = useSelector(querySelector),
        abreModalDesconhecida = React.useCallback(() => dispatch(abreModal('desconhecida', tipo, query)), [dispatch, tipo, query]);

  return (
      <span onClick={ abreModalDesconhecida }>
        <Icone nome="question-circle" estilo="fas">Desconhecida</Icone>
      </span>
  );
}
