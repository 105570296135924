'use strict';
// @flow

import React from 'react';
import Bugsnag, { ErrorBoundary } from 'lib/bugsnag';

import { Breadcrumbs, Erro, Title } from './router_components';
import type { IRoute } from '../../lib/types';

type BaseAppProps = { routes : IRoute[], children? : any };
type BaseAppState = { error? : Error, info? : any };

/**
 * Componente que representa uma aplicação básica.
 */
export default class BaseApp extends React.PureComponent<BaseAppProps, BaseAppState> {
  state : BaseAppState = {};

  componentDidCatch(error : Error, info : any) {
    Bugsnag.notify(error);
    this.setState({ error, info });
  }

  render() {
    const { routes, children } = this.props,
          { error }            = this.state;

    return (
        <ErrorBoundary>
          <Title routes={ routes }/>
          <Breadcrumbs routes={ routes }/>
          { error ? <Erro error={ error }/> : children }
        </ErrorBoundary>
    );
  }
}
