// @flow
'use strict';

import { type Saga } from 'redux-saga';
import { put, takeLatest } from 'redux-saga/effects';

import { MUDA_FILTRO, mudaFiltro } from '../reducers/sped/escriturados/filtro';

export default function* escriturados() : Saga<*> {
  yield takeLatest(ac => ac.type === MUDA_FILTRO && ac.nome === 'modelo', atualizaFiltros);
}

function* atualizaFiltros({ valor } : { valor : any }) : Generator<*, *, *> {
  if (valor !== '55')
    yield put(mudaFiltro('cod_manifestacao_cd', ''));

  if (valor !== '65' && valor !== '67') {
    yield put(mudaFiltro('tomador', ''));
    yield put(mudaFiltro('ctes_tomados', ''));
    yield put(mudaFiltro('situacao_desacordo_servico', ''));
  }
}