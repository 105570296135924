// @flow
'use strict';

import * as React from 'react';

import type { IEmitente } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';

type EmitenteProps = {
  emitente : IEmitente,
};

export default function Emitente({ emitente } : EmitenteProps) {
  return (
      <div className="tab-pane active">
        <h3>Emitente</h3>
        <RowDetalhes data={ emitente } className="no-margin-bottom">
          <ColumnDetalhes field="nome" width="50%">Nome / Razão Social</ColumnDetalhes>
          <ColumnDetalhes field="fantasia" width="50%">Nome Fantasia</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ emitente } className="no-margin-bottom">
          <ColumnDetalhes field="cnpj" type="documento" width="50%">CNPJ</ColumnDetalhes>
          <ColumnDetalhes field="inscricaoEstadual" width="50%">Inscrição Estadual</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ emitente } className="no-margin-bottom">
          <ColumnDetalhes field="inscricaoMunicipal" width="50%">Inscrição Municipal</ColumnDetalhes>
          <ColumnDetalhes field="cnae" width="50%">CNAE Fiscal</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ emitente }>
          <ColumnDetalhes field="regimeTributario" width="50%">Código de Regime Tributário</ColumnDetalhes>
        </RowDetalhes>

        <h3>Endereço</h3>
        <RowDetalhes data={ emitente } className="no-margin-bottom">
          <ColumnDetalhes field="logradouro" width="50%">Logradouro</ColumnDetalhes>
          <ColumnDetalhes field="numero" width="50%">Número</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ emitente } className="no-margin-bottom">
          <ColumnDetalhes field="complemento" width="50%">Complemento</ColumnDetalhes>
          <ColumnDetalhes field="bairro" width="50%">Bairro</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ emitente } className="no-margin-bottom">
          <ColumnDetalhes field="municipio" width="50%">Município</ColumnDetalhes>
          <ColumnDetalhes field="uf" width="50%">UF</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ emitente } className="no-margin-bottom">
          <ColumnDetalhes field="pais" width="50%">País</ColumnDetalhes>
          <ColumnDetalhes field="telefone" width="50%">Telefone</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
