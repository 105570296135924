// @flow
'use strict';

import * as React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, Well } from 'react-bootstrap';

import type { IPaginatedData } from 'lib/types';
import { formatHelper as fmt, useEffectOnce } from 'lib/viewUtils';

import PaginationFooter from 'components/common/pagination_footer';
import DataTable, { Column } from 'components/common/data_table';
import Icone from 'components/common/icone';

type Noticia = {
  id : string,

  data? : string,
  titulo? : string,
  autor : ?string,
}

type ListaProps = {
  dados? : IPaginatedData<Noticia>,
  trocaPagina : (p : number) => void,
  carregaLista : (query : {}) => void,
  handleRowClick : () => void,
}

export default function Lista({ carregaLista, dados, handleRowClick, trocaPagina } : ListaProps) {
  useEffectOnce(() => {
    carregaLista({});
  });

  const { items = null, page, total, pageSize } = dados || {};

  return (
      <div>
        <Well bsStyle="sm" className="clearfix">
          <div className="pull-right">
            <LinkContainer to="/app/ferramentas/noticias/new">
              <Button type="button" bsStyle="primary">
                <Icone nome="layer-plus" estilo="far">Criar Notícia</Icone>
              </Button>
            </LinkContainer>
          </div>
        </Well>

        <DataTable className="link-button" data={ items } onRowClick={ handleRowClick } striped hover>
          <Column expr="data" formatter={ fmt.formatDateTime }>Data</Column>
          <Column expr="titulo">Título</Column>
          <Column expr="autor">Autor</Column>
        </DataTable>
        <PaginationFooter label="Notícias" page={ page } total={ total } pageSize={ pageSize }
                          onPageChange={ trocaPagina }/>
      </div>
  );
}
