'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, State } from 'lib/types';

export const INICIALIZA_FILTROS = 'recuperacao/filtro/INICIALIZA_FILTROS',
             MUDA_FILTRO        = 'recuperacao/filtro/MUDA_FILTRO';

const FiltrosRecord = new Immutable.Record({
  q: '', tipo: 'recupere', conta_id: '', data_inclusao_de: '', data_inclusao_ate: '', chave: '', situacao: '',
});

const INITIAL_STATE = Immutable.fromJS({
  filtro: new FiltrosRecord(),
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case MUDA_FILTRO:
      return state.setIn(['filtro', action.nome], action.valor);
    case INICIALIZA_FILTROS:
      return state.set('filtro', action.valores);
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['recuperacao', 'filtro'], Immutable.Map());
export const filtroSelector = createSelector<*, *, *, *>(rootSelector, (root) : typeof FiltrosRecord => root.get('filtro'));

// actions

export function mudaFiltro(nome : string, valor : any) {
  return { type: MUDA_FILTRO, nome, valor };
}

export function inicializaFiltros(query : typeof FiltrosRecord) {
  return { type: INICIALIZA_FILTROS, valores: new FiltrosRecord(query) };
}
