// @flow
'use strict';

import * as React from 'react';
import { ControlLabel, Row } from 'react-bootstrap';

import CampoTexto from '../../../common/campo_texto';
import { Input, InputForm } from '../../../common/input';

export default function CamposAtores() {
  return (
      <>
        <legend>Atores</legend>
        <Row className="campo-formulario">
          <ControlLabel className="col-sm-3">Chave</ControlLabel>
          <Input className="col-sm-9 no-padding-left" style={ { height: '34px' } } name="chave" label="Chave"
                 placeholder="Insira uma chave por linha" component={ CampoTexto } livre/>
        </Row>
        <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="emitente" label="Emitente"
                   placeholder="nome ou CNPJ"/>
        <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="destinatario" label="Destinatário"
                   placeholder="nome ou CNPJ"/>
        <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="remetente" label="Remetente"
                   placeholder="nome ou CNPJ"/>
        <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="recebedor" label="Recebedor"
                   placeholder="nome ou CNPJ"/>
        <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="expedidor" label="Expedidor"
                   placeholder="nome ou CNPJ"/>
        <InputForm className="campo-input" sm={ 9 } inputClassName="no-padding-left" name="tomador" label="Tomador"
                   placeholder="nome ou CNPJ"/>
      </>
  );
}
