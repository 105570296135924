// @flow
'use strict';

import * as React from 'react';

import type { IPisQtde, IPisOutr } from '../../../../../types_imposto';

import RowDetalhes from '../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../common/detalhes_documento/column_detalhes';

type TributoQuantidadeProps = {
  item : IPisQtde | IPisOutr,
};

export default function TributoQuantidade({ item } : TributoQuantidadeProps) {
  return (
      <RowDetalhes data={ item }>
        <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
        <ColumnDetalhes field="quantidadeVendida" width="25%">Quantidade Vendida</ColumnDetalhes>
        <ColumnDetalhes field="aliquotaPisReais" type="moeda" width="25%">Alíquota do PIS</ColumnDetalhes>
        <ColumnDetalhes field="valorPis" type="moeda" width="25%">Valor do PIS</ColumnDetalhes>
      </RowDetalhes>
  );
}
