'use strict';
// @flow

import Url from 'url';

import type { IPaginatedData } from '../types';
import { fetchJSON, type IFetchOptions, json } from './fetch';

type ResouceUrlOptions = {
  query? : any,
  pathname? : string,
}

export class Resource<T> {
  resourcePath : string;

  constructor(resourcePath : string) {
    this.resourcePath = resourcePath;
  }

  async index(query : {} = {}) : Promise<IPaginatedData<T>> {
    return await fetchJSON(Url.format({ pathname: this.resourcePath, query }));
  }

  async show(id : string) : Promise<T> {
    return await fetchJSON(`${ this.resourcePath }/${ id }`);
  }

  async create<R>(payload : T) : Promise<R> {
    return await fetchJSON(`${ this.resourcePath }`, json('POST', payload));
  }

  async update<R>(id : string, payload : T) : Promise<R> {
    return await fetchJSON(`${ this.resourcePath }/${ id }`, json('PATCH', payload));
  }

  async delete<R>(id : string) : Promise<R> {
    return await fetchJSON(`${ this.resourcePath }/${ id }`, { method: 'DELETE' });
  }

  async memberAction<R>(id : string, action : string, urlOptions : ResouceUrlOptions = {}, fetchOptions : IFetchOptions = {}) : Promise<R> {
    return await this.collectionAction(`${ id }/${ action }`, urlOptions, fetchOptions);
  }

  async collectionAction<R>(action : string, urlOptions : ResouceUrlOptions = {}, fetchOptions : IFetchOptions = {}) : Promise<R> {
    if (!urlOptions.pathname)
      urlOptions.pathname = `${ this.resourcePath }/${ action }`;

    // $FlowFixMe
    const url = Url.format(urlOptions);
    return await fetchJSON(url, fetchOptions);
  }
}
