// @flow
'use strict';

import * as React from 'react';
import classnames from 'classnames';
import Loader from 'react-loader-advanced';

import { FONT_FAMILY_GRAFICO, TRANSPARENTE_STYLE } from './constantes';

type PainelDashboardProps = {
  carregado : bool,
  titulo? : string,
  children? : React.Node,
  classNamePainel? : string,
  classNameTitulo? : string,
  stylePanelBody? : Object,
};

const STYLE_PANEL_BODY = { padding: 5, marginLeft: 15 };

export default function PainelDashboard({ carregado, titulo, children, stylePanelBody, classNamePainel = '', classNameTitulo = '' } : PainelDashboardProps) {
  const cls = ['panel', 'panel-default'],
        clsTitulo = ['titulo-dashboard'];

  if (classNamePainel)
    cls.push(classNamePainel);

  if (classNameTitulo)
    clsTitulo.push(classNameTitulo);

  if (!carregado) {
    return (
        <Loader show message="Carregando..." backgroundStyle={ { opacity: 0.2 } }
                style={ FONT_FAMILY_GRAFICO }>
          <div className={ classnames(cls) } style={ { ...TRANSPARENTE_STYLE, height: '200px' } }/>
        </Loader>
    );
  }

  return (
      <div className={ classnames(cls) } style={ TRANSPARENTE_STYLE }>
        { titulo && <div className={ classnames(clsTitulo) }>{ titulo }</div> }

        <div className="panel-body" style={ stylePanelBody || STYLE_PANEL_BODY }>
          { children }
        </div>
      </div>
  );
}