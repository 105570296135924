// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosCte from './selecao_todos_cte';
import { Input } from '../../../../../../common/input';

export default function CamposIcms() {
  return (
      <FormSection name="dadosIcms">
        <SelecaoTodosCte nomes={ ['dadosIcms'] } titulo="Dados do ICMS"/>

        <div className="grupo-checkbox">
          <Input name="cst" label="Classificação Tributária do Serviço" checkbox/>
          <Input name="valorBc" label="Base de Cálculo" checkbox/>
          <Input name="aliquota" label="Alíquota" checkbox/>
          <Input name="valorIcms" label="Valor ICMS" checkbox/>
          <Input name="percReducaoBc" label="% Redução Base de Cálculo" checkbox/>
          <Input name="bcIcmsStRet" label="Base de Cálculo ICMS ST Retido" checkbox/>
          <Input name="valorIcmsStRet" label="Valor ICMS ST Retido" checkbox/>
          <Input name="aliquotaIcmsStRet" label="Alíquota ICMS ST Retido" checkbox/>
          <Input name="valorCredIcms" label="Valor Crédito ICMS" checkbox/>
          <Input name="percRedBcOutraUf" label="% Redução Base de Cálculo Outra UF" checkbox/>
          <Input name="bcIcmsOutraUf" label="Base de Cálculo ICMS Outra UF" checkbox/>
          <Input name="aliquotaIcmsOutraUf" label="Alíquota ICMS Outra UF" checkbox/>
          <Input name="valorIcmsOutraUf" label="Valor ICMS Outra UF" checkbox/>
        </div>
      </FormSection>
  );
}
