// @flow
'use strict';

import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import SelecaoTodosNfce from './selecao_todos_nfce';
import CamposNfeENfce from '../../common/campos_nfe_e_nfce';
import CamposBasicos from './campos_basicos';
import CamposTransacoesConsyste from '../../common/campos_transacoes_consyste';
import CamposPessoa from '../../common/campos_pessoa';
import CamposLocalRetirada from '../../common/campos_local_retirada';
import CamposLocalEntrega from '../../common/campos_local_entrega';
import CamposTransporte from '../../common/campos_transporte';
import CamposTotais from '../../common/campos_totais';

export default function CamposNfce() {
  return (
      <CamposNfeENfce component={ SelecaoTodosNfce }>
        <Row>
          <Col md={ 6 }>
            <CamposBasicos key="camposBasicos"/>
            <CamposTransacoesConsyste component={ SelecaoTodosNfce }/>
            <CamposTotais key="camposTotais" component={ SelecaoTodosNfce }/>
            <CamposPessoa nome="emit" titulo="Dados do Emitente" component={ SelecaoTodosNfce }/>
            <CamposPessoa nome="dest" titulo="Dados do Destinatário" component={ SelecaoTodosNfce }/>
            <CamposLocalRetirada component={ SelecaoTodosNfce }/>
          </Col>
          <Col md={ 6 }>
            <CamposLocalEntrega component={ SelecaoTodosNfce }/>
            <CamposTransporte tipoDocumento="nfce" component={ SelecaoTodosNfce }/>
          </Col>
        </Row>
      </CamposNfeENfce>
  );
}