// @flow
'use strict';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { IPagamento } from '../../../types';

import { abreDetalhesCartao, modalOpenSelector } from '../../../../../../reducers/nfe/detalhes_cartao';

import ColumnDetalhes from '../../../../../common/detalhes_documento/column_detalhes';
import TableDetalhes from '../../../../../common/detalhes_documento/table_detalhes';
import ModalCartao from './modal_cartao';

type PagamentosProps = {
  pagamentos : IPagamento[],
};

export default function Pagamentos({ pagamentos } : PagamentosProps) {
  const modalOpen           = useSelector(modalOpenSelector),
        dispatch            = useDispatch(),
        handleDetalhesClick = React.useCallback((cartao) => dispatch(abreDetalhesCartao(cartao)), [dispatch]);

  return (
      <>
        <h3>Pagamentos</h3>
        <TableDetalhes data={ pagamentos } noDataClass="padding-10" noDataMsg="Nenhum pagamento informado na NF-e">
          <ColumnDetalhes field="forma" width="15%">Forma</ColumnDetalhes>
          <ColumnDetalhes field="meio" width="45%">Meio</ColumnDetalhes>
          <ColumnDetalhes field="valorPagamento" type="moeda" width="15%">Valor Pgto.</ColumnDetalhes>
          <ColumnDetalhes field="valorTroco" type="moeda" width="15%">Valor Troco</ColumnDetalhes>
          <ColumnDetalhes field="cartao" type="botaoDetalhes" width="10%" buttonClick={ handleDetalhesClick } textoBotaoDetalhes="Cartão"/>
        </TableDetalhes>

        { modalOpen && <ModalCartao/> }
      </>
  );
}
