// @flow
'use strict';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatHelper } from 'lib/viewUtils';

import { openSelector, totalDocumentosSelector, fechaModal, downloadLoteNfe } from 'reducers/nfe/modal_exportacao_lote';

import EsqueletoModal from '../../../../common/esqueleto_modal';

export default function ModalExportacaoLote() {
  const open            = useSelector(openSelector),
        totalDocumentos = useSelector(totalDocumentosSelector);

  const dispatch        = useDispatch(),
        fecharModal     = React.useCallback(() => dispatch(fechaModal()), [dispatch]),
        exportarLoteNfe = React.useCallback(() => dispatch(downloadLoteNfe()), [dispatch]);

  return (
      <EsqueletoModal open={ open } onClose={ fecharModal } onAccept={ exportarLoteNfe } acceptLabel="Confirmar">
        <p>Você solicitou o download dos documentos da busca atual,
          que compreende <strong>{ formatHelper.numberWithDelimiter(totalDocumentos) }</strong> documentos.</p>
        <p>O progresso da operação poderá ser acompanhado pela opção <q>Downloads</q>, no menu lateral.</p>
        <p>Confirma a solicitação?</p>
      </EsqueletoModal>
  );
}
