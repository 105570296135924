// @flow
'use strict';

import * as React from 'react';

import type { IIcms201 } from '../../../../../../types_imposto';

import RowDetalhes from '../../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../../common/detalhes_documento/column_detalhes';

type Icms201Props = {
  item : IIcms201,
};

export default function Icms201({ item } : Icms201Props) {
  return (
      <>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="origem" width="25%">Origem da mercadoria</ColumnDetalhes>
          <ColumnDetalhes field="csosn" width="25%">CSOSN</ColumnDetalhes>
          <ColumnDetalhes field="determinacaoBcIcmsSt" width="25%">Determinação BC ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="margemValorAdicionadoIcmsSt" type="percentual" width="25%">Margem Valor Adicionado ICMS ST</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="reducaoBcIcmsSt" type="percentual" width="25%">Redução BC ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="bcIcmsSt" type="moeda" width="25%">Base de Cálculo ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaIcmsSt" type="percentual" width="25%">Alíquota ICMS ST</ColumnDetalhes>
          <ColumnDetalhes field="icmsSt" type="moeda" width="25%">ICMS ST</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item } className="no-margin-bottom">
          <ColumnDetalhes field="bcFcpRetido" type="moeda" width="25%">BC FCP Retido por ST</ColumnDetalhes>
          <ColumnDetalhes field="fcpRetido" type="percentual" width="25%">FCP Retido por ST</ColumnDetalhes>
          <ColumnDetalhes field="valorFcpRetido" type="moeda" width="25%">Valor FCP Retido por ST</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaCalculoCredito" type="percentual" width="25%">Alíquota de cálculo do crédito SN</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="valorCreditoIcms" type="moeda">Valor crédito ICMS que pode ser aproveitado</ColumnDetalhes>
        </RowDetalhes>
      </>
  );
}
