// @flow
'use strict';

import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import type { ItemProps } from '../types';

import Nfce from './nfce';
import Emitente from './emitente';
import Destinatario from './destinatario';
import Totais from './totais';
import Transporte from './transporte';
import InformacoesAdicionais from './informacoes_adicionais';
import Cobranca from './cobranca';
import ProdutoServico from './produto_servico';

export default function AbasInformacoes({ item } : ItemProps) {
  return (
      <Tabs id="tab-detalhes-nfce" defaultActiveKey="nfce">
        <Tab eventKey="nfce" title="NFC-e">
          <Nfce item={ item }/>
        </Tab>
        <Tab eventKey="emitente" title="Emit.">
          <Emitente emitente={ item.emitente }/>
        </Tab>
        <Tab eventKey="destinatario" title="Dest.">
          <Destinatario destinatario={ item.destinatario }/>
        </Tab>
        <Tab eventKey="produto-servico" title="Produtos/Serviços">
          <ProdutoServico produtoServico={ item.produtoServico }/>
        </Tab>
        <Tab eventKey="totais" title="Totais">
          <Totais totais={ item.totais }/>
        </Tab>
        <Tab eventKey="transporte" title="Transp.">
          <Transporte transporte={ item.transporte }/>
        </Tab>
        <Tab eventKey="cobranca" title="Cobr.">
          <Cobranca cobranca={ item.cobranca }/>
        </Tab>
        <Tab eventKey="informacoes-adicionais" title="Inf. Adicionais">
          <InformacoesAdicionais informacoesAdicionais={ item.informacoesAdicionais }/>
        </Tab>
      </Tabs>
  );
}