// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosCte from './selecao_todos_cte';
import { Input } from '../../../../../../common/input';

export default function CamposTributosFederais() {
  return (
      <FormSection name="dadosTributosFederais">
        <SelecaoTodosCte nomes={ ['dadosTributosFederais'] } titulo="Dados dos Tributos Federais"/>

        <div className="grupo-checkbox">
          <Input name="pis" label="Valor PIS" checkbox/>
          <Input name="cofins" label="Valor COFINS" checkbox/>
          <Input name="ir" label="Valor Imposto Renda (IR)" checkbox/>
          <Input name="inss" label="Valor INSS" checkbox/>
          <Input name="csll" label="Valor CSLL" checkbox/>
        </div>
      </FormSection>
  );
}
