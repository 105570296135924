'use strict';
// @flow

import React from 'react';
import { Checkbox } from 'react-bootstrap';

import type { IReduxFormInputProps } from './types';

type CheckboxReactProps = {
  label : string,
  input : IReduxFormInputProps
}

export default function CheckboxReact(props : CheckboxReactProps) {
  return (
      <Checkbox onChange={ props.input.onChange } checked={ props.input.value }>
        { props.label }
      </Checkbox>
  );
}