// @flow
'use strict';

import * as React from 'react';

import type { IIcms500 } from '../../../../../../types_imposto';

import ColumnDetalhes from '../../../../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../../../../common/detalhes_documento/row_detalhes';

type Icms500Props = {
  item : IIcms500,
};

export default function Icms500({ item } : Icms500Props) {
  return (
      <div>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="origem" width="25%">Origem da Mercadoria</ColumnDetalhes>
          <ColumnDetalhes field="csosn" width="25%">CSOSN</ColumnDetalhes>
          <ColumnDetalhes field="valorBcIcmsSt" type="moeda" width="25%">BC ICMS ST Retido</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaConsumidorFinal" type="percentual" width="25%">
            Alíquota Suportada Consumidor Final
          </ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="valorIcmsStRetido" type="moeda" width="25%">ICMS ST Retido</ColumnDetalhes>
          <ColumnDetalhes field="valorBcFcpRetidoAnteriormente" type="moeda" width="25%">
            BC FCP Retido Anteriormente
          </ColumnDetalhes>
          <ColumnDetalhes field="percentualBcFcpRetidoAnteriormente" type="percentual" width="25%">
            Percentual FCP Retido Anteriormente por ST
          </ColumnDetalhes>
          <ColumnDetalhes field="valorFcpRetido" type="moeda" width="25%">
            FCP Retido Anteriormente por ST
          </ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="percentualReducaoBcEfetiva" type="percentual" width="25%">
            Redução BC Efetiva
          </ColumnDetalhes>
          <ColumnDetalhes field="valorBcEfetiva" type="moeda" width="25%">Base Cálculo Efetiva</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaIcmsEfetiva" type="percentual" width="25%">
            Alíquota ICMS Efetiva
          </ColumnDetalhes>
          <ColumnDetalhes field="valorIcmEfetivo" type="moeda" width="25%">ICMS Efetivo</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
