// @flow
'use strict';

import * as React from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';

import Icone from '../../common/icone';
import Contador from '../../common/contador';
import CampoTexto from '../../common/campo_texto';
import ModalBuscaEstruturada from './modal_busca_estruturada';
import ModalInformacoes from '../../common/modal_informacoes';
import { Input } from '../../common/input';

type BuscaBasicaProps = {
  total : number,
  open : bool,
  handleSubmit : () => void,
  limpaFiltro : () => void,
  inicializaBuscaEstruturada : () => void,
  openInformacoes : bool,
  abreModalInformacoes : () => void,
};

export default function CamposBusca({ total, open, handleSubmit, limpaFiltro, inicializaBuscaEstruturada, openInformacoes, abreModalInformacoes } : BuscaBasicaProps) {
  return (
      <div>
        <Form onSubmit={ handleSubmit }>
          <Row>
            <Col className="coluna-informacoes" xs={ 2 } sm={ 1 } md={ 1 } xl={ 1 }>
              <Button className="botao-informacoes" bsStyle="link" onClick={ abreModalInformacoes }>
                <Icone nome="question-circle" estilo="fas" />
              </Button>
            </Col>
            <Col className="campo-busca-rapida-nfse" xs={ 6 } sm={ 4 } md={ 2 } xl={ 2 }>
              <Input name="q" label="Livre" placeholder="Buscar" component={ CampoTexto }/>
            </Col>
            <Button type="submit" bsStyle="primary"><Icone nome="search" estilo="fas">Buscar</Icone></Button>{ ' ' }
            <Button className="botao-limpar" type="reset" onClick={ limpaFiltro }>
              <Icone nome="eraser" estilo="fas">Limpar</Icone>
            </Button>{ ' ' }
            <Button type="reset" bsStyle="primary" onClick={ inicializaBuscaEstruturada }>Busca Avançada</Button>
          </Row>
          <Contador total={ total } prefix="Documentos encontrados:"/>
        </Form>

        { open && <ModalBuscaEstruturada/> }

        { openInformacoes && <ModalInformacoes/> }
      </div>
  );
}