// @flow
'use strict';

import React from 'react';
import { useSelector } from 'react-redux';

import { jsSelector } from '../../../../../lib/viewUtils';
import { listaRelatoriosSelector, carregandoSelector } from '../../../../../reducers/modelo_relatorio/modal_lista_relatorios';

import NoDataRow from '../../../../common/no_data_row';
import Icone from '../../../../common/icone';
import LinhaTabela from './linha_tabela';

export default function LinhasTabela() {
  const listaRelatoriosJsSelector = React.useMemo(() => jsSelector(listaRelatoriosSelector, []), []),
        listaRelatorios           = useSelector(listaRelatoriosJsSelector),
        carregando                = useSelector(carregandoSelector);

  if (carregando) {
    return (
        <NoDataRow span={ 5 }>
          <Icone nome="sync-alt" estilo="fas" tamanho={ 2 } spin> Carregando… </Icone>
        </NoDataRow>
    );
  }

  if (listaRelatorios.length === 0)
    return <NoDataRow span={ 5 }> Nenhum relatório encontrado </NoDataRow>;

  return <>{ listaRelatorios.map((u, i) => <LinhaTabela relatorio={ u } key={ i }/>) }</>;
}
