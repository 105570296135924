'use strict';
// @flow

import * as React from 'react';
import moment from 'moment';

import ModulesMenu from '../modules_menu';
import Alerts from '../common/alerts';
import NextToLogo from './next_to_logo';
import TopAreaNavigation from './top_area_navigation';
import UserLinks from './user_links';
import AlertaCertificadoExpirado from '../common/alerta_certificado_expirado';
import type { NavigationTree } from './types';

type AppProps = {
  menu : any,
  topAreaMenu : NavigationTree,
  usuario : { id : string, nome : string },
  ambiente : string,
  url : string,
  possuiCertificadoExpirado : bool,
  children : any,
  location : { pathname : string },
};

export default function BaseLayout(props : AppProps) {
  return (
      <div>
        <AlertaCertificadoExpirado possuiCertificadoExpirado={ props.possuiCertificadoExpirado }/>
        <UserLinks usuario={ props.usuario }/>
        <div className="page-header">
          <div className="clearfix clearfix-top">
            <a href={ props.url } className="logo">
              <div>&nbsp;</div>
            </a>
            <NextToLogo { ...props }/>
          </div>
          <TopAreaNavigation { ...props }/>
        </div>
        <ModulesMenu items={ props.menu }/>
        <section className="contents has-modules-menu">
          <Alerts/>
          { props.children }
          <div className="clearfix"/>
          <footer>
            Ambiente de { props.ambiente } – Sistema Consyst-e – Copyright &copy; 2013-{ moment().year() }
            { ' ' } <a href="https://consyste.com.br/" target="_blank" rel="noopener noreferrer">Consyst-e</a>.
          </footer>
        </section>
      </div>
  );
}
