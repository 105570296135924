'use strict';
// @flow

import _ from 'lodash';
import React from 'react';
import url from 'url';
import { Breadcrumb as RBBreadcrumb, Panel } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import type { IRoute } from 'lib/types';

/**
 * Filtro a ser aplicado nas rotas, para decidir quais devem ser consideradas para efeitos de título e breadcrumbs.
 */
function routeFilter(r : IRoute) {
  return r.path && (r.title || r.name) && !r.modal;
}

/**
 * Apresenta o título da tela atual, baseado nas rotas.
 */
export function Title({ routes } : { routes : IRoute[] }) {
  const currRoute = _.findLast(routes, routeFilter) || {},
        title     = currRoute.title || currRoute.name,
        subtitle  = currRoute.subtitle && <p>
          <small>{ currRoute.subtitle }</small>
        </p>;

  return (
      <h1 className="screen-title">
        { title }
        { subtitle }
      </h1>
  );
}

/**
 * Apresenta os breadcrumbs com as rotas utilizadas.
 */
export function Breadcrumbs({ routes } : { routes : IRoute[] }) {
  const allRoutes = routes;
  routes = routes.filter(routeFilter);

  const currRoute = _(routes).last();

  return (
      <RBBreadcrumb>
        { routes.map((item, index) =>
            <Breadcrumb key={ index } item={ item } current={ item === currRoute } allRoutes={ allRoutes }/>,
        ) }
      </RBBreadcrumb>
  );
}

/**
 * Apresenta um único breadcrumb (link).
 */
export function Breadcrumb({ item, current, allRoutes } : { item : IRoute, current : bool, allRoutes : IRoute[] }) {
  let path;
  if (current)
    path = location.pathname;
  else {
    path = item.path || '';
    let parentIndex = _.findIndex(allRoutes, item) - 1;

    while (parentIndex >= 0) {
      const parent = allRoutes[parentIndex];

      let parentPath = parent.path;
      if (parentPath) {
        if (!parentPath.endsWith('/'))
          parentPath += '/';
        path = url.resolve(parentPath, path);
      }

      parentIndex--;
    }
  }

  return (
      <LinkContainer to={ path } onlyActiveOnIndex>
        <RBBreadcrumb.Item>{ item.name }</RBBreadcrumb.Item>
      </LinkContainer>
  );
}

export function Erro({ error } : { error : Error }) {
  return (
      <Panel bsStyle="danger">
        <Panel.Heading>Erro ao montar tela</Panel.Heading>
        <Panel.Body>
          <p>Nossa equipe já recebeu uma notificação do problema. Se persistir, favor entrar em contato com nosso
            suporte.</p>
          <fieldset>
            <legend>Detalhes</legend>
            <pre>{ error.stack }</pre>
          </fieldset>
        </Panel.Body>
      </Panel>
  );
}