'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, Dispatch, State } from 'lib/types';
import request from 'lib/request';
import { ajaxError } from '../alerts';

export const ABRE_HISTORICO  = 'cte/historico/ABRE_HISTORICO',
             FECHA_HISTORICO = 'cte/historico/FECHA_HISTORICO';

const INITIAL_STATE = Immutable.fromJS({
  carregando: false,
  item: {},
  modalOpen: false,
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case ABRE_HISTORICO:
      return state.set('item', action.item).set('modalOpen', true);
    case FECHA_HISTORICO:
      return state = INITIAL_STATE;
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['cte', 'historico'], Immutable.Map());
export const itemSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('item'));
export const modalOpenSelector = createSelector<*, *, *, *>(rootSelector, root => root.get('modalOpen'));

// actions

export function fechaHistorico() {
  return { type: FECHA_HISTORICO };
}

// thunk actions

export function abreHistorico(id : string) {
  return async function(dispatch : Dispatch<any>) {
    await request.get(`/app/cte/${ id }/historico`)
        .accept('json')
        .then(r => dispatch({ type: ABRE_HISTORICO, item: Immutable.fromJS(r.body) }))
        .catch(e => {
          dispatch(ajaxError('Erro ao carregar histórico da CT-e', e));
        });
  };
}
