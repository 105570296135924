// @flow
'use strict';

import * as React from 'react';
// eslint-disable-next-line import/no-unresolved
import useSWR from 'swr';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { limpaFiltro } from '../../../reducers/cte/filtro_livre';
import { urlSelector } from '../../../reducers/cte/lista';
import { modalOpenSelector, inicializaBuscaEstruturada } from '../../../reducers/cte/filtro_estruturado';
import { openModalSelector, abreModal } from '../../../reducers/modal';
import { fetcher } from '../../../lib/api/fetch';

import Icone from '../../common/icone';
import Contador from '../../common/contador';
import CampoTexto from '../../common/campo_texto';
import ModalInformacoes from '../../common/modal_informacoes';
import ModalBuscaEstruturada from '../../cte/formulario/modal_busca_estruturada';
import { Input } from '../../common/input';

type BuscaBasicaProps = {
  handleSubmit : () => void,
};

export default function CamposBusca({ handleSubmit } : BuscaBasicaProps) {
  const open            = useSelector(modalOpenSelector),
        openInformacoes = useSelector(openModalSelector),
        url             = useSelector(urlSelector),
        dispatch        = useDispatch();

  const { data }  = useSWR(url, fetcher),
        { total, suporte } = data || {};

  const limparFiltro                = React.useCallback(() => dispatch(limpaFiltro()), [dispatch]),
        inicializarBuscaEstruturada = React.useCallback(() => dispatch(inicializaBuscaEstruturada()), [dispatch]),
        abrirModalInformacoes       = React.useCallback(() => dispatch(abreModal()), [dispatch]);

  return (
      <>
        <Form onSubmit={ handleSubmit }>
          <div className="form-group no-margin-bottom">
            <Row>
              <Col md={ 7 }>
                <div className="input-group">
                  <span className="input-group-addon">
                    <Button className="botao-informacoes no-padding" bsStyle="link" onClick={ abrirModalInformacoes }>
                      <Icone nome="question-circle" estilo="fas"/>
                    </Button>
                  </span>
                  <Input name="q" label="Livre" placeholder="Buscar" component={ CampoTexto }/>
                  <div className="input-group-btn">
                    <Button type="submit" bsStyle="primary">
                      <Icone nome="search" estilo="fas">Buscar</Icone>
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md={ 5 }>
                <Button type="reset" onClick={ limparFiltro }>
                  <Icone nome="eraser" estilo="fas">Limpar</Icone>
                </Button>{ ' ' }
                <Button type="reset" bsStyle="primary" onClick={ inicializarBuscaEstruturada }>Busca Avançada</Button>
              </Col>
            </Row>
          </div>
          <Contador total={ total } prefix="Documentos encontrados:"/>
        </Form>

        { open && <ModalBuscaEstruturada suporte={ suporte }/> }

        { openInformacoes && <ModalInformacoes/> }
      </>
  );
}
