// @flow
'use strict';

import * as React from 'react';

import type { IIcmsSimplesNacional } from '../../../../../types_imposto';

import OpcoesIcmsSimplesNacional from './opcoes_icms_simples_nacional';

type IcmsSimplesNacionalProps = {
  item : IIcmsSimplesNacional,
};

export default function IcmsSimplesNacional({ item } : IcmsSimplesNacionalProps) {
  const opcoesIcmsSimplesNacional = [];

  Object.keys(item).forEach((i : string) => {
    const Opcao = OpcoesIcmsSimplesNacional[i.replace('icms', 'Icms')];
    if (Opcao)
      opcoesIcmsSimplesNacional.push(<Opcao key={ i } item={ item[i] }/>);
  });

  return (
      <div id="table-icms-simples-nacional">
        <h4>ICMS Tributado pelo Simples Nacional</h4>
        { opcoesIcmsSimplesNacional }
      </div>
  );
}
