// @flow
'use strict';

import React from 'react';

import type { IDocumento } from '../../../../lib/api';
import { textoDescricao } from '../../../common/descricao';

import CampoDetalhes from './campo_detalhes';

type InformacoesSituacaoDocumentoProps = {
  documento : IDocumento
}

export default function InformacoesSituacaoDocumento({ documento } : InformacoesSituacaoDocumentoProps) {
  const ultimaConsulta = documento.portaria.ultima ? documento.portaria.ultima : '',
        situacaoArquivo = ultimaConsulta && ultimaConsulta.situacaoArquivo ? ultimaConsulta.situacaoArquivo : '',
        situacaoSefaz = ultimaConsulta && ultimaConsulta.sefaz ? ultimaConsulta.sefaz.label : '',
        custodiado = ultimaConsulta.custodiado ? 'Sim' : 'Não';

  return (
      <div>
        <CampoDetalhes label="Custodiado" texto={ custodiado }/>
        <CampoDetalhes label="Situação do Arquivo"
                       texto={ textoDescricao('situacao_arquivo', situacaoArquivo) }/>
        <CampoDetalhes label="Situação SEFAZ" texto={ situacaoSefaz }/>
      </div>
  );
}