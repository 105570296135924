// @flow
'use strict';

import * as React from 'react';

import type { IIcms20 } from '../../../../../../types_imposto';

import RowDetalhes from '../../../../../../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../../../../../../common/detalhes_documento/column_detalhes';

type Icms20Props = {
  item : IIcms20,
};

export default function Icms20({ item } : Icms20Props) {
  return (
      <div>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="origem" width="25%">Origem da Mercadoria</ColumnDetalhes>
          <ColumnDetalhes field="cst" width="25%">CST</ColumnDetalhes>
          <ColumnDetalhes field="determinacaoBcIcms" width="25%">Determinação da BC</ColumnDetalhes>
          <ColumnDetalhes field="percentualReducaoBc" type="percentual" width="25%">Redução BC</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="valorBcIcms" type="moeda" width="25%">Base de Cálculo</ColumnDetalhes>
          <ColumnDetalhes field="aliquotaIcms" type="percentual" width="25%">Alíquota</ColumnDetalhes>
          <ColumnDetalhes field="valorIcms" type="moeda" width="25%">Valor do ICMS</ColumnDetalhes>
          <ColumnDetalhes field="valorBcFcp" type="moeda" width="25%">Base de Cálculo FCP</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="percentualFcp" type="percentual" width="25%">Percentual FCP</ColumnDetalhes>
          <ColumnDetalhes field="valorFcp" type="moeda" width="25%">Valor FCP</ColumnDetalhes>
          <ColumnDetalhes field="valorIcmsDesonerado" type="moeda" width="25%">ICMS Desonerado</ColumnDetalhes>
          <ColumnDetalhes field="motivoDesoneracaoIcms" width="25%">Motivo Desoneração</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
