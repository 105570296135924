// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { rootSelector } from '../../../../../reducers/modelo_relatorio/modal_lista_relatorios';

import ModalListaRelatorios from './modal_lista_relatorios';

const mapStateToProps = createStructuredSelector({
  initialValues: rootSelector,
});

export default connect<*, *, *, *, *, *>(mapStateToProps)(ModalListaRelatorios);
