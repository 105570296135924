// @flow
'use strict';

import * as Immutable from 'immutable';
import moment from 'moment';

import esSped from './es-sped-properties.json';

export function buscaEstruturadaParaBuscaLivre(params : Immutable.Map<*, *>) : string {
  return Immutable.Map(params)
      .reduce(reduzIntervalos, Immutable.Map()).entrySeq() // agrupa intervalos pelo nome do campo
      .sortBy(([k]) => k)                                  // ordena pelo campo, para tornar determinístico (e testável)
      .map(([k, v]) => montaClausula(k, v))                // monta as cláusulas do ES
      .join(' ');                                          // junta em uma string
}

function montaClausula(k, v) {
  return `${ campoParaPrefixo(k) }: ${ condicaoParaString(k, v) }`;
}

/**
 * Converte um nome de campo para um prefixo de busca do ES.
 */
function campoParaPrefixo(campo) {
  if (esSped[campo] && 'properties' in esSped[campo])
    return `${ campo }.\\*`;

  return campo;
}

/**
 * Formata um valor para ser utilizado na string de busca do ES.
 *
 * Exemplos:
 *   condicaoParaString('campo', 1) => '1'
 *   condicaoParaString('campo', 'com espaço') => '(com espaço)'
 *   condicaoParaString('campo', 'nao') => 'nao'
 *   condicaoParaString('campo_booleano', 'nao') => 'false'
 *   condicaoParaString('campo', { de: 1 }) => '[1 TO *]'
 *   condicaoParaString('campo', { de: 1, ate: 2 }) => '[1 TO 2]'
 */
function condicaoParaString(k, v : any | Immutable.Map<*, *>) : string {
  // O ElasticSearch trata os seguintes valores como "falso", quando pesquisando em um campo booleano:
  //   false, "false", "off", "no", "0", "" (empty string), 0, 0.0
  // Todos os outros valores são considerados "verdadeiro".
  //   ( refer￿ência: https://www.elastic.co/guide/en/elasticsearch/reference/current/boolean.html )
  // Como queremos tratar o valor "nao" como falso, precisamos faz￿ê-lo aqui.
  if (esSped[k] && esSped[k].type === 'boolean' && v === 'nao')
    v = 'no';

  if (v instanceof Immutable.Map && (v.has('de') || v.has('ate'))) {
    const de  = valorParaString(v.get('de')) || '*',
          ate = valorParaString(v.get('ate')) || '*';
    return `[${ de } TO ${ ate }]`;
  }

  let s = String(valorParaString(v));
  if (s.indexOf(' ') !== -1)
    s = `(${ s })`;

  return s;
}

function valorParaString(v : mixed) : any {
  if (v instanceof moment)
    return v.format('YYYY-MM-DD');

  return v;
}

/**
 * Função pronta para utilizar no método "reduce". Ela preenche o Immutable.Map com o par informado (valor + chave).
 * Porém, se a chave terminar com "_de" ou "_ate", armazena em um novo Immutable.Map.
 *
 * Exemplos:
 *   reduzIntervalos({}, '', "teste") => {}
 *   reduzIntervalos({}, false, "teste") => { teste: false }
 *   reduzIntervalos({}, 123, "teste") => { teste: 123 }
 *   reduzIntervalos({}, 123, "teste_a") => { teste_a: 123 }
 *   reduzIntervalos({}, 123, "teste_de") => { teste: { de: 123 } }
 *   reduzIntervalos({ teste: { de: 123 } }, 456, "teste_ate") => { teste: { de: 123, ate: 456 } }
 */
function reduzIntervalos(r : Immutable.Map<*, *>, valor, chave) : Immutable.Map<*, *> {
  if (!valor && valor !== false && valor !== 0)
    return r; // ignora campos em branco

  const [, campo, sufixo] = /(.*)_(de|ate)$/.exec(chave) || [];
  if (campo && sufixo)
    return r.update(campo, Immutable.Map(), y => y.set(sufixo, valor));
  else
    return r.set(chave, valor);
}