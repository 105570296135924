'use strict';

// Código copiado descaradamente de https://github.com/koenpunt/superagent-use
// Motivo: o componente original mantinha uma dependência rígida no superagent 1.0, e já estamos no 2.0.

import _ from 'lodash';
import _superagent from 'superagent';

// contador global de requisições, para uso em testes (similar ao jQuery.active)
if (!global.activeRequests)
  global.activeRequests = [];

function counter(request) {
  request.once('request', () => global.activeRequests.push(request.url));
  request.once('end', () => global.activeRequests = _.without(global.activeRequests, request.url));
}

// sobrescrevendo os principais métodos do superagent para incluir nosso middleware contador de requisições
const superagent = Object.assign({}, _superagent);

['get', 'post', 'put', 'patch', 'del', 'head', 'options'].forEach(function(method) {
  superagent[method] = function() {
    // eslint-disable-next-line prefer-rest-params
    return _superagent[method].apply(superagent, arguments).use(counter);
  }
});

export default superagent;