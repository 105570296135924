// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosCte from '../form_exportacao_cte/campos_cte/selecao_todos_cte';
import SelecaoTodosNfe from '../form_exportacao_nfe/campos_nfe/selecao_todos_nfe';
import { Input } from '../../../../../common/input';

type CamposTransacoesConsysteProps = {
  component : React.ComponentType<*>,
}

export default function CamposTransacoesConsyste({ component } : CamposTransacoesConsysteProps) {
  const SelecaoTodos = component || SelecaoTodosCte;
  const tipoNfe = (SelecaoTodos === SelecaoTodosNfe);

  return (
      <FormSection name="transacoesConsyste">
        <SelecaoTodos nomes={ ["transacoesConsyste"] } titulo="Transações no Consyste"/>

        <div className="grupo-checkbox">
          <Input name="recebidoEm" label="Data do Recebimento" checkbox/>
          { tipoNfe && <Input name="dataDecisaoPortaria" label="Data Decisão Portaria" checkbox/>}
          { tipoNfe && <Input name="decisaoPortariaDescricao" label="Decisão Portaria" checkbox/>}
          <Input name="custodiado" label="Situação da Custódia" checkbox/>
          <Input name="situacaoArquivo" label="Situação do Arquivo" checkbox/>
        </div>
      </FormSection>
  );
}