// @flow
'use strict';

import * as React from 'react';

import FormExportacao from '../form_exportacao';
import CamposNfe from './campos_nfe';

type FormProps = {
  handleSubmit : () => void,
}

export default function FormExportacaoNfe(props : FormProps) {
  return (
      <FormExportacao { ...props } tipo="nfe">
        <CamposNfe/>
      </FormExportacao>
  );
}