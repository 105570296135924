// @flow
'use strict';

import * as React from 'react';

import type { ITotais } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';
import TableDetalhesComLinhas from '../../../common/detalhes_documento/table_detalhes_com_linhas';

type TotaisProps = {
  totais : ITotais,
};

export default function Totais({ totais } : TotaisProps) {
  const { icms, issqn, retencaoTributos } = totais || {};

  return (
      <div className="tab-pane active">
        <h3>Totais Referentes ao ICMS</h3>
        <TableDetalhesComLinhas data={ icms }>
          <tr>
            <ColumnDetalhes field="bcIcms" type="moeda" width="25%">BC ICMS</ColumnDetalhes>
            <ColumnDetalhes field="icms" type="moeda" width="25%">ICMS</ColumnDetalhes>
            <ColumnDetalhes field="icmsDesonerado" type="moeda" width="25%">ICMS Desonerado</ColumnDetalhes>
            <ColumnDetalhes field="icmsFcpUFDest" type="moeda" width="25%">ICMS FCP UF Dest.</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="icmsInterestUFDest" type="moeda">ICMS Interest. UF Dest.</ColumnDetalhes>
            <ColumnDetalhes field="icmsInterestUFRemet" type="moeda">ICMS Interest. UF
              Remet.</ColumnDetalhes>
            <ColumnDetalhes field="fcp" type="moeda">FCP</ColumnDetalhes>
            <ColumnDetalhes field="bcIcmsSt" type="moeda">BC ICMS ST</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="icmsSt" type="moeda">ICMS ST</ColumnDetalhes>
            <ColumnDetalhes field="fcpRetidoSt" type="moeda">FCP retido ST</ColumnDetalhes>
            <ColumnDetalhes field="fcpRetidoAntSt" type="moeda">FCP retido ant. ST</ColumnDetalhes>
            <ColumnDetalhes field="valorProdServ" type="moeda">Valor Produtos e Serviços</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="frete" type="moeda">Frete</ColumnDetalhes>
            <ColumnDetalhes field="seguro" type="moeda">Seguro</ColumnDetalhes>
            <ColumnDetalhes field="desconto" type="moeda">Desconto</ColumnDetalhes>
            <ColumnDetalhes field="ii" type="moeda">II</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="ipi" type="moeda">IPI</ColumnDetalhes>
            <ColumnDetalhes field="ipiDevolvido" type="moeda">IPI Devolvido</ColumnDetalhes>
            <ColumnDetalhes field="pis" type="moeda">PIS</ColumnDetalhes>
            <ColumnDetalhes field="cofins" type="moeda">COFINS</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="outrasDespesas" type="moeda">Outras Despesas</ColumnDetalhes>
            <ColumnDetalhes field="totalNfe" type="moeda">Total da NF-e</ColumnDetalhes>
            <ColumnDetalhes field="totalAproxTributos" type="moeda" colSpan={ 2 }>Total Aprox. de
              Tributos</ColumnDetalhes>
          </tr>
        </TableDetalhesComLinhas>

        <h3>Totais Referentes ao ISSQN</h3>
        <TableDetalhesComLinhas data={ issqn }>
          <tr>
            <ColumnDetalhes field="valorServicos" type="moeda" width="25%">Valor dos Serviços</ColumnDetalhes>
            <ColumnDetalhes field="bcIss" type="moeda" width="25%">BC ISS</ColumnDetalhes>
            <ColumnDetalhes field="iss" type="moeda" width="25%">ISS</ColumnDetalhes>
            <ColumnDetalhes field="pis" type="moeda" width="25%">PIS</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="cofins" type="moeda">COFINS</ColumnDetalhes>
            <ColumnDetalhes field="dataPrestServico" type="data">Data Prest. Serviço</ColumnDetalhes>
            <ColumnDetalhes field="deducaoReducaoBc" type="moeda">Dedução Redução BC</ColumnDetalhes>
            <ColumnDetalhes field="outrasRetencoes" type="moeda">Outras Retenções</ColumnDetalhes>
          </tr>
          <tr>
            <ColumnDetalhes field="descontoIncod" type="moeda">Desconto Incond.</ColumnDetalhes>
            <ColumnDetalhes field="descontoCond" type="moeda">Desconto Cond.</ColumnDetalhes>
            <ColumnDetalhes field="retencaoIss" type="moeda">Retenção ISS</ColumnDetalhes>
            <ColumnDetalhes field="codRegimeEspTrib" type="moeda">Cód. Regime Esp. Trib.</ColumnDetalhes>
          </tr>
        </TableDetalhesComLinhas>

        <h3>Totais Retenção de Tributos</h3>
        <RowDetalhes data={ retencaoTributos } className="no-margin-bottom">
          <ColumnDetalhes field="valorRetidoPis" type="moeda" width="25%">Valor Retido PIS</ColumnDetalhes>
          <ColumnDetalhes field="valorRetidoCofins" type="moeda" width="25%">Valor Retido COFINS</ColumnDetalhes>
          <ColumnDetalhes field="valorRetidoCsll" type="moeda" width="25%">Valor Retido CSLL</ColumnDetalhes>
          <ColumnDetalhes field="bcIrrf" type="moeda" width="25%">BC do IRRF</ColumnDetalhes>
        </RowDetalhes>
        <RowDetalhes data={ retencaoTributos }>
          <ColumnDetalhes field="valorRetidoIrrf" type="moeda" width="25%">Valor Retido IRRF</ColumnDetalhes>
          <ColumnDetalhes field="bcRetencaoPrevSocial" type="moeda" width="25%">BC Retenção Prev.
            Social</ColumnDetalhes>
          <ColumnDetalhes field="valorRetencaoPrevSocial" type="moeda" width="50%">Valor Retenção Prev.
            Social</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
