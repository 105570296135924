// @flow

import { combineReducers } from 'redux-immutable';

import lista from './lista';
import detalhes from './detalhes';
import historico from './historico';
import filtroLivre from './filtro_livre';
import resumoLista from './resumo_lista';
import filtroEstruturado from './filtro_estruturado';
import modalExportacaoLote from './modal_exportacao_lote';

export default combineReducers({
  lista, detalhes, historico, filtroLivre, resumoLista, filtroEstruturado, modalExportacaoLote
});
