// @flow
'use strict';

import * as React from 'react';

import type { ItemProps } from '../types';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';

export default function Servico({ item } : ItemProps) {
  return (
      <div className="tab-pane active">
        <h3>Serviço</h3>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="discriminacao" width="80%">Discriminação do serviço</ColumnDetalhes>
          <ColumnDetalhes field="valor" type="moeda" width="20%">Valor do serviço</ColumnDetalhes>
        </RowDetalhes>

        <RowDetalhes data={ item }>
          <ColumnDetalhes field="municipioPrestacao" width="40%">Município de prestação</ColumnDetalhes>
          <ColumnDetalhes field="tributacao" type="tributacao" width="40%">Tributação</ColumnDetalhes>
          <ColumnDetalhes field="valorDeducoes" type="moeda" width="20%">Valor Deduções</ColumnDetalhes>
        </RowDetalhes>

        <RowDetalhes data={ item }>
          <ColumnDetalhes field="servicoCodigo" width="20%">Código do Serviço</ColumnDetalhes>
          <ColumnDetalhes field="aliquota" type="percentual" width="20%">Alíquota Serviços</ColumnDetalhes>
          <ColumnDetalhes field="valorIss" type="moeda" width="20%">Valor ISS</ColumnDetalhes>
          <ColumnDetalhes field="valorCredito" type="moeda" width="20%">Valor Crédito</ColumnDetalhes>
          <ColumnDetalhes field="issRetido" width="20%">ISS Retido</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}