// @flow
'use strict';

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, Dispatch, State } from '../lib/types';

const ABRE_MODAL   = 'modal_exportacao_lista/ABRE_MODAL',
      FECHA_MODAL  = 'modal_exportacao_lista/FECHA_MODAL',
      EXIBE_ALERTA = 'modal_exportacao_lista/EXIBE_ALERTA',
      FECHA_ALERTA = 'modal_exportacao_lista/FECHA_ALERTA';

const DEFAULT_STATE = Immutable.fromJS({
  open: false,
  alertas: '',
});

export default function reducer(state : Immutable.Map = DEFAULT_STATE, action : Action = {}) {
  switch (action.type) {
    case ABRE_MODAL:
      return state.set('open', true);
    case FECHA_MODAL:
      return state.set('open', false);
    case EXIBE_ALERTA:
      return state.set('alertas', action.alertas);
    case FECHA_ALERTA:
      return state.set('alertas', '');
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['modalExportacaoLista'], Immutable.Map());
export const openModalSelector = (state : State) => state.getIn(['modalExportacaoLista', 'open'], false);
export const alertasSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('alertas'));

// actions

function abrirModal() {
  return { type: ABRE_MODAL };
}

function fecharModal() {
  return { type: FECHA_MODAL };
}

export function exibeAlertas(alertas : string[]) {
  return { type: EXIBE_ALERTA, alertas };
}

export function fechaAlertas() {
  return { type: FECHA_ALERTA };
}

// thunk actions

export function abreModal(tipo : string, query : any) {
  return async function(dispatch : Dispatch<*>) {
    await dispatch({ type: `lista_documentos/${ tipo }/INICIALIZA_MODAL`, query });
    dispatch(abrirModal());
  };
}

export function fechaModal() {
  return function(dispatch : Dispatch<*>) {
    dispatch(fecharModal());
  };
}