// @flow
'use strict';

import * as React from 'react';

import type { IImposto } from '../../../../types_imposto';

import ColumnDetalhes from '../../../../../../common/detalhes_documento/column_detalhes';
import RowDetalhes from '../../../../../../common/detalhes_documento/row_detalhes';
import Issqn from './issqn';
import Icms from './icms';
import Pis from './pis';
import Cofins from './cofins';

type ImpostoProps = {
  item : IImposto,
};

export default function Imposto({ item } : ImpostoProps) {
  if (!item)
    return <div/>;

  return (
      <div id="table-imposto">
        <h3>Tributos Incidentes</h3>
        <RowDetalhes data={ item }>
          <ColumnDetalhes field="valorTotalTributos" type="moeda">Valor Aproximado Total de Tributos</ColumnDetalhes>
        </RowDetalhes>

        { item.issqn && <Issqn item={ item.issqn }/> }
        { item.icms && <Icms item={ item.icms }/> }
        { item.pis && <Pis item={ item.pis }/> }
        { item.cofins && <Cofins item={ item.cofins }/> }
      </div>
  );
}