// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { jsSelector } from 'lib/viewUtils';

import { aplicaFiltro, filtroSelector, limpaFiltro, mudaFiltro } from 'reducers/ferramentas/prestador/lista';
import { contasSelector } from 'reducers/ferramentas/prestador/detalhes';

import CamposBusca from './campos_busca';

const mapStateToProps = createStructuredSelector({
  filtros: jsSelector(filtroSelector),
  contas: jsSelector(contasSelector, []),
});

export default connect<*, *, *, *, *, *>(mapStateToProps, { mudaFiltro, limpaFiltro, aplicaFiltro })(CamposBusca);
