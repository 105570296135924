'use strict';
// @flow

import * as React from 'react';
import { IndexRedirect, IndexRoute, Route } from 'react-router';

import BaseApp from 'components/common/base_app';
import { dynRoute } from 'lib/viewUtils';

export default (
    <Route name="Administração" path="app/admin" component={ BaseApp }>
      <IndexRedirect to="empresas"/>
      <Route name="Empresas" path="empresas">
        <IndexRoute name="Empresas" getComponent={ dynRoute(() => import('./empresas/lista_empresas')) }/>
        <Route name="Nova Empresa" path="new" getComponent={ dynRoute(() => import('./empresas/formulario')) }/>
        <Route name="Visualizando Empresa" path=":id" getComponent={ dynRoute(() => import('./empresas/detalhes')) }/>
        <Route name="Editando Empresa" path=":id/edit" getComponent={ dynRoute(() => import('./empresas/formulario')) }/>
      </Route>

      <Route name="Certificados Digitais" path="certificados_digitais">
        <IndexRoute name="Certificados Digitais" getComponent={ dynRoute(() => import('./certificados_digitais/lista_certificados_digitais')) }/>
        <Route name="Visualizando Certificado Digital" path=":id" getComponent={ dynRoute(() => import('./certificados_digitais/detalhes')) }/>
      </Route>

      <Route name="Usuários" path="users" component={() => {
        window.location.href = '/app/admin/users';
        return null;
      }}/>

      <Route name="Contas" path="contas" component={() => {
        window.location.href = '/app/admin/contas';
        return null;
      }}/>
    </Route>
);
