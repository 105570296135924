// @flow
'use strict';

import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormSection } from 'redux-form/immutable';

import CampoLido from '../../../../common/campos_busca_estruturada/campo_lido';
import CampoSituacaoArquivo from '../../../../common/campos_busca_estruturada/campo_situacao_arquivo';
import CampoCustodiado from './campo_custodiado';
import CampoSituacaoSefaz from '../../../../common/campos_busca_estruturada/campo_situacao_sefaz';
import { CAMPOS_SITUACAO_SEFAZ_NFE } from '../../../constantes';

export default function CamposSituacoes() {
  return (
      <>
        <fieldset>
          <legend>Situações</legend>
        </fieldset>
        <Row>
          <Col sm={ 6 }>
            <FormSection name="lido">
              <CampoLido/>
            </FormSection>

            <FormSection name="situacao_arquivo">
              <CampoSituacaoArquivo/>
            </FormSection>
          </Col>
          <Col sm={ 6 }>
            <FormSection name="custodiado">
              <CampoCustodiado/>
            </FormSection>

            <FormSection name="situacao_sefaz">
              <CampoSituacaoSefaz campos={ CAMPOS_SITUACAO_SEFAZ_NFE }/>
            </FormSection>
          </Col>
        </Row>
      </>
  );
}
