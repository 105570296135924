'use strict';
// @flow

import React from 'react';

import { formatHelper } from 'lib/viewUtils';

type ContadorProps = {
  total? : ?number,
  prefix : any,
};

/**
 * Contador de registros.
 */
export default function Contador({ total, prefix } : ContadorProps) {
  if (!total && total !== 0)
    return <p className="search-results">Aguarde, consultando…</p>;

  return <p className="search-results">
    { prefix } {' '}
    <strong>{ formatHelper.numberWithDelimiter(total) }</strong>
  </p>;
}
