'use strict';
// @flow

import _ from 'lodash';
import React from 'react';
import { Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { autobind } from 'core-decorators';

import { arquivoNav } from '../arquivo_path';

import BaseComponent from 'lib/base_component';
import DatePicker from 'components/common/date_picker';
import Opcoes from './opcoes';
import Icone from 'components/common/icone';

const EMPTY_STATE = { data_inicial_de: '', data_inicial_ate: '', data_upload_de: '', data_upload_ate: '', empresa: '' };

type FiltrosProps = {
  location : { query : {} },
  carregaLista : (params : {}) => void,
  ignoraCarregaListaInicial? : bool,
  lastQuery? : any,
};

type FiltrosState = {
  data_inicial_de : ?string, data_inicial_ate : ?string,
  data_upload_de : ?string, data_upload_ate : ?string,
  empresa : ?string, page? : ?number | string,
};

export default class Filtros extends BaseComponent<FiltrosProps, FiltrosState> {
  props : FiltrosProps;
  state : FiltrosState = EMPTY_STATE;

  UNSAFE_componentWillMount() {
    const q = this.props.lastQuery || _.get(this.props, 'location.query') || {};
    const query = {
      data_inicial_de: q.data_inicial_de || q.de || '',
      data_inicial_ate: q.data_inicial_ate || q.ate || '',
      data_upload_de: q.data_upload_de || '',
      data_upload_ate: q.data_upload_ate || '',
      empresa: q.empresa || '',
      page: q.page || null,
    };

    this.setState(query);

    if (!this.props.ignoraCarregaListaInicial)
      this.props.carregaLista(query);
  }

  @autobind
  handleFormSubmit(e? : SyntheticEvent<*>) {
    e && e.preventDefault();

    const query = { ...this.state, page: undefined };
    // aplica os fitros, e exclui o parâmetro de paginação
    arquivoNav.listagem(query);
    this.props.carregaLista(query);
  }

  @autobind
  handleFormReset(e : SyntheticEvent<*>) {
    e.preventDefault();

    this.setState(EMPTY_STATE, () => this.handleFormSubmit());
  }

  render() {
    const { empresa, data_inicial_de, data_inicial_ate, data_upload_de, data_upload_ate } = this.state;

    return (
        <div>
          <form method="post" role="search" onSubmit={ this.ignoreEvent }>
            <nav className="navbar navbar-default">
              <div className="container-fluid">
                <div className="navbar-header"><a className="navbar-brand">Filtros</a></div>
                <div className="navbar-form navbar-left">
                  <FormGroup controlId="empresa">
                    <ControlLabel srOnly>Empresa</ControlLabel>
                    <FormControl name="empresa" placeholder="Empresa" type="text" size="18"
                                 value={ empresa } onChange={ this.handleChangeFor('empresa') }/>
                  </FormGroup> { ' ' }
                  <FormGroup>
                    <ControlLabel srOnly>Data do SPED de</ControlLabel>
                    <DatePicker value={ data_inicial_de } placeholder="SPED de"
                                onChange={ this.handleSelectFor('data_inicial_de') }/>
                  </FormGroup> { ' ' }
                  <FormGroup>
                    <ControlLabel srOnly>Data do SPED até</ControlLabel>
                    <DatePicker value={ data_inicial_ate } placeholder="SPED até"
                                onChange={ this.handleSelectFor('data_inicial_ate') }/>
                  </FormGroup> { ' ' }
                  <FormGroup>
                    <ControlLabel srOnly>Upload de</ControlLabel>
                    <DatePicker value={ data_upload_de } placeholder="Upload de"
                                onChange={ this.handleSelectFor('data_upload_de') }/>
                  </FormGroup> { ' ' }
                  <FormGroup>
                    <ControlLabel srOnly>Upload até</ControlLabel>
                    <DatePicker value={ data_upload_ate } placeholder="Upload até"
                                onChange={ this.handleSelectFor('data_upload_ate') }/>
                  </FormGroup> { ' ' }
                  <Button type="reset" bsStyle="default" onClick={ this.handleFormReset }>
                    <Icone nome="eraser" estilo="fas">Limpar</Icone>
                  </Button> { ' ' }
                  <Button type="submit" bsStyle="primary" onClick={ this.handleFormSubmit }>
                    <Icone nome="filter" estilo="far">Filtrar</Icone>
                  </Button>
                </div>
                <div className="navbar-form navbar-right">
                  <Opcoes/>
                </div>
              </div>
            </nav>
          </form>
        </div>
    );
  }
}