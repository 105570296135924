// @flow
'use strict';

import * as React from 'react';

import type { IDestinatario } from '../types';
import { tipoDocumento } from './abas_informacoes';

import RowDetalhes from '../../../common/detalhes_documento/row_detalhes';
import ColumnDetalhes from '../../../common/detalhes_documento/column_detalhes';

type DestinatarioProps = {
  destinatario : IDestinatario,
};

export default function Destinatario({ destinatario } : DestinatarioProps) {
  return (
      <div className="tab-pane active">
        <h3>Destinatário</h3>
        <RowDetalhes data={ destinatario } className="no-margin-bottom">
          <ColumnDetalhes field="nome" width="50%">Nome / Razão Social</ColumnDetalhes>
          <ColumnDetalhes field="cnpj" type="documento" width="50%">{ tipoDocumento(destinatario) }</ColumnDetalhes>
        </RowDetalhes>
      </div>
  );
}
